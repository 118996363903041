import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNewUrl } from "reduxConfig/slices/urlSlice";
import { setResultType } from "reduxConfig/slices/urlSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";

function ResultTypeSelect() {
  const dispatch = useDispatch();
  const defaultData = useSelector((state) => state.urlsData);

  if (defaultData.choosenType === "WHITELISTED") {
    var resultType = ["APK", "DOMAIN"];
  } else {
    var resultType = ["APK", "DOMAIN"];
  }

  return (
    <div>
      <label
        for="countries"
        class="mt-3 mb-1 flex items-center font-semibold text-[#000000] dark:text-white"
      >
        {"Choose Result Type:"}
        <div className="ml-1 text-lg font-normal">
          <a
            data-tooltip-id="my-tooltip-URLs"
            data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the result type."
            data-tooltip-place="right"
          >
            <BsQuestion
              className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
              color="#4318FF"
            />
          </a>
          <Tooltip className="z-10" id="my-tooltip-URLs" />
        </div>
      </label>
      <select
        id="countries"
        class="block w-full  border border-[#000000] bg-white p-2.5 text-sm text-[#000000] focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        onChange={(e) => {
          dispatch(setResultType(e.target.value));
          dispatch(
            updateNewUrl({
              chooseResultType: e.target.value,
            })
          );
        }}
        // value={defaultData.chooseResultType}
      >
        {defaultData.choosenType === "NONE" ? (
          <></>
        ) : (
          resultType.map((element) => (
            <option value={element}>{element}</option>
          ))
        )}
        <option value="UNKNOWN">UNKNOWN</option>
      </select>
    </div>
  );
}

export default ResultTypeSelect;
