import { useEffect, useState, useRef } from "react";
import apiCall from "axiosConfig/axiosConfig";
import CreateBrand from "./components/createBrand";
import { useDispatch } from "react-redux";
import { getBrandData, CreateBrandToggle } from "reduxConfig/slices/brandSlice";
import { useSelector } from "react-redux";
import BrandCards from "./components/BrandCard";
import EditBrandModal from "./components/EditBrandModal";
import { RingLoader } from "react-spinners";
import PaginationComp from "./components/paginationComp";
import InternalNav from "./components/InternalNav";
import CreateClient from "./components/CreateClient";
import { toggleClientCreateModal } from "reduxConfig/slices/brandSlice";
import { getClientData } from "reduxConfig/slices/brandSlice";
import ClientCard from "./components/ClientCard";
import EditClient from "./components/EditClient";
import ClientDeleteModal from "./components/ClientDeleteModal";
import BrandDeleteModal from "./components/BrandDeleteModal";
import ClientPagination from "./components/ClientPagination";
import ExportModal from "./components/ExportModal";
import ExportModalClient from "./components/ExportModalClient";
import { changeBrandEditStatus } from "reduxConfig/slices/brandSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";
import urlParamsBuilder from "./components/helpers/getUrlParams";
import { updateBrandsPerPage } from "reduxConfig/slices/brandSlice";
import { updatePageNumberClientsBrands } from "reduxConfig/slices/brandSlice";
import { getBrandDataSkipLoad } from "reduxConfig/slices/brandSlice";
import { getClientDataSkipLoad } from "reduxConfig/slices/brandSlice";

const ClientBrandsTab = () => {
  const filterData = useSelector((state) => state.brandsData);
  const dropdownRef = useRef(null);
  const pageNumber = useSelector((state) => state.brandsData.pageNumber);
  const perPage = useSelector((state) => state.brandsData.brandsPerPage);
  const urlParams = new URLSearchParams(window.location.search);
  const isBrandParam = urlParams.get("is_brand");
  const initialLivePage = isBrandParam === "true" ? true : false;
  const [livePage, setLivePage] = useState(initialLivePage);
  const prevLivePage = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const brandData = useSelector((state) => state.brandsData.brandData);
  const clientData = useSelector((state) => state.brandsData.clientData);

  const showCreateModal = useSelector(
    (state) => state.brandsData.showCreateModal
  );
  const showEditModal = useSelector(
    (state) => state.brandsData.ShowEditBrandModal
  );
  const showClientCreateModal = useSelector(
    (state) => state.brandsData.showClientCreateModal
  );
  const showClientEditModal = useSelector(
    (state) => state.brandsData.showEditClientModal
  );
  const showClientDeleteModal = useSelector(
    (state) => state.brandsData.showClientDeleteModal
  );
  const showBrandDeleteModal = useSelector(
    (state) => state.brandsData.showBrandDeleteModal
  );
  const showExportModal = useSelector(
    (state) => state.brandsData.showExportModal
  );
  const showExportModalClient = useSelector(
    (state) => state.brandsData.showExportModalClient
  );
  const isLoading = useSelector((state) => state.brandsData.isLoading);
  const error = useSelector((state) => state.brandsData.error);
  const shouldUpdateEditBrand = useSelector((state) => {
    return state.brandsData;
  });

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    if (option === "Client") {
      dispatch(toggleClientCreateModal());
    } else {
      dispatch(CreateBrandToggle());
    }
    setSelectedOption(null);
    setIsDropdownOpen(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrandData());
    dispatch(getClientData());
  }, [pageNumber, perPage]);

  useEffect(() => {
    // Check if there's a change in livePage
    if (prevLivePage.current !== null && livePage !== prevLivePage.current) {
      dispatch(updateBrandsPerPage("15"));
      dispatch(updatePageNumberClientsBrands(1));
    }
    // Update the ref to the current value
    prevLivePage.current = livePage;
    if (
      shouldUpdateEditBrand.status === "CREATE_BRAND" ||
      shouldUpdateEditBrand.status === "CREATE_CLIENT"
    ) {
      dispatch(
        updateAlert({
          alertText: "Create Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getBrandDataSkipLoad());
      dispatch(getClientDataSkipLoad());
      dispatch(changeBrandEditStatus(null));
    }
    if (
      shouldUpdateEditBrand.status === "UPDATE_BRAND" ||
      shouldUpdateEditBrand.status === "UPDATE_CLIENT"
    ) {
      dispatch(
        updateAlert({
          alertText: "Update Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getBrandDataSkipLoad());
      dispatch(getClientDataSkipLoad());
      dispatch(changeBrandEditStatus(null));
    }
  }, [shouldUpdateEditBrand, livePage]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setSelectedOption(null);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const body = document.body;

    if (
      showCreateModal ||
      showEditModal ||
      showClientCreateModal ||
      showClientEditModal ||
      showClientDeleteModal ||
      showBrandDeleteModal ||
      showExportModal ||
      showExportModalClient
    ) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [
    showCreateModal,
    showEditModal,
    showClientCreateModal,
    showClientEditModal,
    showClientDeleteModal,
    showBrandDeleteModal,
    showExportModal,
    showExportModalClient,
  ]);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(
        filterData
      )}&is_brand=${livePage}`
    );
  });

  return (
    <div
      // className={`relative ${
      //   showCreateModal ||
      //   showEditModal ||
      //   showClientCreateModal ||
      //   showClientEditModal ||
      //   showClientDeleteModal ||
      //   showBrandDeleteModal
      //     ? "h-[80vh] overflow-x-hidden overflow-y-hidden"
      //     : ""
      // }`}
      className={`relative ${
        showCreateModal ||
        showEditModal ||
        showClientCreateModal ||
        showClientEditModal ||
        showClientDeleteModal ||
        showBrandDeleteModal ||
        showExportModal ||
        showExportModalClient
          ? ""
          : ""
      }`}
    >
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <div className="relative">
          <div className="mr-[3%] flex flex-row justify-end">
            <button
              onClick={toggleDropdown}
              className="bg-transparent hover:border-transparent relative top-[-8vh] left-[2vw] mt-4 flex items-center rounded-lg border border-[#4318FF] py-2 px-4 font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
            >
              Create
              <svg
                className={`ml-2 h-4 w-4 transform transition-transform ${
                  isDropdownOpen ? "rotate-180" : "rotate-0"
                }`}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </button>

            {isDropdownOpen && (
              <div
                ref={dropdownRef}
                className="absolute right-0 -mt-4  mr-2 w-28 rounded-lg border border-gray-300 bg-white shadow-lg"
              >
                <ul>
                  <li
                    className={`flex cursor-pointer justify-between rounded-lg p-2 hover:bg-[#4318FF] hover:text-white ${
                      selectedOption === "Client" ? "text-[#4318FF]" : ""
                    }`}
                    onClick={() => handleOptionClick("Client")}
                  >
                    Client
                  </li>
                  <li
                    className={`flex cursor-pointer justify-between rounded-lg p-2 hover:bg-[#4318FF] hover:text-white ${
                      selectedOption === "Brand" ? "text-[#4318FF]" : ""
                    }`}
                    onClick={() => handleOptionClick("Brand")}
                  >
                    Brand
                  </li>
                </ul>
              </div>
            )}
          </div>

          <InternalNav updatePageView={setLivePage} displayedPage={livePage} />

          {showCreateModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <CreateBrand />
              </div>
            </div>
          ) : (
            <></>
          )}

          {showClientCreateModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <CreateClient />
              </div>
            </div>
          ) : (
            <></>
          )}

          {showClientEditModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <EditClient />
              </div>
            </div>
          ) : (
            <></>
          )}

          {showClientDeleteModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <ClientDeleteModal />
              </div>
            </div>
          ) : (
            <></>
          )}

          {showBrandDeleteModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <BrandDeleteModal />
              </div>
            </div>
          ) : (
            <></>
          )}

          {showExportModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <ExportModal />
              </div>
            </div>
          ) : (
            <></>
          )}

          {showExportModalClient ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <ExportModalClient />
              </div>
            </div>
          ) : (
            <></>
          )}

          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div>
              {livePage ? (
                <div className="-mt-2  grid h-full grid-cols-1 gap-12 xl:grid-cols-2 2xl:grid-cols-3">
                  {brandData.length ? (
                    brandData.map((cardData) => (
                      <BrandCards cardData={cardData} />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div className="-mt-2  grid h-full grid-cols-1 gap-12 xl:grid-cols-2 2xl:grid-cols-3">
                  {clientData.length ? (
                    clientData.map((cardData) => (
                      <ClientCard cardData={cardData} />
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              )}

              {livePage ? (
                brandData.length ? (
                  <PaginationComp />
                ) : (
                  <></>
                )
              ) : clientData.length ? (
                <ClientPagination />
              ) : (
                <></>
              )}
              {livePage ? (
                brandData.length ? (
                  <></>
                ) : (
                  <>
                    <div className="flex items-center  justify-center">
                      <Lottie
                        animationData={noDataAnimationData}
                        loop={true}
                        autoplay={true}
                        style={{ width: 500, height: 500 }}
                        speed={1.5}
                      />
                      {/* <div className="text-3xl font-bold">No Brands Found</div> */}
                    </div>
                  </>
                )
              ) : clientData.length ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className="flex items-center  justify-center">
                    <Lottie
                      animationData={noDataAnimationData}
                      loop={true}
                      autoplay={true}
                      style={{ width: 500, height: 500 }}
                      speed={1.5}
                    />
                    {/* <div className="text-3xl font-bold">No Clients Found</div> */}
                  </div>
                </>
              )}
            </div>
          )}

          {showEditModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <EditBrandModal />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientBrandsTab;
