import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNewRule, updateExistingRule} from "reduxConfig/slices/rulesSlice";

//Code Added by VigneshB on [20-09-2023] for searchType params
function SearchTypeDropDown({ isNewRule }) {
  const dispatch = useDispatch();
  const searchTypeData = useSelector((state) =>
    isNewRule
      ? state.rulesData.newRuleData.searchType
      : state.rulesData.updatedRuleData.searchType
  );

  return (
    <div>
      <select
        value={searchTypeData}
        onChange={(e) => {
          isNewRule?
          dispatch(updateNewRule({ searchType: e.target.value })):
           dispatch(updateExistingRule({ searchType: e.target.value }));
        }}
        className="w-full  border border-[#000000] bg-white p-2 text-[#000000] sm:text-sm py-2 px-4"
      >
        <option value="GOOGLE_SEARCH">GOOGLE SEARCH</option>
        <option value="VT_SERVER">VT SERVER</option>
        {/* <option value="THIRD_PARTY_APPSTORES">THIRD PARTY APPSTORES</option> */}
        {/* <option value="GOOGLE_CSE">GOOGLE CSE APK</option> */}
        <option value="GOOGLE_PLAYSTORE">GOOGLE PLAYSTORE</option>
      </select>
    </div>
  );
}

export default SearchTypeDropDown;
