import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";
import Cookies from "js-cookie";

const refresh = () => {
  const ownedBrands = Cookies.get("ownedBrands");
  if (ownedBrands) {
    return JSON.parse(ownedBrands);
  }
  return [];
};

const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const initialState = {
  brandData: refresh(),
  clientData: [],
  dumpedData: [],
  isExportLoading: false,
  isLoading: false,
  showCreateModal: false,
  showBrandModal: false,
  alert: false,
  showClientCreateModal: false,
  showEditClientModal: false,
  showClientDeleteModal: false,
  showBrandDeleteModal: false,
  showExportModal: false,
  showExportModalClient: false,
  exportClientId: "",
  exportBrandId: "",
  newBrandData: {
    brandName: "",
    domain: "",
    whitelistedDomains: [],
    client_id: "",
  },
  ShowEditBrandModal: false,
  updateBrandData: {
    brandName: "",
    domain: "",
    whitelistedDomains: [],
    brandId: "",
    client_id: "",
  },
  newClientData: { clientName: "", brandKeyWords: [] },
  updateClientData: {
    clientName: "",
    brandKeyWords: [],
    clientId: "",
  },
  clientDeleteData: { clientName: "", clientId: "" },
  brandDeleteData: { clientName: "", clientId: "" },
  pageNumber: pageNumberHandler(),
  totalPagesBrands: null,
  totalBrands: null,
  totalPagesClient: null,
  totalClients: null,
  brandsPerPage: 15,
  status: null,
  error: null,
};
export const getBrandData = createAsyncThunk("brand/data", () => {
  let stateData = store.getState();
  let brandData = stateData.brandsData;
  return apiCall("sw_brand/list_brands", "get", {
    params: {
      page_number: brandData.pageNumber,
      no_of_results: brandData.brandsPerPage,
    },
  });
});

export const getBrandDataSkipLoad = createAsyncThunk("brand_skip/data", () => {
  let stateData = store.getState();
  let brandData = stateData.brandsData;
  return apiCall("sw_brand/list_brands", "get", {
    params: {
      page_number: brandData.pageNumber,
      no_of_results: brandData.brandsPerPage,
    },
  });
});

export const CreateNewBrand = createAsyncThunk("brand/create", () => {
  let stateData = store.getState();
  let newBrandData = stateData.brandsData.newBrandData;
  if (!newBrandData.brandName.length) {
    return new Promise((resolve, reject) => {
      reject("No brand name");
    });
  }

  let dataToPost = {
    brand_name: newBrandData.brandName,
    domain: newBrandData.domain,
    whitelisted_domains: newBrandData.whitelistedDomains,
    client_id: newBrandData.client_id.value,
  };
  return apiCall("/sw_brand/create_brand/", "post", dataToPost);
});

export const UpdateBrandData = createAsyncThunk("brand/update", () => {
  let stateData = store.getState();
  let updateBrandData = stateData.brandsData.updateBrandData;
  if (!updateBrandData.brandName.length) {
    return new Promise((resolve, reject) => {
      reject("No brand name");
    });
  }

  let dataToPost = {
    brand_name: updateBrandData.brandName,
    domain: updateBrandData.domain,
    whitelisted_domains: updateBrandData.whitelistedDomains,
  };

  const url = `/sw_brand/${updateBrandData.brandId}`;
  return apiCall(url, "put", dataToPost);
});

export const getClientData = createAsyncThunk("client/data", () => {
  let stateData = store.getState();
  let clientData = stateData.brandsData;
  return apiCall("sw_clients/list_clients", "get", {
    params: {
      page_number: clientData.pageNumber,
      no_of_results: clientData.brandsPerPage,
    },
  });
});

export const getClientDataSkipLoad = createAsyncThunk(
  "client_skip/data",
  () => {
    let stateData = store.getState();
    let clientData = stateData.brandsData;
    return apiCall("sw_clients/list_clients", "get", {
      params: {
        page_number: clientData.pageNumber,
        no_of_results: clientData.brandsPerPage,
      },
    });
  }
);

export const CreateNewClient = createAsyncThunk("client/create", () => {
  let stateData = store.getState();
  let newClientData = stateData.brandsData.newClientData;
  if (!newClientData.clientName.length) {
    return new Promise((resolve, reject) => {
      reject("No client name");
    });
  }

  let dataToPost = {
    client_name: newClientData.clientName,
    brand_keywords: newClientData.brandKeyWords,
  };
  return apiCall("/sw_clients/create_client/", "post", dataToPost);
});

export const UpdateClientData = createAsyncThunk("client/update", () => {
  let stateData = store.getState();
  let updateClientData = stateData.brandsData.updateClientData;
  if (!updateClientData.clientName.length) {
    return new Promise((resolve, reject) => {
      reject("No client name");
    });
  }

  let dataToPost = {
    client_name: updateClientData.clientName,
    brand_keywords: updateClientData.brandKeyWords,
  };

  const url = `/sw_clients/${updateClientData.clientId}`;
  return apiCall(url, "put", dataToPost);
});

export const getClientsDump = createAsyncThunk("clients/dump", () => {
  let stateData = store.getState();
  let resultsData = stateData.brandsData;
  let dataToPost = {
    client_id: resultsData.exportClientId,
  };
  let url = `/sw_clients/export/${resultsData.exportClientId}`;
  return apiCall(url, "post", dataToPost);
});

export const getBrandsDump = createAsyncThunk("brands/dump", () => {
  let stateData = store.getState();
  let resultsData = stateData.brandsData;
  let dataToPost = {
    brand_id: resultsData.exportBrandId,
  };
  let url = `/sw_brand/export/${resultsData.exportBrandId}`;
  return apiCall(url, "get", dataToPost);
});

export const brandDataSlice = createSlice({
  name: "brandsData",
  initialState,
  reducers: {
    UpdateNewBrandData: (state, action) => {
      state.newBrandData = { ...state.newBrandData, ...action.payload };
    },
    CreateBrandToggle: (state) => {
      state.showCreateModal = !state.showCreateModal;
    },
    ToggleAlert: (state) => {
      state.alert = false;
    },
    EditBrandToggle: (state) => {
      state.ShowEditBrandModal = !state.ShowEditBrandModal;
    },
    updateExistingBrand: (state, action) => {
      state.updateBrandData = { ...state.updateBrandData, ...action.payload };
    },
    updatePageNumberClientsBrands: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    updateBrandsPerPage: (state, action) => {
      state.brandsPerPage = action.payload;
    },
    changeBrandEditStatus: (state, action) => {
      state.status = action.payload;
    },
    updateErrorBrands: (state, action) => {
      state.error = null;
    },
    toggleClientCreateModal: (state) => {
      state.showClientCreateModal = !state.showClientCreateModal;
    },
    toggleClientEditModal: (state) => {
      state.showEditClientModal = !state.showEditClientModal;
    },
    UpdateNewClientData: (state, action) => {
      state.newClientData = { ...state.newClientData, ...action.payload };
    },
    updateExistingClient: (state, action) => {
      state.updateClientData = { ...state.updateClientData, ...action.payload };
    },
    toggleClientDeleteModal: (state) => {
      state.showClientDeleteModal = !state.showClientDeleteModal;
    },
    updateClientDeleteData: (state, action) => {
      state.clientDeleteData = { ...state.clientDeleteData, ...action.payload };
    },
    toggleBrandDeleteModal: (state) => {
      state.showBrandDeleteModal = !state.showBrandDeleteModal;
    },
    updateBrandDeleteData: (state, action) => {
      state.brandDeleteData = { ...state.brandDeleteData, ...action.payload };
    },
    clearNewClient: (state) => {
      state.newClientData = initialState.newClientData;
    },
    clearNewBrand: (state) => {
      state.newBrandData = initialState.newBrandData;
    },
    toggleExportModalBrands: (state) => {
      state.showExportModal = !state.showExportModal;
    },
    toggleExportModalClients: (state) => {
      state.showExportModalClient = !state.showExportModalClient;
    },
    clearDataDumpBrands: (state) => {
      state.dumpedData = [];
      state.isExportLoading = false;
    },
    updateExportClientId: (state, action) => {
      state.exportClientId = action.payload.exportClientId;
    },
    updateExportBrandId: (state, action) => {
      state.exportBrandId = action.payload.exportBrandId;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBrandData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.brandData = action.payload.data.data.page_results;
        state.totalPagesBrands = action.payload.data.data.total_pages;
        state.totalBrands = action.payload.data.data.total_results;
      })
      .addCase(getBrandData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(CreateNewBrand.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(CreateNewBrand.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.status = "CREATE_BRAND";
        state.showCreateModal = !state.showCreateModal;
        state.newBrandData = initialState.newBrandData;
      })
      .addCase(CreateNewBrand.rejected, (state, action) => {
        // state.isLoading = false;
        state.showCreateModal = !state.showCreateModal;
        state.error = action.error.message;
        state.newBrandData = initialState.newBrandData;
      })
      .addCase(UpdateBrandData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(UpdateBrandData.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.status = "UPDATE_BRAND";
        state.ShowEditBrandModal = initialState.ShowEditBrandModal;
        state.updateBrandData = initialState.updateBrandData;
      })
      .addCase(UpdateBrandData.rejected, (state, action) => {
        // state.isLoading = false;
        state.status = "FAILED";
        state.ShowEditBrandModal = initialState.ShowEditBrandModal;
        state.error = action.error.message;
        state.updateBrandData = initialState.updateBrandData;
      })
      .addCase(getClientData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.clientData = action.payload.data.data.page_results;
        state.totalPagesClient = action.payload.data.data.total_pages;
        state.totalClients = action.payload.data.data.total_results;
      })
      .addCase(getClientData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(CreateNewClient.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(CreateNewClient.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.status = "CREATE_CLIENT";
        state.showClientCreateModal = !state.showClientCreateModal;
        state.newClientData = initialState.newClientData;
      })
      .addCase(CreateNewClient.rejected, (state, action) => {
        // state.isLoading = false;
        state.showClientCreateModal = !state.showClientCreateModal;
        state.error = action.error.message;
        state.newClientData = initialState.newClientData;
      })
      .addCase(UpdateClientData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(UpdateClientData.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.status = "UPDATE_CLIENT";
        state.showEditClientModal = initialState.showEditClientModal;
        state.updateClientData = initialState.updateClientData;
      })
      .addCase(UpdateClientData.rejected, (state, action) => {
        // state.isLoading = false;
        state.status = "FAILED";
        state.showEditClientModal = initialState.showEditClientModal;
        state.error = action.error.message;
        state.updateClientData = initialState.updateClientData;
      })
      .addCase(getClientsDump.pending, (state) => {
        state.isExportLoading = true;
        state.dumpedData = {};
      })
      .addCase(getClientsDump.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.dumpedData = [action.payload.data.data];
      })
      .addCase(getClientsDump.rejected, (state, action) => {
        state.isExportLoading = false;
        state.error = action.error.message;
      })
      .addCase(getBrandsDump.pending, (state) => {
        state.isExportLoading = true;
        state.dumpedData = {};
      })
      .addCase(getBrandsDump.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.dumpedData = [action.payload.data.data];
      })
      .addCase(getBrandsDump.rejected, (state, action) => {
        state.isExportLoading = false;
        state.error = action.error.message;
      })
      .addCase(getBrandDataSkipLoad.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getBrandDataSkipLoad.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.brandData = action.payload.data.data.page_results;
        state.totalPagesBrands = action.payload.data.data.total_pages;
        state.totalBrands = action.payload.data.data.total_results;
      })
      .addCase(getBrandDataSkipLoad.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getClientDataSkipLoad.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getClientDataSkipLoad.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.clientData = action.payload.data.data.page_results;
        state.totalPagesClient = action.payload.data.data.total_pages;
        state.totalClients = action.payload.data.data.total_results;
      })
      .addCase(getClientDataSkipLoad.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  UpdateNewBrandData,
  CreateBrandToggle,
  ToggleAlert,
  EditBrandToggle,
  updateExistingBrand,
  updatePageNumberClientsBrands,
  updateBrandsPerPage,
  changeBrandEditStatus,
  updateErrorBrands,
  toggleClientCreateModal,
  toggleClientEditModal,
  UpdateNewClientData,
  updateExistingClient,
  toggleClientDeleteModal,
  updateClientDeleteData,
  toggleBrandDeleteModal,
  updateBrandDeleteData,
  clearNewClient,
  clearNewBrand,
  toggleExportModalBrands,
  clearDataDumpBrands,
  toggleExportModalClients,
  updateExportClientId,
  updateExportBrandId,
} = brandDataSlice.actions;

export default brandDataSlice.reducer;
