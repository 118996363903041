import React, { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { updateBrandFiltersOfficial } from "reduxConfig/slices/officialApkSlice";

function ClientSelect() {
  const brandData = useSelector((state) => state.brandsData.brandData);

  const [formattedData, setBrandData] = useState([]);
  const choosenBrands = useSelector(
    (state) => state.officialApksData.brand_ids
  );

  const checkClients = useSelector(
    (state) => state.officialApksData.client_ids
  );

  const dispatch = useDispatch();

  const getDefaultValues = () => {
    let returnData = [];
    for (let element in choosenBrands) {
      let brand_object = brandData.find(
        (items) => items._id == choosenBrands[element]
      );
      if (brand_object) {
        returnData.push({
          value: brand_object._id,
          label: brand_object.brand_name,
        });
      }
    }
    return returnData;
  };

  useEffect(() => {
    let stateData = [];
    for (let elements in brandData) {
      stateData.push({
        value: brandData[elements]._id,
        label: brandData[elements].brand_name,
      });
    }

    setBrandData(stateData);
  }, []);

  useEffect(() => {
    let filteredBrandData = [];

    // Extract client_id values from checkClients
    const clientIds = checkClients.map((client) => client);

    // Filter brandData based on extracted client_ids
    if (clientIds && clientIds.length > 0) {
      filteredBrandData = brandData.filter((brand) =>
        clientIds.includes(brand.client_id)
      );
    } else {
      filteredBrandData = [...brandData];
    }
    let stateData = filteredBrandData.map((brand) => ({
      value: brand._id,
      label: brand.brand_name,
    }));

    setBrandData(stateData);
  }, [brandData, checkClients]);

  return (
    <div>
      {formattedData.length ? (
        <MultiSelect
          className="multiselect"
          options={formattedData}
          value={getDefaultValues()}
          onChange={(selectedOptions) => {
            const selectedValues = selectedOptions.map(
              (option) => option.value
            );
            dispatch(updateBrandFiltersOfficial(selectedValues));
          }}
          labelledBy="Select"
        />
      ) : (
        <div className="text-sm font-bold text-red-500">
          No brands have been created. Please create a brand.
        </div>
      )}
    </div>
  );
}

export default ClientSelect;
