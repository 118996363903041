import React from "react";

// Admin Imports
import MainDashboard from "views/admin/analytics";
import NFTMarketplace from "views/admin/brands";
import Profile from "views/admin/profile";
import ThreatsView from "views/admin/threats";
import RulesTab from "views/admin/rules";
import SignIn from "views/auth/SignIn";
import Results from "views/admin/results";
import UrlsImport from "views/admin/UrlsImport";
import UploadApk from "views/admin/uploadApk";
import ApkResults from "views/admin/ApkResults";
import SeoPoisoning from "views/admin/SeoPoisoning";
import TaskStatus from "views/admin/TaskStatus";
import OfficialApks from "views/admin/OfficialApks";
import CertificateTab from "views/admin/certificate";

// Icon Imports
import {
  MdOutlineAnalytics,
  MdYoutubeSearchedFor,
  MdOutlineSettings,
} from "react-icons/md";
import {
  AiOutlineBug,
  AiOutlineUnlock,
  AiOutlineImport,
  AiFillAndroid,
} from "react-icons/ai";
import { MdPhoneAndroid } from "react-icons/md";
import { BsClipboardCheck, BsPeople } from "react-icons/bs";
import { GiPoisonCloud } from "react-icons/gi";
import { FiLink } from "react-icons/fi";
import { FaTasks } from "react-icons/fa";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { TbFileCertificate } from "react-icons/tb";

const routes = [
  {
    name: "Analytics",
    layout: "/admin",
    path: "dashboard",
    icon: <MdOutlineAnalytics className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Clients & Brands",
    layout: "/admin",
    path: "clients_brands",
    icon: <BsPeople className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  // {
  //   name: "Threats",
  //   layout: "/admin",
  //   icon: <AiOutlineBug className="h-6 w-6" />,
  //   path: "threats",
  //   component: <ThreatsView />,
  // },

  {
    name: "Rules",
    layout: "/admin",
    icon: <MdYoutubeSearchedFor className="h-6 w-6" />,
    path: "rules",
    component: <RulesTab />,
  },

  {
    name: "Import URLs",
    layout: "/admin",
    icon: <AiOutlineImport className="h-6 w-6" />,
    path: "urls_import",
    component: <UrlsImport />,
  },

  {
    name: "URL Results",
    layout: "/admin",
    icon: <FiLink className="h-6 w-6" />,
    path: "urls_results",
    component: <Results />,
  },

  {
    name: "Seo Poisoning",
    layout: "/admin",
    icon: <GiPoisonCloud className="h-6 w-6" />,
    path: "seo_poisoning",
    component: <SeoPoisoning />,
  },

  {
    name: "Upload APKs",
    layout: "/admin",
    icon: <AiFillAndroid className="h-6 w-6" />,
    path: "upload_apks",
    component: <UploadApk />,
  },

  {
    name: "Task Status",
    layout: "/admin",
    icon: <FaTasks className="h-6 w-6" />,
    path: "task_status",
    component: <TaskStatus />,
  },

  {
    name: "APK Results",
    layout: "/admin",
    icon: <MdPhoneAndroid className="h-6 w-6" />,
    path: "apks_results",
    component: <ApkResults />,
  },

  {
    name: "Certificate",
    layout: "/admin",
    icon: <TbFileCertificate className="h-6 w-6" />,
    path: "certificate",
    component: <CertificateTab />,
  },

  {
    name: "Official APKs",
    layout: "/admin",
    icon: <IoLogoGooglePlaystore className="h-6 w-6" />,
    path: "official_apks",
    component: <OfficialApks />,
  },

  {
    name: "Settings",
    layout: "/admin",
    path: "profile",
    icon: <MdOutlineSettings className="h-6 w-6" />,
    component: <Profile />,
  },
];
export default routes;
