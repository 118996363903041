import React, { useEffect, useState } from "react";
import { MultiSelect as Selector } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { updateNewUrl } from "reduxConfig/slices/urlSlice";

function BrandSelect() {
  const brandData = useSelector((state) => state.brandsData.brandData);
  const [formattedData, setBrandData] = useState([]);
  const componentDefaultValues = useSelector(
    (state) => state.urlsData.newUrlData.chooseBrands
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let stateData = [];
    for (let elements in brandData) {
      stateData.push({
        value: brandData[elements]._id,
        label: brandData[elements].brand_name,
      });
    }
    setBrandData(stateData);
  }, []);

  return (
    <div>
      {formattedData.length ? (
        <Selector
          className="multiselect"
          options={formattedData}
          value={componentDefaultValues}
          onChange={(selectedOption) => {
            dispatch(updateNewUrl({ chooseBrands: selectedOption }));
          }}
          labelledBy={"Select"}
          isCreatable={false}
        />
      ) : (
        <div className="font-bold text-red-500 text-sm">
        No brands have been created. Please create a brand.
      </div>
      )}
    </div>
  );
}

export default BrandSelect;
