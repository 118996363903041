import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClientSelect from "./ClientSelect";
import BrandSelect from "./BrandSelect";
import { toggleFilterModal } from "reduxConfig/slices/officialApkSlice";
import { getApkDataOfficial } from "reduxConfig/slices/officialApkSlice";
import { clearFiltersApkOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateIndicatorFiltersOfficial } from "reduxConfig/slices/officialApkSlice";
import { updatePageNumberApkResultsOfficial } from "reduxConfig/slices/officialApkSlice";

function FilterModal() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const defaultData = useSelector((state) => state.officialApksData);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleFilterModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[40%] z-20 mt-20 w-[80vw] flex-col justify-center py-6 sm:py-12">
        <div
          className="-mt-16 flex h-1/2 w-[30%] flex-col justify-evenly rounded-xl bg-white p-8"
          ref={modalRef}
        >
          <div className="flex items-center justify-center text-3xl font-semibold text-[#000000]">
            Filters
          </div>
          <div className="mb-4 -mt-7 flex justify-end">
            <button
              type="button"
              onClick={() => {
                dispatch(clearFiltersApkOfficial());
              }}
              className="underline-text text-blue-700 hover:text-[#4318FF]"
            >
              Clear All
            </button>
          </div>
          <div className="mt-3 font-semibold">Choose Clients:</div>
          <ClientSelect />
          <div className="mt-3 font-semibold">Choose Brands:</div>
          <BrandSelect />
          {/* <BrandSelect isWhitelisted={userAction == "WHITELISTED"}/> */}
          {/* <div className="mt-3 font-semibold">Choose Indicator:</div>
          <div>
            <select
              value={defaultData.is_whitelisted}
              onChange={(e) => {
                dispatch(updateIndicatorFiltersOfficial(e.target.value));
              }}
              className="w-full  border border-[#000000] bg-white p-2 py-2 px-4 text-[#000000] sm:text-sm"
            >
              <option value="null">None</option>
              <option value="true">Whitelisted</option>
              <option value="false">Threat</option>
            </select>
          </div> */}
          <div class="flex items-center justify-center space-x-4 pt-4">
            <button
              className="flex w-1/4 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white focus:outline-none"
              onClick={() => {
                dispatch(getApkDataOfficial());
                dispatch(updatePageNumberApkResultsOfficial(1));
                dispatch(toggleFilterModal());
              }}
            >
              Apply
            </button>
            <button
              class="flex w-1/4 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
              onClick={() => {
                dispatch(toggleFilterModal());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterModal;
