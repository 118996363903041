import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const regexParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isRegex = queryParams.get("isRegex") === "true" ? true : false;
  return {
    isRegex: isRegex,
  };
};

const initialState = {
  certicateData: [],
  error: null,
  isLoading: false,
  totalPages: null,
  CertificatePerPage: 15,
  totalRules: null,
  pageNumber: pageNumberHandler(),
  queryString: QueryStringHandler(),
  ...regexParamsHandler(),
  showViewAllData: false,
  showSearchInfoModal: false,
  defaultData: {
    _id: "",
    total_apks: "",
    vt_score_equal_to_zero: "",
    vt_score_higher_than_zero: "",
    has_no_vt_score: "",
    distinguished_name: "",
    country_name: "",
    common_name: "",
    locality: "",
    organization_name: "",
    state_or_province_name: "",
    organizational_unit_name: "",
    email: "",
  },
};

export const getCertificateData = createAsyncThunk("certificate/data", () => {
  let stateData = store.getState();
  let certificateData = stateData.certificateData;
  let dataToPost = {
    page_number: certificateData.pageNumber,
    results_per_page: certificateData.CertificatePerPage,
  };
  return apiCall(`sw_certificate/get_insights`, "post", dataToPost);
});

export const certificateDataSlice = createSlice({
  name: "certificateData",
  initialState,
  reducers: {
    updatePageNumberCertificate: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    updatePerPageCertificate: (state, action) => {
      state.taskPerPage = action.payload;
    },
    updateErroCertificate: (state, action) => {
      state.error = null;
    },
    toggleViewAllData: (state, action) => {
      state.showViewAllData = !state.showViewAllData;
    },
    setDefaultValue: (state, action) => {
      state.defaultData._id = action.payload._id;
      state.defaultData.total_apks = action.payload.total_apks;
      state.defaultData.locality = action.payload.locality;
      state.defaultData.vt_score_equal_to_zero =
        action.payload.vt_score_equal_to_zero;
      state.defaultData.vt_score_higher_than_zero =
        action.payload.vt_score_higher_than_zero;
      state.defaultData.has_no_vt_score = action.payload.has_no_vt_score;
      state.defaultData.distinguished_name = action.payload.distinguished_name;
      state.defaultData.country_name = action.payload.country_name;
      state.defaultData.common_name = action.payload.common_name;
      state.defaultData.organization_name = action.payload.organization_name;
      state.defaultData.state_or_province_name =
        action.payload.state_or_province_name;
      state.defaultData.organizational_unit_name =
        action.payload.organizational_unit_name;
      state.defaultData.email = action.payload.email;
    },
    updateQueryStringCertificate: (state, action) => {
      state.queryString = action.payload;
    },
    toggleIsRegix: (state) => {
      state.isRegex = !state.isRegex;
    },
    toggleSearchInfoModal: (state) => {
      state.showSearchInfoModal = !state.showSearchInfoModal;
    },
    toggleIsRegixCerti: (state) => {
      state.isRegex = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getCertificateData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCertificateData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.certicateData = action.payload.data.data.insights_data;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalRules = action.payload.data.data.total_results;
      })
      .addCase(getCertificateData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updatePageNumberCertificate,
  updatePerPageCertificate,
  updateErroCertificate,
  toggleViewAllData,
  setDefaultValue,
  updateQueryStringCertificate,
  toggleIsRegix,
  toggleSearchInfoModal,
  toggleIsRegixCerti,
} = certificateDataSlice.actions;

export default certificateDataSlice.reducer;
