import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { IoMdNotificationsOutline } from "react-icons/io";
const Navbar = (props) => {
  const { onOpenSidenav, brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);

  return (
    <nav className="top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 pt-5  dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
          <Link
            to="#"
            className="pl-[vw] font-bold capitalize hover:text-navy-700 dark:hover:text-white"
          > 
            {brandText}
          </Link>
        </p>
      </div>
    </nav>
  );
};
export default Navbar;
