import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import {
  showCreateModal,
  showEditModal,
} from "reduxConfig/slices/settingSlice";
import { useDispatch, useSelector } from "react-redux";
import slackIcon from "assets/img/slackIcon.png";
import { updateExistingSlack } from "reduxConfig/slices/settingSlice";
import { toggleType } from "reduxConfig/slices/settingSlice";
import { updateDeleteData } from "reduxConfig/slices/settingSlice";
import { toggleDeleteSlack } from "reduxConfig/slices/settingSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateSlackStatus } from "reduxConfig/slices/settingSlice";
import { getSlackDatasSkipLoad } from "reduxConfig/slices/settingSlice";

const SettingCard = ({ cardData }) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const userData = useSelector((state) => state.userAuth.userData);
  const dispatch = useDispatch();

  const checkStatus = useSelector((state) => state.settingsData.status);

  useEffect(() => {
    if (checkStatus === "CREATE_SLACK") {
      dispatch(
        updateAlert({
          alertText: "Create Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getSlackDatasSkipLoad());
      dispatch(updateSlackStatus());
    }
    if (checkStatus === "UPDATE_SLACK") {
      dispatch(
        updateAlert({
          alertText: "Update Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getSlackDatasSkipLoad());
      dispatch(updateSlackStatus());
    }
    if (checkStatus === "ERROR") {
      dispatch(
        updateAlert({
          alertText: "Error",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getSlackDatasSkipLoad());
      dispatch(updateSlackStatus());
    }
  }, [checkStatus]);

  return (
    <div>
      <div class="m-12 flex w-[75vw] flex-col justify-center rounded-lg border border-gray-200 bg-[#E6E1FF]">
        <table class="w-full text-left text-sm text-gray-500">
          <div class=" text-xl text-[#4318FF]">
            <div className="flex h-12 w-28 items-center justify-center rounded-lg border-b border-[#4318FF] bg-white px-4 py-2 font-semibold">
              Profile
            </div>
            <hr class="m-8 mx-auto -mt-4 ml-28 w-[92%] border-[#4318FF]" />
          </div>
          <div className="flex">
            <div className="w-48 px-14 py-4 text-lg font-bold text-[#000000]">
              Username
            </div>
            <span className="px-14 py-4 text-lg font-bold text-[#000000]">
              :
            </span>
            <div className="px-14 py-4 text-lg  text-[#000000]">
              {userData.username}
            </div>
          </div>
          <div className="flex">
            <div className="w-48 px-14 py-4 text-lg font-bold  text-[#000000]">
              Email ID
            </div>
            <span className="px-14 py-4 text-lg font-bold text-[#000000]">
              :
            </span>
            <div className="px-14 py-4 text-lg  text-[#000000]">
              {userData.email_id}
            </div>
          </div>
          <div className="flex">
            <div className="w-48 px-14 py-4 text-lg font-bold text-[#000000]">
              API Key
            </div>
            <span className="px-14 py-4 text-lg font-bold text-[#000000]">
              :
            </span>
            <div className="flex">
              {showApiKey ? (
                <div className="w-[40vw] px-14 py-4 text-lg text-[#000000]">
                  {userData.api_config.key}
                </div>
              ) : (
                <div className="w-[40vw] px-14 py-4 text-lg text-[#000000]">
                  {Array.from(
                    { length: userData.api_config.key.length },
                    () => "*"
                  ).join("")}
                </div>
              )}
              <AiOutlineEye
                className="mt-3 h-8 w-8 cursor-pointer rounded-md p-[2px]"
                color="#4318FF"
                onClick={(e) => {
                  setShowApiKey(!showApiKey);
                }}
              />
            </div>
          </div>
        </table>
      </div>

      <div className="m-12 flex w-[75vw] flex-col justify-center rounded-lg border border-gray-200 bg-[#E6E1FF]">
        <div className="w-full text-left text-sm text-gray-500">
          <div>
            <div className="h-18 flex w-48 items-center justify-center rounded-lg border-b border-[#4318FF] bg-white py-2 text-xl font-semibold text-[#4318FF]">
              Slack Notification
            </div>
            <hr className="mx-auto -mt-4 mr-0 w-[86%] border-[#4318FF]" />
            <div className="m-12 w-[70vw] rounded-lg bg-white py-1">
              <div className="m-16 w-[65vw] bg-white">
                {cardData.length ? (
                  <>
                    {" "}
                    <div className="flex items-center justify-between rounded-lg py-3 px-10 text-xl font-semibold text-[#000000]">
                      <div>Channel Name</div>
                      <div className="">Type</div>
                      <div className="w-[35%]">Url/Token</div>
                      <div className="w-[7%]">Actions</div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {cardData.length ? (
                  <>
                    {cardData.map((channel, index) => (
                      <div
                        key={index}
                        className={`flex items-center justify-between rounded-lg bg-${
                          index % 2 === 0 ? "[#E6E1FF]" : "[#F4F2FF]"
                        } h-[4vw] py-3 px-10 text-xl text-[#000000]`}
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="hide-scrollbar w-[20%] overflow-y-scroll whitespace-nowrap text-start">
                          {channel.cred_name ? channel.cred_name : "None"}
                        </div>
                        <div className="hide-scrollbar w-[10%] overflow-y-scroll whitespace-nowrap text-start">
                          {channel.cred_type ? channel.cred_type : "None"}
                        </div>
                        <div className="hide-scrollbar w-[50%] overflow-y-scroll  whitespace-nowrap text-start">
                          {channel.cred_type == "WEBHOOK"
                            ? channel.webhook_url
                            : channel.oauth_token}
                        </div>
                        <div className="flex p-1 text-start">
                          <FiEdit
                            className="mr-1 h-9 w-9 cursor-pointer rounded border border-[#4318FF] bg-[#4318FF] p-[4px] text-white"
                            onClick={(e) => {
                              dispatch(
                                updateExistingSlack({
                                  config_id: channel._id,
                                  credName: channel.cred_name,
                                  credtype: channel.cred_type,
                                  oauthToken: channel.oauth_token,
                                  channelId: channel.channel_id,
                                  webHookUrl: channel.webhook_url,
                                })
                              );
                              dispatch(toggleType(channel.cred_type));
                              dispatch(showEditModal());
                            }}
                          />
                          {/* <img
                            src={slackIcon}
                            className="h-9 w-9 cursor-pointer"
                            alt="logo"
                          /> */}
                          <AiOutlineDelete
                            className="ml-1 h-9 w-9 cursor-pointer rounded border border-[#4318FF] p-[4px] text-[#4318FF] hover:border-red-500 hover:bg-red-500 hover:text-white"
                            onClick={(e) => {
                              dispatch(
                                updateDeleteData({
                                  channelName: channel.cred_name,
                                  channelId: channel._id,
                                })
                              );
                              dispatch(toggleDeleteSlack());
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className=" flex justify-center text-xl font-semibold text-red-500">
                    {"Start adding webhook URLs to configure Slack alerts."}
                  </div>
                )}
              </div>
            </div>
            <div className="px-10 py-3">
              <button
                className="linear hover-bg-[#4318FF] active-bg-[#4318FF] ml-2 mr-2 rounded-lg bg-[#4318FF] px-4 py-2 text-base font-medium text-white transition duration-200"
                onClick={(e) => {
                  dispatch(showCreateModal());
                }}
              >
                Add Channel
              </button>
              {/* <button className="linear hover-bg-[#4318FF] hover-text-white active-bg-[#4318FF] ml-2 mr-2 rounded-lg border border-[#4318FF] bg-[#E6E1FF] px-4 py-2 text-base font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white">
                Save
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingCard;
