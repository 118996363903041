import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNewFileUpload } from "reduxConfig/slices/uploadApkSlice";

function TagInput() {
  const [inputValue, setInputValue] = useState("");
  const selectedOption = useSelector(
    (state) => state.UploadApkData.fileUpload.discoverd_source
  );
  const dispatch = useDispatch();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue) {
        const domainsArray = inputValue
          .split(",")
          .map((domain) => domain.trim());
        let uniqueEntries = new Set([...selectedOption, ...domainsArray]);

        dispatch(updateNewFileUpload({ discoverd_source: [...uniqueEntries] }));
        setInputValue("");
      }
    }
  };

  return (
    <div class="">
      <div class="relative">
        <textarea
          class="block max-h-40  w-full border border-[#000000] bg-white p-2.5 text-sm text-[#000000] focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder="Enter Source"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(event) => {
            handleKeyPress(event);
          }}
        />
        <div class="hidden">
          <div class="absolute left-0 z-40 mt-2 w-full">
            <div class="rounded border border-gray-300 bg-white py-1 text-sm shadow-lg">
              <a class="block cursor-pointer py-1 px-5 hover:bg-indigo-600 hover:text-white">
                Add tag "<span class="font-semibold" x-text="textInput"></span>"
              </a>
            </div>
          </div>
        </div>
        <div class="max-h-24 overflow-y-auto">
          {selectedOption &&
            selectedOption.map((element) => (
              <div class="mt-2 mr-1 inline-flex items-center overflow-hidden rounded bg-blue-100 text-sm">
                <span
                  class="ml-2 mr-1 max-w-xs truncate px-1 leading-relaxed"
                  x-text="tag"
                >
                  {element}
                </span>
                <div
                  class=" flex h-8 w-6 items-center justify-center bg-blue-200 text-center align-middle text-gray-500 focus:outline-none"
                  onClick={(e) => {
                    const updatedItems = selectedOption.filter(
                      (i) => i != element
                    );
                    dispatch(
                      updateNewFileUpload({ discoverd_source: updatedItems })
                    );
                  }}
                >
                  <svg
                    class="fill-current mx-auto h-6 w-6 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                    />
                  </svg>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default TagInput;
