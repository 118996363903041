import { configureStore } from "@reduxjs/toolkit";
import userAuthReducer from "./slices/authSlice";
import resultDataReducer from "./slices/resultSlice";
import brandDataReducer from "./slices/brandSlice";
import resultEditReducer from "./slices/resultEditSlice";
import rulesDataReducer from "./slices/rulesSlice";
import alertDataReducer from "./slices/alertSlice";
import settingDataReducer from "./slices/settingSlice";
import urlsDataReducer from "./slices/urlSlice";
import uploadApkDataReducer from "./slices/uploadApkSlice";
import ApksResultsDataReducer from "./slices/apkResultSlice";
import SeoPoisonDataReducer from "./slices/seoPoisonSlice";
import taskStatusDataReducer from "./slices/taskStatusSlice";
import officialApksDataReducer from "./slices/officialApkSlice";
import AllApkDataReducer from "./slices/allApkDataSlice";
import certificateDataReducer from "./slices/certificateSlice";
import OfficialAllApkDataReducer from "./slices/officialAllApkDataSlice";

export const store = configureStore({
  reducer: {
    userAuth: userAuthReducer,
    brandsData: brandDataReducer,
    resultsData: resultDataReducer,
    resultEditData: resultEditReducer,
    rulesData: rulesDataReducer,
    alertData: alertDataReducer,
    settingsData: settingDataReducer,
    urlsData: urlsDataReducer,
    UploadApkData: uploadApkDataReducer,
    ApksResultsData: ApksResultsDataReducer,
    SeoPoisonData: SeoPoisonDataReducer,
    taskStatusData: taskStatusDataReducer,
    officialApksData: officialApksDataReducer,
    AllApkData: AllApkDataReducer,
    certificateData: certificateDataReducer,
    officialAllApkData: OfficialAllApkDataReducer,
  },
});
