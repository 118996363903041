import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { getCertificateData } from "reduxConfig/slices/certificateSlice";
import { updatePageNumberCertificate } from "reduxConfig/slices/certificateSlice";
import { updateQueryStringCertificate } from "reduxConfig/slices/certificateSlice";
import { toggleIsRegix } from "reduxConfig/slices/certificateSlice";
import { toggleSearchInfoModal } from "reduxConfig/slices/certificateSlice";

function SearchBar() {
  const navigate = useNavigate();
  const isRegex = useSelector((state) => state.certificateData.isRegex);
  const searchString = useSelector(
    (state) => state.certificateData.queryString
  );
  const dispatch = useDispatch();
  const [tagsVisible, setTagsVisible] = useState(false);

  const handleSearch = () => {
    if (isRegex) {
      try {
        new RegExp(searchString);
      } catch (error) {
        dispatch(
          updateAlert({
            alertText: "Invalid regular expression query",
            alertType: "Error",
          })
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);
        return;
      }
    }

    if (searchString.length === 0) {
      navigate(`/admin/certificate`);
      dispatch(getCertificateData());
      dispatch(updatePageNumberCertificate(1));
    } else {
      navigate(`/admin/certificate?query=${searchString}`);
      dispatch(getCertificateData());
      dispatch(updatePageNumberCertificate(1));
      setTagsVisible(true);
    }
  };

  const handleRemoveTag = (removedTag) => {
    const updatedSearchTerms = searchString
      .split(" ")
      .filter((term) => term !== removedTag);
    const updatedQueryString = updatedSearchTerms.join(" ");

    // Perform search for the remaining tags
    navigate(`/admin/certificate?query=${updatedQueryString}`);
    dispatch(updateQueryStringCertificate(updatedQueryString));
    dispatch(getCertificateData());
    dispatch(updatePageNumberCertificate(1));

    // Update the tags visibility based on remaining tags
    setTagsVisible(updatedSearchTerms.length > 0);

    // If there are remaining tags, update the visibility after a short delay
    if (updatedSearchTerms.length > 0) {
      setTimeout(() => {
        setTagsVisible(true);
      }, 100);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  useEffect(() => {
    setTagsVisible(false);
  }, [searchString]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParam = urlParams.get("query");
    if (queryParam) {
      dispatch(updateQueryStringCertificate(queryParam));
      setTagsVisible(true);
    }
  }, [dispatch]);

  return (
    <div>
      <div className="flex w-[600px] items-center">
        <div
          className="relative flex h-12 w-[600px] items-center justify-between overflow-hidden  rounded-2xl border
        border-[#9A9A9A] bg-white p-2 focus-within:shadow-lg"
        >
          <input
            className="peer h-full w-[600px] pr-2 text-sm text-[#000000] outline-none "
            type="text"
            id="search"
            value={searchString}
            onChange={(e) => {
              dispatch(updateQueryStringCertificate(e.target.value));
            }}
            onKeyDown={(event) => {
              handleKeyPress(event);
            }}
            placeholder="Search"
          />

          <div
            className="flex h-full cursor-pointer place-items-center text-gray-300"
            onClick={handleSearch}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>
        </div>
        <div className="">
          <a
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Select the checkbox to enable the regex."
            data-tooltip-place="right"
          >
            <input
              className="ml-2 flex h-6 w-6 cursor-pointer items-center"
              type="checkbox"
              checked={isRegex}
              onChange={(e) => dispatch(toggleIsRegix())}
            />
          </a>
          <Tooltip className="z-10" id="my-tooltip" />
        </div>
        {/* <div>
          <div className="group relative cursor-pointer">
            <a
              data-tooltip-id="my-tooltip-search"
              data-tooltip-html="Click the '?' icon to view the modal with example search operators."
              data-tooltip-place="top"
            >
              <BsQuestion
                onClick={() => {
                  dispatch(toggleSearchInfoModal());
                }}
                className="mx-2 flex h-10 w-8 rounded-xl border border-[#4318FF] bg-[#BFDBFE] p-[2px]"
                color="#4318FF"
              />
            </a>
            <Tooltip className="z-20" id="my-tooltip-search" />
          </div>
        </div> */}
      </div>
      <div className="absolute mt-2 flex max-h-10 overflow-y-auto">
        <div className="flex w-[30vw] flex-wrap">
          {tagsVisible &&
            searchString.split(" ").map((tag, index) => (
              <div
                key={index}
                className="ml-2 mb-2 flex max-w-[30v] items-center break-all rounded-md border bg-[#4318FF] px-2 py-1 text-white"
              >
                <span>{tag}</span>
                <span
                  className="ml-2 cursor-pointer text-[#000]"
                  onClick={() => handleRemoveTag(tag)}
                >
                  <svg
                    className="fill-current mx-auto h-6 w-6 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                    />
                  </svg>
                </span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
export default SearchBar;
