import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const initialState = {
  error: null,
  status: null,
  isLoading: false,
  queryString: QueryStringHandler(),
  apkResultData: [],
};

export const getApkDatas = createAsyncThunk("apk/datas", () => {
  let stateData = store.getState();
  let resultsData = stateData.officialAllApkData;
  let dataToPost = {
    query: `apk_id:"${resultsData.queryString}"`,
    is_official: true,
  };

  return apiCall("/sw_apk/apk_feed", "post", dataToPost);
});

export const OfficialAllApkDataReducer = createSlice({
  name: "officialAllApkData",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getApkDatas.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApkDatas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apkResultData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalResults = action.payload.data.data.total_results;
      })
      .addCase(getApkDatas.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {} = OfficialAllApkDataReducer.actions;

export default OfficialAllApkDataReducer.reducer;
