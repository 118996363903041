import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyWordInput from "./KeyWordInput";
import { UpdateNewClientData } from "reduxConfig/slices/brandSlice";
import { toggleClientCreateModal } from "reduxConfig/slices/brandSlice";
import { CreateNewClient } from "reduxConfig/slices/brandSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { clearNewClient } from "reduxConfig/slices/brandSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";

function CreateClient() {
  const dispatch = useDispatch();

  const clientData = useSelector((state) => state.brandsData.newClientData);
  const checkClientName = useSelector(
    (state) => state.brandsData.newClientData.clientName
  );
  const dialogBoxRef = useRef(null);
  const [validationError, setValidationError] = useState(false);

  const handleClickOutside = (e) => {
    if (dialogBoxRef.current && !dialogBoxRef.current.contains(e.target)) {
      dispatch(toggleClientCreateModal());
      dispatch(clearNewClient());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUpload = () => {
    // Validate required fields
    if (!checkClientName) {
      setValidationError(true);

      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    // Perform the upload
    dispatch(CreateNewClient());
  };

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[30%] z-20 mt-20 w-[80vw] flex-col justify-center py-6 sm:py-12">
        <div
          className="top m-2 w-[40vw] rounded-lg bg-white  p-8"
          ref={dialogBoxRef}
        >
          <div className="flex flex-col items-center justify-center">
            <div class="flex items-center justify-center">
              <h2 class="text-3xl font-semibold">Create Client</h2>
            </div>
            <div class="mb-6  mt-5 grid gap-6 md:grid-cols-1">
              <div>
                <div className="flex">
                  <label
                    for="first_name"
                    class="mb-2 block text-base font-semibold text-[#000000] dark:text-white"
                  >
                    Client Name
                    <sup className="text-red-500">&#8727;</sup>
                  </label>
                  <div className="ml-1 text-lg font-normal">
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html="Here you can add </br> a name for your client"
                      data-tooltip-place="right"
                    >
                      <BsQuestion
                        className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                        color="#4318FF"
                      />
                    </a>
                    <Tooltip className="z-10" id="my-tooltip" />
                  </div>
                </div>

                <input
                  onChange={(e) => {
                    dispatch(
                      UpdateNewClientData({ clientName: e.target.value })
                    );
                  }}
                  value={clientData.clientName}
                  type="text"
                  id="first_name"
                  class="block w-full  border border-[#000000] bg-white p-2.5 text-sm text-[#000000] focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  placeholder="Enter Client Name"
                  required
                />
              </div>

              <div class="mb-6 w-[40vw]">
                <label
                  for="password"
                  class="mb-2 flex text-base font-semibold text-[#000000] dark:text-white"
                >
                  Brand Keywords
                  <div className="ml-1 text-lg font-normal">
                    <a
                      data-tooltip-id="my-tooltips"
                      data-tooltip-html="
                      These are terms that symbolize a client's products or services.</br> They are employed to identify threat indicators within the </br> discovered results. You can also paste values separated by commas, </br> and it will auto-split"
                      data-tooltip-place="right"
                    >
                      <BsQuestion
                        className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                        color="#4318FF"
                      />
                    </a>
                    <Tooltip className="z-10" id="my-tooltips" />
                  </div>
                </label>
                <KeyWordInput />
              </div>
            </div>

            <div className="flex  w-[80%] justify-center">
              <button
                type="submit"
                class="mr-4 w-full rounded-lg bg-[#4318FF] px-5 py-2.5 text-center font-medium text-white hover:bg-[#4318FF] focus:outline-none focus:ring-4 dark:bg-blue-600 dark:hover:bg-[#4318FF] sm:w-auto"
                onClick={() => {
                  handleUpload();
                }}
              >
                Create
              </button>
              <button
                type="submit"
                class="w-full rounded-lg border border-[#4318FF] bg-white px-5 py-2.5 text-center font-medium  text-[#4318FF]  hover:bg-[#4318FF] hover:text-white focus:outline-none focus:ring-4 dark:bg-[#4318FF] dark:hover:bg-[#4318FF]  sm:w-auto"
                onClick={() => {
                  dispatch(toggleClientCreateModal());
                  dispatch(clearNewClient());
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateClient;
