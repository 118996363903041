import React, { useEffect, useState } from "react";
import { EditBrandToggle } from "reduxConfig/slices/brandSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete, AiOutlineCopy, AiFillAndroid } from "react-icons/ai";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateExistingBrand } from "reduxConfig/slices/brandSlice";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import BrandIcon from "assets/img/brand.svg";
import { toggleBrandDeleteModal } from "reduxConfig/slices/brandSlice";
import { updateBrandDeleteData } from "reduxConfig/slices/brandSlice";
import { FiLink } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import { toggleExportModalBrands } from "reduxConfig/slices/brandSlice";
import { updateExportBrandId } from "reduxConfig/slices/brandSlice";
import getIstTime from "./helpers/istConvertor";

const BrandCards = ({ cardData }) => {
  const dispatch = useDispatch();

  const clientData = useSelector((state) => state.brandsData.clientData);

  let clientName = "NONE";

  if (cardData.client_id && clientData) {
    const client = clientData.find(
      (client) => client._id === cardData.client_id
    );
    if (client) {
      clientName = client.client_name;
    }
  }

  return (
    <div class="w-[25vw] items-center justify-end rounded-lg border border-gray-200 bg-white p-5 shadow dark:border-gray-700 dark:bg-gray-800">
      <div className="flex justify-end p-2">
        <div>
          <a
            data-tooltip-id="my-tooltip-title"
            data-tooltip-html="Click on this LINK icon;  it will navigate you </br> to the APK results page, showing the results </br> based on your selected brands."
            data-tooltip-place="top"
          >
            <FiLink
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              type="button"
              className="m-0 h-8 w-8 cursor-pointer justify-end  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              onClick={(e) => {
                window.open(
                  `/admin/urls_results?query=brand_id:"${cardData._id}"`,
                  "_blank"
                );
              }}
            />
          </a>
          <Tooltip className="z-10" id="my-tooltip-title" />
        </div>
        <div>
          <a
            data-tooltip-id="my-tooltip-title"
            data-tooltip-html="Click on this APK icon;  it will navigate you </br> to the APK results page, showing the results </br> based on your selected clients."
            data-tooltip-place="top"
          >
            <AiFillAndroid
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              type="button"
              className="m-0 ml-1 h-8 w-8 cursor-pointer justify-end  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              onClick={(e) => {
                window.open(
                  `/admin/apks_results?query=brand:"${cardData._id}"`,
                  "_blank"
                );
              }}
            />
          </a>
          <Tooltip className="z-10" id="my-tooltip-title" />
        </div>
        <div>
          <AiOutlineDelete
            data-modal-target="popup-modal"
            data-modal-toggle="popup-modal"
            type="button"
            className="m-0 ml-1 h-8 w-8 cursor-pointer justify-end  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#ff0000] hover:bg-[#ff0000] hover:text-white"
            onClick={(e) => {
              dispatch(
                updateBrandDeleteData({
                  clientName: cardData.brand_name,
                  clientId: cardData._id,
                })
              );
              dispatch(toggleBrandDeleteModal());
            }}
          />
        </div>
      </div>
      <div class="flex flex-col items-center pb-10">
        {cardData.image_url ? (
          <img
            class="mb-3 h-24 w-24 rounded-full shadow-lg"
            src="https://images.unsplash.com/photo-1555274175-75f4056dfd05"
            alt="brand image"
          />
        ) : (
          <img
            src={BrandIcon}
            class="h-[150px] w-[150px] items-center rounded-lg bg-[#F5F8FE] p-2"
            alt=""
          />
        )}

        <h5 class="mb-1 text-xl font-bold text-[#000000] dark:text-white">
          {cardData.brand_name}
        </h5>
        <span class="text-sm text-[#000000]">
          <CopyToClipboard
            text={cardData._id}
            onCopy={() => {
              dispatch(
                updateAlert({
                  alertText: "Brand ID Copied",
                  alertType: "Success",
                })
              );
              setTimeout(() => {
                dispatch(toggleAlert());
              }, 2000);
            }}
          >
            <span className="flex cursor-grabbing">
              {" "}
              {cardData._id}
              <AiOutlineCopy
                className="m-0 h-5 w-5 rounded-md  p-[2px]"
                color="#4318FF"
              />
            </span>
          </CopyToClipboard>
        </span>
      </div>

      <div class="mb-2 items-center justify-start px-4 pt-4">
        <dt class="font-semibold text-[#000000]">Domain:</dt>
        <dd class="items-center text-sm">
          {cardData.domain ? cardData.domain : "NONE"}
        </dd>
      </div>

      <div className="ml-3 justify-start p-1 font-semibold">
        Whitelisted Domains:
      </div>
      {cardData.whitelisted_domains.length ? (
        <div class="ml-3 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap justify-start overflow-auto">
          {cardData.whitelisted_domains.map((keyword) => (
            <div
              className={`mx-1 ${
                keyword.length > 65 ? `` : `h-[2em]`
              } items-center rounded-lg  border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm text-[#000000]`}
            >
              {keyword}
            </div>
          ))}
        </div>
      ) : (
        <div class="ml-3 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap justify-start overflow-auto">
          <div
            className={`} 
               mx-1
              h-[2em] items-center rounded-lg  border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm text-[#000000]`}
          >
            None
          </div>
        </div>
      )}

      <div class="items-center justify-start px-4 pt-4">
        <dt class="font-semibold text-[#000000]">Client:</dt>
        <dd class="items-center text-sm">{clientName}</dd>
      </div>

      <div className="flex justify-between">
        <div class="items-center justify-start px-4 pt-4">
          <dt class="font-semibold text-[#000000]">Date Created:</dt>
          <dd class="flex text-sm">
            {cardData.created_at ? getIstTime(cardData.created_at) : "None"}
          </dd>
        </div>

        <div class="items-center justify-start px-4 pt-4">
          <dt class="font-semibold text-[#000000]">Update At:</dt>
          <dd class="flex text-sm">
            {cardData.updated_at ? getIstTime(cardData.updated_at) : "None"}
          </dd>
        </div>
      </div>

      <div class="mt-4 flex cursor-pointer justify-center space-x-3 md:mt-6">
        <a
          class="dark:focus:ring-blue-[#4318FF] inline-flex items-center rounded-lg bg-[#4318FF] px-4 py-2 text-center text-sm font-medium text-white hover:bg-[#4318FF] focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-[#4318FF] dark:hover:bg-[#4318FF]"
          onClick={() => {
            dispatch(
              updateExportBrandId({
                exportBrandId: cardData._id,
              })
            );
            dispatch(toggleExportModalBrands());
          }}
        >
          Export Data
        </a>
        <a
          class="inline-flex items-center rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-center text-sm font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
          onClick={() => {
            dispatch(
              updateExistingBrand({
                brandName: cardData.brand_name,
                domain: cardData.domain,
                whitelistedDomains: cardData.whitelisted_domains,
                brandId: cardData._id,
              })
            );
            dispatch(EditBrandToggle());
          }}
        >
          Edit Brand
        </a>
      </div>
    </div>
  );
};

export default BrandCards;
