import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { toggleShowSearchOperatorModal } from "reduxConfig/slices/taskStatusSlice";

const sections = [
  {
    title: "Search Keyword in Note",
    content: "Search Keyword in the note of the result",
    query: "note:$search_keyword",
    example: 'note:"sample"',
  },
  {
    title: "Search Keyword in Source",
    content: "Search Keyword in the Source of the result",
    query: "source:$search_keyword",
    example: 'source:"irctc"',
  },
];

function SearchOperatorModal() {
  const dispatch = useDispatch();

  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleShowSearchOperatorModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-10 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50 backdrop-blur-sm">
      <div className="absolute top-1/2 left-1/2 z-20 mx-auto w-[90%] -translate-x-1/2 -translate-y-1/2 transform sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]">
        <div
          className="relative mx-8 w-[45vw] rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0"
          ref={modalRef}
        >
          <div className="">
            <div className="mb-4 text-center">
              <div className="text-xl font-semibold text-[#000000]">
                SEARCH INFO
              </div>
              <div className=" text-lg text-[#000000]">
                Click to copy the search operator example
              </div>
            </div>

            {sections.map((section, index) => (
              <div key={index} className="m-4 flex items-center">
                <div className="flex w-60 items-center justify-end rounded-md border-2 border-[#4318FF] p-2 text-lg text-[#4318FF] sm:w-72 lg:w-80">
                  <CopyToClipboard
                    text={section.example}
                    onCopy={() => {
                      dispatch(
                        updateAlert({
                          alertText: "Keyword Copied",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                    }}
                  >
                    <span className="flex cursor-grabbing">
                      <BiCopy className="m-0 h-6 w-6 rounded-md  p-[2px] text-[#000000]" />
                    </span>
                  </CopyToClipboard>
                  {section.query}
                </div>
                <span className="mx-2"> - </span>
                <div className="w-72 text-lg text-[#000000] sm:w-80 lg:w-96">
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchOperatorModal;
