import { React, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye, AiOutlineDelete } from "react-icons/ai";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { Tooltip } from "react-tooltip";
import { toggleInfoModal } from "reduxConfig/slices/apkResultSlice";

function ShowInfoModal() {
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT_URL_PARAMS;
  const cardData = useSelector((state) => state.ApksResultsData);
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const dropdownRef = useRef(null);
  const [showDropDown, setShowDropDwon] = useState(false);
  const [selectedOption, setOptioin] = useState("LINK");

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleInfoModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick1 = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropDwon(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick1);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
    };
  }, []);

  const link = `"${cardData.resultId}"`;
  const encodedString = encodeURIComponent(link);

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[30%] z-20 mt-20 w-[30vw] flex-col justify-center py-6 sm:py-12">
        <div class="relative py-3 sm:mx-auto sm:max-w-xl" ref={modalRef}>
          <div class="relative mx-8 h-[10vw] w-[40vw] rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
            <div class="mx-auto">
              <div className="m-2 flex justify-center text-xl font-bold">
                {cardData.resultTitle}
              </div>

              <div className="flex justify-center">
                <div className="flex rounded-lg border border-[#4318FF] bg-[#4318FF]">
                  <div className="flex cursor-pointer justify-center rounded-lg border border-[#4318FF] bg-[#4318FF] px-5 py-1.5 font-medium text-white	">
                    <div class="">
                      {selectedOption == "LINK" ? (
                        <CopyToClipboard
                          text={`${apiEndPoint}admin/apks_results?query=apk_id:${encodedString}`}
                          onCopy={() => {
                            dispatch(
                              updateAlert({
                                alertText: "Link Copied",
                                alertType: "Success",
                              })
                            );
                            setTimeout(() => {
                              dispatch(toggleAlert());
                            }, 2000);
                          }}
                        >
                          <span className="flex">Copy Result's</span>
                        </CopyToClipboard>
                      ) : (
                        <CopyToClipboard
                          text={JSON.stringify(cardData.resultJson)}
                          onCopy={() => {
                            dispatch(
                              updateAlert({
                                alertText: "JSON Copied",
                                alertType: "Success",
                              })
                            );
                            setTimeout(() => {
                              dispatch(toggleAlert());
                            }, 2000);
                          }}
                        >
                          <span className="flex">Copy Result's</span>
                        </CopyToClipboard>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      id="dropdownDefaultButton"
                      data-dropdown-toggle="dropdown"
                      class="inline-flex w-[100px] items-center rounded-lg border border-[#4318FF] bg-white px-5 py-2.5 text-center text-sm font-medium text-[#4318FF] "
                      type="button"
                      onClick={(e) => {
                        setShowDropDwon(!showDropDown);
                      }}
                    >
                      {selectedOption}
                      <svg
                        class="ml-2.5 h-2.5 w-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>

                    <div
                      id="dropdown"
                      class={
                        `absolute z-10 w-[100px]` +
                        (showDropDown ? "" : " hidden")
                      }
                      ref={dropdownRef}
                    >
                      <ul aria-labelledby="dropdownDefaultButton">
                        {selectedOption == "LINK" ? (
                          <li>
                            <a
                              class="block rounded-lg border border-[#4318FF]  bg-white  px-5 py-1.5 font-medium text-[#4318FF]"
                              onClick={(e) => {
                                setOptioin(".JSON");
                                setShowDropDwon(!showDropDown);
                              }}
                            >
                              .JSON
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a
                              class="block rounded-lg border border-[#4318FF]  bg-white  px-5 py-1.5 font-medium text-[#4318FF]"
                              onClick={(e) => {
                                setOptioin("LINK");
                                setShowDropDwon(!showDropDown);
                              }}
                            >
                              Link
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="m-12 flex justify-center">
                <div className="mr-1 flex cursor-pointer">
                <div className="">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Select Icon For Mark The Results As Seen"
                    data-tooltip-place="right"
                  >
                 <AiOutlineEye className="m-0 h-8 w-8 cursor-pointer  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
                  
                </div>
                <div className="">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Select Icon For To Delete The Results"
                    data-tooltip-place="right"
                  >
                    <AiOutlineDelete
                      data-modal-target="popup-modal"
                      data-modal-toggle="popup-modal"
                      type="button"
                      className="m-0 flex h-8 w-8 cursor-pointer  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                      onClick={(e) => {
                        dispatch(
                          updateResultDeleteData({
                            resultName: cardData.resultTitle,
                            resultId: cardData.resultId,
                          })
                        );
                        dispatch(toggleInfoModal());
                        dispatch(toggleDeleteModal());
                      }}
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowInfoModal;
