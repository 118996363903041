/* eslint-disable */
import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";
import UserCard from "./components/UserCard";
import { getSearchResults } from "reduxConfig/slices/resultSlice";
import { useDispatch } from "react-redux";
import { addAdditionalFilters } from "reduxConfig/slices/resultSlice";
import { updateDateFilter } from "reduxConfig/slices/resultSlice";
import { updatePageNumber } from "reduxConfig/slices/resultSlice";
import { updateQueryString } from "reduxConfig/slices/resultSlice";
import { updateQueryStrings } from "reduxConfig/slices/apkResultSlice";
import { updatePageNumberApkResults } from "reduxConfig/slices/apkResultSlice";
import { getApkData } from "reduxConfig/slices/apkResultSlice";
import { toggleIsRegixResults } from "reduxConfig/slices/resultSlice";
import { toggleIsRegixApkResults } from "reduxConfig/slices/apkResultSlice";
import { updateResultPerPage } from "reduxConfig/slices/resultSlice";
import { updateApkResultsPerPage } from "reduxConfig/slices/apkResultSlice";
import { updatePageNumberRules } from "reduxConfig/slices/rulesSlice";
import { updateRulesPerPage } from "reduxConfig/slices/rulesSlice";
import { getRulesData } from "reduxConfig/slices/rulesSlice";
import { addAdditionalFiltersSeoPoisoning } from "reduxConfig/slices/seoPoisonSlice";
import { updateDateFilterSeoPoisoning } from "reduxConfig/slices/seoPoisonSlice";
import { updateQueryStringSeo } from "reduxConfig/slices/seoPoisonSlice";
import { updatePageNumberSeo } from "reduxConfig/slices/seoPoisonSlice";
import { toggleIsRegixSeo } from "reduxConfig/slices/seoPoisonSlice";
import { updateResultPerPageSeo } from "reduxConfig/slices/seoPoisonSlice";
import { getSearchResultsSeo } from "reduxConfig/slices/seoPoisonSlice";
import { updateErrorResults } from "reduxConfig/slices/resultSlice";
import { updateErrorResultsSeo } from "reduxConfig/slices/seoPoisonSlice";
import { updateErrorResultsEdit } from "reduxConfig/slices/resultEditSlice";
import { updateErrorApkResults } from "reduxConfig/slices/apkResultSlice";
import { updateErrorRules } from "reduxConfig/slices/rulesSlice";
import { clearAllDataUrl } from "reduxConfig/slices/urlSlice";
import { clearFiltersApk } from "reduxConfig/slices/apkResultSlice";
import { getApkDataOfficial } from "reduxConfig/slices/officialApkSlice";
import { clearFiltersApkOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateApkResultsPerPageOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateQueryStringsOfficial } from "reduxConfig/slices/officialApkSlice";
import { updatePageNumberApkResultsOfficial } from "reduxConfig/slices/officialApkSlice";
import { toggleIsRegixApkResultsOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateErrorApkResultsOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateDateFilterOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateDateFilterApk } from "reduxConfig/slices/apkResultSlice";
import { updatePageNumberTask } from "reduxConfig/slices/taskStatusSlice";
import { updatePerPageTask } from "reduxConfig/slices/taskStatusSlice";
import { getTaskStatusData } from "reduxConfig/slices/taskStatusSlice";
import { updateQueryStringTask } from "reduxConfig/slices/taskStatusSlice";
import { updateErroTask } from "reduxConfig/slices/taskStatusSlice";
import { updateDateFilterTask } from "reduxConfig/slices/taskStatusSlice";
import { clearFiltersTask } from "reduxConfig/slices/taskStatusSlice";
import { toggleIsRegixTasks } from "reduxConfig/slices/taskStatusSlice";
import { updatePerPageCertificate } from "reduxConfig/slices/certificateSlice";
import { updatePageNumberCertificate } from "reduxConfig/slices/certificateSlice";
import { updateErroCertificate } from "reduxConfig/slices/certificateSlice";
import { getCertificateData } from "reduxConfig/slices/certificateSlice";
import { addAdditionalFiltersApk } from "reduxConfig/slices/apkResultSlice";
import { getClientData } from "reduxConfig/slices/brandSlice";
import { getBrandData } from "reduxConfig/slices/brandSlice";
import { updateErrorBrands } from "reduxConfig/slices/brandSlice";
import { updatePageNumberClientsBrands } from "reduxConfig/slices/brandSlice";
import { updateBrandsPerPage } from "reduxConfig/slices/brandSlice";
import { clearAllDataUpload } from "reduxConfig/slices/uploadApkSlice";
import { getSlackDatas } from "reduxConfig/slices/settingSlice";
import { clearAllDataSetting } from "reduxConfig/slices/settingSlice";
import { toggleIsRegixCerti } from "reduxConfig/slices/certificateSlice";
import { updateQueryStringCertificate } from "reduxConfig/slices/certificateSlice";
import { clearFiltersRules } from "reduxConfig/slices/rulesSlice";
import { clearDataDump } from "reduxConfig/slices/apkResultSlice";
import { clearDataDumpOfficial } from "reduxConfig/slices/officialApkSlice";
import { clearDataDumpUrlResult } from "reduxConfig/slices/resultSlice";
import { clearDataDumpSeo } from "reduxConfig/slices/seoPoisonSlice";

const Sidebar = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const clickHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(
        addAdditionalFilters({
          use_or: false,
          result_type: [],
          entity_type: [],
          source: [],
          marked_as: [],
          possible_url_classifications: [],
        })
      );
      dispatch(
        updateDateFilter({
          selection: { startDate: null, endDate: null },
        })
      );
      dispatch(clearDataDumpUrlResult());
      dispatch(updateQueryString(""));
      dispatch(updatePageNumber(1));
      dispatch(toggleIsRegixResults());
      dispatch(updateResultPerPage("15"));
      dispatch(updateErrorResults());
      dispatch(updateErrorResultsEdit());
      dispatch(getSearchResults());
    }
  };
  const apkResultsClickHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(
        addAdditionalFiltersApk({
          use_or: false,
          threat_indicators: [],
          suggested_classification: [],
        })
      );
      dispatch(
        updateDateFilterApk({
          selection: { startDate: null, endDate: null },
        })
      );
      dispatch(clearDataDump());
      dispatch(clearFiltersApk());
      dispatch(updateApkResultsPerPage("15"));
      dispatch(updateQueryStrings(""));
      dispatch(updatePageNumberApkResults(1));
      dispatch(toggleIsRegixApkResults());
      dispatch(updateErrorApkResults());
      dispatch(getApkData());
    }
  };
  const rulesClickHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(clearFiltersRules());
      dispatch(updatePageNumberRules(1));
      dispatch(updateRulesPerPage(15));
      dispatch(updateErrorRules());
      dispatch(getRulesData());
    }
  };
  const seoPoisoningHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(
        addAdditionalFiltersSeoPoisoning({
          use_or: false,
          result_type: [],
          entity_type: [],
          source: [],
          marked_as: [],
        })
      );
      dispatch(
        updateDateFilterSeoPoisoning({
          selection: { startDate: null, endDate: null },
        })
      );
      dispatch(clearDataDumpSeo());
      dispatch(updateQueryStringSeo(""));
      dispatch(updatePageNumberSeo(1));
      dispatch(toggleIsRegixSeo());
      dispatch(updateResultPerPageSeo("15"));
      dispatch(updateErrorResultsSeo());
      dispatch(getSearchResultsSeo());
    }
  };
  const importUrlsHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(clearAllDataUrl());
    }
  };
  const officialApksClickHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      // dispatch(
      //   addAdditionalFilters({
      //     use_or: false,
      //     result_type: [],
      //     entity_type: [],
      //     source: [],
      //     marked_as: [],
      //   })
      // );
      dispatch(
        updateDateFilterOfficial({
          selection: { startDate: null, endDate: null },
        })
      );
      dispatch(clearDataDumpOfficial());
      dispatch(clearFiltersApkOfficial());
      dispatch(updateApkResultsPerPageOfficial("15"));
      dispatch(updateQueryStringsOfficial(""));
      dispatch(updatePageNumberApkResultsOfficial(1));
      dispatch(toggleIsRegixApkResultsOfficial());
      dispatch(updateErrorApkResultsOfficial());
      dispatch(getApkDataOfficial());
    }
  };
  const TaskClickHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(
        updateDateFilterTask({
          selection: { startDate: null, endDate: null },
        })
      );
      dispatch(clearFiltersTask());
      dispatch(updatePerPageTask("15"));
      dispatch(updatePageNumberTask(1));
      dispatch(updateQueryStringTask(""));
      dispatch(toggleIsRegixTasks());
      dispatch(updateErroTask());
      dispatch(getTaskStatusData());
    }
  };
  const CertiClickHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(updateQueryStringCertificate(""));
      dispatch(toggleIsRegixCerti());
      dispatch(updatePerPageCertificate("15"));
      dispatch(updatePageNumberCertificate(1));
      dispatch(updateErroCertificate());
      dispatch(getCertificateData());
    }
  };
  const ClientsBrandsHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(updateBrandsPerPage("15"));
      dispatch(updatePageNumberClientsBrands(1));
      dispatch(updateErrorBrands());
      dispatch(getClientData());
      dispatch(getBrandData());
    }
  };
  const uploadApkHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(clearAllDataUpload());
    }
  };
  const SettingsHandler = (event) => {
    if (event.detail == 2 || event.detail == 1) {
      dispatch(clearAllDataSetting());
      dispatch(getSlackDatas());
    }
  };
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full w-[16vw] flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] mt-[50px] flex items-center justify-center`}>
        <div className="mt-1  flex h-2.5 whitespace-nowrap font-poppins text-[1.3em] font-bold uppercase text-navy-700 dark:text-white">
          {/* MOD APK <span class="ml-1 font-medium">MON</span> */}
          APPMON
        </div>
      </div>
      <div class="mt-[58px] mb-7 h-px bg-gray-300 dark:bg-white/30" />

      <ul
        className="mb-auto pt-1"
        onClick={(e) => {
          if (e.target.outerText == "URL Results") {
            clickHandler(e);
          }
          if (e.target.outerText == "APK Results") {
            apkResultsClickHandler(e);
          }
          if (e.target.outerText == "Rules") {
            rulesClickHandler(e);
          }
          if (e.target.outerText == "Seo Poisoning") {
            seoPoisoningHandler(e);
          }
          if (e.target.outerText == "Import URLs") {
            importUrlsHandler(e);
          }
          if (e.target.outerText == "Official APKs") {
            officialApksClickHandler(e);
          }
          if (e.target.outerText == "Task Status") {
            TaskClickHandler(e);
          }
          if (e.target.outerText == "Certificate") {
            CertiClickHandler(e);
          }
          if (e.target.outerText == "Clients & Brands") {
            ClientsBrandsHandler(e);
          }
          if (e.target.outerText == "Upload APKs") {
            uploadApkHandler(e);
          }
          if (e.target.outerText == "Settings") {
            SettingsHandler(e);
          }
        }}
      >
        <Links routes={routes} />
      </ul>

      <div className="grid items-center justify-center">
        <UserCard />
      </div>
    </div>
  );
};

export default Sidebar;
