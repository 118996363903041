import React, { useState, useEffect, useRef } from "react";
import BrandSelect from "./BrandSelect";
import TypeSelect from "./TypeSelect";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { toggleEditModal } from "reduxConfig/slices/resultEditSlice";
import { setResultId } from "reduxConfig/slices/resultEditSlice";
import { AddUserActions } from "reduxConfig/slices/resultEditSlice";
import { toggleUserSelection } from "reduxConfig/slices/resultEditSlice";
import { AiOutlineUpload, AiOutlineReload } from "react-icons/ai";
import apiCall from "axiosConfig/axiosConfig";
import axios from "axios";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { updateComment } from "reduxConfig/slices/resultEditSlice";

function EditModal() {
  const postData = useSelector((state) => state.resultEditData);
  const userAction = useSelector((state) => state.resultEditData.choosenType);
  const defaultData = useSelector((state) => state.resultEditData);
  const [selectedImage, setSelectedImage] = useState(defaultData.resultImg);
  const [imageURL, setImageURL] = useState(null);

  const checkIndicator = useSelector(
    (state) => state.resultEditData.choosenType
  );
  const checkTargetedBrands = useSelector(
    (state) => state.resultEditData.brandData
  );

  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);

  const handleUpload = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        const response = await axios.post(
          `http://127.0.0.1:9000/sw_misc/upload_preview/${postData.resultId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          // Handle a successful upload here
        } else {
          // Handle an error here
        }
      } catch (error) {
        // Handle network or other errors here
      }
    }
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      const reader = new FileReader();
      reader.onload = () => {
        setImageURL(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedImage(null);
    }
  };

  useEffect(() => {
    setImageURL(defaultData.resultImg);
  }, [defaultData.resultImg]);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleEditModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const dispatch = useDispatch();

  const handleUploads = () => {
    // Validate required fields
    if (checkIndicator === "NONE") {
      dispatch(AddUserActions());
      dispatch(toggleEditModal());
    } else {
      if (!checkIndicator || !checkTargetedBrands.length) {
        setValidationError(true);

        dispatch(
          updateAlert({
            alertText: "Please Fill The Required Fields",
            alertType: "Error",
          })
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);

        return;
      }

      // Clear validation error state
      setValidationError(false);

      // Perform the upload
      dispatch(AddUserActions());
      dispatch(toggleEditModal());
    }
  };

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 flex-col justify-center ">
        <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
          <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
            <div className="flex items-center justify-center font-semibold text-[#000000]">
              {postData.resultTitle}
            </div>
            <div className="flex items-center justify-center  text-[#000000]">
              {postData.resultId}
            </div>
            <div className="mt-4 mb-1 font-semibold">
              <div className="flex items-center">
                Select Indicator:
                <sup className="text-red-500">&#8727;</sup>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here you can choose </br> a result is </br> whitelisted or threat"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </div>
            </div>
            <div>
              <select
                value={defaultData.choosenType}
                onChange={(e) => {
                  dispatch(toggleUserSelection(e.target.value));
                }}
                className="w-full  border border-[#000000] bg-white p-2 py-2 px-4 text-[#000000] sm:text-sm"
              >
                <option value="NONE">None</option>
                <option value="WHITELISTED">Whitelisted</option>
                <option value="THREAT">Threat</option>
              </select>
            </div>
            <div className="mt-3 mb-1 flex items-center font-semibold">
              Select Brand:
              <sup className="text-red-500">&#8727;</sup>
              <div className="ml-1 text-lg font-normal">
                <a
                  data-tooltip-id="my-tooltip-URLs"
                  data-tooltip-html="Here you can assign a </br> brands for your results"
                  data-tooltip-place="right"
                >
                  <BsQuestion
                    className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                    color="#4318FF"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip-URLs" />
              </div>
            </div>
            <BrandSelect isWhitelisted={userAction == "WHITELISTED"} />
            <TypeSelect type={false} />
            <TypeSelect type={true} />
            <div className="mt-3 mb-1 flex items-center font-semibold">
              Upload Screenshot:
              <div className="ml-1 text-lg font-normal">
                <a
                  data-tooltip-id="my-tooltip-URLs"
                  data-tooltip-html="Here you can upload image </br> for your result"
                  data-tooltip-place="right"
                >
                  <BsQuestion
                    className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                    color="#4318FF"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip-URLs" />
              </div>
            </div>
            <label htmlFor="imageUpload" className="custom-file-upload w-full">
              <div className="flex justify-between">
                <span>Upload File</span>
                <input
                  type="file"
                  id="imageUpload"
                  accept="image/*"
                  onChange={handleImageChange}
                />
                <span>
                  <AiOutlineUpload
                    className="h-6 w-6 rounded-md p-[2px]"
                    color="#4318FF"
                  />
                </span>
              </div>
            </label>
            {selectedImage && (
              <div className="flex justify-center">
                <div className="flex items-center justify-center">
                  <img
                    src={imageURL}
                    alt="Selected Screenshot"
                    className="mt-2 h-40 w-44 rounded-lg"
                  />
                </div>

                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    apiCall("sw_misc/refresh_preview", "post", {
                      results_id: postData.resultId,
                    });
                  }}
                >
                  <AiOutlineReload
                    className="h-6 w-6  cursor-pointer p-[1px]"
                    color="#4318FF"
                  />
                </div>
              </div>
            )}
            <div class="flex flex-col">
              <label class="flex items-center font-semibold leading-loose">
                Comment:
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here you can add your comments"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </label>
              <input
                type="text"
                class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:outline-none "
                placeholder="Researcher comment"
                value={defaultData.comment}
                onChange={(e) => {
                  dispatch(updateComment(e.target.value));
                }}
              />
            </div>
            <div class="flex items-center justify-center space-x-4 pt-4">
              <button
                className="flex w-1/4 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white focus:outline-none"
                onClick={() => {
                  handleUpload();
                  handleUploads();
                }}
              >
                Save
              </button>
              <button
                class="flex w-1/4 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
                onClick={() => {
                  dispatch(toggleEditModal());
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditModal;
