import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { AiOutlineCopy } from "react-icons/ai";
import EditBrandSelect from "./EditBrandSelect";
import EditClientSelect from "./EditClientSelect";
import noPreview from "assets/img/androdApk.png";
import { IoMdDownload } from "react-icons/io";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { toggleShowEditModal } from "reduxConfig/slices/officialApkSlice";
import { toggleApkDetailsModal } from "reduxConfig/slices/officialApkSlice";
import { toggleShowEditModalFalseOfficial } from "reduxConfig/slices/officialApkSlice";

function ViewAllDetailsCard() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const defaultCardData = useSelector(
    (state) => state.officialApksData.defaultData
  );
  const showEditModal = useSelector(
    (state) => state.officialApksData.showEditModal
  );

  const brandData = useSelector((state) => state.brandsData.brandData);
  const clientData = useSelector((state) => state.brandsData.clientData);

  const getClientName = (client_id) => {
    const clientName = clientData.find((element) => element._id == client_id);
    if (clientName) {
      return clientName.client_name;
    }
    return "Client Deleted";
  };

  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "Brand Deleted";
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleApkDetailsModal());
      dispatch(toggleShowEditModalFalseOfficial());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className="relative mb-10 mt-5 w-full max-w-[40vw] rounded-[20px] bg-white p-[18px] shadow-3xl shadow-shadow-500 md:max-w-[80vw] lg:max-w-[60vw] xl:max-w-[40vw]"
        ref={modalRef}
      >
        <div>
          <div className="">
            <button className="absolute top-1 left-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"></button>
          </div>

          <div className="flex w-[39vw] justify-between">
            <div>
              <div className="flex items-center justify-start">
                <div className="flex w-[85%] items-center justify-start">
                  <p
                    className={`hide-scrollbar mb-2 flex justify-start whitespace-nowrap p-1 text-center text-xl font-bold ${
                      defaultCardData?.title ? "text-navy-700" : "text-red-500"
                    } `}
                    style={{
                      maxWidth: "80%",
                      overflow:
                        defaultCardData?.title &&
                        defaultCardData?.title?.length > 15
                          ? "auto "
                          : "hidden",
                    }}
                  >
                    {defaultCardData?.title
                      ? defaultCardData?.title.toUpperCase()
                      : "NO NAME"}
                  </p>
                </div>
              </div>

              <div class="text-md mb-6 -mt-4 flex justify-start p-1 text-center font-medium text-gray-800">
                <CopyToClipboard
                  text={defaultCardData?.id}
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "Result ID Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <span className="flex cursor-grabbing">
                    {" "}
                    {defaultCardData?.id}
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 rounded-md  p-[2px]"
                      color="#4318FF"
                    />
                  </span>
                </CopyToClipboard>
              </div>

              <div className="flex w-[135%] justify-between">
                <div class="flex items-center justify-between px-1 md:items-start">
                  <div class="mb-6">
                    <dl class="grid-cols-2">
                      <div class="font-semibold">Cert-Organization Name</div>
                      <div class="text-[#000000]">
                        {defaultCardData?.organizationName
                          ? defaultCardData.organizationName
                          : "NONE"}
                      </div>
                    </dl>
                  </div>
                </div>
                <div class="flex items-center justify-between px-1 md:items-start">
                  <div class="">
                    <dl class="grid-cols-2">
                      <div class="font-semibold">Cert-Common Name</div>
                      <div class="text-[#000000]">
                        {defaultCardData?.commonName
                          ? defaultCardData.commonName
                          : "NONE"}
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div class="-mt-5 flex h-[35%] w-[25%] rounded-lg">
              <div class="relative flex w-full justify-center rounded-lg bg-[#ADB7D5] p-2">
                <img
                  src={
                    defaultCardData?.apkIcon
                      ? `https://dsgx98c21wj98.cloudfront.net/${defaultCardData.apkIcon}`
                      : noPreview
                  }
                  class="h-[150px] w-[150px] items-center rounded-xl"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex p-1 text-left font-semibold md:items-start">
          Source
        </div>
        {defaultCardData?.source?.length ? (
          <div className="mb-2 flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(defaultCardData.source).map((source, index) => {
              let domainName = source;

              try {
                const url = new URL(source);
                domainName = url.hostname;
              } catch (error) {
                console.error(
                  `Error creating URL object for source: ${source}`
                );
              }

              return (
                <div
                  key={index}
                  className="text-#000000 mx-1 flex h-[20%] items-center break-all rounded-lg border-2 border-[#000000] p-1 text-center text-sm"
                >
                  {domainName}
                  <CopyToClipboard
                    text={source}
                    onCopy={() => {
                      dispatch(
                        updateAlert({
                          alertText: "Source Copied",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                    }}
                  >
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 rounded-md  p-[2px]"
                      color="#4318FF"
                    />
                  </CopyToClipboard>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mb-2 flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap overflow-auto">
            <div className="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
              {"NONE"}
            </div>
          </div>
        )} */}

        {/* <div className="p-1 text-left font-semibold md:items-start">
          Classifications
        </div>
        {defaultCardData?.classification?.length ? (
          <div class="mb-2 flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(defaultCardData.classification).map((brand_data) => (
              <div class="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
                {brand_data.value}
              </div>
            ))}
          </div>
        ) : (
          <div class="mb-2 flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap overflow-auto">
            <div class="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
              {"NONE"}
            </div>
          </div>
        )} */}

        <div className="flex p-1 text-left font-semibold md:items-start">
          Download Apk
          <div className="ml-1 text-lg font-normal">
            <a
              data-tooltip-id="my-tooltip-title"
              data-tooltip-html="Here you can click the </br>  icon it will download </br>  the apk"
              data-tooltip-place="right"
              href={`https://dsgx98c21wj98.cloudfront.net/${defaultCardData?.downloadLinks}`}
              // target="_blank"
            >
              <IoMdDownload className="h-6 w-7 cursor-pointer items-center rounded-md border border-[#4318FF] p-[2px] text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
            </a>
            <Tooltip className="z-10" id="my-tooltip-title" />
          </div>
        </div>
        {/* <div className="flex">
          <div>
            {defaultCardData?.downloadLinks ? (
              <div class="flex max-h-[3em] min-h-[3em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
                  {defaultCardData.downloadLinks}
                </div>
              </div>
            ) : (
              <div class="flex max-h-[3em] min-h-[3em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
                  {"NONE"}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="ml-1 text-lg font-normal">
              <a
                data-tooltip-id="my-tooltip-title"
                data-tooltip-html="Here you can click the </br>  icon it will download </br>  the apk"
                data-tooltip-place="right"
                href={`https://dsgx98c21wj98.cloudfront.net/${defaultCardData?.downloadLinks}`}
                // target="_blank"
              >
                <IoMdDownload className="h-8 w-7 cursor-pointer items-center rounded-md border border-[#4318FF] p-[2px] text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
              </a>
              <Tooltip className="z-10" id="my-tooltip-title" />
            </div>
          </div>
        </div> */}

        <div className="flex justify-between">
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-4">
              <dl class="grid-cols-2">
                <div class="flex items-center font-semibold">Indicators</div>
                <div className="text-sm text-green-500">OFFICIAL</div>
              </dl>
            </div>
          </div>
          <div class="flex w-[55%] items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">md5</div>
                <div class="flex text-[#000000]">
                  {defaultCardData.md5}
                  <CopyToClipboard
                    text={defaultCardData.md5}
                    onCopy={() => {
                      dispatch(
                        updateAlert({
                          alertText: "MD5 Copied",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                    }}
                  >
                    <span className="flex cursor-grabbing">
                      <AiOutlineCopy
                        className="m-0 flex h-6 w-6  rounded-md p-[2px]"
                        color="#4318FF"
                      />
                    </span>
                  </CopyToClipboard>
                </div>
              </dl>
            </div>
          </div>
        </div>

        <div className="mb-2 flex">
          <div className="p-1 text-left font-semibold ">Sha1: </div>
          <div class="mt-1 flex items-center px-1 text-center md:items-start">
            <div class="flex text-[#000000]">
              {defaultCardData.sha1}
              <CopyToClipboard
                text={defaultCardData.sha1}
                onCopy={() => {
                  dispatch(
                    updateAlert({
                      alertText: "sha1 Copied",
                      alertType: "Success",
                    })
                  );
                  setTimeout(() => {
                    dispatch(toggleAlert());
                  }, 2000);
                }}
              >
                <span className="flex cursor-grabbing">
                  <AiOutlineCopy
                    className="m-0 flex h-6 w-6  rounded-md p-[2px]"
                    color="#4318FF"
                  />
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className="mb-2 flex">
          <div className="p-1 text-left font-semibold ">Sha256: </div>
          <div class="mt-1 flex items-center px-1 text-center md:items-start">
            <div class="flex text-[#000000]">
              {defaultCardData.sha256}
              <CopyToClipboard
                text={defaultCardData.sha256}
                onCopy={() => {
                  dispatch(
                    updateAlert({
                      alertText: "sha256 Copied",
                      alertType: "Success",
                    })
                  );
                  setTimeout(() => {
                    dispatch(toggleAlert());
                  }, 2000);
                }}
              >
                <span className="flex cursor-grabbing">
                  <AiOutlineCopy
                    className="m-0 flex h-6 w-6  rounded-md p-[2px]"
                    color="#4318FF"
                  />
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className="mb-2 flex">
          <div className="p-1 text-left font-semibold ">Certificate Sha1: </div>
          <div class="mt-1 flex items-center px-1 text-center md:items-start">
            <div class="flex text-[#000000]">
              {defaultCardData?.cert_sha? defaultCardData.cert_sha : "None"}
              <CopyToClipboard
                text={defaultCardData?.cert_sha}
                onCopy={() => {
                  dispatch(
                    updateAlert({
                      alertText: "Certificate Sha1",
                      alertType: "Success",
                    })
                  );
                  setTimeout(() => {
                    dispatch(toggleAlert());
                  }, 2000);
                }}
              >
                <span className="flex cursor-grabbing">
                  <AiOutlineCopy
                    className="m-0 flex h-6 w-6  rounded-md p-[2px]"
                    color="#4318FF"
                  />
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className="flex items-center p-1 text-left font-semibold">
          Selected Clients
          {/* <div className="ml-1 text-lg font-normal">
            <a
              data-tooltip-id="my-tooltip-title"
              data-tooltip-html="Here you can select the</br> Clients for your apk file"
              data-tooltip-place="right"
            >
              <BsQuestion
                className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                color="#4318FF"
              />
            </a>
            <Tooltip className="z-10" id="my-tooltip-title" />
          </div> */}
        </div>

        {showEditModal ? (
          <div>{/* <EditClientSelect /> */}</div>
        ) : (
          <div class="ml-1 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {defaultCardData?.targetedClients?.length &&
            defaultCardData?.targetedClients[0] !== "" ? (
              <>
                {Object.values(defaultCardData?.targetedClients).map(
                  (client_id) => (
                    <div
                      class="mr-1 h-[35%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm text-[#000000]"
                      style={{ marginBottom: "2px" }}
                    >
                      {getClientName(client_id)}
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                <div class="mr-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  p-1 text-center text-sm text-[#000000]">
                  Client Not Selected
                </div>
              </>
            )}
          </div>
        )}

        <div className="flex items-center p-1 text-left font-semibold">
          Selected Brands
          {/* <div className="ml-1 text-lg font-normal">
            <a
              data-tooltip-id="my-tooltip-title"
              data-tooltip-html="Here you can select the</br> Brands for your apk file"
              data-tooltip-place="right"
            >
              <BsQuestion
                className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                color="#4318FF"
              />
            </a>
            <Tooltip className="z-10" id="my-tooltip-title" />
          </div> */}
        </div>
        {showEditModal ? (
          <div>{/* <EditBrandSelect /> */}</div>
        ) : (
          <div class="ml-1 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {defaultCardData?.targetedBrands?.length &&
            defaultCardData?.targetedBrands[0] !== "" ? (
              <>
                {Object.values(defaultCardData?.targetedBrands).map(
                  (brand_id) => (
                    <div
                      class="h-[35%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm text-[#000000]"
                      style={{ marginBottom: "2px" }}
                    >
                      {getBrandName(brand_id)}
                    </div>
                  )
                )}
              </>
            ) : (
              <>
                <div class="h-[35%] items-center rounded-lg border-2 border-[#000000]  p-1 text-center text-sm text-[#000000]">
                  Brand Not Selected
                </div>
              </>
            )}
          </div>
        )}

        <div className="flex w-[66%] justify-between">
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-4">
              <dl class="grid-cols-2">
                <div class="font-semibold">Created At</div>
                <div class="text-[#000000]">{defaultCardData.createdAt}</div>
              </dl>
            </div>
          </div>
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Last Seen</div>
                <div class="text-[#000000]">{defaultCardData.lastSeen}</div>
              </dl>
            </div>
          </div>
        </div>

        {showEditModal ? (
          <></>
        ) : (
          // <div className="ml-1 flex justify-between">
          //   <div class="mb-2">
          //     <dl class="grid-cols-2">
          //       <div class="flex font-semibold">
          //         Comment
          //         <div className="ml-1 text-lg font-normal">
          //           <a
          //             data-tooltip-id="my-tooltip-title"
          //             data-tooltip-html="Here you can Add/Edit comment</br>for your apk file"
          //             data-tooltip-place="right"
          //           >
          //             <BsQuestion
          //               className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
          //               color="#4318FF"
          //             />
          //           </a>
          //           <Tooltip className="z-10" id="my-tooltip-title" />
          //         </div>
          //       </div>
          //       <input
          //         type="text"
          //         className="hide-scrollbar flex max-h-[4em] min-h-[4em] w-[27vw] flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start"
          //         defaultValue={defaultCardData.comment}
          //         onChange={(e) => {
          //           const enteredUrls = e.target.value;
          //           const urlsArray = enteredUrls.split(",");
          //           dispatch(
          //             updateNewUrl({
          //               enterUrls: urlsArray,
          //             })
          //           );
          //         }}
          //       />
          //     </dl>
          //   </div>
          //   <div className="flex items-center justify-end">
          //     <button
          //       className="m-1 flex h-10 w-20 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
          //       onClick={(e) => {
          //         dispatch(toggleShowEditModal());
          //         dispatch(toggleApkDetailsModal());
          //       }}
          //     >
          //       Save
          //     </button>

          //     <button
          //       className="m-1 flex h-10 w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
          //       onClick={(e) => {
          //         dispatch(toggleShowEditModal());
          //         dispatch(toggleApkDetailsModal());
          //       }}
          //     >
          //       Close
          //     </button>
          //   </div>
          // </div>
          <div className=" flex justify-between px-1">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="flex font-semibold ">
                  Comment
                  {/* <div className="ml-1 text-lg font-normal">
                    <a
                      data-tooltip-id="my-tooltip-title"
                      data-tooltip-html="Here you can Add/Edit comment</br>for your apk file"
                      data-tooltip-place="right"
                    >
                      <BsQuestion
                        className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                        color="#4318FF"
                      />
                    </a>
                    <Tooltip className="z-10" id="my-tooltip-title" />
                  </div> */}
                </div>
                <div class="text-[#000000]">
                  {defaultCardData.comment ? defaultCardData.comment : "NONE"}
                </div>
              </dl>
            </div>
            <div className="flex justify-end ">
              {/* <button
                className="m-1 flex w-20 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
                onClick={(e) => {
                  dispatch(toggleShowEditModal());
                }}
              >
                Edit
              </button> */}

              <button
                className="m-1 flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={(e) => {
                  dispatch(toggleApkDetailsModal());
                }}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ViewAllDetailsCard;
