import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { toggleViewAllData } from "reduxConfig/slices/certificateSlice";
import { AiOutlineCopy, AiFillAndroid } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

function ViewAllData() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const defaultCardData = useSelector(
    (state) => state.certificateData.defaultData
  );

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleViewAllData());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className="relative mb-10 mt-5 w-full max-w-[40vw] rounded-[20px] bg-white p-[18px] shadow-3xl shadow-shadow-500 md:max-w-[80vw] lg:max-w-[60vw] xl:max-w-[40vw]"
        ref={modalRef}
      >
        <div>
          <div>
            <div className="flex items-center justify-start">
              <div className="flex w-[85%] items-center justify-start">
                <p
                  className="hide-scrollbar mb-2 flex justify-start whitespace-nowrap p-1 text-center text-xl font-bold text-navy-700"
                  style={{
                    maxWidth: "80%",
                    overflow:
                      defaultCardData.organization_name &&
                      defaultCardData.organization_name.length > 15
                        ? "auto "
                        : "hidden",
                  }}
                >
                  {defaultCardData.organization_name
                    ? defaultCardData.organization_name.toUpperCase()
                    : "No Organization Name"}
                </p>
              </div>
            </div>

            <div class="text-md mb-6 -mt-4 flex justify-start p-1 text-center font-medium text-gray-800">
              <CopyToClipboard
                text={defaultCardData._id}
                onCopy={() => {
                  dispatch(
                    updateAlert({
                      alertText: "Result ID Copied",
                      alertType: "Success",
                    })
                  );
                  setTimeout(() => {
                    dispatch(toggleAlert());
                  }, 2000);
                }}
              >
                <span className="flex cursor-grabbing">
                  {" "}
                  {defaultCardData._id}
                  <AiOutlineCopy
                    className="m-0 h-6 w-6 rounded-md  p-[2px]"
                    color="#4318FF"
                  />
                </span>
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className=" m-1 mb-4 flex  justify-between">
          <div>
            <div class="font-semibold">Common Name</div>
            <div className={``}>
              {defaultCardData.common_name
                ? defaultCardData.common_name
                : "NONE"}
            </div>
          </div>
          <div>
            <div class="flex justify-end font-semibold"> Country Name</div>
            <div className="flex justify-end">
              {defaultCardData.country_name
                ? defaultCardData.country_name
                : "NONE"}
            </div>
          </div>
        </div>

        <div className=" m-1 mb-4 flex  justify-between">
          <div>
            <div class="font-semibold">Organization Name</div>
            <div className={``}>
              {defaultCardData.organization_name
                ? defaultCardData.organization_name
                : "NONE"}
            </div>
          </div>
          <div>
            <div class="flex justify-end font-semibold">
              {" "}
              Organizational Unit Name
            </div>
            <div className="flex justify-end">
              {defaultCardData.organizational_unit_name
                ? defaultCardData.organizational_unit_name
                : "NONE"}
            </div>
          </div>
        </div>

        <div className=" m-1 mb-4 flex  justify-between">
          <div>
            <div class="font-semibold">Low Detection Score</div>
            <div className={``}>
              {defaultCardData.vt_score_equal_to_zero
                ? defaultCardData.vt_score_equal_to_zero
                : "0"}
            </div>
          </div>
          <div>
            <div class="flex justify-end font-semibold">
              {" "}
              High Detection Score
            </div>
            <div className="flex justify-end">
              {defaultCardData.vt_score_higher_than_zero
                ? defaultCardData.vt_score_higher_than_zero
                : "0"}
            </div>
          </div>
        </div>

        <div className=" m-1 mb-4 flex  justify-between">
          <div>
            <div class="font-semibold">Total Apks</div>
            <div className="">
              {defaultCardData.total_apks ? defaultCardData.total_apks : "0"}
            </div>
          </div>
          <div>
            <div class="flex justify-end font-semibold"> No Detection</div>
            <div className="flex justify-end">
              {defaultCardData.has_no_vt_score
                ? defaultCardData.has_no_vt_score
                : "0"}
            </div>
          </div>
        </div>

        <div class="m-1 mb-2">
          <dl class="grid-cols-2">
            <div class="font-semibold">Locality</div>
            <div class="text-[#000000]">
              {defaultCardData.locality ? defaultCardData.locality : "NONE"}
            </div>
          </dl>
        </div>

        <div class="m-1 mb-2">
          <dl class="grid-cols-2">
            <div class="font-semibold">Distinguished Name</div>
            <div class="text-[#000000]">
              {defaultCardData.distinguished_name
                ? defaultCardData.distinguished_name
                : "NONE"}
            </div>
          </dl>
        </div>

        <div className="ml-1 flex justify-between">
          <div class="mb-2">
            <dl class="grid-cols-2">
              <div class="font-semibold">Email</div>
              <div class="text-[#000000]">
                {defaultCardData.email ? defaultCardData.email : "NONE"}
              </div>
            </dl>
          </div>
          <div className="flex justify-end">
            <div
              className="flex cursor-pointer items-center justify-center"
              onClick={() => {
                window.open(
                  `/admin/apks_results?query=certificate_sha:"${defaultCardData._id}"`,
                  "_blank"
                );
              }}
            >
              <div className="">
                <a
                  data-tooltip-id="my-tooltip-navigateCerti"
                  data-tooltip-html="Selecting this icon will navigate to the APK results page,</br> search for APK data based on this certificate ID."
                  data-tooltip-place="top"
                >
                  <AiFillAndroid
                    data-modal-target="popup-modal"
                    data-modal-toggle="popup-modal"
                    type="button"
                    className="mr-1 h-12 w-12  rounded-xl border border-[#4318FF] p-[6px] text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip-navigateCerti" />
              </div>
            </div>
            {/* <button
                className="m-1 flex w-20 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
                onClick={(e) => {
                  dispatch(toggleShowAllDetails());
                }}
              >
                Edit
              </button> */}

            <button
              className="m-1 flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              onClick={(e) => {
                dispatch(toggleViewAllData());
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAllData;
