import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateExistingRule } from "reduxConfig/slices/rulesSlice";
import { updateNewRule } from "reduxConfig/slices/rulesSlice";
function TagInput() {
  const [inputValue, setInputValue] = useState("");
  const whichModal = useSelector((state) => state.rulesData.showRuleModal);
  const checkBox = useSelector((state) => state.rulesData.checkBoxSelect);

  const queryStrings = useSelector((state) =>
    whichModal
      ? state.rulesData.updatedRuleData.queryStrings
      : state.rulesData.newRuleData.queryStrings
  );
  const dispatch = useDispatch();

  const handleKeyPress = (event) => {
    if (checkBox == true) {
      if (event.key === "Enter") {
        event.preventDefault();
        if (inputValue) {
          dispatch(
            whichModal
              ? updateExistingRule({
                  queryStrings: [...queryStrings, ...inputValue.split("\,")],
                })
              : updateNewRule({
                  queryStrings: [...queryStrings, ...inputValue.split("\,")],
                })
          );
          setInputValue("");
        }
      }
    } else {
      if (event.key === "Enter") {
        event.preventDefault();
        if (inputValue) {
          dispatch(
            whichModal
              ? updateExistingRule({
                  queryStrings: [...queryStrings, inputValue],
                })
              : updateNewRule({
                  queryStrings: [...queryStrings, inputValue],
                })
          );
          setInputValue("");
        }
      }
    }
  };
  return (
    <div class="max-w-[30vw]">
      <div class="relative">
        <textarea
          class="block w-full appearance-none  border border-[#000000] bg-white py-2 px-4 leading-tight text-[#000000]  focus:outline-none sm:text-sm max-h-16"
          placeholder="Enter some tags"
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          onKeyDown={(event) => {
            handleKeyPress(event);
          }}
        />
        <div class="hidden">
          <div class="absolute left-0 z-40 mt-2 w-full">
            <div class="rounded border border-gray-300 bg-white py-1 text-sm shadow-lg">
              <a class="block cursor-pointer py-1 px-5 hover:bg-indigo-600 hover:text-white">
                Add tag "<span class="font-semibold" x-text="textInput"></span>"
              </a>
            </div>
          </div>
        </div>
        {queryStrings.length ? (
          <div
            className="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap justify-start overflow-auto"
            style={{ scrollbarWidth: "thin" }}
          >
            {queryStrings.map((element) => (
              <div
                className={`mx-1 ${
                  element.length > 65 ? "" : "h-[3em]"
                } border-bg-blue-200 items-center rounded-lg border bg-blue-200 px-[4px] py-[2px] text-center text-sm text-[#000] mt-1`}
                style={{ marginBottom: "2px" }}
              >
                <span
                  class="ml-2 mr-1 max-w-xs truncate px-1 leading-relaxed"
                  x-text="tag"
                >
                  {element}
                </span>
                <button
                  class="inline-block h-8 w-6 bg-blue-200 align-middle text-gray-500 focus:outline-none"
                  onClick={(e) => {
                    const updatedItems = queryStrings.filter(
                      (i) => i != element
                    );
                    dispatch(
                      whichModal
                        ? updateExistingRule({
                            queryStrings: updatedItems,
                          })
                        : updateNewRule({
                            queryStrings: updatedItems,
                          })
                    );
                  }}
                >
                  <svg
                    class="fill-current mx-auto h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                    />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default TagInput;
