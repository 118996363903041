import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleUrlUploadSuccessModal } from "reduxConfig/slices/urlSlice";

function UploadSuccessModal() {
  const dispatch = useDispatch();
  const checkuploadStatus = useSelector((state) => state.urlsData.status);
  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[15%] z-20 mt-32 h-[50vw] w-[80vw] flex-col justify-center py-6 sm:py-12">
        <div className="relative py-3 sm:mx-auto sm:max-w-xl">
          {checkuploadStatus === "POSTED" ? (
            <div className="-mt-16 flex flex-col justify-evenly rounded-xl bg-white p-8">
              <div className="flex justify-center text-2xl font-bold">
                URLs upload processing
              </div>
            </div>
          ) : checkuploadStatus === "SUCCESS" ? (
            <div className="-mt-16 flex flex-col justify-evenly rounded-xl bg-white p-8">
              <div className="flex justify-center text-2xl font-bold">
                URLs uploaded successfully
              </div>
              <div className="mt-10 flex justify-center">
                <button
                  className="ml-2 mr-2 h-10 w-20 rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-base font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white active:bg-[#4318FF]"
                  onClick={(e) => {
                    dispatch(toggleUrlUploadSuccessModal());
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          ) : checkuploadStatus === "FAILED" ? (
            <div className="-mt-16 flex flex-col justify-evenly rounded-xl bg-white p-8">
              <div className="flex justify-center text-2xl font-bold">
              Error on Urls upload
              </div>
              <div className="mt-10 flex justify-center">
                <button
                  className="ml-2 mr-2 h-10 w-24 rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-base font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white active:bg-[#4318FF]"
                  onClick={(e) => {
                    dispatch(toggleUrlUploadSuccessModal());
                  }}
                >
                  Reupload
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export default UploadSuccessModal;
