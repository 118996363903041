function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}
const urlParamsBuilder = (filterData) => {
  const urlParams = [];

  if (filterData.queryString) {
    urlParams.push(`query=${encodeURIComponent(filterData.queryString)}`);
  }
  if (filterData.fromDate) {
    urlParams.push(
      `fromDate=${encodeURIComponent(
        formatDateToDDMMYYYY(filterData.fromDate)
      )}`
    );
  }
  if (filterData.toDate) {
    urlParams.push(
      `toDate=${encodeURIComponent(formatDateToDDMMYYYY(filterData.toDate))}`
    );
  }
  if (filterData.client_ids.length) {
    urlParams.push(
      `clients=${encodeURIComponent(
        filterData.client_ids
        // filterData.client_ids.map((client) => client.value).join(",")
      )}`
    );
  }

  if (filterData.brand_ids.length) {
    urlParams.push(
      `brands=${encodeURIComponent(
        filterData.brand_ids
        // filterData.brand_ids.map((brand) => brand.value).join(",")
      )}`
    );
  }

  if (filterData.is_whitelisted) {
    urlParams.push(
      `isWhitelisted=${encodeURIComponent(filterData.is_whitelisted)}`
    );
  }

  if (filterData.additionalFilters.threat_indicators.length) {
    urlParams.push(
      `threatIndicators=${encodeURIComponent(
        filterData.additionalFilters.threat_indicators.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.suggested_classification.length) {
    urlParams.push(
      `suggestedClassification=${encodeURIComponent(
        filterData.additionalFilters.suggested_classification.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.use_or) {
    urlParams.push(`useOr=${filterData.additionalFilters.use_or}`);
  }
  if (filterData.pageNumber) {
    urlParams.push(`page=${filterData.pageNumber}`);
  }

  if (filterData.isRegex) {
    urlParams.push(`isRegex=${filterData.isRegex}`);
  }

  return urlParams.join("&");
};

export default urlParamsBuilder;
