import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const apiEndPoint = process.env.REACT_APP_API_ENDPOINT_URL;

// Define a function to set data in localStorage
const setLocalStorage = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {}
};

// Define a function to retrieve data from localStorage
const getLocalStorage = (key) => {
  try {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  } catch (error) {
    console.error(`Error retrieving data from localStorage:`, error);
    return null;
  }
};

// Define a function to remove data from localStorage
const removeLocalStorage = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {}
};

const refresh = () => {
  const storedUserData = getLocalStorage("userData");
  const storedOwnedBrands = getLocalStorage("ownedBrands");
  const apiKey = getLocalStorage("apiKey");

  if (storedUserData && storedOwnedBrands && apiKey) {
    return {
      userData: storedUserData,
      isAuthenticated: true,
      ownedBrands: storedOwnedBrands,
      apiKey,
    };
  } else {
    return {
      userData: {},
      isAuthenticated: false,
      ownedBrands: null,
      apiKey: null,
    };
  }
};

export const authPostBackend = createAsyncThunk("user/auth", (dataToPost) => {
  return axios.post(`${apiEndPoint}sw_auth/login`, dataToPost);
});

export const userAuthSlice = createSlice({
  name: "authData",
  initialState: {
    ...refresh(),
    isLoading: false,
    isWrongCreds: false,
  },
  reducers: {
    logout: (state) => {
      removeLocalStorage("userData");
      removeLocalStorage("apiKey");
      removeLocalStorage("ownedBrands");
      state.userData = {};
    },
    changeAlert: (state) => {
      state.isWrongCreds = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authPostBackend.pending, (state) => {
        state.isLoading = true;
        state.isWrongCreds = false;
      })
      .addCase(authPostBackend.fulfilled, (state, action) => {
        state.userData = action.payload.data.data;
        state.isAuthenticated = true;
        state.isLoading = false;
        state.isWrongCreds = false;
        state.ownedBrands = action.payload.data.data.owned_brands;
        state.apiKey = action.payload.data.data.api_config.key;

        // Set data in localStorage when authentication is successful
        setLocalStorage("userData", state.userData);
        setLocalStorage("apiKey", state.apiKey);
        setLocalStorage("ownedBrands", state.ownedBrands);
      })
      .addCase(authPostBackend.rejected, (state, action) => {
        state.isWrongCreds = true;
        state.isLoading = false;
      });
  },
});

export const { logout, changeAlert } = userAuthSlice.actions;

export default userAuthSlice.reducer;
