import React from "react";
import { AiOutlineKey } from "react-icons/ai";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { logout } from "../../../reduxConfig/slices/authSlice";
function UserCard() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userAuth.userData);
  return (
    <div className="m-2 w-[12vw] rounded-xl bg-blue-200 p-8 dark:bg-gray-900 dark:text-gray-100 sm:flex sm:space-x-6 items-center">
      <div className="flex flex-col">
        <div className="mb-2">
          <h2 className="text-xl font-semibold">{userData.username}</h2>
          {/* <span className="text-md dark:text-gray-400">
            {userData.display_name}
          </span> */}
        </div>
        <div className="space-y-1">
          <span className="flex items-center space-x-2">
            <span className="text-xs dark:text-[#000000]">
              {userData.email_id}
            </span>
          </span>

          <span className="flex items-center space-x-2 ">
            <button
              type="button"
              class="my-2 inline-flex items-center rounded-lg bg-[#4318FF] px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-[#4318FF] focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() => {
                dispatch(logout());
                window.location.reload();
              }}
            >
              Logout
              <svg
                class="ml-2 h-3.5 w-3.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>
    </div>
  );
}

export default UserCard;
