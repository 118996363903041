import React from "react";
import noPreview from "assets/img/androdApk.png";
import { Tooltip } from "react-tooltip";
import { IoMdDownload } from "react-icons/io";
import getIstTime from "./helpers/istConvertor";

const ApkViewAllData = ({ cardData }) => {
  return (
    <div class="flex min-h-screen items-center justify-center p-2 px-4">
      <div class="max-w-8xl  w-full rounded-lg border-2 border-[#000000] bg-white p-1 shadow-xl">
        <div class="border-b p-4">
          <h2 class="text-2xl ">App Data</h2>
        </div>
        <div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">App Name</p>
            <p>{cardData?.app_name ? cardData.app_name : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">App Icon</p>
            <p>
              {" "}
              <img
                src={
                  cardData?.main_icon?.storage_attributes?.bucket_id
                    ? `https://dsgx98c21wj98.cloudfront.net/${cardData.main_icon?.storage_attributes?.bucket_id}`
                    : noPreview
                }
                class="h-[150px] w-[150px] items-center rounded-xl"
                alt=""
              />
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Download Apk</p>
            <p>
              <div className="flex">
                {/* <div>
                  {cardData?.file_attributes?.bucket_id ? (
                    <div class="text-#000000 mx-1 items-center rounded-lg  p-1 text-center text-sm">
                      {cardData.file_attributes.bucket_id}
                    </div>
                  ) : (
                    <div class="text-#000000 mx-1 items-center rounded-lg  p-1 text-center text-sm">
                      {"NONE"}
                    </div>
                  )}
                </div> */}
                <div>
                  <div className="ml-1 text-lg font-normal">
                    <a
                      data-tooltip-id="my-tooltip-title"
                      data-tooltip-html="Here you can click the </br>  icon it will download </br>  the apk"
                      data-tooltip-place="right"
                      href={`https://dsgx98c21wj98.cloudfront.net/${cardData?.file_attributes?.bucket_id}`}
                      // target="_blank"
                    >
                      <IoMdDownload className="h-8 w-7 cursor-pointer items-center rounded-md border border-[#4318FF] p-[2px] text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
                    </a>
                    <Tooltip className="z-10" id="my-tooltip-title" />
                  </div>
                </div>
              </div>
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Certificate Sha1</p>
            <p>
              {cardData?.certificate_sha1 ? cardData.certificate_sha1 : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Created At</p>
            <p>
              {cardData?.created_at ? getIstTime(cardData.created_at) : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Engine Score</p>
            <p>{cardData?.engine_score ? cardData.engine_score : "None"}</p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">First Seen Date Timestamp</p>
            <p>
              {cardData?.first_seen_date_timestamp
                ? cardData.first_seen_date_timestamp
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Md5</p>
            <p>{cardData?.md5 ? cardData.md5 : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Package Name</p>
            <p>{cardData?.package_name ? cardData.package_name : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Playstore Attributes</p>
            <p>
              {cardData?.playstore_attributes?.length
                ? cardData.playstore_attributes
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Sha1</p>
            <p>{cardData?.sha1 ? cardData.sha1 : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Sha256</p>
            <p>{cardData?.sha256 ? cardData.sha256 : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Size</p>
            <p>{cardData?.size ? cardData.size : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Tags</p>
            <p>{cardData?.tags?.length ? cardData.tags : "None"}</p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Updated At</p>
            <p>
              {cardData?.updated_at ? getIstTime(cardData.updated_at) : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Verdict</p>
            <p>{cardData?.verdict?.length ? cardData.verdict : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">_type</p>
            <p>{cardData?._type ? cardData._type : "None"}</p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">_id</p>
            <p>{cardData?._id ? cardData._id : "None"}</p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">User Actions</h2>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Threat Indicator</p>
            <p>
              {cardData?.user_actions?.threat_indicators?.length > 0 ? (
                cardData.user_actions.threat_indicators.map((threat) => (
                  <p>{threat}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Classifications</p>
            <p>
              {cardData?.user_actions?.suggested_classifications?.length > 0 ? (
                cardData.user_actions.suggested_classifications.map(
                  (Classifications) => <p>{Classifications.value}</p>
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
        </div>
        <div class="border-b p-4">
          <h2 class="text-2xl ">Certificates</h2>
          <h2 class="text-xl ">issuer</h2>
        </div>
        <div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">C</p>
            <p>
              {cardData?.certificate?.issuer?.C
                ? cardData.certificate.issuer.C
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">DN</p>
            <p>
              {cardData?.certificate?.issuer?.DN
                ? cardData.certificate.issuer.DN
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Sha1</p>
            <p>
              {cardData?.certificate?.sha1 ? cardData.certificate.sha1 : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">validfrom</p>
            <p>
              {cardData?.certificate?.validfrom
                ? cardData.certificate.validfrom
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">validto</p>
            <p>
              {cardData?.certificate?.validto
                ? cardData.certificate.validto
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">error</p>
            <p>
              {cardData?.certificate?.error
                ? cardData.certificate.error
                : "None"}
            </p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">Communication Endpoints</h2>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Contacted Domains</p>
            <p>
              {cardData?.communication_endpoints?.contacted_domains?.length >
              0 ? (
                cardData.communication_endpoints.contacted_domains.map(
                  (source) => <p className="break-all">{source?.id}</p>
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Contacted Ips</p>
            <p>
              {cardData?.communication_endpoints?.contacted_ips?.length > 0 ? (
                cardData.communication_endpoints.contacted_ips.map((source) => (
                  <p className="break-all">{source?.id}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Contacted Urls</p>
            <p>
              {cardData?.communication_endpoints?.contacted_urls?.length > 0 ? (
                cardData.communication_endpoints.contacted_urls.map(
                  (source) => (
                    <p className="break-all">
                      {source?.context_attributes?.url}
                    </p>
                  )
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">itw_ips</p>
            <p>
              {cardData?.communication_endpoints?.itw_ips?.length > 0 ? (
                cardData.communication_endpoints.itw_ips.map((source) => (
                  <p className="break-all">{source?.id}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">itw_urls</p>
            <p>
              {cardData?.communication_endpoints?.itw_urls?.length > 0 ? (
                cardData.communication_endpoints.itw_urls.map((source) => (
                  <p className="break-all">{source.context_attributes.url}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>

          <div class="border-b p-4">
            <h2 class="text-2xl ">File Attributes</h2>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Bucket Id</p>
            <p>
              {cardData?.file_attributes?.bucket_id
                ? cardData.file_attributes.bucket_id
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">error</p>
            <p>
              {cardData?.file_attributes?.error
                ? cardData.file_attributes.error
                : "None"}
            </p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">File Info Attributes</h2>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Discovered Sources</p>
            <p>
              {cardData?.file_info_attributes?.discovered_sources?.length >
              0 ? (
                cardData.file_info_attributes.discovered_sources.map(
                  (source) => <p className="break-all">{source}</p>
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">File Type</p>
            <p>
              {cardData?.file_info_attributes?.file_type
                ? cardData.file_info_attributes.file_type
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">File Names</p>
            <p>
              {cardData?.file_names.map((fileName) => (
                <p>{fileName}</p>
              ))}
            </p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">Hash Values</h2>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Md5</p>
            <p>
              {cardData?.hash_values?.md5 ? cardData.hash_values.md5 : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Sha256</p>
            <p>
              {cardData?.hash_values?.sha256
                ? cardData.hash_values.sha256
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Sha1</p>
            <p>
              {cardData?.hash_values?.sha1 ? cardData.hash_values.sha1 : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Cert Sha1</p>
            <p>
              {cardData?.hash_values?.cert_sha1
                ? cardData.hash_values.cert_sha1
                : "None"}
            </p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">Main Icon</h2>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Icon Md5</p>
            <p>
              {cardData?.main_icon?.icon_md5
                ? cardData.main_icon.icon_md5
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Icon Present</p>
            <p>
              {cardData?.main_icon?.icon_present
                ? cardData.main_icon.icon_present
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Error</p>
            <p>
              {cardData?.main_icon?.error ? cardData.main_icon.error : "None"}
            </p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">Storage Attributes</h2>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Bucket Id</p>
            <p>
              {cardData?.main_icon?.storage_attributes?.bucket_id
                ? cardData.main_icon.storage_attributes.bucket_id
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Download Url</p>
            <p>
              {cardData?.main_icon?.storage_attributes?.download_url
                ? cardData.main_icon.storage_attributes.download_url
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Error</p>
            <p>
              {cardData?.main_icon?.storage_attributes?.error
                ? cardData.main_icon.storage_attributes.error
                : "None"}
            </p>
          </div>
          <div class="border-b p-4">
            <h2 class="text-2xl ">Androguard Attributes</h2>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">App Name</p>
            <p>
              {cardData?.androguard_attributes?.app_name
                ? cardData.androguard_attributes.app_name
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Android Internal Version</p>
            <p>
              {cardData?.androguard_attributes?.android_internal_version
                ? cardData.androguard_attributes.android_internal_version
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Android Displayed Version</p>
            <p>
              {cardData?.androguard_attributes?.android_displayed_version
                ? cardData.androguard_attributes.android_displayed_version
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Main Activity</p>
            <p>
              {cardData?.androguard_attributes?.main_activity
                ? cardData.androguard_attributes.main_activity
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Package Name</p>
            <p>
              {cardData?.androguard_attributes?.package_name
                ? cardData.androguard_attributes.package_name
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Target Sdk Version</p>
            <p>
              {cardData?.androguard_attributes?.target_sdk_version
                ? cardData.androguard_attributes.target_sdk_version
                : "None"}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Error</p>
            <p>
              {cardData?.androguard_attributes?.error
                ? cardData.androguard_attributes.error
                : "None"}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Activities</p>
            <p>
              {cardData?.androguard_attributes?.activities?.length > 0 ? (
                cardData.androguard_attributes.activities.map((activity) => (
                  <p className=" break-all">{activity}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Actions Dangerous</p>
            <p>
              {cardData?.androguard_attributes?.actions_dangerous?.length >
              0 ? (
                cardData.androguard_attributes.actions_dangerous.map(
                  (action) => <p>{action}</p>
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Permissions</p>
            <p>
              {cardData?.androguard_attributes?.permissions?.length > 0 ? (
                cardData.androguard_attributes.permissions.map((permission) => (
                  <p className="break-all">{permission}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Permissions Dangerous</p>
            <p>
              {cardData?.androguard_attributes?.permissions_dangerous?.length >
              0 ? (
                cardData.androguard_attributes.permissions_dangerous.map(
                  (dangerousPermission) => <p>{dangerousPermission}</p>
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Providers</p>
            <p>
              {cardData?.androguard_attributes?.providers?.length > 0 ? (
                cardData.androguard_attributes.providers.map((provider) => (
                  <p>{provider}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Receivers</p>
            <p>
              {cardData?.androguard_attributes?.receivers?.length > 0 ? (
                cardData.androguard_attributes.receivers.map((receiver) => (
                  <p>{receiver}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>

          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Services</p>
            <p>
              {cardData?.androguard_attributes?.services?.length > 0 ? (
                cardData.androguard_attributes.services.map((service) => (
                  <p>{service}</p>
                ))
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
          <div class="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
            <p class="text-[#4318FF]">Found Strings</p>
            <p>
              {cardData?.androguard_attributes?.found_strings?.length > 0 ? (
                cardData.androguard_attributes.found_strings.map(
                  (foundString) => <p className=" break-all">{foundString}</p>
                )
              ) : (
                <div className="space-y-1 border-b p-4 hover:bg-gray-50 md:grid md:grid-cols-2 md:space-y-0">
                  <p>None</p>
                </div>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApkViewAllData;
