import React, { useEffect } from "react";
import SearchBar from "./components/SearchBar";
import DatePicker from "./components/DatePicker";
import FilterModal from "./components/FilterModal";
import ExportModal from "./components/ExportModal";
import { useDispatch, useSelector } from "react-redux";
import { toggleFilterModal } from "reduxConfig/slices/apkResultSlice";
import { toggleExportModal } from "reduxConfig/slices/apkResultSlice";
import { RingLoader } from "react-spinners";
import ApkResultsCard from "./components/ApkResultsCard";
import ViewAllDetailsCard from "./components/ViewAllDetailsCard";
import { getApkData } from "reduxConfig/slices/apkResultSlice";
import { getBrandData } from "reduxConfig/slices/brandSlice";
import { getClientData } from "reduxConfig/slices/brandSlice";
import PaginationComp from "./components/PaginationComp";
import ShowInfoModal from "./components/ShowInfoModal";
import urlParamsBuilder from "./components/helpers/getUrlParams";
import SearchInfoModal from "./components/SearchInfoModalw";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { changeApkEditStatus } from "reduxConfig/slices/apkResultSlice";
import AdditionalFilter from "./components/AdditionalFilter";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";
import { getApkDataSkipLoad } from "reduxConfig/slices/apkResultSlice";

function ApkResultsView() {
  const dispatch = useDispatch();

  const showFilterModal = useSelector(
    (state) => state.ApksResultsData.showFilterModal
  );
  const pageNumber = useSelector((state) => state.ApksResultsData.pageNumber);
  const isLoading = useSelector((state) => state.ApksResultsData.isLoading);
  const filterData = useSelector((state) => state.ApksResultsData);
  const apkResultData = useSelector(
    (state) => state.ApksResultsData.apkResultData
  );
  const showExportModal = useSelector(
    (state) => state.ApksResultsData.showExportModal
  );
  const showApkDetailsModal = useSelector(
    (state) => state.ApksResultsData.showApkDetailsModal
  );
  const showInfoModal = useSelector(
    (state) => state.ApksResultsData.showInfoModal
  );
  const showSearchInfoModal = useSelector(
    (state) => state.ApksResultsData.showSearchInfoModal
  );

  const shouldUpdateEditBrand = useSelector((state) => state.ApksResultsData);

  const error = false;

  useEffect(() => {
    if (shouldUpdateEditBrand.status === "SUCCESS") {
      dispatch(
        updateAlert({
          alertText: "Update Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getApkDataSkipLoad());
      dispatch(changeApkEditStatus(null));
    }
  }, [shouldUpdateEditBrand]);

  useEffect(() => {
    dispatch(getApkData());
    dispatch(getBrandData());
    dispatch(getClientData());
  }, [pageNumber]);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(filterData)}`
    );
  });

  useEffect(() => {
    const body = document.body;

    if (
      showFilterModal ||
      showExportModal ||
      showApkDetailsModal ||
      showInfoModal ||
      showSearchInfoModal
    ) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [
    showFilterModal,
    showExportModal,
    showApkDetailsModal,
    showInfoModal,
    showSearchInfoModal,
  ]);

  return (
    <div
      // className={`relative ${
      //   showExportModal ||
      //   showFilterModal ||
      //   showApkDetailsModal ||
      //   showInfoModal ||
      //   showSearchInfoModal
      //     ? "h-[80vh] overflow-x-hidden overflow-y-hidden"
      //     : ""
      // }`}
      className={`relative ${
        showExportModal ||
        showFilterModal ||
        showApkDetailsModal ||
        showInfoModal ||
        showSearchInfoModal
          ? ""
          : ""
      }`}
    >
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          {" "}
          <div className="relative top-[-7vh] left-[9vw] z-30 flex w-[70vw] justify-between">
            <div className="ml-[3vw]">
              <SearchBar />
            </div>

            <div className="flex items-center space-x-4">
              <AdditionalFilter />
              <DatePicker />
              <div>
                <button
                  type="button"
                  onClick={(e) => {
                    dispatch(toggleFilterModal());
                  }}
                  className={`bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-3 py-2 px-6 text-center font-semibold text-blue-700 hover:bg-[#4318FF] hover:text-white ${"w-[6vw]"}`}
                >
                  Filter
                </button>
              </div>
              <div>
                <button
                  class="inline-flex h-12 items-center rounded-xl border border-[#4318FF] px-4 py-3.5 text-center  text-sm font-bold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  type="button"
                  data-dropdown-toggle="dropdown"
                  onClick={(e) => {
                    dispatch(toggleExportModal());
                  }}
                >
                  Export Data
                </button>
              </div>
            </div>
          </div>
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <div className="-mt-4 grid h-full  grid-cols-1 gap-2 2xl:grid-cols-3">
                {apkResultData.length ? (
                  apkResultData.map((element) => (
                    <ApkResultsCard cardData={element} />
                  ))
                ) : (
                  <></>
                )}
              </div>
              {apkResultData.length ? (
                <></>
              ) : (
                <div className="flex items-center  justify-center">
                  <Lottie
                    animationData={noDataAnimationData}
                    loop={true}
                    autoplay={true}
                    style={{ width: 500, height: 500 }}
                    speed={1.5}
                  />
                  {/* <div className="text-3xl font-bold">No Results Found</div> */}
                </div>
              )}
              {apkResultData.length ? <PaginationComp /> : <></>}
            </>
          )}
          {showFilterModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <FilterModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showExportModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <ExportModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showApkDetailsModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <ViewAllDetailsCard />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showInfoModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <ShowInfoModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showSearchInfoModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <SearchInfoModal />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default ApkResultsView;
