import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";
import axios from "axios";
const apiEndPoint = process.env.REACT_APP_API_ENDPOINT_URL;

function parseDDMMYYYYToDate(dateStr) {
  const parts = dateStr.split("/");

  if (parts.length !== 3) {
    throw new Error('Invalid date format. Use "dd/mm/yyyy" format.');
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    throw new Error("Invalid date components.");
  }

  return new Date(year, month, day);
}

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const additionalParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const resultType = queryParams.get("resultType")
    ? queryParams.get("resultType").split(",")
    : [];
  const entityType = queryParams.get("entityType")
    ? queryParams.get("entityType").split(",")
    : [];
  const source = queryParams.get("source")
    ? queryParams.get("source").split(",")
    : [];
  const markedAs = queryParams.get("markedAs")
    ? queryParams.get("markedAs").split(",")
    : [];
  const possibleUrls = queryParams.get("possibleUrls")
    ? queryParams.get("possibleUrls").split(",")
    : [];
  const useOr = queryParams.get("useOr") === "true" ? true : false;
  return {
    use_or: useOr,
    result_type: resultType,
    entity_type: entityType,
    source: source,
    marked_as: markedAs,
    possible_url_classifications: possibleUrls,
  };
};

const regexParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isRegex = queryParams.get("isRegex") === "true" ? true : false;
  return {
    isRegex: isRegex,
  };
};

const dateParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get("fromDate")
    ? parseDDMMYYYYToDate(queryParams.get("fromDate"))
    : null;
  const toDate = queryParams.get("toDate")
    ? parseDDMMYYYYToDate(queryParams.get("toDate"))
    : null;

  return { fromDate: fromDate, toDate: toDate };
};
const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const initialState = {
  resultData: [],
  isLoading: false,
  isExportLoading: false,
  queryString: QueryStringHandler(),
  ...dateParamsHandler(),
  pageNumber: pageNumberHandler(),
  dumpedData: [],
  totalPages: null,
  resultsPerPage: 15,
  showSearchInfoModal: false,
  showEyeModal: false,
  additionalFilters: additionalParamsHandler(),
  showExportModal: false,
  showResultDeleteModal: false,
  totalResults: null,
  ...regexParamsHandler(),
  error: null,
  showUploadModal: false,
  status: null,
  updateResultDeleteData: {
    resultName: "",
    resultId: "",
  },
  uploadApk: {
    type: "local",
    client_id: [],
    brand_id: [],
    is_official: "",
    apk_file: [],
    discoverd_source: [],
    description: "",
    url: [],
    folder_id: "",
    url_id: "",
  },
  exportLink: "",
  exportText: "",
  exportTitle: "",
};

function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  if (!date) {
    return "";
  }
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}

export const getSearchResults = createAsyncThunk("result/data", () => {
  let stateData = store.getState();
  let resultsData = stateData.resultsData;
  let dataToPost = {
    query: resultsData.queryString,
    brand_id: resultsData.brandId,
    page_number: resultsData.pageNumber,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    results_per_page: resultsData.resultsPerPage,
    additional_filters: resultsData.additionalFilters,
    is_regex: resultsData.isRegex,
  };

  return apiCall("/sw_results/results", "post", dataToPost);
});

export const getResultsDump = createAsyncThunk("results/dump", () => {
  let stateData = store.getState();
  let resultsData = stateData.resultsData;

  let dataToPost = {
    query: resultsData.queryString,
    brand_id: resultsData.brandId,
    page_number: resultsData.pageNumber,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    results_per_page: resultsData.resultsPerPage,
    additional_filters: resultsData.additionalFilters,
    dump_all: true,
  };

  if (
    resultsData.exportLink ||
    resultsData.exportText ||
    resultsData.exportTitle
  ) {
    dataToPost.requested_attributes = [
      resultsData?.exportLink ? resultsData.exportLink : null,
      resultsData?.exportText ? resultsData.exportText : null,
      resultsData?.exportTitle ? resultsData.exportTitle : null,
    ].filter(Boolean); // Filters out null values
  }

  return apiCall("/sw_results/results", "post", dataToPost);
});

export const createFileUploads = createAsyncThunk("file/uploads", async () => {
  let stateData = store.getState();
  let fileUpload = stateData.resultsData.uploadApk;
  let apiKey = stateData.userAuth.apiKey;

  const cIds = fileUpload.client_id;
  const bIds = fileUpload.brand_id;
  const client_ids = cIds.map((item) => item.value.toString());
  const brand_ids = bIds.map((item) => item.value.toString());

  const formData = new FormData();
  formData.append("client_ids", client_ids);
  formData.append("brand_ids", brand_ids);

  // Convert FileList to an array
  const apkFilesArray = Array.from(fileUpload.apk_file);

  if (apkFilesArray && apkFilesArray.length > 0) {
    apkFilesArray.forEach((apk) => {
      formData.append("files", apk);
    });
  } else {
    console.error("apk_file is not defined or is an empty array");
    return;
  }

  formData.append("user_note", fileUpload.description);
  formData.append("discoverd_source", fileUpload.discoverd_source);
  const response = await axios.post(
    `${apiEndPoint}sw_apk/url_based_local_upload`,
    formData,
    {
      params: {
        url_id: fileUpload.url_id,
        user_note: fileUpload.description,
        // discoverd_source: fileUpload.discoverd_source,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${apiKey}`,
      },
    }
  );
});

export const createDriveUploads = createAsyncThunk("drive/uploads", () => {
  let stateData = store.getState();
  let driveUpload = stateData.resultsData.uploadApk;
  let cIds = driveUpload.client_id;
  let bIds = driveUpload.brand_id;
  let client_ids = cIds.map((item) => item.value);
  let brand_ids = bIds.map((item) => item.value);

  let dataToPost = {
    // spreadsheet_name: driveUpload.spreadsheet_name,
    // worksheet_name: driveUpload.worksheet_name,
    folder_id: driveUpload.folder_id,
    targeted_clients: client_ids,
    targeted_brands: brand_ids,
    target_url: driveUpload.url,
    user_note: driveUpload.description,
  };
  return apiCall(`sw_apk/url_based_drive_upload`, "post", dataToPost);
});

export const resultDataSlice = createSlice({
  name: "resultData",
  initialState,
  reducers: {
    updateQueryString: (state, action) => {
      state.queryString = action.payload;
    },
    updatePageNumber: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    clearDataDumpUrlResult: (state) => {
      state.dumpedData = [];
      state.isExportLoading = false;
    },
    updateDateFilter: (state, action) => {
      state.fromDate = action.payload.selection.startDate;
      state.toDate = action.payload.selection.endDate;
    },
    updateResultPerPage: (state, action) => {
      state.resultsPerPage = action.payload;
    },
    toggleSearchInfoModal: (state) => {
      state.showSearchInfoModal = !state.showSearchInfoModal;
    },
    toggleEyeModal: (state) => {
      state.showEyeModal = !state.showEyeModal;
    },
    addAdditionalFilters: (state, action) => {
      state.additionalFilters = {
        ...state.additionalFilters,
        ...action.payload,
      };
    },
    toggleExportModal: (state) => {
      state.showExportModal = !state.showExportModal;
    },
    exportVisibleData: (state) => {
      state.dumpedData = state.resultData;
    },
    toggleIsRegix: (state) => {
      state.isRegex = !state.isRegex;
    },
    updateErrorResults: (state, action) => {
      state.error = null;
    },
    toggleDeleteModal: (state) => {
      state.showResultDeleteModal = !state.showResultDeleteModal;
    },
    updateResultDeleteData: (state, action) => {
      state.updateResultDeleteData = {
        ...state.updateResultDeleteData,
        ...action.payload,
      };
    },
    toggleIsRegixResults: (state) => {
      state.isRegex = false;
    },
    toggleShowUploadModal: (state) => {
      state.showUploadModal = !state.showUploadModal;
    },
    updateNewUploadApk: (state, action) => {
      state.uploadApk = { ...state.uploadApk, ...action.payload };
    },
    clearUploadApk: (state, action) => {
      state.uploadApk = initialState.uploadApk;
    },
    setUploadData: (state, action) => {
      state.uploadApk.url = action.payload.url;
      state.uploadApk.discoverd_source = action.payload.dSource;
      state.uploadApk.url_id = action.payload.url_id;
    },
    changeStatusResults: (state, action) => {
      state.status = action.payload;
    },
    updateExportLink: (state, action) => {
      state.exportLink = action.payload;
    },
    updateExportText: (state, action) => {
      state.exportText = action.payload;
    },
    updateExportTitle: (state, action) => {
      state.exportTitle = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchResults.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.data.data.total_pages;
        state.resultData = action.payload.data.data.page_results;
        state.totalResults = action.payload.data.data.total_results;
      })
      .addCase(getSearchResults.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getResultsDump.pending, (state) => {
        state.isExportLoading = true;
        state.dumpedData = {};
      })
      .addCase(getResultsDump.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.dumpedData = action.payload.data.data.page_results;
      })
      .addCase(getResultsDump.rejected, (state, action) => {
        state.isExportLoading = false;
        state.error = action.error.message;
      })
      .addCase(createFileUploads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFileUploads.fulfilled, (state, action) => {
        state.status = "UPLOAD_SUCCESS";
        state.isLoading = false;
        state.uploadApk = initialState.uploadApk;
      })
      .addCase(createFileUploads.rejected, (state, action) => {
        state.status = "ERROR";
        state.isLoading = false;
        state.error = action.error.message;
        state.uploadApk = initialState.uploadApk;
      })
      .addCase(createDriveUploads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDriveUploads.fulfilled, (state, action) => {
        state.status = "UPLOAD_SUCCESS";
        state.isLoading = false;
        state.uploadApk = initialState.uploadApk;
      })
      .addCase(createDriveUploads.rejected, (state, action) => {
        state.status = "ERROR";
        state.isLoading = false;
        state.error = action.error.message;
        state.uploadApk = initialState.uploadApk;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateQueryString,
  updatePageNumber,
  clearDataDumpUrlResult,
  updateDateFilter,
  updateResultPerPage,
  toggleSearchInfoModal,
  toggleEyeModal,
  toggleExportModal,
  addAdditionalFilters,
  exportVisibleData,
  toggleIsRegix,
  updateErrorResults,
  toggleDeleteModal,
  updateResultDeleteData,
  toggleIsRegixResults,
  toggleShowUploadModal,
  updateNewUploadApk,
  clearUploadApk,
  setUploadData,
  changeStatusResults,
  updateExportLink,
  updateExportTitle,
  updateExportText,
} = resultDataSlice.actions;

export default resultDataSlice.reducer;
