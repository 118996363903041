const getIstTime = (timestamp) => {
    const utcDate = new Date(timestamp);
  
    // Set the time zone offset for IST (Indian Standard Time)
    const istOffsetMinutes = 330; // IST is UTC+5:30
  
    // Calculate the local time in IST by adding the offset
    const istDate = new Date(utcDate.getTime() + istOffsetMinutes * 60000);
  
    // Format the IST date as a string (you can use any format you prefer)
    const istDateString = istDate.toLocaleString();
    return istDateString;
  };
  
  export default getIstTime;
  