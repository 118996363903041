import { store } from "../reduxConfig/store";
import axios from "axios";

const apiEndPoint = process.env.REACT_APP_API_ENDPOINT_URL;

const apiCall = (path, type, payload) => {
  const authData = store.getState();
  let apiKey = authData.userAuth.apiKey;
  const axiosInstance = axios.create({
    baseURL: apiEndPoint,
    timeout: 100000,
    headers: { Authorization: `Bearer ${apiKey}` },
  });

  try {
    if (type === "get") {
      return axiosInstance.get(path, payload);
    }
    if (type === "post") {
      return axiosInstance.post(path, payload);
    }
    if (type === "put") {
      return axiosInstance.put(path, payload);
    }
    if (type === "delete") {
      return axiosInstance.delete(path, payload);
    }
  } catch (error) {
    window.alert(error);
  }
};

export default apiCall;
