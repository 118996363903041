import ResultCard from "./components/ResultCard";
import { useEffect } from "react";
import PaginationComp from "./components/PaginationComp";
import SearchBar from "./components/SearchBar";
// import ExportButton from "./components/ExportButton";
import { useDispatch, useSelector } from "react-redux";
import EditModal from "./components/EditModal";
import FilterButton from "./components/Filter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RingLoader } from "react-spinners";
import SearchInfoModal from "./components/SearchInfoModal";
import EyeModal from "./components/EyeModal";
import VtResultCard from "./components/VtResultCard";
import urlParamsBuilder from "./components/helpers/getUrlParams";
import ExportModal from "./components/ExportModal";
import ExportButtonModal from "./components/ExportButtonModal";
import ResultsDeleteModal from "./components/ResultsDeleteModal";
import { getSearchResultsSeo } from "reduxConfig/slices/seoPoisonSlice";
import DatePicker from "./components/DatePicker";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";

const SeoPoisoningTab = () => {
  const isLoading = useSelector((state) => state.SeoPoisonData.isLoading);
  const [queryParameters] = useSearchParams();
  const resultsData = useSelector((state) => state.SeoPoisonData.resultData);
  const filterData = useSelector((state) => state.SeoPoisonData);
  const showEditModal = useSelector((state) => state.resultEditData.showModal);
  const pageNumber = useSelector((state) => state.SeoPoisonData.pageNumber);
  const showSearchInfoModal = useSelector(
    (state) => state.SeoPoisonData.showSearchInfoModal
  );
  const showEyeModal = useSelector((state) => state.SeoPoisonData.showEyeModal);
  const showExportModal = useSelector(
    (state) => state.SeoPoisonData.showExportModal
  );
  const showResultDeleteModal = useSelector(
    (state) => state.SeoPoisonData.showResultDeleteModal
  );
  const error = useSelector((state) => state.SeoPoisonData.error);
  const errorEditResults = null;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSearchResultsSeo());
  }, [pageNumber]);
  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(filterData)}`
    );
  });

  useEffect(() => {
    const body = document.body;

    if (
      showEditModal ||
      showSearchInfoModal ||
      showEyeModal ||
      showExportModal ||
      showResultDeleteModal
    ) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [
    showEditModal,
    showSearchInfoModal,
    showEyeModal,
    showExportModal,
    showResultDeleteModal,
  ]);

  return (
    <div
      // className={`relative ${
      //   showEditModal ||
      //   showSearchInfoModal ||
      //   showEyeModal ||
      //   showExportModal ||
      //   showResultDeleteModal
      //     ? "h-[80vh] overflow-x-hidden overflow-y-hidden"
      //     : ""
      // }`}
      className={`relative ${
        showEditModal ||
        showSearchInfoModal ||
        showEyeModal ||
        showExportModal ||
        showResultDeleteModal
          ? ""
          : ""
      }`}
    >
      {error || errorEditResults ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error || errorEditResults}
        </div>
      ) : (
        <>
          {" "}
          <div className="relative top-[-7vh] left-[8vw] z-30 flex w-[70vw] justify-between">
            <div className="ml-[6vw]">
              <SearchBar />
            </div>
            <div className="flex items-center space-x-4">
              <DatePicker />
              <FilterButton />
              <ExportButtonModal />
            </div>
          </div>
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              {/* <div className="-mt-6 grid h-full  grid-cols-1 gap-2 2xl:grid-cols-3">
                {resultsData.length ? (
                  resultsData.map((element) =>
                    element.user_data.search_type.some(
                      (type) => type == "VT_SERVER"
                    ) ? (
                      <VtResultCard cardData={element} />
                    ) : (
                      <ResultCard cardData={element} />
                    )
                  )
                ) : (
                  <></>
                )}
              </div> */}
              <div className="-mt-6 grid h-full  grid-cols-1 gap-2 2xl:grid-cols-3">
                {resultsData.length ? (
                  resultsData.map((element) =>
                    element.result_type === "APK" ? (
                      <VtResultCard key={element.id} cardData={element} />
                    ) : (
                      <ResultCard key={element.id} cardData={element} />
                    )
                  )
                ) : (
                  <></>
                )}
              </div>
              {resultsData.length ? (
                <></>
              ) : (
                <div className="flex items-center  justify-center">
                  <Lottie
                    animationData={noDataAnimationData}
                    loop={true}
                    autoplay={true}
                    style={{ width: 500, height: 500 }}
                    speed={1.5}
                  />
                  {/* <div className="text-3xl font-bold">No Results Found</div> */}
                </div>
              )}
              {resultsData.length ? <PaginationComp /> : <> </>}
            </>
          )}
          {showEditModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <EditModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showSearchInfoModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <SearchInfoModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showEyeModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <EyeModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showExportModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <ExportModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showResultDeleteModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <ResultsDeleteModal />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default SeoPoisoningTab;
