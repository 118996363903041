import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleDriveUploadModal } from "reduxConfig/slices/uploadApkSlice";
import { toggleFileUploadModal } from "reduxConfig/slices/uploadApkSlice";
import FileUploadModal from "./components/FileUploadModal";
import DriveUploadModal from "./components/DriveUploadModal";
import { AiOutlineInfoCircle, AiOutlineCopy } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import { getClientData } from "reduxConfig/slices/brandSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { updateUploadStatus } from "reduxConfig/slices/uploadApkSlice";
import { RingLoader } from "react-spinners";
import DriveID from "assets/img/driveFolderID.png";
import Access from "assets/img/access.png";
import WorkSheet from "assets/img/worksheet.png";
import SpreadSheet from "assets/img/ssName.png";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";

function UploadApkView() {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownButtonRef = useRef(null);
  const dropdownContentRef = useRef(null);
  const error = useSelector((state) => state.UploadApkData.error);
  const shouldUpdateStatus = useSelector((state) => state.UploadApkData.status);
  const isLoading = useSelector((state) => state.UploadApkData.isLoading);

  const showFileUploadModal = useSelector(
    (state) => state.UploadApkData.showFileUploadModal
  );

  const showDriveUploadModal = useSelector(
    (state) => state.UploadApkData.showDriveUploadModal
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    if (option === "File Upload") {
      dispatch(toggleFileUploadModal());
    } else {
      dispatch(toggleDriveUploadModal());
    }
    setSelectedOption(null);
    setIsDropdownOpen(false);
  };

  const handleOutsideClick1 = (e) => {
    if (
      dropdownButtonRef.current &&
      !dropdownButtonRef.current.contains(e.target) &&
      dropdownContentRef.current &&
      !dropdownContentRef.current.contains(e.target)
    ) {
      setIsDropdownOpen(false);
      setSelectedOption(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick1);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick1);
    };
  }, []);

  useEffect(() => {
    dispatch(getClientData());
    if (shouldUpdateStatus === "SUCCESS") {
      dispatch(
        updateAlert({
          alertText: "Upload Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      // dispatch(getBrandData());
      dispatch(updateUploadStatus());
    }
  }, [shouldUpdateStatus]);

  useEffect(() => {
    const body = document.body;

    if (showFileUploadModal || showDriveUploadModal) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [showFileUploadModal, showDriveUploadModal]);

  return (
    <div className="relative">
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <button
                onClick={toggleDropdown}
                className="flex items-center rounded-lg border border-[#4318FF] py-2 px-4 font-bold  text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
                ref={dropdownButtonRef}
              >
                Upload Type
                <svg
                  className={`ml-2 h-4 w-4 transform transition-transform ${
                    isDropdownOpen ? "rotate-180" : "rotate-0"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>

              {showFileUploadModal ? (
                <div className="mt-1/4  fixed top-0 right-80 z-30">
                  <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                    {" "}
                    <FileUploadModal />
                  </div>
                </div>
              ) : (
                <></>
              )}

              {showDriveUploadModal ? (
                <div className="mt-1/4  fixed top-0 z-30 ">
                  <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                    {" "}
                    <DriveUploadModal />
                  </div>
                </div>
              ) : (
                <></>
              )}

              {isDropdownOpen && (
                <div
                  className="absolute top-12 left-0 w-48 rounded-lg border border-gray-300 bg-white shadow-lg"
                  ref={dropdownContentRef}
                >
                  <ul>
                    <li
                      className={`flex cursor-pointer justify-between rounded-lg p-2 hover:bg-[#4318FF] hover:text-white ${
                        selectedOption === "File Upload" ? "text-[#4318FF]" : ""
                      }`}
                      onClick={() => handleOptionClick("File Upload")}
                    >
                      File Upload
                      <div className="">
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html="Select File Upload You Can Choose Single File"
                          data-tooltip-place="right"
                        >
                          <AiOutlineInfoCircle className="mt-1 h-5 w-5 rounded-md  p-[1px]" />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip" />
                      </div>
                    </li>
                    <li
                      className={`flex cursor-pointer justify-between rounded-lg p-2 hover:bg-[#4318FF] hover:text-white ${
                        selectedOption === "Drive Upload"
                          ? "text-[#4318FF]"
                          : ""
                      }`}
                      onClick={() => handleOptionClick("Drive Upload")}
                    >
                      Drive Upload
                      <div className="">
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html="Select Drive Upload You Can Choose Multiple"
                          data-tooltip-place="right"
                        >
                          <AiOutlineInfoCircle className="mt-1 h-5 w-5 rounded-md  p-[1px]" />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip" />
                      </div>
                    </li>
                  </ul>
                </div>
              )}

              {selectedOption && (
                <span className="ml-4 font-bold text-[#4318FF]">
                  {selectedOption}
                </span>
              )}

              <div className="mt-32">
                <p className="mb-2 text-2xl font-medium">
                  Here the steps for a drive upload:
                </p>
                <div className="mb-10">
                  <li class="flex items-center space-x-2.5 text-blue-600 rtl:space-x-reverse dark:text-blue-500">
                    <span class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-[#4318FF] text-[#4318FF] dark:border-[#4318FF]">
                      1
                    </span>
                    <span>
                      <h3 class="text-lg font-bold leading-tight text-[#4318FF]">
                        Create Google Drive
                      </h3>
                      <p class="mb-2 text-sm">
                        Once you create a Google Drive, you can then copy and
                        paste the Drive ID into the Drive input field.
                        Additionally, you can check whether the Drive ID is
                        valid or not by using the check button.
                      </p>
                    </span>
                  </li>
                  <div className="flex justify-center">
                    <img
                      className="h-[600px] w-[600px]"
                      src={DriveID}
                      alt="Imported Image"
                      // style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>

                <div className="mb-10">
                  <li class="flex items-center space-x-2.5 text-blue-600 rtl:space-x-reverse dark:text-blue-500">
                    <span class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-[#4318FF] text-[#4318FF] dark:border-[#4318FF]">
                      2
                    </span>
                    <span>
                      <h3 class="text-lg font-bold leading-tight text-[#4318FF]">
                        Create Google Spread Sheet
                      </h3>
                      <p class="mb-2 text-sm">
                        Once you have created a Google Spreadsheet, you can then
                        copy and paste the spreadsheet name into the spreadsheet
                        input box.
                      </p>
                    </span>
                  </li>
                  <div className="flex justify-center">
                    <img
                      className="h-[600px] w-[600px]"
                      src={SpreadSheet}
                      alt="Imported Image"
                      // style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>

                <div className="mb-10">
                  <li class="flex items-center space-x-2.5 text-blue-600 rtl:space-x-reverse dark:text-blue-500">
                    <span class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-[#4318FF] text-[#4318FF] dark:border-[#4318FF]">
                      3
                    </span>
                    <span>
                      <h3 class="text-lg font-bold leading-tight text-[#4318FF]">
                        Create Google Work Sheet
                      </h3>
                      <p class="mb-2 text-sm">
                        Once you create a worksheet, you can then copy and paste
                        the worksheet name into the worksheet input box.
                        Additionally, you can check whether the worksheet name
                        is valid or not by clicking the check button.
                      </p>
                    </span>
                  </li>
                  <div className="flex justify-center">
                    <img
                      className="h-[600px] w-[600px]"
                      src={WorkSheet}
                      alt="Imported Image"
                      // style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>

                <div className="mb-10">
                  <li class="flex items-center space-x-2.5 text-blue-600 rtl:space-x-reverse dark:text-blue-500">
                    <span class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full border border-[#4318FF] text-[#4318FF] dark:border-[#4318FF]">
                      4
                    </span>
                    <span>
                      <h3 class="text-lg font-bold leading-tight text-[#4318FF]">
                        Give access mail iD
                      </h3>
                      <p class="mb-2 text-sm">
                        Once you are done with all three steps, you can then
                        give access to this email address:
                        <p className=" flex font-bold text-[#000000]">
                          'appmon-sheet-autmation@g-sheet-reader-403808.iam.gserviceaccount.com'.
                          <CopyToClipboard
                            text={`appmon-sheet-autmation@g-sheet-reader-403808.iam.gserviceaccount.com`}
                            onCopy={() => {
                              dispatch(
                                updateAlert({
                                  alertText: "Email Copied",
                                  alertType: "Success",
                                })
                              );
                              setTimeout(() => {
                                dispatch(toggleAlert());
                              }, 2000);
                            }}
                          >
                            <span className="flex cursor-grabbing">
                              {" "}
                              <AiOutlineCopy
                                className="m-0 h-6 w-6 rounded-md  p-[2px]"
                                color="#4318FF"
                              />
                            </span>
                          </CopyToClipboard>
                        </p>
                      </p>
                    </span>
                  </li>
                  <div className="flex justify-center">
                    <img
                      className="h-[600px] w-[600px]"
                      src={Access}
                      alt="Imported Image"
                      // style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default UploadApkView;
