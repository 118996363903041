import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { AiOutlineCopy, AiFillAndroid } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { Tooltip } from "react-tooltip";
import { toggleShowAllDetails } from "reduxConfig/slices/taskStatusSlice";

function ShowAllDetails() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const defaultCardData = useSelector(
    (state) => state.taskStatusData.defaultData
  );

  const brandData = useSelector((state) => state.brandsData.brandData);
  const clientData = useSelector((state) => state.brandsData.clientData);

  const getClientName = (client_id) => {
    const clientName = clientData.find((element) => element._id == client_id);
    if (clientName) {
      return clientName.client_name;
    }
    return "Client Deleted";
  };

  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "Brand Deleted";
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleShowAllDetails());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className="relative mb-10 mt-5 min-w-[30vw]  max-w-[40vw] rounded-[20px] bg-white p-[18px] shadow-3xl shadow-shadow-500 md:max-w-[80vw] lg:max-w-[60vw] xl:max-w-[40vw]"
        ref={modalRef}
      >
        <div>
          <div className="">
            <button className="absolute top-1 left-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"></button>
          </div>

          <div className="flex  justify-between">
            <div>
              <div className="flex items-center justify-start">
                <div className="flex items-center justify-start">
                  <p className="hide-scrollbar mb-2 flex justify-start whitespace-nowrap p-1 text-center text-xl font-bold text-navy-700">
                    {defaultCardData.user_note
                      ? defaultCardData.user_note.toUpperCase()
                      : "NO NOTES PROVIDED"}
                  </p>
                </div>
              </div>

              <div class="text-md mb-6 -mt-4 flex justify-start p-1 text-center font-medium text-gray-800">
                <CopyToClipboard
                  text={defaultCardData._id}
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "Result ID Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <span className="flex cursor-grabbing">
                    {" "}
                    {defaultCardData._id}
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 rounded-md  p-[2px]"
                      color="#4318FF"
                    />
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          {defaultCardData?.file_path ? (
            <div className="flex p-1 text-left font-semibold md:items-start">
              Download Apk
              <div className="ml-1 text-lg font-normal">
                <a
                  data-tooltip-id="my-tooltip-title"
                  data-tooltip-html="Here you can click the </br>  icon it will download </br>  the apk"
                  data-tooltip-place="right"
                  href={`https://dsgx98c21wj98.cloudfront.net/${defaultCardData?.file_path}`}
                  // target="_blank"
                >
                  <IoMdDownload className="h-6 w-7 cursor-pointer items-center rounded-md border border-[#4318FF] p-[3px] text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
                </a>
                <Tooltip className="z-10" id="my-tooltip-title" />
              </div>
            </div>
          ) : (
            <div className="p-1 text-left font-semibold md:items-start">
              Drive Link
              {defaultCardData?.drive_link ? (
                <>
                  <div className="hide-scrollbar flex w-[90%] overflow-hidden font-normal">
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={
                        defaultCardData?.drive_link?.length > 150
                          ? `${defaultCardData.drive_link.substring(
                              0,
                              150
                            )}....`
                          : defaultCardData.drive_link
                      }
                      data-tooltip-place="top"
                      className="flex cursor-pointer overflow-hidden whitespace-nowrap text-sm text-blue-700"
                      style={{
                        maxWidth: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => {
                        window.open(`${defaultCardData.drive_link}`, "_blank");
                      }}
                    >
                      {defaultCardData?.drive_link?.length > 60
                        ? `${defaultCardData.drive_link.substring(0, 50)}....`
                        : defaultCardData.drive_link}
                    </a>
                    <Tooltip className="z-10 font-normal" id="my-tooltip" />
                    <div className="">
                      <CopyToClipboard
                        text={defaultCardData.drive_link}
                        onCopy={() => {
                          dispatch(
                            updateAlert({
                              alertText: "Link Copied",
                              alertType: "Success",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        }}
                      >
                        <span className="flex cursor-grabbing">
                          {" "}
                          <AiOutlineCopy
                            className="m-0 h-5 w-6 rounded-md p-[1px]"
                            color="#4318FF"
                          />
                        </span>
                      </CopyToClipboard>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" text-base font-normal">
                    {"No Link Provided"}
                  </div>
                </>
              )}
            </div>
          )}

          <div className=" m-1 mb-4 flex justify-between">
            <div>
              <div class="font-semibold">Status</div>
              <div
                className={` ${
                  defaultCardData.current_status == "SUCCESS"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {defaultCardData.current_status
                  ? defaultCardData.current_status
                  : "NONE"}
              </div>
            </div>
            <div>
              <div class="font-semibold">Job Type</div>
              <div className="">
                {defaultCardData.job_type ? defaultCardData.job_type : "NONE"}
              </div>
            </div>
          </div>
        </div>

        <div class="m-1 mb-2">
          <dl class="grid-cols-2">
            <div class="font-semibold">Failed Message</div>
            <div class="break-all text-[#000000]">
              {defaultCardData.error ? defaultCardData.error : "NONE"}
            </div>
          </dl>
        </div>

        <div className="flex p-1 text-left font-semibold md:items-start">
          Source
        </div>
        {defaultCardData.discoverd_sources?.length ? (
          <div className="mb-2 flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(defaultCardData.discoverd_sources).map(
              (source, index) => {
                let domainName = source;

                try {
                  const url = new URL(source);
                  domainName = url.hostname;
                } catch (error) {
                  console.error(
                    `Error creating URL object for source: ${source}`
                  );
                }

                return (
                  <div
                    key={index}
                    className="text-#000000 mx-1 flex h-[20%] items-center break-all rounded-lg border-2 border-[#000000] p-1 text-center text-sm"
                  >
                    {domainName}
                    <CopyToClipboard
                      text={source}
                      onCopy={() => {
                        dispatch(
                          updateAlert({
                            alertText: "Source Copied",
                            alertType: "Success",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                      }}
                    >
                      <AiOutlineCopy
                        className="m-0 h-6 w-6 rounded-md  p-[2px]"
                        color="#4318FF"
                      />
                    </CopyToClipboard>
                  </div>
                );
              }
            )}
          </div>
        ) : (
          <div className="mb-2 flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap overflow-auto">
            <div className="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
              {"NONE"}
            </div>
          </div>
        )}

        <div className="flex items-center p-1 text-left font-semibold">
          Clients
        </div>

        <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
          {defaultCardData?.client_ids?.length &&
          defaultCardData?.client_ids[0] !== "" ? (
            <>
              {Object.values(defaultCardData.client_ids).map((client_id) => (
                <div
                  class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {getClientName(client_id)}
                </div>
              ))}
            </>
          ) : (
            <>
              <div class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]">
                Client Not Selected
              </div>
            </>
          )}
        </div>

        <div className="flex items-center p-1 text-left font-semibold">
          Brands
        </div>

        <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
          {defaultCardData.brand_ids?.length &&
          defaultCardData.brand_ids[0] !== "" ? (
            <>
              {Object.values(defaultCardData.brand_ids).map((brand_id) => (
                <div
                  class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {getBrandName(brand_id)}
                </div>
              ))}
            </>
          ) : (
            <>
              <div class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]">
                Brand Not Selected
              </div>
            </>
          )}
        </div>

        <div className="flex  justify-between">
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-4">
              <dl class="grid-cols-2">
                <div class="font-semibold">Created At</div>
                <div class="text-[#000000]">{defaultCardData.created_at}</div>
              </dl>
            </div>
          </div>
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Updated At</div>
                <div class="text-[#000000]">{defaultCardData.updated_at}</div>
              </dl>
            </div>
          </div>
        </div>

        <div className="ml-1 flex justify-between">
          <div class="mb-2">
            <dl class="grid-cols-2">
              <div class="font-semibold">Comment</div>
              <div class="text-[#000000]">
                {defaultCardData.user_note ? defaultCardData.user_note : "NONE"}
              </div>
            </dl>
          </div>
          <div className="flex justify-end ">
            {defaultCardData?.current_status === "SUCCESS" &&
            defaultCardData?.file_path ? (
              <div
                className="flex cursor-pointer items-center justify-center"
                // onClick={() => {
                //   window.open(
                //     `/admin/apks_results?query=md5:"${defaultCardData.file_path}"`,
                //     "_blank"
                //   );
                // }}
                onClick={() => {
                  const filePath = defaultCardData.file_path;
                  const regex = /\/([^\/]+)\.apk$/;
                  const match = filePath.match(regex);

                  if (match && match[1]) {
                    const id = match[1];
                    window.open(
                      `/admin/apks_results?query=md5:"${id}"`,
                      "_blank"
                    );
                  } else {
                    console.log("ID not found in the file path");
                  }
                }}
              >
                <div className="">
                  <a
                    data-tooltip-id="my-tooltip-navigateCerti"
                    data-tooltip-html="Selecting this icon will navigate to the APK results page,</br> search for APK data based on this md5 ID."
                    data-tooltip-place="top"
                  >
                    <AiFillAndroid
                      data-modal-target="popup-modal"
                      data-modal-toggle="popup-modal"
                      type="button"
                      className="mr-1 h-12 w-12  rounded-xl border border-[#4318FF] p-[6px] text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-navigateCerti" />
                </div>
              </div>
            ) : (
              <></>
            )}
            {/* <button
              className="m-1 flex w-20 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
              onClick={(e) => {
                dispatch(toggleShowAllDetails());
              }}
            >
              Edit
            </button> */}

            <button
              className="m-1 flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              onClick={(e) => {
                dispatch(toggleShowAllDetails());
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShowAllDetails;
