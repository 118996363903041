import React from "react";
import {
  setResultType,
  setEntityType,
} from "reduxConfig/slices/resultEditSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";

function TypeSelect({ type }) {
  const defaultData = useSelector((state) => state.resultEditData);

  if (defaultData.choosenType === "NONE") {
    var resultType = [];
    var entityType = [];
  } else {
    if (defaultData.choosenType === "WHITELISTED") {
      var resultType = ["APK", "DOMAIN"];
      var entityType = [
        "ORIGINAL_APP",
        "ORIGINAL",
        "ORIGINAL_DOMAIN",
        "SEO_POISONING",
      ];
    } else {
      var resultType = ["APK", "DOMAIN"];
      var entityType = [
        "PHISHING_DOMAIN",
        "SUSPICIOUS",
        "MALICIOUS_APP",
        "MODDED_APP",
        "SEO_POISONING",
      ];
    }
  }

  const userAction = useSelector((state) => state.resultEditData.choosenType);
  const dispatch = useDispatch();
  return (
    <div>
      <label
        for="countries"
        class=" mt-3  mb-1 flex items-center font-semibold text-[#000000] dark:text-white"
      >
        {type ? "Select Result Type:" : "Select Entity  Type:"}
        {type ? (
          <div className="ml-1 text-lg font-normal">
            <a
              data-tooltip-id="my-tooltip-URLs"
              data-tooltip-html="Here you can assign a </br> result type for your results"
              data-tooltip-place="right"
            >
              <BsQuestion
                className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                color="#4318FF"
              />
            </a>
            <Tooltip className="z-10" id="my-tooltip-URLs" />
          </div>
        ) : (
          <div className="ml-1 text-lg font-normal">
            <a
              data-tooltip-id="my-tooltip-URLs"
              data-tooltip-html="Here you can assign a </br> entity type for your results"
              data-tooltip-place="right"
            >
              <BsQuestion
                className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                color="#4318FF"
              />
            </a>
            <Tooltip className="z-10" id="my-tooltip-URLs" />
          </div>
        )}
      </label>
      <select
        id="countries"
        class="block w-full  border border-[#000000] bg-white p-2.5 text-sm text-[#000000] focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
        onChange={(e) => {
          type
            ? dispatch(setResultType(e.target.value))
            : dispatch(setEntityType(e.target.value));
        }}
        value={type ? defaultData.resultType : defaultData.entityInterestType}
      >
        {type
          ? resultType.map((element) => (
              <option value={element}>{element}</option>
            ))
          : entityType.map((element) => (
              <option value={element}>{element}</option>
            ))}
        <option value="UNKNOWN">UNKNOWN</option>
      </select>
    </div>
  );
}

export default TypeSelect;
