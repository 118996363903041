import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";
const initialState = {
  slackData: [],
  newSlackData: {
    credName: null,
    credtype: "WEBHOOK",
    oauthToken: null,
    channelId: null,
    webHookUrl: null,
  },
  updateSlackData: {
    config_id: null,
    credName: null,
    credtype: null,
    oauthToken: null,
    channelId: null,
    webHookUrl: null,
  },
  updateDeleteData: {
    channelName: null,
    channelId: null,
  },
  showCreateModal: false,
  showEditModal: false,
  showDeleteModal: false,
  type: "WEBHOOK",
  error: null,
  status: null,
  isLoading: false,
};

export const getSlackDatas = createAsyncThunk("slack/data", () => {
  let stateData = store.getState();
  return apiCall("sw_misc/slack_config", "get");
});

export const getSlackDatasSkipLoad = createAsyncThunk("slack_load/data", () => {
  let stateData = store.getState();
  return apiCall("sw_misc/slack_config", "get");
});

export const createSlackConfig = createAsyncThunk("slack/new", () => {
  let stateData = store.getState();
  let newSlackData = stateData.settingsData.newSlackData;
  let dataToPost = {
    cred_name: newSlackData.credName,
    cred_type: newSlackData.credtype,
    oauth_token: newSlackData.oauthToken,
    channel_id: newSlackData.channelId,
    webhook_url: newSlackData.webHookUrl,
  };
  return apiCall("sw_misc/slack_config", "post", dataToPost);
});

export const EditSlackConfig = createAsyncThunk("slack/edit", () => {
  let stateData = store.getState();
  let updateSlackData = stateData.settingsData.updateSlackData;
  let dataToPost = {
    config_id: updateSlackData.config_id,
    cred_name: updateSlackData.credName,
    cred_type: updateSlackData.credtype,
    oauth_token: updateSlackData.oauthToken,
    channel_id: updateSlackData.channelId,
    webhook_url: updateSlackData.webHookUrl,
  };
  return apiCall(
    `sw_misc/slack_config/${updateSlackData.config_id}`,
    "post",
    dataToPost
  );
});

export const settingsDataSlice = createSlice({
  name: "settingsData",
  initialState,
  reducers: {
    showCreateModal: (state) => {
      state.showCreateModal = !state.showCreateModal;
    },
    showEditModal: (state) => {
      state.showEditModal = !state.showEditModal;
    },
    updateNewSlackData: (state, action) => {
      state.newSlackData = {
        ...state.newSlackData,
        ...action.payload,
      };
    },
    toggleType: (state, action) => {
      state.type = action.payload;
    },
    updateErrorSlack: (state, action) => {
      state.error = null;
    },
    updateSlackStatus: (state, action) => {
      state.status = null;
    },
    updateExistingSlack: (state, action) => {
      state.updateSlackData = { ...state.updateSlackData, ...action.payload };
    },
    toggleDeleteSlack: (state, action) => {
      state.showDeleteModal = !state.showDeleteModal;
    },
    updateDeleteData: (state, action) => {
      state.updateDeleteData = { ...state.updateDeleteData, ...action.payload };
    },
    clearDataSlackNew: (state) => {
      state.newSlackData = initialState.newSlackData;
    },
    clearDataSlackOld: (state) => {
      state.updateSlackData = initialState.updateSlackData;
    },
    clearDataSlackDelete: (state) => {
      state.updateDeleteData = initialState.updateDeleteData;
    },
    clearAllDataSetting: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSlackDatas.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSlackDatas.fulfilled, (state, action) => {
        state.isLoading = false;
        state.slackData = action.payload.data.data;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalRules = action.payload.data.data.total_results;
      })
      .addCase(getSlackDatas.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createSlackConfig.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(createSlackConfig.fulfilled, (state, action) => {
        state.status = "CREATE_SLACK";
        // state.isLoading = false;
        state.newSlackData = initialState.newSlackData;
      })
      .addCase(createSlackConfig.rejected, (state, action) => {
        state.status = "ERROR";
        // state.isLoading = false;
        state.error = action.error.message;
        state.newSlackData = initialState.newSlackData;
      })
      .addCase(EditSlackConfig.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(EditSlackConfig.fulfilled, (state, action) => {
        state.status = "UPDATE_SLACK";
        // state.isLoading = false;
        state.updateSlackData = initialState.updateSlackData;
      })
      .addCase(EditSlackConfig.rejected, (state, action) => {
        state.status = "ERROR";
        // state.isLoading = false;
        state.error = action.error.message;
        state.updateSlackData = initialState;
      })
      .addCase(getSlackDatasSkipLoad.pending, (state) => {})
      .addCase(getSlackDatasSkipLoad.fulfilled, (state, action) => {
        state.slackData = action.payload.data.data;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalRules = action.payload.data.data.total_results;
      })
      .addCase(getSlackDatasSkipLoad.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  showCreateModal,
  showEditModal,
  updateNewSlackData,
  toggleType,
  updateErrorSlack,
  updateSlackStatus,
  updateExistingSlack,
  toggleDeleteSlack,
  updateDeleteData,
  clearDataSlackNew,
  clearDataSlackOld,
  clearDataSlackDelete,
  clearAllDataSetting,
} = settingsDataSlice.actions;

export default settingsDataSlice.reducer;
