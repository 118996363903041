import React, { useRef, useState } from "react";
import {
  AiOutlineCopy,
  AiOutlineInfoCircle,
  AiOutlineUpload,
  AiFillAndroid,
} from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import noPreview from "../../../../assets/img/urlImageGray.jpg";
import { useDispatch } from "react-redux";
import {
  setDefaultResult,
  setEyeResult,
} from "reduxConfig/slices/resultEditSlice";
import { toggleEditModal } from "reduxConfig/slices/resultEditSlice";
import { toggleEyeModal } from "reduxConfig/slices/resultSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { useSelector } from "react-redux/es/hooks/useSelector";
import getIstTime from "./helpers/istConvertor";
import apiCall from "axiosConfig/axiosConfig";
import { useEffect } from "react";
import { changeStatus } from "reduxConfig/slices/resultEditSlice";
import { Tooltip } from "react-tooltip";
import { toggleShowUploadModal } from "reduxConfig/slices/resultSlice";
import { setUploadData } from "reduxConfig/slices/resultSlice";
import { changeStatusResults } from "reduxConfig/slices/resultSlice";
import { TbApps } from "react-icons/tb";
import { IoIosCheckmarkCircle } from "react-icons/io";

function ResultCard({ cardData }) {
  const brandData = useSelector((state) => state.brandsData.brandData);
  const dispatch = useDispatch();
  const shouldUpdate = useSelector((state) => {
    return state.resultEditData;
  });
  const checkUploadStatus = useSelector((state) => state.resultsData);
  const [resultData, setResultData] = useState(cardData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedSiAndroidstudioOption, setSelectedSiAndroidstudioOption] =
    useState(null);
  const isImport = false;
  const isApkUpload =
    resultData?.user_data?.apk_attributes?.apk_result_ids?.length > 1;
  const isNotApkUpload =
    resultData?.user_data?.apk_attributes?.apk_result_ids.length === 0 ||
    !resultData?.user_data?.apk_attributes;

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionClick = (option) => {
    window.open(`/admin/apks_results?query=apk_id:"${option}"`, "_blank");
    setSelectedSiAndroidstudioOption(option);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("mousedown", handleOutsideClick);

    // Remove event listener when component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const updateId = `result_id:"${resultData._id}"`;

  useEffect(() => {
    if (
      shouldUpdate.status === "SUCCESS" &&
      shouldUpdate.resultId == resultData._id
    ) {
      apiCall("/sw_results/results", "post", {
        query: updateId,
      }).then((response) => {
        setResultData(response.data.data.page_results[0]);
        dispatch(
          updateAlert({
            alertText: "Update Success",
            alertType: "Success",
          })
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);
        dispatch(changeStatus(null));
      });
    }
    if (checkUploadStatus.status === "UPLOAD_SUCCESS") {
      dispatch(
        updateAlert({
          alertText: "Upload Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(changeStatusResults(null));
    }
  }, [shouldUpdate]);
  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "Brand Deleted";
  };
  return (
    <div class="mb-10 flex w-[25vw] max-w-[25vw] flex-wrap items-center bg-lightPrimary p-2">
      <div className="h-full w-full">
        <div className="border-grey-400 relative flex items-center justify-center rounded-t-xl border-2 bg-[#ADB7D5]">
          <div className="flex">
            <div className="">
              <a
                data-tooltip-id="my-tooltip-img"
                data-tooltip-html="This image serve as a preview when sharing the provided link."
                data-tooltip-place="top"
              >
                <img
                  alt=""
                  src={
                    resultData?.preview_metadata?.preview_bucket_id
                      ? `https://dsgx98c21wj98.cloudfront.net/${resultData.preview_metadata.preview_bucket_id}`
                      : noPreview
                  }
                  tabindex="0"
                  class="m-2 h-[200px] w-[200px] rounded-xl focus:outline-none"
                />
              </a>
              <Tooltip className="z-40" id="my-tooltip-img" />
            </div>
            <div>
              <a
                data-tooltip-id="my-tooltip-favIcon"
                data-tooltip-html="Favi Icon, an icon specific to a website, often seen in the browser's address bar."
                data-tooltip-place="top"
              >
                <img
                  alt=""
                  src={
                    resultData?.preview_metadata?.favicon
                      ? `https://dsgx98c21wj98.cloudfront.net/${resultData.preview_metadata.favicon}`
                      : noPreview
                  }
                  tabindex="0"
                  class="  m-2 h-[30px] w-[30px] rounded-md focus:outline-none"
                />
              </a>
              <Tooltip className="z-40" id="my-tooltip-favIcon" />
            </div>
            {resultData?.action_data?.seen ? (
              <div className="flex items-center justify-center text-center">
                <a
                  data-tooltip-id="my-tooltip-seen"
                  data-tooltip-html={`This Result is Marked as Seen by the User`}
                  data-tooltip-place="top"
                >
                  <IoIosCheckmarkCircle
                    type="button"
                    className="h-8 w-8 rounded-md p-[2px] text-center text-[#4318FF]"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip-seen" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div class="rounded-b-2xl bg-white">
          <div class="flex items-center justify-between px-4 pt-4">
            <div>
              {" "}
              <h2
                tabindex="0"
                class="flex max-h-[3em] min-h-[3em] w-full flex-row flex-wrap justify-start text-[100%] font-semibold focus:outline-none"
              >
                {resultData?.title ? resultData.title : "No Title Available"}
              </h2>
            </div>
            <div
              className="-mt-6 flex cursor-pointer items-center justify-center"
              onClick={() => {
                dispatch(
                  setEyeResult({
                    resultId: resultData._id,
                    resultTitle: resultData.title,
                    resultLink: resultData.link,
                    resultJson: {
                      resultId: resultData._id,
                      resultTitle: resultData.title,
                      resultLink: resultData.link,
                      ruleId: resultData._id,
                      keyWords: resultData.query_keywords,
                      actionData: resultData.action_data,
                      createdAt: resultData.created_at,
                      lastMatched: resultData.last_matched,
                      appDescription: resultData.text_snippet,
                      searchType: resultData.search_type,
                      title: resultData.title,
                    },
                    seen: resultData?.action_data?.seen,
                  })
                );
                dispatch(toggleEyeModal());
              }}
            >
              <div className="">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Click the info icon to open the modal </br> where you can perform actions such as </br> > marking as seen </br> > deleting the result </br>  > copying result links and JSON."
                  data-tooltip-place="top"
                >
                  <AiOutlineInfoCircle
                    data-modal-target="popup-modal"
                    data-modal-toggle="popup-modal"
                    type="button"
                    className="h-8 w-8 rounded-md  p-[2px]"
                    color="#4318FF"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip" />
              </div>
            </div>
          </div>
          <div class="flex flex-col items-start justify-start px-4 pt-4">
            <dt class="font-semibold dark:text-gray-400">Result id:</dt>
            <div className="flex w-[100%] justify-between">
              <div class="text-sm">
                {resultData._id ? resultData._id : "UNKNOWN"}
              </div>
              <div className="">
                <CopyToClipboard
                  text={`result_id:\"${resultData._id}\"`}
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "Result Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <span className="flex cursor-grabbing">
                    {" "}
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 rounded-md  p-[2px]"
                      color="#4318FF"
                    />
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-start justify-start px-4 pt-4">
            <dt class="font-semibold dark:text-gray-400">Rule id:</dt>
            <div class="max-h-[4em] min-h-[4em]  w-[100%] justify-between overflow-auto text-sm">
              {resultData?.user_data?.query_ids?.length
                ? resultData.user_data.query_ids.map((item) => (
                    <div className="flex w-[100%] justify-between">
                      <div className="">{item}</div>
                      <div className="">
                        <CopyToClipboard
                          text={`rule:\"${item}\"`}
                          onCopy={() => {
                            dispatch(
                              updateAlert({
                                alertText: "Rule Copied",
                                alertType: "Success",
                              })
                            );
                            setTimeout(() => {
                              dispatch(toggleAlert());
                            }, 2000);
                          }}
                        >
                          <span className="flex cursor-grabbing">
                            {" "}
                            <AiOutlineCopy
                              className="m-0 h-6 w-6 rounded-md  p-[2px]"
                              color="#4318FF"
                            />
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  ))
                : "UNKNOWN"}
            </div>
          </div>
          <div class="p-4">
            <dl class="max-w-md   dark:divide-gray-700 dark:text-white">
              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Link:</dt>
                <div className="flex justify-between">
                  <div className="hide-scrollbar w-[90%] overflow-hidden">
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={
                        resultData?.link?.length > 150
                          ? `${resultData.link.substring(0, 150)}....`
                          : resultData.link
                      }
                      data-tooltip-place="top"
                      className="flex cursor-pointer overflow-hidden whitespace-nowrap text-sm text-blue-700"
                      style={{
                        maxWidth: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => {
                        window.open(`${resultData.link}`, "_blank");
                      }}
                    >
                      {resultData?.link?.length > 60
                        ? `${resultData.link.substring(0, 50)}....`
                        : resultData.link}
                    </a>
                    <Tooltip className="z-10" id="my-tooltip" />
                  </div>
                  <div className="">
                    <CopyToClipboard
                      text={resultData.link}
                      onCopy={() => {
                        dispatch(
                          updateAlert({
                            alertText: "Link Copied",
                            alertType: "Success",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                      }}
                    >
                      <span className="flex cursor-grabbing">
                        {" "}
                        <AiOutlineCopy
                          className="m-0 h-6 w-6 rounded-md p-[2px]"
                          color="#4318FF"
                        />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">
                  Url Description:
                </dt>
                <dd className="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap justify-start overflow-auto break-all">
                  {resultData?.text_snippet
                    ? resultData.text_snippet
                    : "No Description"}
                </dd>
              </div>
              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Indicator:</dt>
                <dd
                  className={`text-sm ${
                    resultData?.action_data?.marked_as
                      ? resultData.action_data.marked_as === "WHITELISTED"
                        ? "text-green-500"
                        : "text-red-500"
                      : " "
                  }`}
                >
                  {resultData?.action_data?.marked_as
                    ? resultData.action_data.marked_as
                    : "Not Marked"}
                </dd>
              </div>
              <div className="flex justify-start">
                {" "}
                <div class="mr-8 flex flex-col pb-3">
                  <dt class="font-semibold dark:text-gray-400">Entity Type:</dt>
                  <dd class="text-sm">
                    {resultData?.action_data?.result_type
                      ? resultData.action_data.result_type
                      : "UNKNOWN"}
                  </dd>
                </div>
                <div class="flex flex-col pb-3">
                  <dt class="font-semibold dark:text-gray-400">Result Type:</dt>
                  <dd class="text-sm">
                    {resultData?.action_data?.entity_type
                      ? resultData.action_data.entity_type
                      : "UNKNOWN"}
                  </dd>
                </div>
              </div>
            </dl>

            <div className="flex justify-between">
              <div className="my-1 text-left font-semibold">Keywords:</div>
              <div>
                <CopyToClipboard
                  text={
                    resultData?.user_data?.query_keywords?.length
                      ? resultData.user_data.query_keywords
                      : "None"
                  }
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "keyword Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <AiOutlineCopy
                    className="m-0 h-6 w-6 cursor-grabbing rounded-md  p-[2px]"
                    color="#4318FF"
                  />
                </CopyToClipboard>
              </div>
            </div>
            {resultData?.user_data?.query_keywords?.length ? (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                {resultData.user_data.query_keywords.map((words) => (
                  <div
                    class="mr-1 flex h-[20%] items-center rounded-lg border-2  border-[#000000] px-[10px] py-[1px] text-center text-sm text-[#000000]"
                    style={{ marginBottom: "5px" }}
                  >
                    {words}
                  </div>
                ))}
              </div>
            ) : (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000  h-[20%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                  Keywords Not Selected
                </div>
              </div>
            )}

            <div className="my-1 text-left font-semibold">Targeted Brands:</div>
            {resultData?.action_data?.brands?.length ? (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                {Object.values(resultData.action_data.brands).map(
                  (brand_id) => (
                    <div class=" mr-1 h-[20%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]">
                      {getBrandName(brand_id)}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000  h-[20%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                  Brands Not Selected
                </div>
              </div>
            )}

            <div className="my-1 text-left font-semibold">
              Possible URLs Classifications:
            </div>
            {cardData?.possible_url_classifications?.length ? (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                {Object.values(cardData.possible_url_classifications).map(
                  (possible_url_classifications) => (
                    <div class=" mr-1 h-[20%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]">
                      {possible_url_classifications.replace(/_/g, " ")}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000  h-[20%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                  None
                </div>
              </div>
            )}

            <div className="flex items-center justify-between">
              <div class=" flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">
                  Date Discovered:
                </dt>
                <dd class="text-sm">
                  {resultData?.user_data?.created_at
                    ? getIstTime(resultData.user_data.created_at)
                    : "No Date"}
                </dd>
              </div>

              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Last Seen:</dt>
                <dd class="text-sm">
                  {resultData?.user_data?.last_matched
                    ? getIstTime(resultData.user_data.last_matched)
                    : "No Date"}
                </dd>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div class=" flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Search Type:</dt>
                <dd class="text-sm">
                  {isImport ? (
                    "MANUAL_IMPORT"
                  ) : (
                    <>
                      {resultData?.user_data?.search_type?.length ? (
                        <div class="">
                          {Object.values(resultData.user_data.search_type).map(
                            (search_type) => (
                              <div class="">
                                {search_type.replace(/_/g, " ")}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div class="">
                          <div class="">None</div>
                        </div>
                      )}
                    </>
                  )}
                </dd>
              </div>

              <div className="flex">
                {isNotApkUpload ? (
                  <></>
                ) : (
                  <>
                    {isApkUpload ? (
                      <>
                        <div
                          className="mr-1 flex cursor-pointer items-center justify-center rounded-xl border border-[#4318FF] bg-white px-3 text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                          onClick={toggleDropdown}
                        >
                          <div className="flex items-center justify-center text-center">
                            <a
                              data-tooltip-id="my-tooltip-multi"
                              data-tooltip-html={`Select this icon, and it will display the list of APKs you will upload from this URL.`}
                              data-tooltip-place="top"
                            >
                              <TbApps
                                type="button"
                                className="h-8 w-8 items-center  justify-center rounded-md p-[2px] text-center"
                              />
                            </a>
                            <Tooltip className="z-10" id="my-tooltip-multi" />
                          </div>
                        </div>
                        {isDropdownOpen && (
                          <div
                            ref={dropdownRef}
                            className="absolute z-10 ml-16 rounded-lg border border-gray-300 bg-white shadow-lg"
                          >
                            <ul>
                              {resultData?.user_data?.apk_attributes?.apk_result_ids.map(
                                (option) => (
                                  <>
                                    <a
                                      data-tooltip-id="my-tooltip-options"
                                      data-tooltip-html={`Select this ${option}, and it will navigate the apk results page`}
                                      data-tooltip-place="right"
                                    >
                                      <li
                                        key={option}
                                        className={`flex cursor-pointer justify-between rounded-lg p-2 hover:bg-[#4318FF] hover:text-white ${
                                          selectedSiAndroidstudioOption ===
                                          option
                                            ? "text-[#4318FF]"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          handleOptionClick(option)
                                        }
                                      >
                                        <spam className="px-2 py-1">
                                          {option}
                                        </spam>
                                      </li>
                                    </a>
                                    <Tooltip
                                      className="z-10"
                                      id="my-tooltip-options"
                                    />
                                  </>
                                )
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div
                          className="mr-1 flex cursor-pointer items-center justify-center rounded-xl border border-[#4318FF] bg-white px-3 text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                          onClick={() => {
                            window.open(
                              `/admin/apks_results?query=apk_id:"${resultData?.user_data?.apk_attributes?.apk_result_ids}"`,
                              "_blank"
                            );
                          }}
                        >
                          <div className="flex items-center justify-center text-center">
                            <a
                              data-tooltip-id="my-tooltip-apk"
                              data-tooltip-html={`Select this icon, and it will navigate to the APK results page to search for the ${resultData?.user_data?.apk_attributes?.apk_result_ids} to upload from this URL.`}
                              data-tooltip-place="top"
                            >
                              <AiFillAndroid
                                type="button"
                                className="h-8 w-8 items-center  justify-center rounded-md p-[2px] text-center"
                              />
                            </a>
                            <Tooltip className="z-10" id="my-tooltip-apk" />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}

                <div
                  className="mr-1 flex cursor-pointer items-center justify-center rounded-xl border border-[#4318FF] bg-white px-3 text-center text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    dispatch(
                      setUploadData({
                        url: [resultData.link],
                        dSource: [resultData.link],
                        url_id: resultData._id,
                      })
                    );
                    dispatch(toggleShowUploadModal());
                  }}
                >
                  <div className="flex items-center justify-center text-center">
                    <a
                      data-tooltip-id="my-tooltip-upload"
                      data-tooltip-html="Select the upload icon to open a modal where you can upload an APK file."
                      data-tooltip-place="top"
                    >
                      <AiOutlineUpload
                        // data-modal-target="popup-modal"
                        // data-modal-toggle="popup-modal"
                        type="button"
                        className="h-8 w-8 items-center  justify-center rounded-md p-[2px] text-center"
                        // color="#4318FF"
                      />
                    </a>
                    <Tooltip className="z-10" id="my-tooltip-upload" />
                  </div>
                </div>
                <div
                  className="flex h-10 cursor-pointer items-center justify-center rounded-xl border border-[#4318FF] bg-white px-3 text-center font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    dispatch(
                      setDefaultResult({
                        resultId: resultData._id,
                        entityInterestType: resultData.action_data.result_type,
                        resultType: resultData.action_data.entity_type,
                        choosenType: resultData.action_data.marked_as,
                        brandData: Array.from(resultData.action_data.brands),
                        resultTitle: resultData.title,
                        resultImg: resultData.preview_metadata.preview_bucket_id
                          ? `https://dsgx98c21wj98.cloudfront.net/${resultData.preview_metadata.preview_bucket_id}`
                          : noPreview,
                        comment: resultData.action_data.comment,
                      })
                    );
                    dispatch(toggleEditModal());
                  }}
                >
                  Edit
                  <FiEdit className="-mt-1 h-6 w-6 p-[4px]" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultCard;
