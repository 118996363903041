import DoubleBarChart from "views/admin/analytics/components/DoubleBarChart";
import TotalSpent from "views/admin/analytics/components/TotalSpent";
import PieChartCard from "views/admin/analytics/components/PieChartCard";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";
import { AiFillAndroid, AiOutlineAppstoreAdd } from "react-icons/ai";
import { FiLink } from "react-icons/fi";
import { GiSuspicious } from "react-icons/gi";

import Widget from "components/widget/Widget";

import DailyTraffic from "views/admin/analytics/components/DailyTraffic";

const Dashboard = () => {
  return (
    <div>
      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <DoubleBarChart />
      </div>
      {/* <div className="flex flex-row justify-evenly mt-5">
        <div className="flex w-[100%] flex-row justify-between rounded-[20px] md:grid-cols-2 mr-10">
          <DailyTraffic />
          <PieChartCard />
        </div>
        <div className="flex w-[100%] flex-row justify-between rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>
      </div> */}

      <div className="mt-5 grid w-[100%] grid-cols-4  grid-rows-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3  3xl:grid-cols-1 ">
        <Widget
          icon={<AiFillAndroid className="h-7 w-7" />}
          title={
            <span style={{ fontSize: "18px", color: "#000000" }}>
              Discovered APKs
            </span>
          }
          subtitle={"210"}
        />
        <Widget
          icon={<FiLink className="h-6 w-6" />}
          title={
            <span style={{ fontSize: "18px", color: "#000000" }}>
              URLs Analysis
            </span>
          }
          subtitle={"3254"}
        />
        <Widget
          icon={<AiOutlineAppstoreAdd className="h-7 w-7" />}
          title={
            <span style={{ fontSize: "18px", color: "#000000" }}>
              APK Distribution Domains
            </span>
          }
          subtitle={"39"}
        />
        <Widget
          icon={<GiSuspicious className="h-6 w-6" />}
          title={
            <span style={{ fontSize: "18px", color: "#000000" }}>
              Total Malicious APK
            </span>
          }
          subtitle={"75"}
        />
      </div>
    </div>
  );
};

export default Dashboard;
