import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux/es/hooks/useSelector";
import TasksPerPage from "./TasksPerPage";
import { updatePageNumberTask } from "reduxConfig/slices/taskStatusSlice";

function PaginationComp() {
  const currentPage = useSelector((state) => state.taskStatusData.pageNumber);
  const totalPages = useSelector((state) => state.taskStatusData.totalPages);
  const totalResultsCount = useSelector(
    (state) => state.taskStatusData.totalRules
  );
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      const pageNumber = parseInt(inputValue);

      if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
        dispatch(updatePageNumberTask(pageNumber));
      }

      setInputValue("");
    }
  };

  useEffect(() => {
    updatePageNumberTask(currentPage);
  });
  return (
    <div className="flex items-center justify-center py-4 px-2">
      <div className="flex items-center">
        <span className="font-bold text-[#4318FF]">Go to page</span>
        <input
          type="text"
          placeholder="0"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
          className="ml-2 h-8 w-[20%] items-center rounded-md border-2 border-[#4318FF] bg-white p-2 text-center text-[#4318FF] outline-none"
        />
      </div>
      <div className="flex w-full items-center justify-center">
        <nav aria-label="Page navigation example">
          <ul class="inline-flex -space-x-px text-sm">
            {totalPages <= 1 ? (
              <></>
            ) : (
              <li>
                <a
                  class="mr-1 flex h-8 cursor-pointer items-center justify-center rounded-md border-2 border-[#4318FF] bg-white px-3 leading-tight text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    currentPage !== 1 &&
                      dispatch(updatePageNumberTask(currentPage - 1));
                  }}
                >
                  Previous
                </a>
              </li>
            )}

            {Array(totalPages)
              .fill()
              .map((_, index) => {
                if (index < 5) {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        dispatch(updatePageNumberTask(index + 1));
                      }}
                    >
                      <p
                        className={`mr-1 flex h-8 cursor-pointer items-center justify-center  rounded-md border-2 border-[#4318FF] bg-white px-3 ${
                          currentPage === index + 1
                            ? "bg-[#4318FF] text-white"
                            : "text-[#4318FF] "
                        } hover:bg-[#4318FF] hover:text-white`}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                } else if (index === 5) {
                  return (
                    <li key={index}>
                      <p className="flex h-8 items-center justify-center rounded-md">
                        {" "}
                        . . . .{" "}
                        <li
                          key={index}
                          onClick={() => {
                            dispatch(updatePageNumberTask(index + 1));
                          }}
                        >
                          <p
                            className={`mr-1 ml-1 flex h-8 cursor-pointer items-center justify-center  rounded-md border-2 border-[#4318FF] bg-white px-3 ${
                              currentPage === index + 1
                                ? "bg-[#4318FF] text-white"
                                : "text-[#4318FF] "
                            } hover:bg-[#4318FF] hover:text-white`}
                          >
                            {index + 1}
                          </p>
                        </li>
                      </p>
                    </li>
                  );
                } else if (
                  index === totalPages - 1 ||
                  index === currentPage - 2 ||
                  index === currentPage - 1 ||
                  index === currentPage ||
                  index === currentPage + 1 ||
                  index === currentPage + 2
                ) {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        dispatch(updatePageNumberTask(index + 1));
                      }}
                    >
                      <p
                        className={`mr-1 flex h-8 cursor-pointer items-center justify-center  rounded-md border-2 border-[#4318FF] bg-white px-3 ${
                          currentPage === index + 1
                            ? "bg-[#4318FF] text-white"
                            : "text-[#4318FF] "
                        } hover:bg-[#4318FF] hover:text-white`}
                      >
                        {index + 1}
                      </p>
                    </li>
                  );
                }
                return null;
              })}
            {totalPages <= 1 ? (
              <></>
            ) : (
              <li>
                <p
                  class="mr-1 flex h-8 cursor-pointer items-center justify-center rounded-md border-2 border-[#4318FF] bg-white px-3 leading-tight text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    currentPage !== totalPages &&
                      dispatch(updatePageNumberTask(currentPage + 1));
                  }}
                >
                  Next
                </p>
              </li>
            )}
          </ul>
        </nav>
        <div className="m-2 rounded-md border-2 border-[#4318FF] bg-white p-1">
          <p className="flex h-5 items-center justify-center font-bold text-[#4318FF]">
            Total Results
            <span className="m-2 font-bold text-[#4318FF]">
              {totalResultsCount}
            </span>
          </p>
        </div>
      </div>
      <div className="ml-4 mt-5 flex items-center justify-between">
        <TasksPerPage />
      </div>
    </div>
  );
}

export default PaginationComp;
