import React, { useState, useEffect, useRef } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { updatePageNumber } from "reduxConfig/slices/resultSlice";
import { addAdditionalFilters } from "reduxConfig/slices/resultSlice";
import { getSearchResults } from "reduxConfig/slices/resultSlice";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

function FilterOptions() {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState({
    Or: false,
    Threat: false,
    Whitelisted: false,
    Suspicious: false,
    APK: false,
    "Phishing Domains": false,
    Unknown: false,
  });
  const filterOptionsRef = useRef(null);
  const dispatch = useDispatch();
  const additionalFilters = useSelector(
    (state) => state.resultsData.additionalFilters
  );
  // const resultType = ["APK", "DOMAIN", "UNKNOWN"];
  // const entityType = [
  //   "PHISHING_DOMAIN",
  //   "UNKNOWN",
  //   "SUSPICIOUS",
  //   "MALICIOUS_APP",
  //   "MODDED_APP",
  //   "ORIGINAL_APP",
  //   "ORIGINAL",
  //   "ORIGINAL_DOMAIN",
  // ];
  const source = [
    // "GOOGLE_CSE",
    "GOOGLE_SEARCH",
    "GOOGLE_PLAYSTORE",
    "VT_SERVER",
    // "THIRD_PARTY_CRAWLERS",
  ];
  const markedAs = ["THREAT", "WHITELISTED", "UNKNOWN"];
  const possibleUrl = [
    "TRUSTED_APP_STORE",
    "MISC",
    "MOD_APP_STORE",
    "THIRD_PARTY_APP_STORE",
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterOptionsRef.current &&
        !filterOptionsRef.current.contains(event.target)
      ) {
        setShowFilterOptions(false);
      }
    };

    if (showFilterOptions) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterOptions]);

  const handleFilterClick = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const applyFilters = () => {
    setShowFilterOptions(false);
  };

  const cancelFilters = () => {
    setShowFilterOptions(false);
  };

  return (
    <div className="relative z-10 inline-block text-center">
      <button
        type="button"
        onClick={handleFilterClick}
        className={`bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-3 py-2 px-6 text-center font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white ${
          showFilterOptions ? "w-[6vw]" : "w-[6vw]"
        }`}
      >
        Filter
      </button>
      {showFilterOptions && (
        <div
          ref={filterOptionsRef}
          className="transdiv absolute right-[5%]  mt-2 rounded border border-gray-300 bg-white p-4 shadow-md"
        >
          <div className="mb-2 flex items-center justify-center">
            <div className="mx-8">
              <label className="mr-4 text-xl font-semibold">Filters</label>
              <label className="flex items-center">
                <input
                  className=" cursor-pointer"
                  type="checkbox"
                  name="Or"
                  checked={additionalFilters.use_or}
                  onChange={(e) => {
                    dispatch(
                      addAdditionalFilters({
                        use_or: !additionalFilters.use_or,
                      })
                    );
                  }}
                />
                <span className="mx-2">Or</span>
                <div className="">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Select the checkbox to enable the or operator."
                    data-tooltip-place="top"
                  >
                    <AiOutlineInfoCircle className="cursor-pointer text-[#4318FF]" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </label>
            </div>
          </div>
          <div className="-mt-20 flex justify-end">
            <button
              type="button"
              onClick={() => {
                dispatch(
                  addAdditionalFilters({
                    use_or: false,
                    result_type: [],
                    entity_type: [],
                    source: [],
                    marked_as: [],
                    possible_url_classifications: [],
                  })
                );
              }}
              className="underline-text p-7  text-[#4318FF] hover:text-[#4318FF]"
            >
              Clear All
            </button>
          </div>
          <div className="flex w-full justify-evenly">
            {/* <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Result Type
              </h3>
              {resultType.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.result_type.includes(element)}
                    onChange={(e) => {
                      additionalFilters.result_type.includes(element)
                        ? dispatch(
                            addAdditionalFilters({
                              result_type: additionalFilters.result_type.filter(
                                (item) => item != element
                              ),
                            })
                          )
                        : dispatch(
                            addAdditionalFilters({
                              result_type: [
                                ...additionalFilters.result_type,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  <span className="ml-2">{element}</span>
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div> */}
            <span className="border-black border-2"></span>
            <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Source
              </h3>
              {source.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.source.includes(element)}
                    onChange={(e) => {
                      additionalFilters.source.includes(element)
                        ? dispatch(
                            addAdditionalFilters({
                              source: additionalFilters.source.filter(
                                (item) => item != element
                              ),
                            })
                          )
                        : dispatch(
                            addAdditionalFilters({
                              source: [
                                ...additionalFilters.source,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  {/* <span className="ml-2">{element}</span> */}
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div>
            <span className="border-black border-2"></span>
            {/* <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Entity Type
              </h3>
              {entityType.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.entity_type.includes(element)}
                    onChange={(e) => {
                      additionalFilters.entity_type.includes(element)
                        ? dispatch(
                            addAdditionalFilters({
                              entity_type: additionalFilters.entity_type.filter(
                                (item) => item != element
                              ),
                            })
                          )
                        : dispatch(
                            addAdditionalFilters({
                              entity_type: [
                                ...additionalFilters.entity_type,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  <span className="ml-2">{element}</span>
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div>
            <span className="border-black border-2"></span> */}
            <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Marked as
              </h3>
              {markedAs.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.marked_as.includes(element)}
                    onChange={(e) => {
                      additionalFilters.marked_as.includes(element)
                        ? dispatch(
                            addAdditionalFilters({
                              marked_as: additionalFilters.marked_as.filter(
                                (item) => item != element
                              ),
                            })
                          )
                        : dispatch(
                            addAdditionalFilters({
                              marked_as: [
                                ...additionalFilters.marked_as,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  {/* <span className="ml-2">{element}</span> */}
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div>
            <span className="border-black border-2"></span>
            <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Possible URLs
              </h3>
              {possibleUrl.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.possible_url_classifications.includes(
                      element
                    )}
                    onChange={(e) => {
                      additionalFilters.possible_url_classifications.includes(
                        element
                      )
                        ? dispatch(
                            addAdditionalFilters({
                              possible_url_classifications:
                                additionalFilters.possible_url_classifications.filter(
                                  (item) => item != element
                                ),
                            })
                          )
                        : dispatch(
                            addAdditionalFilters({
                              possible_url_classifications: [
                                ...additionalFilters.possible_url_classifications,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  {/* <span className="ml-2">{element}</span> */}
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div className="mr-8 mt-4 flex justify-center">
            <button
              type="button"
              onClick={() => {
                dispatch(getSearchResults());
                dispatch(updatePageNumber(1));
                setShowFilterOptions(false);
              }}
              className="mr-4 rounded-xl bg-[#4318FF] px-4 py-2 text-white "
            >
              Apply
            </button>
            <button
              type="button"
              onClick={cancelFilters}
              className="rounded-xl border border-[#4318FF] px-4 py-2 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterOptions;
