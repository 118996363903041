import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./components/SearchBar";
import { RingLoader } from "react-spinners";
import { getBrandData } from "reduxConfig/slices/brandSlice";
import { getClientData } from "reduxConfig/slices/brandSlice";
import SearchOperatorModal from "./components/SearchOperatorModal";
// import DatePicker from "./components/DatePicker";
// import FilterModal from "./components/FilterModal";
import CardData from "./components/CardData";
import { getCertificateData } from "reduxConfig/slices/certificateSlice";
import PaginationComp from "./components/PaginationComp";
import ViewAllData from "./components/ViewAllData";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";
import urlParamsBuilder from "./components/helpers/getUrlParams";

function TaskStatusTab() {
  const filterData = useSelector((state) => state.certificateData);
  const dispatch = useDispatch();
  const pageNumber = useSelector((state) => state.certificateData.pageNumber);
  const isLoading = useSelector((state) => state.certificateData.isLoading);
  const error = useSelector((state) => state.certificateData.error);
  const certicateData = useSelector(
    (state) => state.certificateData.certicateData
  );
  const showViewAllData = useSelector(
    (state) => state.certificateData.showViewAllData
  );
  const showSearchOperator = useSelector(
    (state) => state.certificateData.showSearchInfoModal
  );

  useEffect(() => {
    dispatch(getCertificateData());
    dispatch(getBrandData());
    dispatch(getClientData());
  }, [pageNumber]);

  useEffect(() => {
    const body = document.body;

    if (showViewAllData || showSearchOperator) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [showViewAllData, showSearchOperator]);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(filterData)}`
    );
  });

  return (
    <div className={`relative`}>
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          {" "}
          <div className="relative top-[-7vh] left-[8vw] z-30 flex w-[70vw] justify-between">
            <div className="ml-[5vw]">
              {/* <SearchBar /> */}
            </div>
            <div className="flex items-center space-x-4">
              {/* <DatePicker /> */}
              <div>
                {/* <button
                  type="button"
                  onClick={(e) => {
                    dispatch(toggleShowFilterModalTask());
                  }}
                  className={`bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-3 py-2 px-6 text-center font-semibold text-blue-700 hover:bg-[#4318FF] hover:text-white ${"w-[6vw]"}`}
                >
                  Filter
                </button> */}
              </div>
              {/* <ExportButtonModal /> */}
            </div>
          </div>
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <div className="mt-8 grid h-full  grid-cols-1 gap-2 2xl:grid-cols-1">
                {certicateData.length ? (
                  // taskStatusData.map((element) => (
                  <CardData cardData={certicateData} />
                ) : (
                  // ))
                  <></>
                )}
              </div>
              {certicateData.length ? (
                <></>
              ) : (
                <div className="flex items-center  justify-center">
                  <Lottie
                    animationData={noDataAnimationData}
                    loop={true}
                    autoplay={true}
                    style={{ width: 500, height: 500 }}
                    speed={1.5}
                  />
                  {/* <div className="text-3xl font-bold">
                    No certificateData are available
                  </div> */}
                </div>
              )}
              {certicateData.length ? <PaginationComp /> : <></>}
            </>
          )}
          {showViewAllData ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <ViewAllData />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showSearchOperator ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <SearchOperatorModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {/* {showFilterModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <FilterModal />
              </div>
            </div>
          ) : (
            <></>
          )} */}
        </>
      )}
    </div>
  );
}

export default TaskStatusTab;
