import React, { useEffect, useState } from "react";
import { MultiSelect as Selector } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { updateNewFileUpload } from "reduxConfig/slices/uploadApkSlice";
import { updateNewDriveUpload } from "reduxConfig/slices/uploadApkSlice";

function ClientSelect({ isFileupload }) {
  const clientData = useSelector((state) => state.brandsData.clientData);
  const [formattedData, setBrandData] = useState([]);
  const componentDefaultValues = useSelector((state) =>
    isFileupload
      ? state.UploadApkData.fileUpload.client_id
      : state.UploadApkData.driveUpload.client_id
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let stateData = [];
    for (let elements in clientData) {
      stateData.push({
        value: clientData[elements]._id,
        label: clientData[elements].client_name,
      });
    }
    setBrandData(stateData);
  }, []);

  return (
    <div>
      {formattedData.length ? (
        <Selector
          className="multiselect"
          options={formattedData}
          value={componentDefaultValues}
          onChange={(selectedOption) => {
            isFileupload
              ? dispatch(updateNewFileUpload({ client_id: selectedOption }))
              : dispatch(updateNewDriveUpload({ client_id: selectedOption }));
          }}
          labelledBy={"Select"}
          isCreatable={false}
        />
      ) : (
        <div className="text-sm font-bold text-red-500">
        No clients have been created. Please create a client.
      </div>
      )}
    </div>
  );
}

export default ClientSelect;
