import React, { useEffect } from "react";

import { useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { updateBrandData } from "reduxConfig/slices/resultEditSlice";
function BrandSelect() {
  const brandData = useSelector((state) => state.brandsData.brandData);
  const [formattedData, setBrandData] = useState([]);
  const choosenBrands = useSelector((state) => state.resultEditData.brandData);
  const checkIndicator = useSelector(
    (state) => state.resultEditData.choosenType
  );
  const dispatch = useDispatch();

  const getDefaultValues = () => {
    let returnData = [];
    for (let element in choosenBrands) {
      let brand_object = brandData.find(
        (items) => items._id == choosenBrands[element]
      );
      if (brand_object) {
        returnData.push({
          value: brand_object._id,
          label: brand_object.brand_name,
        });
      }
    }
    return returnData;
  };

  useEffect(() => {
    let stateData = [];
    for (let elements in brandData) {
      stateData.push({
        value: brandData[elements]._id,
        label: brandData[elements].brand_name,
      });
    }

    setBrandData(stateData);
  }, []);

  if (checkIndicator === "NONE") {
    return null;
  }

  return (
    <div>
      {formattedData.length ? (
        <Select
          isMulti
          name="colors"
          defaultValue={getDefaultValues()}
          onChange={(selectedOption) => {
            dispatch(updateBrandData(selectedOption));
          }}
          options={formattedData}
          className="w-full border border-[#000000] text-[#000000]"
          classNamePrefix="select"
        />
      ) : (
        <div className="text-sm font-bold text-red-500">
          No brands have been created. Please create a brand.
        </div>
      )}
    </div>
  );
}

export default BrandSelect;
