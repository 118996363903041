function formatDateToDDMMYYYY(date) {
    // Get day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();
  
    // Format the date as "dd/mm/yyyy"
    return `${day}/${month}/${year}`;
  }
  const urlParamsBuilder = (filterData) => {
    const urlParams = [];
  
    if (filterData.queryString) {
      urlParams.push(`query=${encodeURIComponent(filterData.queryString)}`);
    }
  
    return urlParams.join("&");
  };
  
  export default urlParamsBuilder;
  