import React from "react";
import {
  MdArrowDropUp,

} from "react-icons/md";
import Card from "components/card";
import {
  lineChartDataTotalSpent,
  lineChartOptionsTotalSpent,
} from "variables/charts";
import LineChart from "components/charts/LineChart";

const TotalSpent = () => {
  return (
    <Card extra="!p-[20px] text-center">
      {/* <div className="flex justify-between">
      <div className="mb-6 flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center font-bold text-[#000000] hover:cursor-pointer  dark:text-white p-2 bg-white rounded-md border-2 border-[#000000] text-lg">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>
      </div> */}

      <div className="flex h-full w-full flex-col justify-between sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white flex justify-start">
        Discover Statistics 
        </h2>

        <div className="flex flex-col">
          {/* <p className="mt-[20px] text-3xl font-bold text-navy-700 dark:text-white text-start">
          70
          </p> */}
          <div className="flex flex-col items-start">
            <p className="mt-2 text-start text-sm text-gray-600">
              Discovered URLs
            </p>
            <div className="flex flex-row items-center justify-center">
              <MdArrowDropUp className="font-medium text-green-500" />
              <p className="text-sm font-bold text-green-500"> 3254 </p>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <p className="mt-2 text-start text-sm text-gray-600">
              Discovered APKs
            </p>
            <div className="flex flex-row items-center justify-center">
              <MdArrowDropUp className="font-medium text-green-500" />
              <p className="text-sm font-bold text-green-500"> 210 </p>
            </div>
          </div>
        </div>
        <div className="h-full w-full">
          <LineChart
            options={lineChartOptionsTotalSpent}
            series={lineChartDataTotalSpent}
          />
        </div>
      </div>
    </Card>
  );
};

export default TotalSpent;
