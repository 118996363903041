import React from "react";
function InternalNav({updatePageView,displayedPage}) {
  return (
    <ul class="my-4 flex w-[20%] -mt-5 cursor-pointer ">
      <li
        class="flex-1"
        onClick={(e) => {
          updatePageView(false);
        }}
      >
        <p
          class={`block border-b py-2 px-4 text-center ${
            displayedPage
              ? "border-[#4318FF] text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              : "border-[#6AD2FF] bg-[#4318FF] text-white hover:bg-[#4318FF]"
          }`}
        >
          Clients
        </p>
      </li>
      <li
        class="flex-1"
        onClick={(e) => {
          updatePageView(true);
        }}
      >
        <p
          class={`block  border-b   py-2   px-4 text-center ${
            displayedPage
              ? "border-[#6AD2FF] bg-[#4318FF] text-white hover:bg-[#4318FF]"
              : "border-[#4318FF] text-[#4318FF] hover:border-[#4318FF] hover:bg-[#4318FF] hover:text-white"
          }`}
        >
          Brands
        </p>
      </li>
    </ul>
  );
}

export default InternalNav;
