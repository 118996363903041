import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { toggleSearchInfoModal } from "reduxConfig/slices/officialApkSlice";

const sections = [
  {
    title: "Search Keyword in Apk Id",
    content: "Search Keyword in the Apk Id of the result",
    query: "apk_id:$search_keyword",
    example: 'apk_id:"6585374da63a897e07e2c61c"',
  },
  {
    title: "Search Keyword in App Name",
    content: "Search Keyword in the App Name of the result",
    query: "app_name:$search_keyword",
    example: 'app_name:"APTOIDE"',
  },
  {
    title: "Search by package name",
    content: "Search results belong to a particular package name",
    query: "package_name:$package_name",
    example: 'package_name:"com.paka.po"',
  },
  {
    title: "Search by client id",
    content: "Search results belong to a particular Client Id",
    query: "client:$client_id",
    example: 'client:"6583d72c4d4fe2ab6ea643ce"',
  },
  {
    title: "Search by brand id",
    content: "Search results belong to a particular Brand Id",
    query: "brand:$brand_id",
    example: 'brand:"6583d74b4d4fe2ab6ea643cf"',
  },
  {
    title: "Search by sha1",
    content: "Search results belong to a particular sha1",
    query: "sha1:$sha1_id",
    example: 'sha1:"13c024c233f28a447919ab8f9beb4af3271af916"',
  },
  {
    title: "Search by certificate sha1",
    content: "Search results belong to a particular certificate sha",
    query: "certificate_sha:$certificateShaId",
    example:
      'certificate_sha:"611e1350d32723bb264d94fadade0659b53fb6ce"',
  },
  {
    title: "Search by md5",
    content: "Search results belong to a particular MD5",
    query: "md5:$md5Id",
    example: 'md5:"384a69439157ceba102fe4e590c06e44"',
  },
  {
    title: "Search by source",
    content: "Search results belong to a particular source",
    query: "source:$source",
    example: 'source:"mod"',
  },
];

function SearchInfoModal() {
  const dispatch = useDispatch();

  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleSearchInfoModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-10 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50 backdrop-blur-sm">
    <div className="absolute top-1/2 left-1/2 z-20 mx-auto w-[90%] -translate-x-1/2 -translate-y-1/2 transform sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]">
      <div
        className="relative mx-8 w-[45vw] rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0"
        ref={modalRef}
      >
            <div className="">
              <div className="mb-4 text-center">
                <div className="text-xl font-semibold text-[#000000]">
                  SEARCH INFO
                </div>
                <div className=" text-lg text-[#000000]">
                  Click to copy the search operator example
                </div>
              </div>

              {sections.map((section, index) => (
                <div key={index} className="m-4 flex items-center">
                  <div className="flex w-60 items-center justify-end rounded-md border-2 border-[#4318FF] p-2 text-lg text-[#4318FF] sm:w-72 lg:w-80">
                    <CopyToClipboard
                      text={section.example}
                      onCopy={() => {
                        dispatch(
                          updateAlert({
                            alertText: "Operator Copied",
                            alertType: "Success",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                      }}
                    >
                      <span className="flex cursor-grabbing">
                        <BiCopy className="m-0 h-6 w-6 rounded-md  p-[2px] text-[#000000]" />
                      </span>
                    </CopyToClipboard>
                    {section.query}
                  </div>
                  <span className="mx-2"> - </span>
                  <div className="w-72 text-lg text-[#000000] sm:w-80 lg:w-96">
                    {section.content}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
}

export default SearchInfoModal;
