import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";
import axios from "axios";
const apiEndPoint = process.env.REACT_APP_API_ENDPOINT_URL;

const initialState = {
  showFileUploadModal: false,
  showDriveUploadModal: false,
  isLoading: false,
  status: null,
  error: null,
  fileUpload: {
    client_id: [],
    apk_file: "",
    is_official: "",
    discoverd_source: [],
    brand_id: [],
    description: "",
  },
  driveUpload: {
    folder_id: "",
    spreadsheet_name: "",
    worksheet_name: "",
    vtHashes: [],
    client_id: [],
    brand_id: [],
    user_note: "",
  },
};

export const createFileUpload = createAsyncThunk("file/upload", async () => {
  let stateData = store.getState();
  let fileUpload = stateData.UploadApkData.fileUpload;
  let apiKey = stateData.userAuth.apiKey;

  const selectedOfficial = fileUpload.is_official === "WHITELISTED";
  const cIds = fileUpload.client_id;
  const bIds = fileUpload.brand_id;
  const client_ids = cIds.map((item) => item.value.toString());
  const brand_ids = bIds.map((item) => item.value.toString());

  const formData = new FormData();
  formData.append("client_ids", client_ids);
  formData.append("brand_ids", brand_ids);
  formData.append("apk_file", fileUpload.apk_file);
  formData.append("is_official", selectedOfficial);
  formData.append("discoverd_source", fileUpload.discoverd_source);
  // formData.append("user_note", fileUpload.description);
  const response = await axios.post(`${apiEndPoint}sw_apk/upload`, formData, {
    params: {
      user_note: fileUpload.description,
    },
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${apiKey}`,
    },
  });
});

export const createDriveUpload = createAsyncThunk("drive/upload", () => {
  let stateData = store.getState();
  let driveUpload = stateData.UploadApkData.driveUpload;
  let cIds = driveUpload.client_id;
  let bIds = driveUpload.brand_id;
  let client_ids = cIds.map((item) => item.value);
  let brand_ids = bIds.map((item) => item.value);
  let dataToPost = {
    spreadsheet_name: driveUpload.spreadsheet_name,
    worksheet_name: driveUpload.worksheet_name,
    folder_id: driveUpload.folder_id,
    targeted_clients: client_ids,
    targeted_brands: brand_ids,
    vt_hash: driveUpload.vtHashes,
    user_note: driveUpload.user_note,
  };
  return apiCall(`sw_apk/bulk_upload`, "post", dataToPost);
});

export const uploadApkDataSlice = createSlice({
  name: "UploadApkData",
  initialState,
  reducers: {
    toggleFileUploadModal: (state) => {
      state.showFileUploadModal = !state.showFileUploadModal;
    },
    toggleDriveUploadModal: (state) => {
      state.showDriveUploadModal = !state.showDriveUploadModal;
    },
    updateNewFileUpload: (state, action) => {
      state.fileUpload = { ...state.fileUpload, ...action.payload };
    },
    updateNewDriveUpload: (state, action) => {
      state.driveUpload = { ...state.driveUpload, ...action.payload };
    },
    updateUploadApkError: (state, action) => {
      state.error = null;
    },
    updateUploadStatus: (state, action) => {
      state.status = null;
    },
    clearNewFileUpload: (state) => {
      state.fileUpload = initialState.fileUpload;
    },
    clearNewDriveUpload: (state) => {
      state.driveUpload = initialState.driveUpload;
    },
    clearAllDataUpload: (state) => {
      Object.assign(state, initialState);
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(createFileUpload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFileUpload.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.isLoading = false;
        state.fileUpload = initialState.fileUpload;
      })
      .addCase(createFileUpload.rejected, (state, action) => {
        state.status = "ERROR";
        state.isLoading = false;
        state.error = action.error.message;
        state.fileUpload = initialState.fileUpload;
      })
      .addCase(createDriveUpload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDriveUpload.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.isLoading = false;
        state.driveUpload = initialState.fileUpload;
      })
      .addCase(createDriveUpload.rejected, (state, action) => {
        state.status = "ERROR";
        state.isLoading = false;
        state.error = action.error.message;
        state.driveUpload = initialState.fileUpload;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleFileUploadModal,
  toggleDriveUploadModal,
  updateNewFileUpload,
  updateNewDriveUpload,
  updateUploadApkError,
  updateUploadStatus,
  clearNewFileUpload,
  clearNewDriveUpload,
  clearAllDataUpload,
} = uploadApkDataSlice.actions;

export default uploadApkDataSlice.reducer;
