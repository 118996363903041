import React, { useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlineCopy } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import noPreview from "../../../../assets/img/urlImageGray.jpg";
import { useDispatch } from "react-redux";
import {
  setDefaultResult,
  setEyeResult,
} from "reduxConfig/slices/resultEditSlice";
import { toggleEditModal } from "reduxConfig/slices/resultEditSlice";
import { toggleEyeModal } from "reduxConfig/slices/seoPoisonSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { useSelector } from "react-redux/es/hooks/useSelector";
import getIstTime from "./helpers/istConvertor";
import apiCall from "axiosConfig/axiosConfig";
import { Tooltip } from "react-tooltip";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { changeStatus } from "reduxConfig/slices/resultEditSlice";

function VtResultCard({ cardData }) {
  const isImport = false;
  const brandData = useSelector((state) => state.brandsData.brandData);
  const dispatch = useDispatch();
  const showEditModal = useSelector((state) => state.resultEditData);
  const shouldUpdate = useSelector((state) => {
    return state.resultEditData;
  });
  const [resultData, setResultData] = useState(cardData);
  const updateId = `result_id:"${resultData._id}"`;

  useEffect(() => {
    if (
      shouldUpdate.status === "SUCCESS" &&
      shouldUpdate.resultId == resultData._id
    ) {
      apiCall("/sw_results/results", "post", {
        query: updateId,
      }).then((response) => {
        setResultData(response.data.data.page_results[0]);
        dispatch(
          updateAlert({
            alertText: "Update Success",
            alertType: "Success",
          })
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);
        dispatch(changeStatus(null));
      });
    }
    // if (checkUploadStatus.status === "UPLOAD_SUCCESS") {
    //   dispatch(
    //     updateAlert({
    //       alertText: "Upload Success",
    //       alertType: "Success",
    //     })
    //   );
    //   setTimeout(() => {
    //     dispatch(toggleAlert());
    //   }, 2000);
    //   dispatch(changeStatusResults(null));
    // }
  }, [shouldUpdate]);
  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "Brand Deleted";
  };
  return (
    <div class="mb-10 flex w-[25vw] max-w-[25vw] flex-wrap items-center bg-lightPrimary p-2">
      <div className="h-full w-full">
        <div className="border-grey-400 relative flex items-center justify-center rounded-t-xl border-2 bg-[#ADB7D5]">
          <div className="flex">
            <div className="">
              <a
                data-tooltip-id="my-tooltip-img"
                data-tooltip-html="This image serve as a preview when sharing the provided link."
                data-tooltip-place="top"
              >
                <img
                  alt=""
                  src={resultData?.icon ? resultData.icon : noPreview}
                  tabindex="0"
                  class="  m-2 h-[200px] w-[200px] rounded-xl focus:outline-none"
                />
              </a>
              <Tooltip className="z-40" id="my-tooltip-img" />
            </div>
            <div>
              <a
                data-tooltip-id="my-tooltip-favIcon"
                data-tooltip-html="Favi Icon, an icon specific to a website, often seen in the browser's address bar."
                data-tooltip-place="top"
              >
                <img
                  alt=""
                  src={resultData?.icon ? resultData.icon : noPreview}
                  tabindex="0"
                  class="  m-2 h-[30px] w-[30px] rounded-md focus:outline-none"
                />
              </a>
              <Tooltip className="z-40" id="my-tooltip-favIcon" />
            </div>
            {resultData?.action_data?.seen ? (
              <div className="flex items-center justify-center text-center">
                <a
                  data-tooltip-id="my-tooltip-seen"
                  data-tooltip-html={`This Result is Marked as Seen by the User`}
                  data-tooltip-place="top"
                >
                  <IoIosCheckmarkCircle
                    type="button"
                    className="h-8 w-8 rounded-md p-[2px] text-center text-[#4318FF]"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip-seen" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div class="rounded-b-2xl bg-white">
          <div class="flex items-center justify-between px-4 pt-4">
            <div>
              {" "}
              <h2
                tabindex="0"
                class="flex max-h-[3em] min-h-[3em] w-full flex-row flex-wrap justify-start text-[100%] font-semibold focus:outline-none"
              >
                {" "}
                <span className="mr-2 flex">MD5:</span>
                {resultData?.md5 ? resultData.md5 : "None"}
              </h2>
            </div>
            <div
              className="-mt-6 cursor-pointer"
              onClick={() => {
                dispatch(
                  setEyeResult({
                    resultId: resultData._id,
                    resultTitle: resultData.md5,
                    resultLink: resultData.link,
                    resultJson: {
                      resultId: resultData._id,
                      resultLink: resultData.link,
                      ruleId: resultData._id,
                      keyWords: resultData.query_keywords,
                      actionData: resultData.action_data,
                      createdAt: resultData.created_at,
                      lastMatched: resultData.updated_at,
                      searchType: resultData.search_type,
                      title: resultData.md5,
                      certifiacteSha: resultData.certificate_sha1,
                      Sha256: resultData.sha256,
                      packageName: resultData.package_name,
                    },
                    seen: resultData?.action_data?.seen,
                  })
                );
                dispatch(toggleEyeModal());
              }}
            >
              <div className="">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Click the info icon to open the modal </br> where you can perform actions such as </br> > marking as seen </br> > deleting the result </br>  > copying result links and JSON."
                  data-tooltip-place="top"
                >
                  <AiOutlineInfoCircle
                    className="h-8 w-8 rounded-md  p-[2px]"
                    color="#4318FF"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip" />
              </div>
            </div>
          </div>
          <div class="flex flex-col items-start justify-start px-4 pt-4">
            <dt class="font-semibold dark:text-gray-400">Result id:</dt>
            <div className="flex w-[100%] justify-between">
              <div class="text-sm">
                {resultData._id ? resultData._id : "None"}
              </div>
              <div className="">
                <CopyToClipboard
                  text={`result_id:\"${resultData._id}\"`}
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "Result Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <span className="flex cursor-grabbing">
                    {" "}
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 rounded-md  p-[2px]"
                      color="#4318FF"
                    />
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div class="flex flex-col items-start justify-start px-4 pt-4">
            <dt class="font-semibold dark:text-gray-400">Rule id:</dt>
            <div class="max-h-[4em] min-h-[4em]  w-[100%] justify-between overflow-auto text-sm">
              {resultData?.user_data?.query_ids
                ? resultData.query_ids.map((item) => (
                    <div className="flex w-[100%] justify-between">
                      <div className="">{item}</div>
                      <div className="">
                        <CopyToClipboard
                          text={`rule:\"${item}\"`}
                          onCopy={() => {
                            dispatch(
                              updateAlert({
                                alertText: "Rule Copied",
                                alertType: "Success",
                              })
                            );
                            setTimeout(() => {
                              dispatch(toggleAlert());
                            }, 2000);
                          }}
                        >
                          <span className="flex cursor-grabbing">
                            {" "}
                            <AiOutlineCopy
                              className="m-0 h-6 w-6 rounded-md  p-[2px]"
                              color="#4318FF"
                            />
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  ))
                : "None"}
            </div>
          </div>
          <div class="p-4">
            <dl class="max-w-md   dark:divide-gray-700 dark:text-white">
              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Link:</dt>
                <div className="flex justify-between">
                  <div className="hide-scrollbar w-[90%] overflow-hidden">
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-html={
                        resultData?.link?.length > 150
                          ? `${resultData.link.substring(0, 150)}....`
                          : resultData.link
                      }
                      data-tooltip-place="top"
                      className="flex cursor-pointer overflow-hidden whitespace-nowrap text-sm text-blue-700"
                      style={{
                        maxWidth: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      onClick={() => {
                        window.open(`${resultData.link}`, "_blank");
                      }}
                    >
                      {resultData?.link?.length > 60
                        ? `${resultData.link.substring(0, 50)}....`
                        : resultData.link}
                    </a>
                    <Tooltip className="z-10" id="my-tooltip" />
                  </div>
                  <div className="">
                    <CopyToClipboard
                      text={resultData.link}
                      onCopy={() => {
                        dispatch(
                          updateAlert({
                            alertText: "Link Copied",
                            alertType: "Success",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                      }}
                    >
                      <span className="flex cursor-grabbing">
                        {" "}
                        <AiOutlineCopy
                          className="m-0 h-6 w-6 rounded-md p-[2px]"
                          color="#4318FF"
                        />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">
                  App Properties:
                </dt>
                <dd className="hide-scrollbar flex max-h-[5em] min-h-[5em] w-full flex-row  flex-wrap justify-start overflow-auto">
                  <div>
                    <span className="font-semibold">Package Name: </span>
                    {resultData.package_name}
                  </div>
                  <div>
                    <span className="font-semibold">SHA256: </span>
                    {resultData.sha256}
                  </div>
                  <div>
                    <span className="font-semibold">Certificate SHA1: </span>
                    {resultData?.certificate_sha1
                      ? resultData.certificate_sha1
                      : "None"}
                  </div>
                </dd>
              </div>

              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Indicator:</dt>
                <dd
                  className={`text-sm ${
                    resultData.action_data.marked_as
                      ? resultData.action_data.marked_as === "WHITELISTED"
                        ? "text-green-500"
                        : "text-red-500"
                      : " "
                  }`}
                >
                  {resultData.action_data.marked_as
                    ? resultData.action_data.marked_as
                    : "Not Marked"}
                </dd>
              </div>
              <div className="flex justify-start">
                {" "}
                <div class="mr-8 flex flex-col pb-3">
                  <dt class="font-semibold dark:text-gray-400">Entity Type:</dt>
                  <dd class="text-sm">
                    {resultData.action_data.result_type
                      ? resultData.action_data.result_type
                      : "UNKNOWN"}
                  </dd>
                </div>
                <div class="flex flex-col pb-3">
                  <dt class="font-semibold dark:text-gray-400">Result Type:</dt>
                  <dd class="text-sm">
                    {resultData.action_data.entity_type
                      ? resultData.action_data.entity_type
                      : "UNKNOWN"}
                  </dd>
                </div>
              </div>
            </dl>
            <div className="flex justify-between">
              <div className="my-1 text-left font-semibold">Keywords:</div>
              <div>
                <CopyToClipboard
                  text={
                    resultData?.user_data?.query_keywords?.length
                      ? resultData.user_data.query_keywords
                      : "None"
                  }
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "keyword Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <AiOutlineCopy
                    className="m-0 h-6 w-6 cursor-grabbing  rounded-md p-[2px]"
                    color="#4318FF"
                  />
                </CopyToClipboard>
              </div>
            </div>
            {resultData?.user_data?.query_keywords?.length ? (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                {resultData.user_data.query_keywords.map((words) => (
                  <div
                    class=" mr-1 flex h-[20%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                    style={{ marginBottom: "5px" }}
                  >
                    {words}
                  </div>
                ))}
              </div>
            ) : (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000  h-[20%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                  Keywords Not Selected
                </div>
              </div>
            )}

            <div className="my-1 text-left font-semibold">Targeted Brands:</div>
            {resultData?.action_data?.brands?.length ? (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                {Object.values(resultData.action_data.brands).map(
                  (brand_id) => (
                    <div class="mr-1 h-[20%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]">
                      {getBrandName(brand_id)}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000  h-[20%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                  Brands Not Selected
                </div>
              </div>
            )}

            <div className="my-1 text-left font-semibold">
              Possible URLs Classifications:
            </div>
            {resultData?.possible_url_classifications?.length ? (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                {Object.values(resultData.possible_url_classifications).map(
                  (possible_url_classifications) => (
                    <div class=" mr-1 h-[20%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]">
                      {possible_url_classifications.replace(/_/g, " ")}
                    </div>
                  )
                )}
              </div>
            ) : (
              <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
                <div class="text-#000000  h-[20%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                  None
                </div>
              </div>
            )}

            <div className="flex items-center justify-between">
              <div class=" flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">
                  Date Discovered:
                </dt>
                <dd class="text-sm">
                  {resultData?.user_data?.created_at
                    ? getIstTime(resultData.user_data.created_at)
                    : "No Date"}
                </dd>
              </div>

              <div class="flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Last Seen:</dt>
                <dd class="text-sm">
                  {resultData?.user_data?.last_matched
                    ? getIstTime(resultData.user_data.last_matched)
                    : "No Date"}
                </dd>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div class=" flex flex-col pb-3">
                <dt class="font-semibold dark:text-gray-400">Search Type:</dt>
                <dd class="text-sm">
                  {isImport ? (
                    "MANUAL_IMPORT"
                  ) : (
                    <>
                      {resultData?.user_data?.search_type?.length ? (
                        <div class="">
                          {Object.values(resultData.user_data.search_type).map(
                            (search_type) => (
                              <div class="">
                                {search_type.replace(/_/g, " ")}
                              </div>
                            )
                          )}
                        </div>
                      ) : (
                        <div class="">
                          <div class="">None</div>
                        </div>
                      )}
                    </>
                  )}
                </dd>
              </div>

              <div
                className="flex h-10 cursor-pointer items-center justify-center rounded-xl border border-[#4318FF] bg-white px-3 text-center font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={() => {
                  dispatch(
                    setDefaultResult({
                      resultId: resultData._id,
                      entityInterestType: resultData.action_data.result_type,
                      resultType: resultData.action_data.entity_type,
                      choosenType: resultData.action_data.marked_as,
                      brandData: Array.from(resultData.action_data.brands),
                      resultTitle: resultData.md5,
                      resultImg: resultData.icon.bucket_id,
                      comment: resultData.action_data.comment,
                    })
                  );
                  dispatch(toggleEditModal());
                }}
              >
                Edit
                <FiEdit className="-mt-1 h-6 w-6 p-[4px]" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VtResultCard;
