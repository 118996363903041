import React from "react";
import { useState } from "react";
function SearchBar() {
  const [searchString, setSearchString] = useState("");
  return (
    <div class="mx-auto max-w-md">
      <div class="relative flex h-12 w-full items-center overflow-hidden rounded-lg bg-white focus-within:shadow-lg border border-[#000000]">


        <input
          class="peer h-full w-full pr-2 text-sm text-[#000000] outline-none px-2"
          type="text"
          id="search"
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          placeholder="Search something.."
        />
                <div class="grid h-full w-12 place-items-center text-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
