import React from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { UpdateNewBrandData } from "reduxConfig/slices/brandSlice";

function ClientSelect() {
  const clientData = useSelector((state) => state.brandsData.clientData);
  const componentDefaultValue = useSelector(
    (state) => state.brandsData.newBrandData.client_id
  );
  const dispatch = useDispatch();

  const options = clientData.map((rule) => ({
    value: rule._id,
    label: rule.client_name,
  }));
  return (
    <div>
      {options.length === 0 ? (
        <div className="font-bold text-red-500">
          No clients have been created. Please create a client.
        </div>
      ) : (
        <Select
          className="border border-[#000000] outline-none"
          options={options}
          value={options.find(
            (option) => option.value === componentDefaultValue.value
          )}
          onChange={(selectedOption) => {
            dispatch(UpdateNewBrandData({ client_id: selectedOption }));
          }}
        />
      )}
    </div>
  );
}

export default ClientSelect;
