import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateNewUploadApk } from "reduxConfig/slices/resultSlice";

function TagInput() {
  const [inputValue, setInputValue] = useState("");
  const selectedOption = useSelector(
    (state) => state.resultsData.uploadApk.discoverd_source
  );
  const dispatch = useDispatch();

  const isDefaultValue = (value) => {
    // Add logic here to determine if the value is the default value
    return selectedOption.length > 0 && selectedOption[0] === value;
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (inputValue) {
        const domainsArray = inputValue
          .split(",")
          .map((domain) => domain.trim());
        let uniqueEntries = new Set([...selectedOption, ...domainsArray]);

        dispatch(updateNewUploadApk({ discoverd_source: [...uniqueEntries] }));
        setInputValue("");
      }
    }
  };

  return (
    <div className="">
      <div className="relative">
        <textarea
          className="block max-h-40 w-full border border-[#000000] bg-white p-2.5 text-sm text-[#000000] focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
          placeholder="Enter Discovered Source"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(event) => handleKeyPress(event)}
        />
        <div class="max-h-24 overflow-y-auto">
        {selectedOption.map((element, index) => (
          <div
            key={index}
            className="mt-2 mr-1 inline-flex items-center overflow-hidden rounded bg-blue-100 text-sm"
          >
            <span
              className={`ml-2 mr-1 max-w-xs truncate px-1 leading-relaxed ${
                isDefaultValue(element) ? "non-editable" : ""
              }`}
            >
              {element}
            </span>
            {!isDefaultValue(element) && (
              <div
                className="flex h-8 w-6 items-center justify-center bg-blue-200 text-center align-middle text-gray-500 focus:outline-none"
                onClick={() => {
                  const updatedItems = selectedOption.filter(
                    (i) => i !== element
                  );
                  dispatch(
                    updateNewUploadApk({ discoverd_source: updatedItems })
                  );
                }}
              >
                <svg
                  className="fill-current mx-auto h-6 w-6 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"
                  />
                </svg>
              </div>
            )}
          </div>
        ))}
        </div>
      </div>
    </div>
  );
}

export default TagInput;
