import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

function parseDDMMYYYYToDate(dateStr) {
  const parts = dateStr.split("/");

  if (parts.length !== 3) {
    throw new Error('Invalid date format. Use "dd/mm/yyyy" format.');
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    throw new Error("Invalid date components.");
  }

  return new Date(year, month, day);
}

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const additionalParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const resultType = queryParams.get("resultType")
    ? queryParams.get("resultType").split(",")
    : [];
  const entityType = queryParams.get("entityType")
    ? queryParams.get("entityType").split(",")
    : [];
  const source = queryParams.get("source")
    ? queryParams.get("source").split(",")
    : [];
  const markedAs = queryParams.get("markedAs")
    ? queryParams.get("markedAs").split(",")
    : [];
  const useOr = queryParams.get("useOr") === "true" ? true : false;
  return {
    use_or: useOr,
    result_type: resultType,
    entity_type: entityType,
    source: source,
    marked_as: markedAs,
  };
};

const regexParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isRegex = queryParams.get("isRegex") === "true" ? true : false;
  return {
    isRegex: isRegex,
  };
};

const dateParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get("fromDate")
    ? parseDDMMYYYYToDate(queryParams.get("fromDate"))
    : null;
  const toDate = queryParams.get("toDate")
    ? parseDDMMYYYYToDate(queryParams.get("toDate"))
    : null;

  return { fromDate: fromDate, toDate: toDate };
};
const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const initialState = {
  resultData: [],
  isLoading: false,
  isExportLoading: false,
  queryString: QueryStringHandler(),
  ...dateParamsHandler(),
  pageNumber: pageNumberHandler(),
  dumpedData: [],
  totalPages: null,
  resultsPerPage: 15,
  showSearchInfoModal: false,
  showEyeModal: false,
  additionalFilters: additionalParamsHandler(),
  showExportModal: false,
  showResultDeleteModal: false,
  totalResults: null,
  ...regexParamsHandler(),
  error: null,
  updateResultDeleteData: {
    resultName: "",
    resultId: "",
  },
};

function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  if (!date) {
    return "";
  }
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}

export const getSearchResultsSeo = createAsyncThunk("result/data", () => {
  let stateData = store.getState();
  let resultsData = stateData.SeoPoisonData;
  let dataToPost = {
    query: resultsData.queryString,
    brand_id: resultsData.brandId,
    page_number: resultsData.pageNumber,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    results_per_page: resultsData.resultsPerPage,
    additional_filters: resultsData.additionalFilters,
    is_regex: resultsData.isRegex,
    only_seo_poisoning: true,
  };

  return apiCall("/sw_results/results", "post", dataToPost);
});
export const getResultsDump = createAsyncThunk("results/dump", () => {
  let stateData = store.getState();
  let resultsData = stateData.SeoPoisonData;
  let dataToPost = {
    query: resultsData.queryString,
    brand_id: resultsData.brandId,
    page_number: resultsData.pageNumber,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    results_per_page: resultsData.resultsPerPage,
    additional_filters: resultsData.additionalFilters,
    dump_all: true,
    only_seo_poisoning: true,
  };
  return apiCall("/sw_results/results", "post", dataToPost);
});
export const SeoPoisonDataSlice = createSlice({
  name: "SeoPoisonData",
  initialState,
  reducers: {
    updateQueryStringSeo: (state, action) => {
      state.queryString = action.payload;
    },
    updatePageNumberSeo: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    clearDataDumpSeo: (state) => {
      state.dumpedData = [];
      state.isExportLoading = false;
    },
    updateDateFilterSeoPoisoning: (state, action) => {
      state.fromDate = action.payload.selection.startDate;
      state.toDate = action.payload.selection.endDate;
    },
    updateResultPerPageSeo: (state, action) => {
      state.resultsPerPage = action.payload;
    },
    toggleSearchInfoModal: (state) => {
      state.showSearchInfoModal = !state.showSearchInfoModal;
    },
    toggleEyeModal: (state) => {
      state.showEyeModal = !state.showEyeModal;
    },
    addAdditionalFiltersSeoPoisoning: (state, action) => {
      state.additionalFilters = {
        ...state.additionalFilters,
        ...action.payload,
      };
    },
    toggleExportModal: (state) => {
      state.showExportModal = !state.showExportModal;
    },
    exportVisibleData: (state) => {
      state.dumpedData = state.resultData;
    },
    toggleIsRegix: (state) => {
      state.isRegex = !state.isRegex;
    },
    updateErrorResultsSeo: (state, action) => {
      state.error = null;
    },
    toggleDeleteModal: (state) => {
      state.showResultDeleteModal = !state.showResultDeleteModal;
    },
    updateResultDeleteData: (state, action) => {
      state.updateResultDeleteData = {
        ...state.updateResultDeleteData,
        ...action.payload,
      };
    },
    toggleIsRegixSeo: (state) => {
      state.isRegex = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchResultsSeo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchResultsSeo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.totalPages = action.payload.data.data.total_pages;
        state.resultData = action.payload.data.data.page_results;
        state.totalResults = action.payload.data.data.total_results;
      })
      .addCase(getSearchResultsSeo.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getResultsDump.pending, (state) => {
        state.isExportLoading = true;
        state.dumpedData = {};
      })
      .addCase(getResultsDump.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.dumpedData = action.payload.data.data.page_results;
      })
      .addCase(getResultsDump.rejected, (state, action) => {
        state.isExportLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateQueryStringSeo,
  updatePageNumberSeo,
  clearDataDumpSeo,
  updateDateFilterSeoPoisoning,
  updateResultPerPageSeo,
  toggleSearchInfoModal,
  toggleEyeModal,
  toggleExportModal,
  addAdditionalFiltersSeoPoisoning,
  exportVisibleData,
  toggleIsRegix,
  updateErrorResultsSeo,
  toggleDeleteModal,
  updateResultDeleteData,
  toggleIsRegixSeo,
} = SeoPoisonDataSlice.actions;

export default SeoPoisonDataSlice.reducer;
