import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { updateNewUploadApk } from "reduxConfig/slices/resultSlice";

function BrandSelectUpload() {
  const brandData = useSelector((state) => state.brandsData.brandData);
  const [formattedData, setBrandData] = useState([]);
  const checkClients = useSelector(
    (state) => state.resultsData.uploadApk.client_id
  );
  const choosenBrands = useSelector(
    (state) => state.resultsData.uploadApk.brand_id
  );

  const dispatch = useDispatch();

  const getDefaultValues = () => {
    let returnData = [];
    for (let element in choosenBrands) {
      let brand_object = brandData.find(
        (items) => items._id == choosenBrands[element]
      );
      if (brand_object) {
        returnData.push({
          value: brand_object._id,
          label: brand_object.brand_name,
        });
      }
    }
    return returnData;
  };

  useEffect(() => {
    let filteredBrandData = [];

    // Extract client_id values from checkClients
    const clientIds = checkClients.map((client) => client.value);

    // Filter brandData based on extracted client_ids
    if (clientIds && clientIds.length > 0) {
      filteredBrandData = brandData.filter((brand) =>
        clientIds.includes(brand.client_id)
      );
    } else {
      filteredBrandData = [...brandData];
    }
    let stateData = filteredBrandData.map((brand) => ({
      value: brand._id,
      label: brand.brand_name,
    }));

    setBrandData(stateData);
  }, [brandData, checkClients]);

  return (
    <div>
      {formattedData.length ? (
        <Select
          isMulti
          name="colors"
          defaultValue={getDefaultValues()}
          onChange={(selectedOption) => {
            dispatch(
              updateNewUploadApk({
                brand_id: selectedOption,
              })
            );
          }}
          options={formattedData}
          className="w-full border border-[#000000] text-[#000000]"
          classNamePrefix="select"
        />
      ) : (
        <div className="text-sm font-bold text-red-500">
          No clients have been created. Please create a client.
        </div>
      )}
    </div>
  );
}

export default BrandSelectUpload;
