import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

function parseDDMMYYYYToDate(dateStr) {
  const parts = dateStr.split("/");

  if (parts.length !== 3) {
    throw new Error('Invalid date format. Use "dd/mm/yyyy" format.');
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    throw new Error("Invalid date components.");
  }

  return new Date(year, month, day);
}

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const dateParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get("fromDate")
    ? parseDDMMYYYYToDate(queryParams.get("fromDate"))
    : null;
  const toDate = queryParams.get("toDate")
    ? parseDDMMYYYYToDate(queryParams.get("toDate"))
    : null;

  return { fromDate: fromDate, toDate: toDate };
};

const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};
const regexParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isRegex = queryParams.get("isRegex") === "true" ? true : false;
  return {
    isRegex: isRegex,
  };
};

const filterParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const jobType = queryParams.get("jobType") ? queryParams.get("jobType") : "";
  const status = queryParams.get("status") ? queryParams.get("status") : "";
  return {
    job_type: jobType,
    status: status,
  };
};

const initialState = {
  taskData: [],
  error: null,
  isLoading: false,
  showAllData: false,
  showSearchOperatorModal: false,
  showFilterModal: false,
  queryString: QueryStringHandler(),
  ...dateParamsHandler(),
  pageNumber: pageNumberHandler(),
  ...regexParamsHandler(),
  ...filterParamsHandler(),
  totalPages: null,
  taskPerPage: 15,
  totalRules: null,
  defaultData: {
    _id: "",
    job_id: "",
    job_type: "",
    current_status: "",
    created_at: "",
    updated_at: "",
    user_note: "",
    user_id: "",
    is_official: "",
    file_path: "",
    discoverd_sources: "",
    brand_ids: [],
    client_ids: [],
    error: "",
    drive_link: ""
  },
};

function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  if (!date) {
    return "";
  }
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}

export const getTaskStatusData = createAsyncThunk("task/status", () => {
  let stateData = store.getState();
  let taskData = stateData.taskStatusData;
  let dataToPost = {
    from_date: formatDateToDDMMYYYY(taskData.fromDate),
    to_date: formatDateToDDMMYYYY(taskData.toDate),
    results_per_page: taskData.taskPerPage,
    page_number: taskData.pageNumber,
    status: taskData.status,
    search_query: taskData.queryString,
    is_regex: taskData.isRegex,
    job_type: taskData.job_type,
  };
  return apiCall(`sw_tasks/task_status`, "post", dataToPost);
});

export const taskStatusDataSlice = createSlice({
  name: "taskStatusData",
  initialState,
  reducers: {
    updateQueryStringTask: (state, action) => {
      state.queryString = action.payload;
    },
    toggleIsRegix: (state) => {
      state.isRegex = !state.isRegex;
    },
    updatePageNumberTask: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    updatePerPageTask: (state, action) => {
      state.taskPerPage = action.payload;
    },
    toggleShowAllDetails: (state) => {
      state.showAllData = !state.showAllData;
    },
    setDefaultValue: (state, action) => {
      state.defaultData._id = action.payload._id;
      state.defaultData.job_id = action.payload.job_id;
      state.defaultData.job_type = action.payload.job_type;
      state.defaultData.current_status = action.payload.current_status;
      state.defaultData.created_at = action.payload.created_at;
      state.defaultData.updated_at = action.payload.updated_at;
      state.defaultData.user_note = action.payload.user_note;
      state.defaultData.user_id = action.payload.user_id;
      state.defaultData.is_official = action.payload.is_official;
      state.defaultData.file_path = action.payload.file_path;
      state.defaultData.discoverd_sources = action.payload.discoverd_sources;
      state.defaultData.brand_ids = action.payload.brand_ids;
      state.defaultData.client_ids = action.payload.client_ids;
      state.defaultData.error = action.payload.error;
      state.defaultData.drive_link = action.payload.drive_link;
    },
    toggleShowSearchOperatorModal: (state) => {
      state.showSearchOperatorModal = !state.showSearchOperatorModal;
    },
    updateDateFilterTask: (state, action) => {
      state.fromDate = action.payload.selection.startDate;
      state.toDate = action.payload.selection.endDate;
    },
    toggleIsRegixTasks: (state) => {
      state.isRegex = false;
    },
    updateErroTask: (state, action) => {
      state.error = null;
    },
    toggleShowFilterModalTask: (state) => {
      state.showFilterModal = !state.showFilterModal;
    },
    clearFiltersTask: (state, action) => {
      state.job_type = "";
      state.status = "";
    },
    updateJobType: (state, action) => {
      state.job_type = action.payload;
    },
    updateStatusType: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getTaskStatusData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTaskStatusData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.taskData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalRules = action.payload.data.data.total_results;
      })
      .addCase(getTaskStatusData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  updateQueryStringTask,
  toggleIsRegix,
  updatePageNumberTask,
  updatePerPageTask,
  toggleShowAllDetails,
  setDefaultValue,
  toggleShowSearchOperatorModal,
  updateDateFilterTask,
  toggleIsRegixTasks,
  updateErroTask,
  toggleShowFilterModalTask,
  clearFiltersTask,
  updateJobType,
  updateStatusType,
} = taskStatusDataSlice.actions;

export default taskStatusDataSlice.reducer;
