import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./components/SearchBar";
import { RingLoader } from "react-spinners";
import TaskCard from "./components/TaskCard";
import PaginationComp from "./components/PaginationComp";
import { getTaskStatusData } from "reduxConfig/slices/taskStatusSlice";
import ShowAllDetails from "./components/ShowAllDetails";
import { getBrandData } from "reduxConfig/slices/brandSlice";
import { getClientData } from "reduxConfig/slices/brandSlice";
import urlParamsBuilder from "./components/helpers/getUrlParams";
import SearchOperatorModal from "./components/SearchOperatorModal";
import DatePicker from "./components/DatePicker";
import FilterModal from "./components/FilterModal";
import { toggleShowFilterModalTask } from "reduxConfig/slices/taskStatusSlice";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";


function TaskStatusTab() {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.taskStatusData);
  const pageNumber = useSelector((state) => state.taskStatusData.pageNumber);
  const isLoading = useSelector((state) => state.taskStatusData.isLoading);
  const error = useSelector((state) => state.taskStatusData.error);
  const taskStatusData = useSelector((state) => state.taskStatusData.taskData);
  const showAllData = useSelector((state) => state.taskStatusData.showAllData);
  const showFilterModal = useSelector(
    (state) => state.taskStatusData.showFilterModal
  );
  const showSearchOperator = useSelector(
    (state) => state.taskStatusData.showSearchOperatorModal
  );
  useEffect(() => {
    dispatch(getTaskStatusData());
    dispatch(getBrandData());
    dispatch(getClientData());
  }, [pageNumber]);

  useEffect(() => {
    const body = document.body;

    if (showAllData || showSearchOperator || showFilterModal) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [showAllData, showSearchOperator, showFilterModal]);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(filterData)}`
    );
  });

  return (
    <div
      // className={`relative ${
      //   showAllData ? "h-[80vh] overflow-x-hidden overflow-y-hidden" : ""
      // }`}
      className={`relative ${
        showAllData || showSearchOperator || showFilterModal ? "" : ""
      }`}
    >
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          {" "}
          <div className="relative top-[-7vh] left-[8vw] z-30 flex w-[70vw] justify-between">
            <div className="ml-[5vw]">
              <SearchBar />
            </div>
            <div className="flex items-center space-x-4">
              <DatePicker />
              <div>
                <button
                  type="button"
                  onClick={(e) => {
                    dispatch(toggleShowFilterModalTask());
                  }}
                  className={`bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-3 py-2 px-6 text-center font-semibold text-blue-700 hover:bg-[#4318FF] hover:text-white ${"w-[6vw]"}`}
                >
                  Filter
                </button>
              </div>
              {/* <ExportButtonModal /> */}
            </div>
          </div>
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <div className="-mt-4 grid h-full  grid-cols-1 gap-2 2xl:grid-cols-1">
                {taskStatusData.length ? (
                  // taskStatusData.map((element) => (
                  <TaskCard cardData={taskStatusData} />
                ) : (
                  // ))
                  <></>
                )}
              </div>
              {taskStatusData.length ? (
                <></>
              ) : (
                <div className="flex items-center  justify-center">
                     <Lottie
                    animationData={noDataAnimationData}
                    loop={true}
                    autoplay={true}
                    style={{ width: 500, height: 500 }}
                    speed={1.5}
                  />
                  {/* <div className="text-3xl font-bold">
                    No Tasks are available
                  </div> */}
                </div>
              )}
              {taskStatusData.length ? <PaginationComp /> : <></>}
            </>
          )}
          {showAllData ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <ShowAllDetails />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showSearchOperator ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <SearchOperatorModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showFilterModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <FilterModal />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default TaskStatusTab;
