import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateJobType } from "reduxConfig/slices/taskStatusSlice";
import { updateStatusType } from "reduxConfig/slices/taskStatusSlice";
import { updatePageNumberTask } from "reduxConfig/slices/taskStatusSlice";
import { getTaskStatusData } from "reduxConfig/slices/taskStatusSlice";
import { clearFiltersTask } from "reduxConfig/slices/taskStatusSlice";
import { toggleShowFilterModalTask } from "reduxConfig/slices/taskStatusSlice";

function FilterModal() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();

  const defaultData = useSelector((state) => state.taskStatusData);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleShowFilterModalTask());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[40%] z-20 mt-20 w-[80vw] flex-col justify-center py-6 sm:py-12">
        <div
          className="-mt-16 flex h-1/2 w-[30%] flex-col justify-evenly rounded-xl bg-white p-8"
          ref={modalRef}
        >
          <div className="flex items-center justify-center text-3xl font-semibold text-[#000000]">
            Filters
          </div>
          <div className="mb-4 -mt-7 flex justify-end">
            <button
              type="button"
              onClick={() => {
                dispatch(clearFiltersTask());
              }}
              className="underline-text text-blue-700 hover:text-[#4318FF]"
            >
              Clear All
            </button>
          </div>
          <div className="mt-3 font-semibold">Choose Job Type:</div>
          <div>
            <select
              value={defaultData.job_type}
              onChange={(e) => {
                dispatch(updateJobType(e.target.value));
              }}
              className="w-full  border border-[#000000] bg-white p-2 py-2 px-4 text-[#000000] sm:text-sm"
            >
              <option value="">None</option>
              <option value="SINGLE_APK_UPLOAD">SINGLE APK UPLOAD</option>
              <option value="DRIVE_APK_UPLOAD">DRIVE APK UPLOAD</option>
              <option value="DRIVE_APK_UPLOAD_CHILD">
                DRIVE APK UPLOAD CHILD
              </option>
              <option value="SINGLE_APK_UPLOAD_URL">
                SINGLE APK UPLOAD URL
              </option>
              <option value="BULK_APK_UPLOAD_URL">BULK APK UPLOAD URL</option>
              <option value="BULK_APK_UPLOAD_URL_CHILD">
                BULK APK UPLOAD URL CHILD
              </option>
              <option value="DRIVE_APK_UPLOAD_URL">DRIVE APK UPLOAD URL</option>
              <option value="DRIVE_APK_UPLOAD_URL_CHILD">
                DRIVE APK UPLOAD URL CHILD
              </option>
            </select>
          </div>
          <div className="mt-3 font-semibold">Choose Status:</div>
          <div>
            <select
              value={defaultData.status}
              onChange={(e) => {
                dispatch(updateStatusType(e.target.value));
              }}
              className="w-full  border border-[#000000] bg-white p-2 py-2 px-4 text-[#000000] sm:text-sm"
            >
              <option value="">None</option>
              <option value="SUCCESS">SUCCESS</option>
              <option value="FAILED">FAILED</option>
              <option value="RUNNING">RUNNING</option>
              <option value="PENDING">PENDING</option>
            </select>
          </div>
          <div class="flex items-center justify-center space-x-4 pt-4">
            <button
              className="flex w-1/4 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white focus:outline-none"
              onClick={() => {
                dispatch(getTaskStatusData());
                dispatch(updatePageNumberTask(1));
                dispatch(toggleShowFilterModalTask());
              }}
            >
              Apply
            </button>
            <button
              class="flex w-1/4 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
              onClick={() => {
                dispatch(toggleShowFilterModalTask());
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterModal;
