import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import urlParamsBuilder from "./components/helpers/getUrlParams";
import ApkViewAllData from "./components/cardData";
import { RingLoader } from "react-spinners";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";
import { getApkDatas } from "reduxConfig/slices/officialAllApkDataSlice";

function ApkResultsView() {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.officialAllApkData.isLoading);
  const filterData = useSelector((state) => state.officialAllApkData);
  const cardData = useSelector(
    (state) => state.officialAllApkData.apkResultData
  );

  useEffect(() => {
    dispatch(getApkDatas());
  }, []);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(filterData)}`
    );
  });

  return (
    <div>
      {isLoading ? (
        <div className="mt-[10%] flex h-full w-full items-center justify-center">
          <RingLoader
            loading={true}
            size={200}
            color="#4318FF"
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <>
          {" "}
          {cardData.length ? (
            cardData.map((element) => <ApkViewAllData cardData={element} />)
          ) : (
            <></>
          )}
          {cardData.length ? (
            <></>
          ) : (
            <div className="flex items-center  justify-center">
              <Lottie
                animationData={noDataAnimationData}
                loop={true}
                autoplay={true}
                style={{ width: 500, height: 500 }}
                speed={1.5}
              />
              {/* <div className="text-3xl font-bold">No Results Found</div> */}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ApkResultsView;
