import React, { useEffect } from "react";
import ImportUrlImg from "assets/img/ImportUrlImg.png";
import { useSelector, useDispatch } from "react-redux";
import UploadUrl from "./components/UploadUrl";
import { toggleUploadUrlModal } from "reduxConfig/slices/urlSlice";
import UrlPreviewCard from "./components/UrlPreviewCard";
import { getRulesData } from "reduxConfig/slices/rulesSlice";
import UploadSuccessModal from "./components/UploadSuccessModal";

function Urls() {
  const dispatch = useDispatch();
  const showUploadUrlModal = useSelector(
    (state) => state.urlsData.showUploadUrlModal
  );
  const showUrlPreviewModal = useSelector(
    (state) => state.urlsData.showUrlPreviewCard
  );
  const urlsData = useSelector((state) => state.urlsData.urlsData);
  const showUrlsSuccessModal = useSelector(
    (state) => state.urlsData.showUploadSuccessModal
  );
  const error = useSelector((state) => state.urlsData.error);
  useEffect(() => {
    dispatch(getRulesData());
  }, []);

  return (
    <div className={"mt-20 flex flex-col items-center"}>
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          {" "}
          {showUrlPreviewModal ? (
            <></>
          ) : (
            <>
              <div className="text-center">
                <img
                  src={ImportUrlImg}
                  alt="Imported Image"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            </>
          )}
          {showUploadUrlModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <UploadUrl />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showUrlsSuccessModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                {" "}
                <UploadSuccessModal />
              </div>
            </div>
          ) : (
            <></>
          )}
          {showUrlPreviewModal ? (
            <div className="">
              <div className="">
                {" "}
                <UrlPreviewCard />
              </div>
            </div>
          ) : (
            <>
              <div className="mt-8 text-2xl font-bold text-[#000000]">
                Start importing URLs
              </div>
              <div className="mt-10">
                <button
                  className="bg-transparent hover:border-transparent rounded-xl border border-[#4318FF] py-2 px-4 font-semibold  text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    dispatch(toggleUploadUrlModal());
                  }}
                >
                  Import URLs
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Urls;
