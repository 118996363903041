import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";
import * as Loadash from "lodash";

const initialState = {
  urlsData: [],
  isLoading: false,
  enterUrls: [],
  chooseRule: "",
  chooseIndicator: "",
  chooseEntityType: "UNKNOWN",
  chooseResultType: "UNKNOWN",
  showUploadUrlModal: false,
  showUrlPreviewCard: false,
  showUrlEditCard: false,
  choosenType: "NONE",
  showUploadSuccessModal: false,
  status: null,
  error: null,
  allowMutlipleUrlsBox: false,
  newUrlData: {
    chooseBrands: [],
    enterUrls: [],
    chooseRule: "",
    chooseIndicator: "",
    chooseEntityType: "UNKNOWN",
    chooseResultType: "UNKNOWN",
  },
};

export const AddUploadUrls = createAsyncThunk("upload/urls", () => {
  const storeData = store.getState();
  let userProvidedData = storeData.urlsData.newUrlData;
  let urlSelection = storeData.urlsData;

  const selectedBrand = () => {
    const selectedBrands = Loadash.map(userProvidedData.chooseBrands, "value");
    if (urlSelection.choosenType === "WHITELISTED") {
      return {
        actions: {
          threat_brand_ids: [],
          whitelisted_brand_ids: selectedBrands,
          entity_interest_type: userProvidedData.chooseEntityType,
          result_type: userProvidedData.chooseResultType,
        },
      };
    } else {
      return {
        actions: {
          threat_brand_ids: selectedBrands,
          whitelisted_brand_ids: [],
          entity_interest_type: userProvidedData.chooseEntityType,
          result_type: userProvidedData.chooseResultType,
        },
      };
    }
  };
  let dataToPost = {
    urls: userProvidedData.enterUrls,
    rule_id: userProvidedData.chooseRule.value,
    ...selectedBrand(),
  };
  return apiCall("/sw_import/save_documents", "post", dataToPost);
});

export const ProcessUploadUrls = createAsyncThunk("process/urls", () => {
  const storeData = store.getState();
  let userProvidedData = storeData.urlsData.newUrlData;
  let urlsToProcess = userProvidedData.enterUrls;
  if (typeof urlsToProcess === "string") {
    urlsToProcess = [urlsToProcess];
  }
  let dataToPost = {
    urls: urlsToProcess,
    rule_id: userProvidedData.chooseRule.value,
  };
  return apiCall("/sw_import/process_url", "post", dataToPost);
});

export const urlsDataSlice = createSlice({
  name: "urlsData",
  initialState,
  reducers: {
    toggleUploadUrlModal: (state) => {
      state.showUploadUrlModal = !state.showUploadUrlModal;
    },
    updateNewUrl: (state, action) => {
      state.newUrlData = { ...state.newUrlData, ...action.payload };
    },
    togglechoosenType: (state, action) => {
      state.choosenType = action.payload;
    },
    setEntityType: (state, action) => {
      state.chooseEntityType = action.payload;
    },
    setResultType: (state, action) => {
      state.chooseResultType = action.payload;
    },
    toggleUrlPreviewModal: (state) => {
      state.showUrlPreviewCard = !state.showUrlPreviewCard;
    },
    toggleUrlEditModal: (state) => {
      state.showUrlEditCard = !state.showUrlEditCard;
    },
    toggleUrlUploadSuccessModal: (state) => {
      state.showUploadSuccessModal = !state.showUploadSuccessModal;
    },
    changeUploadStatus: (state, action) => {
      state.status = action.payload;
    },
    updateErrorUrls: (state, action) => {
      state.error = null;
    },
    clearNewUrlData: (state) => {
      state.newUrlData = initialState.newUrlData;
    },
    clearAllDataUrl: (state) => {
      Object.assign(state, initialState);
    },
    toggleAllowMultiBox: (state) => {
      state.allowMutlipleUrlsBox = !state.allowMutlipleUrlsBox;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(AddUploadUrls.pending, (state) => {
        state.isLoading = true;
        state.status = "POSTED";
      })
      .addCase(AddUploadUrls.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "SUCCESS";
        state.newUrlData = initialState.newUrlData;
      })
      .addCase(AddUploadUrls.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "FAILED";
        state.newUrlData = initialState.newUrlData;
        state.error = action.error.message;
      })

      .addCase(ProcessUploadUrls.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ProcessUploadUrls.fulfilled, (state, action) => {
        state.isLoading = false;
        state.urlsData = action.payload.data.data.normal_results;
      })
      .addCase(ProcessUploadUrls.rejected, (state, action) => {
        state.isLoading = false;
        state.urlsData = initialState.urlsData;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleUploadUrlModal,
  updateNewUrl,
  togglechoosenType,
  setEntityType,
  setResultType,
  toggleUrlPreviewModal,
  toggleUrlEditModal,
  toggleUrlUploadSuccessModal,
  updateErrorUrls,
  clearNewUrlData,
  clearAllDataUrl,
  toggleAllowMultiBox,
} = urlsDataSlice.actions;

export default urlsDataSlice.reducer;
