import React from "react";
import { useDispatch } from "react-redux";
import { setDefaultValue } from "reduxConfig/slices/taskStatusSlice";
import { toggleShowAllDetails } from "reduxConfig/slices/taskStatusSlice";
import getIstTime from "./helpers/istConvertor";

function TaskCard({ cardData }) {
  const dispatch = useDispatch();
  const handleViewClick = (data) => {
    dispatch(
      setDefaultValue({
        _id: data._id,
        job_id: data.job_id,
        job_type: data.job_type.replace(/_/g, " "),
        current_status: data.current_status,
        created_at: getIstTime(data.created_at),
        updated_at: getIstTime(data.updated_at),
        user_note: data.job_cache.user_note,
        user_id: data.job_cache.user_id,
        is_official: data.job_cache.is_official,
        file_path: data.job_cache.file_path,
        discoverd_sources: data.job_cache.discoverd_sources,
        brand_ids: data.job_cache.brand_ids,
        client_ids: data.job_cache.client_ids,
        error: data.job_cache.error,
        drive_link: data?.job_cache?.folder_id
          ? `https://drive.google.com/drive/folders/${data.job_cache.folder_id}`
          : "",
      })
    );
    dispatch(toggleShowAllDetails());
  };
  return (
    <div className="">
      <table className="min-w-full border border-gray-300 bg-white">
        <thead className="bg-gray-100 text-2xl">
          <tr>
            <th className="border-b py-2 px-4 text-start">Job ID</th>
            <th className="border-b py-2 px-4  text-start">Job Type</th>
            <th className="border-b py-2 px-4  text-start">Notes</th>
            <th className="border-b py-2 px-4  text-start">Status</th>
            <th className="border-b py-2 px-4  text-start">Created At</th>
            <th className="border-b py-2 px-4  text-start">Updated At</th>
            <th className="border-b py-2 px-4   text-start">Actions</th>
          </tr>
        </thead>

        <tbody>
          {cardData.map((data, index) => (
            <tr
              key={data.id}
              className={index % 2 === 1 ? "bg-gray-200" : "bg-white"}
            >
              <td className="border-b py-2 px-4">{data._id}</td>
              <td className="border-b py-2 px-4">
                {data.job_type.replace(/_/g, " ")}
              </td>
              <td className="border-b py-2 px-4">
                {data?.job_cache?.user_note ? data.job_cache.user_note : "None"}
              </td>
              <td
                className={`border-b py-2 px-4 ${
                  data.current_status == "SUCCESS"
                    ? "text-green-500"
                    : "text-red-500"
                }`}
              >
                {data.current_status}
              </td>
              <td className="border-b py-2 px-4">
                {getIstTime(data.created_at)}
              </td>
              <td className="border-b py-2 px-4">
                {getIstTime(data.updated_at)}
              </td>
              <td className="border-b py-2 px-4">
                <button
                  className="rounded-lg border border-[#4318FF] py-2  px-4 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => handleViewClick(data)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TaskCard;
