import React from "react";
import { useDispatch } from "react-redux";
import { setDefaultValue } from "reduxConfig/slices/certificateSlice";
import { toggleViewAllData } from "reduxConfig/slices/certificateSlice";
import { Tooltip } from "react-tooltip";

function CardData({ cardData }) {
  const dispatch = useDispatch();

  const handleViewClick = (data) => {
    dispatch(
      setDefaultValue({
        _id: data._id,
        total_apks: data.total_apks,
        vt_score_equal_to_zero: data.engine_score.low,
        vt_score_higher_than_zero: data.engine_score.high,
        has_no_vt_score: data.engine_score.none,
        distinguished_name: data.developer_info.distinguished_name,
        country_name: data.developer_info.country_name,
        common_name: data.developer_info.common_name,
        locality: data.developer_info.locality,
        organization_name: data.developer_info.organization_name,
        state_or_province_name: data.developer_info.state_or_province_name,
        organizational_unit_name: data.developer_info.organizational_unit_name,
        email: data.developer_info.email,
      })
    );
    dispatch(toggleViewAllData());
  };
  return (
    <div className="">
      <table className="min-w-full border border-gray-300 bg-white">
        <thead className="bg-gray-100 text-2xl">
          <tr>
            <th className="py-2 px-4 text-start">Cert-Id</th>
            <th className="py-2 px-4  text-start">Total APKs</th>
            <th className="py-2 px-4  text-start">Detection Score</th>
            <th className="py-2 px-4  text-start">Organization Name</th>
            <th className="py-2 px-4  text-start">Email</th>
            <th className="py-2 px-4   text-start">Actions</th>
          </tr>
        </thead>

        <tbody>
          {cardData.map((data, index) => (
            <tr
              key={data.id}
              className={index % 2 === 1 ? "bg-gray-200" : "bg-white"}
            >
              <td className="py-2 px-4">{data._id}</td>
              <td className="py-2 px-4">
                {data?.total_apks ? data.total_apks : "0"}
              </td>
              <td className="flex items-start justify-start py-2 px-4 text-start">
                <div>
                  <a
                    data-tooltip-id="my-tooltip-red"
                    data-tooltip-html="No of Apks with threat engine score greater than 0"
                    data-tooltip-place="right"
                  >
                    <p class="rounded bg-red-400 px-4 py-4 text-sm font-medium text-[#000] me-2 dark:bg-red-400 dark:text-red-400">
                      {data?.engine_score?.high
                        ? data.engine_score.high
                        : "0"}
                    </p>
                  </a>
                  <Tooltip
                    className="z-10"
                    id="my-tooltip-red"
                    style={{ backgroundColor: "#EF4444", color: "white" }}
                  />
                </div>

                <div>
                  <a
                    data-tooltip-id="my-tooltip-green"
                    data-tooltip-html="No of Apks with threat engine score Equal to  0"
                    data-tooltip-place="right"
                  >
                    <p className="rounded bg-green-400 px-4 py-4 text-sm font-medium text-[#000] me-2 dark:bg-green-400 dark:text-green-400 ">
                      {data?.engine_score?.low
                        ? data.engine_score.low
                        : "0"}
                    </p>
                  </a>
                  <Tooltip
                    className="z-10"
                    id="my-tooltip-green"
                    style={{ backgroundColor: "#48BB78", color: "white" }}
                  />
                </div>
                <div>
                  <a
                    data-tooltip-id="my-tooltip-gray"
                    data-tooltip-html="No of Apks does not have a engine score"
                    data-tooltip-place="right"
                  >
                    <p className="rounded bg-gray-500  px-4 py-4 text-sm font-medium text-[#000] me-2 dark:bg-gray-500 dark:text-gray-500">
                      {data?.engine_score?.none ? data.engine_score.none : "0"}
                    </p>
                  </a>
                  <Tooltip
                    className="z-10"
                    id="my-tooltip-gray"
                    style={{ backgroundColor: "#6B7280", color: "white" }}
                  />
                </div>
              </td>
              <td className="py-2 px-4">
                {data?.developer_info?.organization_name
                  ? data.developer_info.organization_name
                  : "None"}
              </td>
              <td className="py-2 px-4">
                {data?.developer_info?.email
                  ? data.developer_info.email
                  : "None"}
              </td>
              <td className="py-2 px-4">
                <button
                  className="rounded-lg border border-[#4318FF] py-2  px-4 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  onClick={() => handleViewClick(data)}
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CardData;
