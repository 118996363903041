import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const filterParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const rule_active = queryParams.get("rule_active")
    ? queryParams.get("rule_active")
    : "";
  const slack_alert = queryParams.get("slack_alert")
    ? queryParams.get("slack_alert")
    : "";
  return {
    rule_active: rule_active,
    slack_alert: slack_alert,
  };
};

const initialState = {
  rulesData: [],
  isLoading: false,
  pageNumber: pageNumberHandler(),
  ...filterParamsHandler(),
  totalPages: null,
  rulesPerPage: 15,
  showRuleModal: false,
  showCreateRuleModal: false,
  showStatChartModal: false,
  showDeleteModal: false,
  totalRules: null,
  checkBoxSelect: false,
  error: null,
  statChartData: {
    description: "",
    id: "",
    links: "",
    threatCount: 0,
    whitelistedCount: 0,
    noOfApk: 0,
    noOfPhishingDomains: 0,
    noOfUnknows: 0,
  },
  newRuleData: {
    brandIds: [],
    queryStrings: [],
    cronExpression: "0 0 * * *",
    description: "",
    searchType: "GOOGLE_SEARCH",
    title: "",
    slack_web_hook: [],
    auto_assign: null,
    toggle: true,
  },
  updatedRuleData: {
    ruleId: null,
    brandIds: [],
    queryStrings: [],
    cronExpression: null,
    description: null,
    searchType: null,
    title: "",
    slack_web_hook: [],
    auto_assign: null,
    toggle: true,
  },
  updateDeleteData: {
    title: "",
    ruleId: "",
  },
  updateRunOption: {
    runDate: "",
    query_id: "",
  },
  status: null,
  showDateRunModal: false,
  showFilterModal: false,
};

export const getRulesData = createAsyncThunk("rules/data", () => {
  let stateData = store.getState();
  let ruleSliceData = stateData.rulesData;

  let arr = [];
  if (ruleSliceData.rule_active !== "") {
    arr.push(`rule_active=${ruleSliceData.rule_active}`);
  }

  if (ruleSliceData.slack_alert !== "") {
    arr.push(`slack_alert=${ruleSliceData.slack_alert}`);
  }

  return apiCall(
    `sw_query/?page=${ruleSliceData.pageNumber}&results=${
      ruleSliceData.rulesPerPage
    }&${arr.join("&")}`,
    "get",
    {}
  );
});

export const getRulesDataSkipLoad = createAsyncThunk("rules_skip/data", () => {
  let stateData = store.getState();
  let ruleSliceData = stateData.rulesData;

  let arr = [];
  if (ruleSliceData.rule_active !== "") {
    arr.push(`rule_active=${ruleSliceData.rule_active}`);
  }

  if (ruleSliceData.slack_alert !== "") {
    arr.push(`slack_alert=${ruleSliceData.slack_alert}`);
  }

  return apiCall(
    `sw_query/?page=${ruleSliceData.pageNumber}&results=${
      ruleSliceData.rulesPerPage
    }&${arr.join("&")}`,
    "get",
    {}
  );
});

export const updateRulesData = createAsyncThunk("rules/update", () => {
  let stateData = store.getState();
  let updatedRuleData = stateData.rulesData.updatedRuleData;
  let dataToPost = {
    brand_ids: updatedRuleData.brandIds.map(function (obj) {
      return obj.value;
    }),
    query_strings: updatedRuleData.queryStrings,
    cron_value: updatedRuleData.cronExpression,
    description: updatedRuleData.description,
    search_type: updatedRuleData.searchType,
    title: updatedRuleData.title,
    slack_web_hook: updatedRuleData.slack_web_hook.map(function (obj) {
      return obj.url;
    }),
    auto_assign: updatedRuleData.auto_assign,
    active: updatedRuleData.toggle,
  };
  return apiCall(`sw_query/${updatedRuleData.ruleId}`, "put", dataToPost);
});

export const createNewRule = createAsyncThunk("rules/new", () => {
  let stateData = store.getState();
  let newRuleData = stateData.rulesData.newRuleData;
  let dataToPost = {
    brand_ids: newRuleData.brandIds.map(function (obj) {
      return obj.value;
    }),
    query_strings: newRuleData.queryStrings,
    cron_value: newRuleData.cronExpression,
    description: newRuleData.description,
    search_type: newRuleData.searchType,
    title: newRuleData.title,
    slack_web_hook: newRuleData.slack_web_hook.map(function (obj) {
      return obj.url;
    }),
    auto_assign: newRuleData.auto_assign,
    is_active: newRuleData.toggle,
  };
  return apiCall("sw_query/", "post", dataToPost);
});
export const rulesDataSlice = createSlice({
  name: "rulesData",
  initialState,
  reducers: {
    toggleRuleModal: (state) => {
      state.showRuleModal = !state.showRuleModal;
    },
    toggleCreateRuleModal: (state) => {
      state.showCreateRuleModal = !state.showCreateRuleModal;
    },
    updateNewRule: (state, action) => {
      state.newRuleData = { ...state.newRuleData, ...action.payload };
    },
    updateExistingRule: (state, action) => {
      state.updatedRuleData = { ...state.updatedRuleData, ...action.payload };
    },
    clearNewRule: (state) => {
      state.newRuleData = initialState.newRuleData;
    },
    clearUpdatedRule: (state) => {
      state.updatedRuleData = initialState.updatedRuleData;
    },
    toggleStatChartModal: (state) => {
      state.showStatChartModal = !state.showStatChartModal;
    },
    updateStatChart: (state, action) => {
      state.statChartData = action.payload;
    },
    updatePageNumberRules: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    updateRulesPerPage: (state, action) => {
      state.rulesPerPage = action.payload;
    },
    toggleCheckBoxSelect: (state) => {
      state.checkBoxSelect = !state.checkBoxSelect;
    },
    updateErrorRules: (state, action) => {
      state.error = null;
    },
    toggleShowDeleteModal: (state) => {
      state.showDeleteModal = !state.showDeleteModal;
    },
    updateDeleteData: (state, action) => {
      state.updateDeleteData = { ...state.updateDeleteData, ...action.payload };
    },
    updateRuleStatus: (state, action) => {
      state.status = null;
    },
    updateIsLoadingRules: (state, action) => {
      state.isLoading = action.payload;
    },
    toggleDateRunModal: (state) => {
      state.showDateRunModal = !state.showDateRunModal;
    },
    updateRunDate: (state, action) => {
      state.updateRunOption = { ...state.updateRunOption, ...action.payload };
    },
    toggleFilterModal: (state) => {
      state.showFilterModal = !state.showFilterModal;
    },

    updateRuleActive: (state, action) => {
      state.rule_active = action.payload;
    },
    updateSlackActive: (state, action) => {
      state.slack_alert = action.payload;
    },
    clearFiltersRules: (state, action) => {
      state.rule_active = "";
      state.slack_alert = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRulesData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRulesData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rulesData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalRules = action.payload.data.data.total_results;
      })
      .addCase(getRulesData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(createNewRule.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(createNewRule.fulfilled, (state, action) => {
        state.status = "CREATE_RULE";
        // state.isLoading = false;
        state.newRuleData = initialState.newRuleData;
      })
      .addCase(createNewRule.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
        state.newRuleData = initialState.newRuleData;
      })
      .addCase(updateRulesData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(updateRulesData.fulfilled, (state, action) => {
        state.status = "UPDATE_RULE";
        // state.isLoading = false;
        state.updatedRuleData = initialState.newRuleData;
      })
      .addCase(updateRulesData.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
        state.updatedRuleData = initialState.newRuleData;
      })
      .addCase(getRulesDataSkipLoad.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(getRulesDataSkipLoad.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.rulesData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalRules = action.payload.data.data.total_results;
      })
      .addCase(getRulesDataSkipLoad.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleRuleModal,
  updateNewRule,
  newRuleData,
  toggleCreateRuleModal,
  updateExistingRule,
  clearNewRule,
  updatePageNumberRules,
  clearUpdatedRule,
  toggleStatChartModal,
  updateStatChart,
  updateRulesPerPage,
  toggleCheckBoxSelect,
  updateErrorRules,
  toggleShowDeleteModal,
  updateDeleteData,
  updateRuleStatus,
  updateIsLoadingRules,
  toggleDateRunModal,
  updateRunDate,
  toggleFilterModal,
  clearFiltersRules,
  updateRuleActive,
  updateSlackActive,
} = rulesDataSlice.actions;

export default rulesDataSlice.reducer;
