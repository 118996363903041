import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleUploadUrlModal } from "reduxConfig/slices/urlSlice";
import BrandSelect from "./BrandSelect";
import { togglechoosenType } from "reduxConfig/slices/urlSlice";
import ResultTypeSelect from "./ResultTypeSelect";
import EntityTypeSelect from "./EntityTypeSelect";
import RuleSelect from "./RuleSelect";
import { toggleUrlPreviewModal } from "reduxConfig/slices/urlSlice";
import { updateNewUrl } from "reduxConfig/slices/urlSlice";
import { ProcessUploadUrls } from "reduxConfig/slices/urlSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { clearNewUrlData } from "reduxConfig/slices/urlSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { toggleAllowMultiBox } from "reduxConfig/slices/urlSlice";
import { clearAllDataUrl } from "reduxConfig/slices/urlSlice";

function UploadUrl() {
  const dispatch = useDispatch();
  const defaultData = useSelector((state) => state.urlsData);
  const urlsData = useSelector((state) => state.urlsData.newUrlData);
  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);

  const enterUrls = useSelector((state) => state.urlsData.newUrlData.enterUrls);
  const chooseRule = useSelector(
    (state) => state.urlsData.newUrlData.chooseRule
  );
  const checkBox = useSelector((state) => state.urlsData.allowMutlipleUrlsBox);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleUploadUrlModal());
      dispatch(clearNewUrlData());
      dispatch(clearAllDataUrl());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleUpload = () => {
    // Validate required fields
    if (!enterUrls.length > 0 || !chooseRule) {
      setValidationError(true);
      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    dispatch(ProcessUploadUrls());
    dispatch(toggleUploadUrlModal());
    dispatch(toggleUrlPreviewModal());
  };

  return (
    <div className="mt-1/4  fixed top-0 z-30 ">
      <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
        <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 flex-col justify-center ">
          <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
            <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
              <div class="">
                <div class="flex items-center justify-center">
                  <h2 class="text-#000000 block  pl-2 text-4xl font-semibold">
                    Upload URLs
                  </h2>
                </div>
                <div class="text-#000000 space-y-2 py-8 leading-6 sm:text-lg sm:leading-7">
                  <div class="flex flex-col text-lg">
                    <div className="flex items-center">
                      <label class="font-semibold leading-loose">
                        Enter URLs:
                        <sup className="text-red-500">&#8727;</sup>
                      </label>
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-URLs"
                          data-tooltip-html="Here, you can add URLs, and you can also paste </br> multiple URLs. Based on the URLs you provide, </br> we will process them."
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-URLs" />
                      </div>
                      <div className="">
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html="Select checkbox for paste multiple urls <br/> and you can paste valued separated by comma, and new line <br/> and it will auto split"
                          data-tooltip-place="right"
                        >
                          <input
                            className="ml-2 flex h-5 w-5 cursor-pointer items-center"
                            type="checkBox"
                            // id="autoAssignBrands"
                            // checked={ruleData.autoAssignBrands}
                            onChange={(e) => dispatch(toggleAllowMultiBox())}
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip" />
                      </div>
                    </div>
                    <textarea
                      class="max-h-40 w-full border border-[#000000]  p-2.5 text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      placeholder="Enter URLs"
                      value={urlsData.enterUrls}
                      onChange={(e) => {
                        const enteredUrls = e.target.value;
                        const urlsArray = checkBox
                          ? enteredUrls.split(/[,\n]+/)
                          : enteredUrls;
                        dispatch(
                          updateNewUrl({
                            enterUrls: urlsArray,
                          })
                        );
                      }}
                    />
                  </div>

                  <div class="flex flex-col text-lg">
                    <label class="flex items-center font-semibold leading-loose">
                      Choose Brands:
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-URLs"
                          data-tooltip-html="Here, you can select brands from the dropdown. </br> It will be considered as the targeted brands for the above URLs."
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-URLs" />
                      </div>
                    </label>
                    <BrandSelect />
                  </div>

                  <div class="flex flex-col text-lg">
                    <div className="flex items-center">
                      <label class="font-semibold leading-loose">
                        Choose Rule:
                        <sup className="text-red-500">&#8727;</sup>
                      </label>
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-URLs"
                          data-tooltip-html="Here, you can select rule from the dropdown. </br> It will be considered as the rule id for the above URLs."
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-URLs" />
                      </div>
                    </div>
                    <RuleSelect />
                  </div>

                  <div className="mt-3 flex items-center font-semibold">
                    Choose Indicator:
                    <div className="ml-1 text-lg font-normal">
                      <a
                        data-tooltip-id="my-tooltip-URLs"
                        data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the indicator."
                        data-tooltip-place="right"
                      >
                        <BsQuestion
                          className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                          color="#4318FF"
                        />
                      </a>
                      <Tooltip className="z-10" id="my-tooltip-URLs" />
                    </div>
                  </div>
                  <div>
                    <select
                      value={defaultData.choosenType}
                      onChange={(e) => {
                        dispatch(togglechoosenType(e.target.value));
                        dispatch(
                          updateNewUrl({
                            chooseIndicator: e.target.value,
                          })
                        );
                      }}
                      className="w-full  border border-[#000000] bg-white p-2.5 text-[#000000] sm:text-sm"
                    >
                      <option value="NONE">None</option>
                      <option value="WHITELISTED">Whitelisted</option>
                      <option value="THREAT">Threat</option>
                    </select>
                  </div>

                  <div className="flex flex-col text-lg">
                    <div className="flex items-center justify-start"></div>
                    <EntityTypeSelect />
                  </div>

                  <div className="flex flex-col text-lg">
                    <div className="flex items-center justify-start"></div>
                    <ResultTypeSelect />
                  </div>
                </div>
                <div class="flex items-center justify-center space-x-4 pt-4">
                  <button
                    className="flex w-20  items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
                    onClick={() => {
                      handleUpload();
                    }}
                  >
                    Preview
                  </button>
                  <button
                    class="flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                    onClick={() => {
                      dispatch(toggleUploadUrlModal());
                      dispatch(clearNewUrlData());
                      dispatch(clearAllDataUrl());
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadUrl;
