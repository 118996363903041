import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

  const alterColors = {
    Success: "bg-green-500",
    Error: "bg-red-500",
    Info: "bg-yellow-500"
  }

const initialState = {
  alertText : null,
  showAlert : false,
  alertColor : alterColors.Info,
};


export const alertDataSlice = createSlice({
  name: "alertData",
  initialState,
  reducers: {
    updateAlert: (state, action) => {
      state.alertText = action.payload.alertText;
      state.showAlert = true;
      state.alertColor = alterColors[`${action.payload.alertType}`]
    },
    toggleAlert : (state) =>{
      state.showAlert = false;
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateAlert, toggleAlert } = alertDataSlice.actions;

export default alertDataSlice.reducer;
