import React, { useState } from "react";
import { useEffect, useRef } from "react";
import MultiSelect from "./MultiSelect";
import TagInput from "./TagInput";
import cronstrue from "cronstrue";
import { useDispatch, useSelector } from "react-redux";
import { toggleCreateRuleModal } from "reduxConfig/slices/rulesSlice";
import { updateNewRule } from "reduxConfig/slices/rulesSlice";
import { createNewRule } from "reduxConfig/slices/rulesSlice";
import { clearNewRule } from "reduxConfig/slices/rulesSlice";
import SearchTypeDropDown from "./SearchTypeDropDown";
import { AiOutlineInfoCircle } from "react-icons/ai";
import apiCall from "axiosConfig/axiosConfig";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { toggleCheckBoxSelect } from "reduxConfig/slices/rulesSlice";
import SlackSelect from "./SlackSelect";

const CreateRule = () => {
  const dispatch = useDispatch();
  const ruleData = useSelector((state) => state.rulesData.newRuleData);
  const checkBox = useSelector((state) => state.rulesData.checkBoxSelect);
  const checkTitle = useSelector((state) => state.rulesData.newRuleData.title);
  const [toggle, setToggle] = useState(ruleData.toggle);
  const checkTargetedBrands = useSelector(
    (state) => state.rulesData.newRuleData.brandIds
  );
  const checkQueryString = useSelector(
    (state) => state.rulesData.newRuleData.queryStrings
  );

  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);
  const getCronReadable = (cronValue) => {
    try {
      return cronstrue.toString(cronValue);
    } catch (error) {
      return "Invalid Cron value";
    }
  };
  const queryStringInfoText = {
    GOOGLE_SEARCH:
      '<p>inurl: Find pages with a keyword in their webaddress.<br/> E.g., inurl:apkmonk. intitle: Discover pages with a keyword in their title.<br/> E.g.,intitle:irctc apk download.<br/> Exact Match (""):Use quotes for precise phrases, like "irctcair".<br/> Arithmetic Operators: Use + to add wordsand - to exclude them.<br/> E.g., irctc-site:www.irctc.co.in.<br/> indomain: Get results from a specific website withindomain:en.softonic.com.<br/> intext: Find words within web page content using intext:irctc.<br/> site: Search only within a particular website.Example: site:www.9apps.com.<br/></p>',
    VT_SERVER: "VT_SERVER",
    // THIRD_PARTY_APPSTORES: "THIRD_PARTY_APPSTORES",
    // GOOGLE_CSE:
    //   '<p>inurl: Find pages with a keyword in their webaddress.<br/> E.g., inurl:apkmonk. intitle: Discover pages with a keyword in their title.<br/> E.g.,intitle:irctc apk download.<br/> Exact Match (""):Use quotes for precise phrases, like "irctcair".<br/> Arithmetic Operators: Use + to add wordsand - to exclude them.<br/> E.g., irctc-site:www.irctc.co.in.<br/> indomain: Get results from a specific website withindomain:en.softonic.com.<br/> intext: Find words within web page content using intext:irctc.<br/> site: Search only within a particular website.Example: site:www.9apps.com.<br/></p>',
    GOOGLE_PLAYSTORE:
      "Use keyword related to the brand app eg: for irctc , tatkal, train ticket booking,<br/> Use package names eg : com.abc.cde<br/> Double quotes, or single quotes will not be useful here",
  };
  //Code Added by VigneshB on [20-09-2023] for handle outsideClick
  // Function to close the modal when clicking outside of it
  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(clearNewRule());
      dispatch(toggleCreateRuleModal());
      dispatch(toggleCheckBoxSelect());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleUpload = () => {
    // Validate required fields
    if (
      !checkTitle ||
      !checkTargetedBrands.length ||
      !checkQueryString.length
    ) {
      setValidationError(true);

      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    // Perform the upload
    dispatch(createNewRule());
    dispatch(toggleCreateRuleModal());
  };

  return (
    <div className="mt-1/4  fixed top-0 z-30 ">
      <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
        <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 flex-col justify-center ">
          <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
            <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
              <div class="">
                <div class="flex items-center justify-center">
                  <h2 class="text-#000000 block  pl-2 text-4xl font-semibold">
                    Create a Rule
                  </h2>
                  <div className="">
                    <a
                      data-tooltip-id="my-tooltip-titleCreate"
                      data-tooltip-html="Turn on the toggle button to initiate the periodic execution of the rule."
                      data-tooltip-place="top"
                    >
                      <button className=" relative flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer">
                        <div className="flex h-full w-full items-center justify-center rounded-full text-xl hover:bg-gray-50">
                          <label className="relative inline-flex cursor-pointer items-center">
                            <input
                              type="checkbox"
                              // value={toggle}
                              checked={toggle}
                              className="peer sr-only"
                              onChange={(e) => {
                                dispatch(updateNewRule({ toggle: !toggle }));
                                setToggle(!toggle);
                              }}
                              // checked={toggle}
                              // onChange={() => {
                              //   toggleRules(!toggle, rulesData._id);
                              //   setToggle(!toggle);
                              // }}
                            />
                            <div className="peer h-6 w-10 rounded-full border-2 bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#4318FF] peer-checked:after:translate-x-full peer-checked:after:border-2 peer-checked:after:border-[#4318FF] peer-focus:outline-none"></div>
                          </label>
                        </div>
                      </button>
                    </a>
                    <Tooltip className="z-10" id="my-tooltip-titleCreate" />
                  </div>
                </div>

                <div class="text-#000000 py-8 text-base leading-6 sm:text-lg sm:leading-7">
                  <div class="flex flex-col text-xl">
                    <div className="flex items-center">
                      <label class="font-semibold leading-loose">
                        Title
                        <sup className="text-red-500">&#8727;</sup>
                      </label>
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-title"
                          data-tooltip-html="Here you can add </br> a title for your rule"
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-title" />
                      </div>
                    </div>
                    <input
                      type="text"
                      class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      placeholder="Add Title"
                      value={ruleData.tittle}
                      onChange={(e) =>
                        dispatch(
                          updateNewRule({
                            title: e.target.value,
                          })
                        )
                      }
                    />
                  </div>

                  <div class="flex flex-col text-xl">
                    <label class="flex items-center font-semibold leading-loose">
                      Slack Webhook URL
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-Webhook"
                          data-tooltip-html="Select a Webhook URL from the </br> dropdown. It will send notifications </br> while the rule is running."
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-Webhook" />
                      </div>
                    </label>
                    <SlackSelect />
                  </div>

                  <div class="flex flex-col text-xl">
                    <div className="flex items-center">
                      <label class="font-semibold leading-loose">
                        Targeted Brands{" "}
                        <sup className="text-red-500">&#8727;</sup>
                      </label>
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-Targeted"
                          data-tooltip-html="Here you can choose a brand from </br> the dropdown. It will target the results </br> based on our selected brand."
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-Targeted" />
                      </div>
                    </div>

                    <MultiSelect />
                  </div>

                  <div class="mt-2 flex items-center">
                    <label className="mr-1 text-xl font-semibold leading-loose">
                      Auto Assign Brands
                    </label>
                    {/* <input
                      className="cursor-pointer"
                      type="checkbox"
                      id="autoAssignBrands"
                      class="mr-4 h-5 w-5 cursor-pointer"
                      // checked={ruleData.autoAssignBrands}
                      // onChange={(e) =>
                      //   dispatch(
                      //     updateNewRule({
                      //       autoAssignBrands: e.target.checked,
                      //     })
                      //   )
                      // }
                    /> */}
                    <a
                      data-tooltip-id="my-tooltips"
                      data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the brands </br> you selected above."
                      data-tooltip-place="right"
                    >
                      <AiOutlineInfoCircle
                        className="h-6 w-6 cursor-pointer rounded-md"
                        color="#4318FF"
                      />
                    </a>
                    <Tooltip className="z-10" id="my-tooltips" />
                  </div>
                  <div>
                    <select
                      value={ruleData.auto_assign}
                      onChange={(e) => {
                        dispatch(
                          updateNewRule({ auto_assign: e.target.value })
                        );
                      }}
                      className="w-full  border border-[#000000] bg-white p-2 py-2 px-4 text-[#000000] sm:text-sm"
                    >
                      <option value="null">None</option>
                      <option value="WHITELISTED">Whitelisted</option>
                      <option value="THREAT">Threat</option>
                    </select>
                  </div>

                  <div class="flex flex-col text-xl">
                    <label class="flex items-center font-semibold leading-loose">
                      Search Type
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-Search"
                          data-tooltip-html="Here, you can select a search type from </br> the dropdown. Based on your selected </br> type, it will search the rule on that type."
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-Search" />
                      </div>
                    </label>
                    <SearchTypeDropDown isNewRule={true} />
                  </div>

                  <div className="flex flex-col text-xl">
                    <div className="flex items-center justify-start">
                      <label className="font-semibold leading-loose">
                        Query String
                        <sup className="text-red-500">&#8727;</sup>
                      </label>
                      <div className="m-2 flex">
                        <div className="">
                          <a
                            data-tooltip-id="my-tooltip-search"
                            data-tooltip-html={
                              queryStringInfoText[`${ruleData.searchType}`]
                            }
                            data-tooltip-place="right"
                          >
                            <BsQuestion
                              className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                              color="#4318FF"
                            />
                          </a>
                          <Tooltip className="z-10" id="my-tooltip-search" />
                        </div>
                      </div>
                      <div className="">
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-html="Select checkbox for paste multiple query <br/> and you can paste valued separated by comma, <br/> and it will auto split"
                          data-tooltip-place="right"
                        >
                          <input
                            className="ml-2 flex h-5 w-5 cursor-pointer items-center"
                            type="checkbox"
                            // id="autoAssignBrands"
                            // checked={ruleData.autoAssignBrands}
                            onChange={(e) => dispatch(toggleCheckBoxSelect())}
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip" />
                      </div>
                    </div>
                    <TagInput />
                  </div>

                  <div class="flex flex-col text-xl">
                    <label class="flex items-center font-semibold leading-loose">
                      Cron Expression{""}
                      <a
                        href="https://crontab.cronhub.io/"
                        target="_blank"
                        className="m-1"
                      >
                        <div className="text-lg font-normal">
                          <a
                            data-tooltip-id="my-tooltip-cron"
                            data-tooltip-html="Click this info icon; it will navigate to the </br> Croptab page. There, you can select a </br> periodic time, paste it into the input box, </br> and it will run at the specified time."
                            data-tooltip-place="right"
                          >
                            <AiOutlineInfoCircle
                              className="h-6 w-6 rounded-md"
                              color="#4318FF"
                            />
                          </a>
                          <Tooltip className="z-10" id="my-tooltip-cron" />
                        </div>
                      </a>
                    </label>
                    <input
                      type="text"
                      class="w-full  border border-[#000000] px-4 py-2 text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      placeholder="0 0 */3 * *"
                      value={ruleData.cronExpression}
                      onChange={(e) =>
                        dispatch(
                          updateNewRule({ cronExpression: e.target.value })
                        )
                      }
                    />
                    <div className="text-sm italic">
                      {getCronReadable(ruleData.cronExpression)}
                    </div>
                  </div>

                  <div class="flex flex-col text-xl">
                    <label class="flex items-center font-semibold leading-loose">
                      Description
                      <div className="ml-1 text-lg font-normal">
                        <a
                          data-tooltip-id="my-tooltip-Description"
                          data-tooltip-html="Here you can add description for a rule"
                          data-tooltip-place="right"
                        >
                          <BsQuestion
                            className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                            color="#4318FF"
                          />
                        </a>
                        <Tooltip className="z-10" id="my-tooltip-Description" />
                      </div>
                    </label>
                    <input
                      type="text"
                      class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      placeholder="Add Description"
                      value={ruleData.description}
                      onChange={(e) =>
                        dispatch(
                          updateNewRule({
                            description: e.target.value,
                          })
                        )
                      }
                    />
                  </div>
                </div>
                <div class="flex items-center justify-center space-x-4 ">
                  <button
                    className="flex w-20  items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
                    onClick={() => {
                      handleUpload();
                    }}
                  >
                    Save
                  </button>
                  <button
                    class="flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                    onClick={() => {
                      dispatch(clearNewRule());
                      dispatch(toggleCreateRuleModal());
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRule;
