import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { toggleSearchInfoModal } from "reduxConfig/slices/seoPoisonSlice";
import { BiCopy } from "react-icons/bi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";

const sections = [
  {
    title: "Search Keyword",
    content:
      "Search Keyword in [link/displayed link/text snippet/domain/queryKeywords/link hash]",
    query: "$search_keyword",
    example: "irctc",
  },
  {
    title: "Search Keyword in Title",
    content: "Search Keyword in the title of the result",
    query: "intitle:$search_keyword",
    example: 'intitle:"irctc"',
  },
  {
    title: "Search Keyword in Link",
    content: "Search Keyword in the link of the result",
    query: "inurl:$search_keyword",
    example:
      'inurl:"https://www.virustotal.com/gui/file/8d8d3cdf270519ca63b7184bd1bc393453b99b03139fb4ea5d5387600a1b2950"',
  },
  {
    title: "Search Keyword in Domain",
    content: "Search Keyword in the domain of the result",
    query: "indomain$search_keyword",
    example: 'indomain:"apkmody.io"',
  },
  {
    title: "Search by Rule ID",
    content: "Search results belong to a particular rule",
    query: "rule:$rule_id",
    example: 'rule:"652cd6a7926c0549cbbf0bba"',
  },
  {
    title: "Search by md5 ID",
    content: "Search results belong to a particular md5",
    query: "md5:$md5_id",
    example: 'md5:"a74c386e693c5ef1b325a813f31d753f"',
  },
  {
    title: "Search by brand id",
    content: "Search results belong to a particular brand id",
    query: "brand_id:$brand_id",
    example: 'brand_id:"65335acdda7e08bbf1f01f3a"',
  },
  {
    title: "Search by result id",
    content: "Search results belong to a particular result id",
    query: "result_id:$result_id",
    example: 'result_id:"651ea9ad30b46622c602f26f"',
  },
];

function SearchInfoModal() {
  const dispatch = useDispatch();

  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleSearchInfoModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-10 h-full w-full overflow-y-auto bg-gray-600 bg-opacity-50 backdrop-blur-sm">
      <div className="absolute top-1/2 left-1/2 z-20 mx-auto w-[90%] -translate-x-1/2 -translate-y-1/2 transform sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]">
        <div
          className="relative mx-8 w-[45vw] rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0"
          ref={modalRef}
        >
          <div className="">
            <div className="mb-4 text-center">
              <div className="text-xl font-semibold text-[#000000]">
                SEARCH INFO
              </div>
              <div className=" text-lg text-[#000000]">
                Click to copy the search operator example
              </div>
            </div>

            {sections.map((section, index) => (
              <div key={index} className="m-4 flex items-center">
                <div className="flex w-60 items-center justify-end rounded-md border-2 border-[#4318FF] p-2 text-lg text-[#4318FF] sm:w-72 lg:w-80">
                  <CopyToClipboard
                    text={section.example}
                    onCopy={() => {
                      dispatch(
                        updateAlert({
                          alertText: "Keyword Copied",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                    }}
                  >
                    <span className="flex cursor-grabbing">
                      <BiCopy className="m-0 h-6 w-6 rounded-md  p-[2px] text-[#000000]" />
                    </span>
                  </CopyToClipboard>
                  {section.query}
                </div>
                <span className="mx-2"> - </span>
                <div className="w-72 text-lg text-[#000000] sm:w-80 lg:w-96">
                  {section.content}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchInfoModal;
