import apiCall from "axiosConfig/axiosConfig";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { getBrandDataSkipLoad } from "reduxConfig/slices/brandSlice";
import { toggleBrandDeleteModal } from "reduxConfig/slices/brandSlice";

function BrandDeleteModal() {
  const dispatch = useDispatch();
  const dialogBoxRef = useRef(null);
  const showBrandDeleteModal = useSelector(
    (state) => state.brandsData.showBrandDeleteModal
  );
  const cardData = useSelector((state) => state.brandsData.brandDeleteData);

  const handleClickOutside = (e) => {
    if (dialogBoxRef.current && !dialogBoxRef.current.contains(e.target)) {
      dispatch(toggleBrandDeleteModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div class="fixed top-96 z-10">
      <div
        class={`relative rounded-lg bg-white shadow dark:bg-gray-700`}
        style={{ display: showBrandDeleteModal ? "" : "none" }}
      >
        <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
          <div class="absolute top-0 left-[40%] z-20 mt-20 h-full w-full flex-col justify-center py-6 sm:py-12">
            <div
              class="fixed top-60 rounded-lg border bg-white p-6 text-center"
              ref={dialogBoxRef}
            >
              <h3 class="mb-5 font-normal text-[#000000]">
                Are you sure you want to delete
                <span className="text-[#0000000] font-bold">
                  {` ${cardData.clientName.toUpperCase()}`}
                </span>
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                class="mr-2 inline-flex items-center rounded-lg bg-[#4318FF] px-5 py-2.5 text-center text-sm font-medium text-white"
                onClick={() => {
                  apiCall(`/sw_brand/${cardData.clientId}`, "delete", {})
                    .then((response) => {
                      dispatch(
                        updateAlert({
                          alertText: "Brand Deleted",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                      dispatch(toggleBrandDeleteModal());
                      dispatch(getBrandDataSkipLoad());
                    })
                    .catch((error) => {
                      dispatch(
                        updateAlert({
                          alertText: "Error on brand delete",
                          alertType: "Error",
                        })
                      );
                      dispatch(getBrandDataSkipLoad());
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                      dispatch(toggleBrandDeleteModal());
                    });
                }}
              >
                Yes, delete
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                class="rounded-lg border border-[#4318FF] bg-white px-5 py-2.5 text-sm font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={(e) => {
                  dispatch(toggleBrandDeleteModal());
                }}
              >
                No, don't delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BrandDeleteModal;
