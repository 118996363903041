import { getSlackDatas } from "reduxConfig/slices/settingSlice";
import Banner from "./components/Banner";
import CreateChannel from "./components/CreateChannel";
import EditChannel from "./components/EditChannel";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import SettingCard from "./components/SettingCard";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DeleteModal from "./components/DeleteModal";
import { RingLoader } from "react-spinners";

const ProfileOverview = () => {
  const dispatch = useDispatch();

  const slackData = useSelector((state) => state.settingsData.slackData);
  const showCreateModal = useSelector(
    (state) => state.settingsData.showCreateModal
  );

  const showEditModal = useSelector(
    (state) => state.settingsData.showEditModal
  );

  const showDeleteModal = useSelector(
    (state) => state.settingsData.showDeleteModal
  );

  const error = useSelector((state) => state.settingsData.error);
  const isLoading = useSelector((state) => state.settingsData.isLoading);

  useEffect(() => {
    dispatch(getSlackDatas());
  }, [""]);

  useEffect(() => {
    const body = document.body;

    if (showCreateModal || showEditModal || showDeleteModal) {
      // Disable scrolling
      body.style.overflow = 'hidden';
    } else {
      // Enable scrolling
      body.style.overflow = 'auto';
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = 'auto';
    };
  }, [showCreateModal, showEditModal, showDeleteModal]);

  return (
    <div
      // className={
      //   "mt-4 " +
      //   (showCreateModal || showEditModal
      //     ? "h-[80vh] overflow-x-hidden overflow-y-hidden"
      //     : "")
      // }
      className={
        "mt-4 " +
        (showCreateModal || showEditModal || showDeleteModal
          ? ""
          : "")
      }
    >
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              {" "}
              {showCreateModal ? (
                <div className="mt-1/4  fixed top-0 z-30 ">
                  <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                    {" "}
                    <CreateChannel />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {showEditModal ? (
                <div className="mt-1/4  fixed top-0 z-30 ">
                  <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                    {" "}
                    <EditChannel />
                  </div>
                </div>
              ) : (
                <></>
              )}
              {showDeleteModal ? (
                <div className="mt-1/4  fixed top-0 z-30 ">
                  <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                    {" "}
                    <DeleteModal />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="mt-5">
                <SettingCard cardData={slackData} />
                {/* {slackData.length ? <SettingCard cardData={slackData} /> : <></>} */}
              </div>
            </>
          )}
        </>
      )}
    </div>

    // <div className="flex w-full flex-col gap-5">
    //   <div className="w-ful mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
    //     <div className="col-span-4 lg:!mb-0">
    //       <Banner />
    //     </div>

    //     <div className="col-span-3 lg:!mb-0">
    //       <Storage />
    //     </div>

    //     <div className="z-0 col-span-5 lg:!mb-0">
    //       <Upload />
    //     </div>
    //   </div>

    //   <div className="grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
    //     <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
    //       <Project />
    //     </div>
    //     <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
    //       <General />
    //     </div>

    //     <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
    //       <Notification />
    //     </div>
    //   </div>
    // </div>
  );
};

export default ProfileOverview;
