import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddUploadUrls } from "reduxConfig/slices/urlSlice";
import { toggleUrlUploadSuccessModal } from "reduxConfig/slices/urlSlice";
import { toggleUrlEditModal } from "reduxConfig/slices/urlSlice";
import { toggleUrlPreviewModal } from "reduxConfig/slices/urlSlice";
import BrandSelect from "./BrandSelect";
import RuleSelect from "./RuleSelect";
import { togglechoosenType } from "reduxConfig/slices/urlSlice";
import { updateNewUrl } from "reduxConfig/slices/urlSlice";
import EntityTypeSelect from "./EntityTypeSelect";
import ResultTypeSelect from "./ResultTypeSelect";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { clearAllDataUrl } from "reduxConfig/slices/urlSlice";

function UrlPreviewCard() {
  const showUrlEditModal = useSelector(
    (state) => state.urlsData.showUrlEditCard
  );
  const uploadData = useSelector((state) => state.urlsData.newUrlData);

  const urlsData = useSelector((state) => state.urlsData.urlsData);

  const dispatch = useDispatch();

  return (
    <div className="">
      <div class=" absolute top-0 left-[18%] z-20  w-[80vw] flex-col justify-center py-6 sm:py-12">
        {showUrlEditModal ? (
          <div className="text mt-10 flex items-center justify-between rounded-lg border border-[#BFDBFE] bg-[#BFDBFE] p-3">
            <div className="items-start justify-start px-4 pt-4">
              <div className="mb-1 flex items-center font-semibold">
                Selected Brands
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here, you can select brands from the dropdown. </br> It will be considered as the targeted brands for the above URLs."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </div>
              <BrandSelect />
            </div>
            <div className="items-start justify-start px-4 pt-4">
              <div className="mb-1 flex items-center font-semibold">
                Rule ID
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here, you can select rule from the dropdown. </br> It will be considered as the rule id for the above URLs."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </div>
              <RuleSelect />
            </div>
            <div className="items-start justify-start px-4 pt-4">
              <div className="mb-1 flex items-center font-semibold">
                Indicator
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the indicator."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </div>
              <div>
                <select
                  value={uploadData.chooseIndicator}
                  onChange={(e) => {
                    dispatch(togglechoosenType(e.target.value));
                    dispatch(
                      updateNewUrl({
                        chooseIndicator: e.target.value,
                      })
                    );
                  }}
                  className="w-full  border border-[#000000] bg-white p-2 py-2 px-4 text-[#000000] sm:text-sm"
                >
                  <option value="NONE">None</option>
                  <option value="WHITELISTED">Whitelisted</option>
                  <option value="THREAT">Threat</option>
                </select>
              </div>
            </div>

            <div className="items-start justify-start px-4 pt-4">
              {/* <div className="font-semibold">Entity Type</div> */}
              <EntityTypeSelect />
            </div>
            <div className="items-start justify-start px-4 pt-4">
              {/* <div className="font-semibold">Result Type</div> */}
              <ResultTypeSelect />
            </div>
          </div>
        ) : (
          <div className="text mt-10 flex items-center justify-between">
            <div className="items-start justify-start px-4 pt-4">
              <div className="mb-1 flex items-center font-semibold">
                Selected Brands
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here, you can select brands from the dropdown. </br> It will be considered as the targeted brands for the above URLs."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </div>
              {uploadData.chooseBrands &&
              Object.values(uploadData.chooseBrands).length > 0 ? (
                <div className="flex h-[20%] w-full flex-row flex-wrap overflow-auto">
                  {Object.values(uploadData.chooseBrands).map((brand_data) => (
                    <div class="text-#000000 mx-1 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
                      {brand_data.label}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-#000000 h-[20%] items-center rounded-lg border-2 border-[#000000] p-1 text-center text-sm">
                  None
                </div>
              )}
            </div>
            <div class="items-start justify-start px-4 pt-4">
              <dt class="mb-1 flex items-center font-semibold dark:text-gray-400">
                Rule ID
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here, you can select rule from the dropdown. </br> It will be considered as the rule id for the above URLs."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </dt>
              <dd class="text-sm">
                {uploadData.chooseRule.value
                  ? uploadData.chooseRule.value
                  : "UNKNOWN"}
              </dd>
            </div>
            <div class="items-start justify-start px-4 pt-4">
              <dt class="mb-1 flex items-center font-semibold dark:text-gray-400">
                Indicator
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the indicator."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </dt>
              <dd
                className={`text-sm ${
                  uploadData.chooseIndicator
                    ? uploadData.chooseIndicator === "WHITELISTED"
                      ? "text-green-500"
                      : "text-red-500"
                    : " "
                }`}
              >
                {uploadData.chooseIndicator
                  ? uploadData.chooseIndicator
                  : "Not Marked"}
              </dd>
            </div>

            <div class="items-start justify-start px-4 pt-4">
              <dt class="mb-1 flex items-center font-semibold dark:text-gray-400">
                Entity Type
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the entity type."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </dt>
              <dd class="text-sm">
                {uploadData.chooseEntityType
                  ? uploadData.chooseEntityType
                  : "UNKNOWN"}
              </dd>
            </div>
            <div class="items-start justify-start px-4 pt-4">
              <dt class="mb-1 flex items-center font-semibold dark:text-gray-400">
                Result Type
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-URLs"
                    data-tooltip-html="Here you can select any option from </br> the dropdown. Based on your selection,</br> it will automatically assign the results type."
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-URLs" />
                </div>
              </dt>
              <dd class="text-sm">
                {uploadData.chooseResultType
                  ? uploadData.chooseResultType
                  : "UNKNOWN"}
              </dd>
            </div>
          </div>
        )}

        {showUrlEditModal ? (
          <div className="m-12 w-[75vw] rounded-lg bg-white p-10">
            <div className="flex items-center justify-between rounded-lg py-3 px-10 text-xl font-semibold text-[#000000]">
              <div>URL</div>
              <div>Title</div>
              <div className="w-[25%]">Description</div>
            </div>
            {urlsData.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-between rounded-lg  ${
                  index % 2 === 0 ? "bg-[#E6E1FF]" : "bg-[#F4F2FF]"
                } h-[5vw] py-3 px-10 text-lg text-[#000000]`}
                style={{ marginBottom: "10px" }}
              >
                {/* <input
                  type="text"
                  className="hide-scrollbar flex max-h-[4em] min-h-[4em] w-[30%] flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start"
                  defaultValue={item.url}
                  onChange={(e) => {
                    const enteredUrls = e.target.value;
                    const urlsArray = enteredUrls.split(",");
                    dispatch(
                      updateNewUrl({
                        enterUrls: urlsArray,
                      })
                    );
                  }}
                />
                <input
                  type="text"
                  className="hide-scrollbar flex max-h-[4em] min-h-[4em] w-[30%] flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start"
                  defaultValue={item.title}
                  onChange={(e) => {
                  }}
                />
                <input
                  type="text"
                  className="hide-scrollbar flex max-h-[4em] min-h-[4em] w-[30%] flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start"
                  defaultValue={item.description}
                  onChange={(e) => {
                  }}
                /> */}
                <p className=" hide-scrollbar  flex max-h-[4em] min-h-[4em] w-[30%]  flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start">
                  {item.url}
                </p>
                <p className=" hide-scrollbar  flex max-h-[4em] min-h-[4em] w-[30%]  flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start">
                  {item.title ? item.title : "No Title available for this url"}
                </p>
                <div className=" hide-scrollbar  flex max-h-[4em] min-h-[4em] w-[30%]  flex-row flex-wrap justify-start overflow-auto border-2 border-gray-500 p-1 text-start">
                  {item.description
                    ? item.description
                    : "No Description available for this url"}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="m-12 w-[75vw] rounded-lg bg-white p-10">
            <div className="flex items-center justify-between rounded-lg   py-3 px-10 text-xl font-semibold text-[#000000]">
              <div>URL</div>
              <div>Title</div>
              <div className="w-[25%]">Description</div>
            </div>
            {urlsData.map((item, index) => (
              <div
                key={index}
                className={`flex items-center justify-between rounded-lg  ${
                  index % 2 === 0 ? "bg-[#E6E1FF]" : "bg-[#F4F2FF]"
                } h-[4vw] py-3 px-10 text-lg text-[#000000]`}
                style={{ marginBottom: "10px" }}
              >
                <p className=" hide-scrollbar  flex max-h-[4em] min-h-[4em] w-[30%]  flex-row flex-wrap justify-start overflow-auto text-start">
                  {item.url}
                </p>
                <p className=" hide-scrollbar  flex max-h-[4em] min-h-[4em] w-[30%]  flex-row flex-wrap justify-start overflow-auto text-start">
                  {item.title ? item.title : "No Title available for this url"}
                </p>
                <div className=" hide-scrollbar  flex max-h-[4em] min-h-[4em] w-[30%]  flex-row flex-wrap justify-start overflow-auto text-start">
                  {item.description
                    ? item.description
                    : "No Description available for this url"}
                </div>
              </div>
            ))}
          </div>
        )}

        {showUrlEditModal ? (
          <div className="flex justify-center">
            <button
              class="linear ml-2 mr-2 rounded-lg bg-[#4318FF] px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-[#4318FF] active:bg-[#4318FF]"
              onClick={(e) => {
                dispatch(toggleUrlEditModal());
              }}
            >
              Save{" "}
            </button>
            <button
              class="linear ml-2 mr-2 rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-base  font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white active:bg-[#4318FF]"
              onClick={(e) => {
                dispatch(toggleUrlEditModal());
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex justify-center px-10 py-3">
            <button
              class="linear ml-2 mr-2 rounded-lg bg-[#4318FF] px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-[#4318FF] active:bg-[#4318FF]"
              onClick={(e) => {
                dispatch(toggleUrlPreviewModal());
                dispatch(AddUploadUrls());
                dispatch(toggleUrlUploadSuccessModal());
              }}
            >
              Upload{" "}
            </button>
            <button
              class="linear ml-2 mr-2 rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-base  font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white active:bg-[#4318FF]"
              onClick={(e) => {
                dispatch(toggleUrlEditModal());
              }}
            >
              Edit
            </button>
            <button
              class="linear ml-2 mr-2 rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-base  font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white active:bg-[#4318FF]"
              onClick={(e) => {
                dispatch(clearAllDataUrl());
              }}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UrlPreviewCard;
