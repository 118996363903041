import React, { useState, useEffect, useRef } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { AiOutlineCalendar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getApkDataOfficial } from "reduxConfig/slices/officialApkSlice";
import { updatePageNumberApkResultsOfficial } from "reduxConfig/slices/officialApkSlice";
import { updateDateFilterOfficial } from "reduxConfig/slices/officialApkSlice";

function DatePicker() {
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const startDate = useSelector((state) => state.officialApksData.fromDate);
  const endDate = useSelector((state) => state.officialApksData.toDate);
  const dispatch = useDispatch();

  const datePickerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setDatePickerVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDatePicker = () => {
    setDatePickerVisible(!datePickerVisible);
  };

  const clearDateRange = () => {
    dispatch(
      updateDateFilterOfficial({ selection: { startDate: null, endDate: null } })
    );
    dispatch(updatePageNumberApkResultsOfficial(1));
    dispatch(getApkDataOfficial());
  };

  const todayDateRange = () => {
    dispatch(
      updateDateFilterOfficial({
        selection: { startDate: new Date(), endDate: new Date() },
      })
    );
    dispatch(updatePageNumberApkResultsOfficial(1));
    dispatch(getApkDataOfficial());
  };

  function formatDateToReadable(date) {
    if (!date) {
      return "";
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month because months are zero-based
    const year = String(date.getFullYear()).slice(-2);

    return `${day}/${month}/${year}`;
  }
  return (
    <div>
      <h2></h2>
      <div
        className="date-picker-trigger cursor-pointer"
        onClick={toggleDatePicker}
      >
        <div className="calendar-icon">
          {startDate && endDate ? (
            <div className="rounded-xl border border-[#4318FF]  py-[10px] px-6 text-[#4318FF] hover:bg-[#4318FF] hover:text-white">
              {formatDateToReadable(startDate)} to{" "}
              {formatDateToReadable(endDate)}
            </div>
          ) : (
            <AiOutlineCalendar
              className="rounded-xl border border-[#4318FF] py-[5px] px-2 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              size={48}
            />
          )}
        </div>
      </div>
      {datePickerVisible && (
        <div
          className="border-grey-400 absolute left-[74%] z-10 mt-2 border-2 bg-white"
          ref={datePickerRef}
        >
          <DateRange
            editableDateInputs={true}
            onChange={(e) => {
              dispatch(updateDateFilterOfficial(e));
              dispatch(getApkDataOfficial(e));
              dispatch(updatePageNumberApkResultsOfficial(1));
            }}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
                color: "#4318FF",
              },
            ]}
          />
          <div className="flex justify-center">
            <div className="-mt-3 flex items-center justify-center p-1">
              <button
                className="mt-2 w-16 cursor-pointer rounded-lg border border-[#4318FF] p-1 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={todayDateRange}
              >
                Today
              </button>
            </div>
            <div className="-mt-3 flex items-center justify-center p-1">
              <button
                className="mt-2 w-16 cursor-pointer rounded-lg border border-[#4318FF] p-1 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={clearDateRange}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DatePicker;
