import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleExportModal } from "reduxConfig/slices/apkResultSlice";
import { GiCancel } from "react-icons/gi";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { clearDataDump } from "reduxConfig/slices/apkResultSlice";
import { getResultsDump } from "reduxConfig/slices/apkResultSlice";
import { exportVisibleData } from "reduxConfig/slices/apkResultSlice";

function ExportModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const [userNote, setNote] = useState("");

  const totalResultsCount = useSelector(
    (state) => state.ApksResultsData.totalResults
  );
  const isLoading = useSelector(
    (state) => state.ApksResultsData.isExportLoading
  );
  const dumpedData = useSelector((state) => state.ApksResultsData.dumpedData);
  const visibleCount = useSelector(
    (state) => state.ApksResultsData.apkResultData
  );

  function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = today.getFullYear().toString().slice(-2); // Get the last 2 digits of the year

    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  useEffect(() => {}, [dumpedData]);
  const handleDownload = () => {
    const jsonData = {
      Note: userNote,
      Url: window.location.href,
      Data: dumpedData,
    };
    const dataStr = JSON.stringify(jsonData, null, 2);
    const blob = new Blob([dataStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${getCurrentDate()}_AppmonSearchExport.json`;
    link.click();
    URL.revokeObjectURL(url);
    dispatch(clearDataDump());
    setNote("");
  };

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleExportModal());
      dispatch(clearDataDump());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);
  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[10%] z-20 mt-32 h-[50vw] w-[80vw] flex-col justify-center py-6 sm:py-12">
        <div className="relative py-3 sm:mx-auto sm:max-w-xl" ref={modalRef}>
          <div className="-mt-16 flex  flex-col justify-evenly rounded-xl bg-white p-8">
            <div className="flex items-center justify-center text-2xl font-semibold text-[#000000]">
              Export Data
            </div>
            <div className="-mt-7 flex  justify-end">
              <GiCancel
                className="h-8 w-8 cursor-pointer text-[#4318FF]"
                onClick={(e) => {
                  dispatch(toggleExportModal());
                  dispatch(clearDataDump());
                }}
              />
            </div>
            <div className="cursor-pointer items-center text-center ">
              {isLoading ? (
                <button
                  disabled
                  type="button"
                  class="bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-2 py-2 px-4 text-center font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                >
                  <svg
                    aria-hidden="true"
                    role="status"
                    class="text-black mr-3 inline h-4 w-4 animate-spin"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#E5E7EB"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentColor"
                    />
                  </svg>
                  Loading...
                </button>
              ) : dumpedData.length ? (
                <div
                  className="bg-transparent hover:border-transparent flex h-12 items-center justify-center rounded-xl border border-[#4318FF] bg-[#4318FF] p-2  py-2 px-4 text-center font-semibold text-white  hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  <AiOutlineCloudDownload className="h-8 w-8" />
                </div>
              ) : (
                <div class="">
                  <div class="mt-5 flex flex-col text-lg">
                    <label class="flex justify-start leading-loose">
                      Add Note (optional):
                    </label>
                    <textarea
                      class="w-full border border-[#000000] px-4 py-2 text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      placeholder="Add note for self"
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>

                  <div className="mt-5 flex justify-center">
                    <div className="mr-2">
                      <button
                        class="rounded-[10px] border border-[#4318FF] bg-[#4318FF] px-4 py-2 text-sm text-white  hover:bg-[#4318FF] hover:text-white"
                        onClick={() => {
                          dispatch(getResultsDump());
                        }}
                      >
                        {`Export All ${totalResultsCount}`}
                      </button>
                    </div>

                    <div>
                      <button
                        class="rounded-[10px] border border-[#4318FF] px-4 py-2 text-sm text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                        onClick={() => {
                          dispatch(exportVisibleData());
                        }}
                      >
                        {`Export Visible ${visibleCount.length}`}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportModal;
