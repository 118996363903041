import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import AdminLayout from "layouts/admin";
import ProtectedRoutes from "protectedRoute";
import SignIn from "./views/auth/SignIn";
import ApkAllData from "./views/admin/AllApkData";
import OfficialApkAllData from "./views/admin/OfficialAllApkData";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/admin/dashboard" replace />} />
      <Route path="auth/sign-in" element={<SignIn />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="admin/*" element={<AdminLayout />} />
        <Route path="apk_detailed_view/" element={<ApkAllData />} />
        <Route
          path="official_apk_detailed_view/"
          element={<OfficialApkAllData />}
        />
      </Route>
    </Routes>
  );
};

export default App;
