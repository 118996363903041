import apiCall from "axiosConfig/axiosConfig";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { getSearchResultsSeo } from "reduxConfig/slices/seoPoisonSlice";
import { toggleDeleteModal } from "reduxConfig/slices/seoPoisonSlice";

function ResultsDeleteModal() {
  const dispatch = useDispatch();
  const dialogBoxRef = useRef(null);
  const showResultDeleteModal = useSelector(
    (state) => state.SeoPoisonData.showResultDeleteModal
  );
  const cardData = useSelector(
    (state) => state.SeoPoisonData.updateResultDeleteData
  );

  const handleClickOutside = (e) => {
    if (dialogBoxRef.current && !dialogBoxRef.current.contains(e.target)) {
      dispatch(toggleDeleteModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center">
      <div
        className={`relative rounded-lg bg-white shadow dark:bg-gray-700`}
        style={{ display: showResultDeleteModal ? "" : "none" }}
      >
        <div className="flex items-center justify-center h-full">
          <div className="relative z-10 w-[80vw]">
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div
                className="rounded-lg border bg-white p-6 text-center"
                ref={dialogBoxRef}
              >
                  <h3 class="mb-5 text-lg font-normal text-[#000000]">
                    Are you sure you want to delete
                    <span className="text-[#0000000] font-bold">
                      {` ${cardData.resultName}`}
                    </span>
                  </h3>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    class="mr-2 inline-flex items-center rounded-lg bg-[#4318FF] px-5 py-2.5 text-center text-sm font-medium text-white"
                    onClick={() => {
                      apiCall(`/sw_results/${cardData.resultId}`, "delete", {})
                        .then((response) => {
                          dispatch(
                            updateAlert({
                              alertText: "Result Deleted",
                              alertType: "Success",
                            })
                          );
                          dispatch(getSearchResultsSeo());
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                          dispatch(toggleDeleteModal());
                        })
                        .catch((error) => {
                          dispatch(
                            updateAlert({
                              alertText: "Error on result delete",
                              alertType: "Error",
                            })
                          );
                          dispatch(getSearchResultsSeo());
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                          dispatch(toggleDeleteModal());
                        });
                    }}
                  >
                    Yes, delete
                  </button>
                  <button
                    data-modal-hide="popup-modal"
                    type="button"
                    class="rounded-lg border border-[#4318FF] bg-white px-5 py-2.5 text-sm font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:z-10 "
                    onClick={(e) => {
                      dispatch(toggleDeleteModal());
                    }}
                  >
                    No, don't delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ResultsDeleteModal;
