import React, { useEffect, useState } from "react";
import { MultiSelect as Selector } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { updateNewRule } from "reduxConfig/slices/rulesSlice";
import { updateExistingRule } from "reduxConfig/slices/rulesSlice";

function SlackSelect() {
  const slackData = useSelector((state) => state.settingsData.slackData);
  const [formattedData, setBrandData] = useState([]);
  const whichModal = useSelector(
    (state) => state.rulesData.showCreateRuleModal
  );
  const componentDefaultValue = useSelector((state) =>
    whichModal
      ? state.rulesData.newRuleData.slack_web_hook
      : state.rulesData.updatedRuleData.slack_web_hook
  );
  const dispatch = useDispatch();

  useEffect(() => {
    let stateData = [];
    for (let elements in slackData) {
      stateData.push({
        value: slackData[elements]._id,
        label: slackData[elements].cred_name,
        url: slackData[elements].webhook_url,
      });
    }
    whichModal
      ? dispatch(
          updateNewRule({
            slack_web_hook: stateData.filter((option) =>
              componentDefaultValue?.includes(option.url)
            ),
          })
        )
      : dispatch(
          updateExistingRule({
            slack_web_hook: stateData.filter((option) =>
              componentDefaultValue?.includes(option.url)
            ),
          })
        );
    setBrandData(stateData);
  }, []);
  return (
    <div>
      {formattedData.length ? (
        <Selector
          className="multiselect"
          options={formattedData}
          value={componentDefaultValue}
          onChange={(selectedOption) => {
            whichModal
              ? dispatch(updateNewRule({ slack_web_hook: selectedOption }))
              : dispatch(
                  updateExistingRule({ slack_web_hook: selectedOption })
                );
          }}
          labelledBy={"Select"}
          isCreatable={false}
        />
      ) : (
        <div className="font-bold text-red-500 text-sm">
          No webhook url have been created. Please create a wrbhook url.
        </div>
      )}
    </div>
  );
}

export default SlackSelect;
