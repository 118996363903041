import { React, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { updateAlert, toggleAlert } from "reduxConfig/slices/alertSlice";
import {toggleStatChartModal} from "reduxConfig/slices/rulesSlice";
import {
  AiOutlineCopy,
  AiOutlineLink,
  AiOutlineWarning,
  AiOutlineFileUnknown,
} from "react-icons/ai";
import {RiGlobalLine} from "react-icons/ri";
import listIcon from "assets/svg/Vector.svg";
import AndroidIcon from "assets/img/android.png"
//Code Added by VigneshB on [20-09-2023] for statChart Component
function StatChartRule() {
  const dispatch = useDispatch();
  const ruleData = useSelector((state) => state.rulesData.statChartData);

  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleStatChartModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="mt-1/4  fixed top-0 z-30 ">
    <div className="inset-0 fixed h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" absolute top-0 left-[30%] z-20 w-[30vw] flex-col justify-center py-6 sm:py-12 mt-8">
        <div class="relative py-3 sm:mx-auto sm:max-w-xl mt-14"ref={modalRef}>
          <div class="relative mx-8 w-[40vw] rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
            <div class="mx-auto">

              <div className="flex items-center justify-center">
                <div className="w-[85%]">
                  <p class="w-[100%] p-2 text-center text-[90%] font-bold text-[#000000]">
                    {ruleData.description.toUpperCase()}{" "}
                  </p>
                </div>
              </div>

              <div class="text-md m-0 flex justify-center p-2 text-center font-medium text-[#000000]">
                <CopyToClipboard
                  text={ruleData.id}
                  onCopy={() => {
                    dispatch(updateAlert({alertText:"Rule ID Copied",alertType:"Success"}));
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <span className="flex cursor-grabbing">
                    {" "}
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 rounded-md  p-[2px]"
                      color="#4318FF"
                    />
                    {ruleData.id}
                  </span>
                </CopyToClipboard>
              </div>

              <div className="m-4 flex flex-wrap w-full justify-center">
                {[
                  {
                    displayText: "Links Discovered",
                    displayValue: ruleData.links,
                    icon: (
                      <AiOutlineLink
                        className="m-0 h-8 w-8 rounded-md  p-[2px]"
                        color="#4318FF"
                      />
                    ),
                  },
                  {
                    displayText: "Marked Threat Count",
                    displayValue: ruleData.threatCount,
                    icon: (
                      <AiOutlineWarning
                        className="m-0 h-8 w-8 rounded-md  p-[2px]"
                        color="#4318FF"
                      />
                    ),
                  },
                  {
                    displayText: "Whitelisted Count",
                    displayValue: ruleData.whitelistedCount,
                    icon:               <img
                    src={listIcon}
                    className="m-o h-8 w-8"
                    alt="logo"
                  />
                  },
                  {
                    displayText: <>Number of <span className="text-[#000000] text-xl">APKs</span></>,
                    displayValue: ruleData.noOfApk,
                    icon:               <img
                    src={AndroidIcon}
                    className="m-o h-8 w-7"
                    alt="logo"
                  />
                  },
                  {
                    displayText: <>Number of <span className="text-[#000000] text-xl">Domains</span></>,
                    displayValue: ruleData.noOfPhishingDomains,
                    icon: (
                      <RiGlobalLine
                        className="m-0 h-8 w-8 rounded-md  p-[2px]"
                        color="#4318FF"
                      />
                    ),
                  },
                  {
                    displayText: <>Number of <span className="text-[#000000] text-xl">Unknowns</span></>,
                    displayValue: ruleData.noOfUnknowns,
                    icon: (
                      <AiOutlineFileUnknown
                        className="m-0 h-8 w-8 rounded-md  p-[2px]"
                        color="#4318FF"
                      />
                    ),
                  },
                ].map((item) => (
                  <div class="relative m-2 flex h-[150px]  w-[200px] justify-center overflow-hidden rounded-xl border-2 border-[#ADB7D5] bg-[#F4F7FE]">
                    <div className="flex flex-col justify-center">
                      <div className="">
                        <p class="w-[100%] text-center text-[18px] font-bold text-[#000000]">
                          {item.displayText}
                        </p>
                      </div>
                      <div class="text-center text-[40px] text-[#4318FF]">
                        {item.displayValue}
                      </div>
                    </div>
                    <div className="absolute bottom-[-25px] right-[-25px] rounded-full bg-[#e8e8f6c2] p-4">
                      <div className="rounded-full  bg-[#E6E1FF]  p-4">
                        {" "}
                        {item.icon}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-center">
                <button
                  onClick={()=>{
                    dispatch(toggleStatChartModal());
                  }}
                  href=""
                  class="linear ml-2 rounded-[10px] bg-[#4318FF] px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-[#4318FF] active:bg-[#4318FF]"
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default StatChartRule;
