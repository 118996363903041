import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

function parseDDMMYYYYToDate(dateStr) {
  const parts = dateStr.split("/");

  if (parts.length !== 3) {
    throw new Error('Invalid date format. Use "dd/mm/yyyy" format.');
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    throw new Error("Invalid date components.");
  }

  return new Date(year, month, day);
}

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const additionalParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const resultType = queryParams.get("resultType")
    ? queryParams.get("resultType").split(",")
    : [];
  const entityType = queryParams.get("entityType")
    ? queryParams.get("entityType").split(",")
    : [];
  const source = queryParams.get("source")
    ? queryParams.get("source").split(",")
    : [];
  const markedAs = queryParams.get("markedAs")
    ? queryParams.get("markedAs").split(",")
    : [];
  const useOr = queryParams.get("useOr") === "true" ? true : false;
  return {
    use_or: useOr,
    result_type: resultType,
    entity_type: entityType,
    source: source,
    marked_as: markedAs,
  };
};

const filterParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const client = queryParams.get("clients")
    ? queryParams.get("clients").split(",")
    : [];
  const brand = queryParams.get("brands")
    ? queryParams.get("brands").split(",")
    : [];
  const isWhitelisted = queryParams.get("isWhitelisted")
    ? queryParams.get("isWhitelisted").split(",")
    : null;
  return {
    client_ids: client,
    brand_ids: brand,
    is_whitelisted: isWhitelisted,
  };
};

const regexParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isRegex = queryParams.get("isRegex") === "true" ? true : false;
  return {
    isRegex: isRegex,
  };
};

const dateParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get("fromDate")
    ? parseDDMMYYYYToDate(queryParams.get("fromDate"))
    : null;
  const toDate = queryParams.get("toDate")
    ? parseDDMMYYYYToDate(queryParams.get("toDate"))
    : null;

  return { fromDate: fromDate, toDate: toDate };
};

const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const initialState = {
  error: null,
  status: null,
  showFilterModal: false,
  showExportModal: false,
  showApkDetailsModal: false,
  showEditModal: false,
  showInfoModal: false,
  isLoading: false,
  isExportLoading: false,
  showSearchInfoModal: false,
  showSyncOptionModal: false,
  ...regexParamsHandler(),
  queryString: QueryStringHandler(),
  pageNumber: pageNumberHandler(),
  ...dateParamsHandler(),
  additionalFilters: additionalParamsHandler(),
  totalPages: null,
  totalResults: null,
  apkResultsPerPage: 15,
  apkResultData: [],
  resultId: "",
  resultTitle: "",
  resultLink: "",
  resultJson: {},
  dumpedData: [],
  clientsSync: [],
  syncType: "syncAll",
  ...filterParamsHandler(),
  defaultData: {
    id: "",
    title: "",
    packageName: "",
    source: "",
    organizationName: "",
    commonName: "",
    classification: "",
    downloadLinks: "",
    indicators: "",
    md5: "",
    sha1: "",
    sha256: "",
    targetedBrands: "",
    targetedClients: "",
    createdAt: "",
    lastSeen: "",
    apkIcon: "",
    comment: "",
    cert_sha: "",
  },
};

function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  if (!date) {
    return "";
  }
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}

export const getApkDataOfficial = createAsyncThunk("apk/data", () => {
  let stateData = store.getState();
  let resultsData = stateData.officialApksData;
  let isWhitelistes = resultsData.is_whitelisted;
  let cIds = resultsData.client_ids;
  let bIds = resultsData.brand_ids;
  if (isWhitelistes) {
    if (typeof isWhitelistes === "object") {
      isWhitelistes = isWhitelistes.toString();
    }
    if (isWhitelistes === "null") {
      isWhitelistes = null;
    }
  }
  let dataToPost = {
    page_number: resultsData.pageNumber,
    results_per_page: resultsData.apkResultsPerPage,
    dump_all: false,
    query: resultsData.queryString,
    is_regex: resultsData.isRegex,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    client_ids: cIds,
    brand_ids: bIds,
    is_whitelisted: isWhitelistes,
    is_official: true,
    // additional_filters: {
    //   use_or: false,
    //   suggested_classification: [],
    //   threat_indicators: [],
    // },
  };
  return apiCall("/sw_apk/apk_feed", "post", dataToPost);
});

export const getResultsDumpOfficial = createAsyncThunk("apk/dump", () => {
  let stateData = store.getState();
  let resultsData = stateData.officialApksData;
  let isWhitelistes = resultsData.is_whitelisted;
  let cIds = resultsData.client_ids;
  let bIds = resultsData.brand_ids;
  if (isWhitelistes) {
    if (typeof isWhitelistes === "object") {
      isWhitelistes = isWhitelistes.toString();
    }
    if (isWhitelistes === "null") {
      isWhitelistes = null;
    }
  }
  let dataToPost = {
    page_number: resultsData.pageNumber,
    results_per_page: resultsData.apkResultsPerPage,
    dump_all: true,
    query: resultsData.queryString,
    is_regex: resultsData.isRegex,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    client_ids: cIds,
    brand_ids: bIds,
    is_whitelisted: isWhitelistes,
    is_official: true,
    // additional_filters: {
    //   use_or: false,
    //   suggested_classification: [],
    //   threat_indicators: [],
    // },
  };

  return apiCall("/sw_apk/apk_feed", "post", dataToPost);
});

export const updateSyncClient = createAsyncThunk("client/sync", () => {
  let stateData = store.getState();
  let resultsData = stateData.officialApksData;
  let dataToPost = {
    client_ids: resultsData.clientsSync,
  };
  return apiCall("/sw_apk/syncronize_db/", "post", dataToPost);
});

export const officialApksDataReducer = createSlice({
  name: "officialApksData",
  initialState,
  reducers: {
    toggleFilterModal: (state) => {
      state.showFilterModal = !state.showFilterModal;
    },
    toggleExportModal: (state) => {
      state.showExportModal = !state.showExportModal;
    },
    toggleApkDetailsModal: (state) => {
      state.showApkDetailsModal = !state.showApkDetailsModal;
    },
    setDefaultValue: (state, action) => {
      state.defaultData.id = action.payload.id;
      state.defaultData.title = action.payload.title;
      state.defaultData.packageName = action.payload.packageName;
      state.defaultData.source = action.payload.source;
      state.defaultData.organizationName = action.payload.organizationName;
      state.defaultData.commonName = action.payload.commonName;
      state.defaultData.classification = action.payload.classification;
      state.defaultData.downloadLinks = action.payload.downloadLinks;
      state.defaultData.indicators = action.payload.indicators;
      state.defaultData.md5 = action.payload.md5;
      state.defaultData.sha1 = action.payload.sha1;
      state.defaultData.sha256 = action.payload.sha256;
      state.defaultData.targetedBrands = action.payload.targetedBrands;
      state.defaultData.targetedClients = action.payload.targetedClients;
      state.defaultData.createdAt = action.payload.createdAt;
      state.defaultData.lastSeen = action.payload.lastSeen;
      state.defaultData.apkIcon = action.payload.apkIcon;
      state.defaultData.comment = action.payload.comment;
      state.defaultData.cert_sha = action.payload.cert_sha;
    },
    toggleShowEditModal: (state) => {
      state.showEditModal = !state.showEditModal;
    },
    updatePageNumberApkResultsOfficial: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    updateApkResultsPerPageOfficial: (state, action) => {
      state.apkResultsPerPage = action.payload;
    },
    toggleInfoModal: (state) => {
      state.showInfoModal = !state.showInfoModal;
    },
    setInfoResult: (state, action) => {
      state.resultId = action.payload.resultId;
      state.resultTitle = action.payload.resultTitle;
      state.resultLink = action.payload.resultLink;
      state.resultJson = action.payload.resultJson;
    },
    clearDataDumpOfficial: (state) => {
      state.dumpedData = [];
      state.isExportLoading = false;
    },
    exportVisibleData: (state) => {
      state.dumpedData = state.apkResultData;
    },
    updateQueryStringsOfficial: (state, action) => {
      state.queryString = action.payload;
    },
    toggleSearchInfoModal: (state) => {
      state.showSearchInfoModal = !state.showSearchInfoModal;
    },
    toggleIsRegix: (state) => {
      state.isRegex = !state.isRegex;
    },
    toggleIsRegixApkResultsOfficial: (state) => {
      state.isRegex = false;
    },
    updateDateFilterOfficial: (state, action) => {
      state.fromDate = action.payload.selection.startDate;
      state.toDate = action.payload.selection.endDate;
    },
    updateErrorApkResultsOfficial: (state) => {
      state.error = null;
    },
    updateClientFiltersOfficial: (state, action) => {
      state.client_ids = action.payload;
    },
    updateBrandFiltersOfficial: (state, action) => {
      state.brand_ids = action.payload;
    },
    updateIndicatorFiltersOfficial: (state, action) => {
      state.is_whitelisted = action.payload;
    },
    clearFiltersApkOfficial: (state, action) => {
      state.brand_ids = [];
      state.client_ids = [];
      state.is_whitelisted = null;
    },
    toggleShowEditModalFalseOfficial: (state) => {
      state.showEditModal = false;
    },
    updateClientSync: (state, action) => {
      state.clientsSync = action.payload;
    },
    clearClientSync: (state, action) => {
      state.clientsSync = [];
      state.syncType = "syncAll";
    },
    toggleShowSyncOptionModal: (state) => {
      state.showSyncOptionModal = !state.showSyncOptionModal;
    },
    updateSyncType: (state, action) => {
      state.syncType = action.payload;
    },
    changeOffApkEditStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getApkDataOfficial.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApkDataOfficial.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apkResultData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalResults = action.payload.data.data.total_results;
      })
      .addCase(getApkDataOfficial.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getResultsDumpOfficial.pending, (state) => {
        state.isExportLoading = true;
        state.dumpedData = {};
      })
      .addCase(getResultsDumpOfficial.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.dumpedData = action.payload.data.data.page_results;
      })
      .addCase(getResultsDumpOfficial.rejected, (state, action) => {
        state.isExportLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateSyncClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSyncClient.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        state.isLoading = false;
        state.clientsSync = initialState.clientsSync;
        state.syncType = initialState.syncType;
      })
      .addCase(updateSyncClient.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
        state.clientsSync = initialState.clientsSync;
        state.syncType = initialState.syncType;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleFilterModal,
  toggleExportModal,
  toggleApkDetailsModal,
  setDefaultValue,
  toggleShowEditModal,
  updatePageNumberApkResultsOfficial,
  updateApkResultsPerPageOfficial,
  toggleInfoModal,
  setInfoResult,
  clearDataDumpOfficial,
  exportVisibleData,
  updateQueryStringsOfficial,
  toggleSearchInfoModal,
  toggleIsRegix,
  toggleIsRegixApkResultsOfficial,
  updateDateFilterOfficial,
  updateErrorApkResultsOfficial,
  updateClientFiltersOfficial,
  updateBrandFiltersOfficial,
  clearFiltersApkOfficial,
  updateIndicatorFiltersOfficial,
  toggleShowEditModalFalseOfficial,
  toggleShowSyncOptionModal,
  updateClientSync,
  clearClientSync,
  updateSyncType,
  changeOffApkEditStatus,
} = officialApksDataReducer.actions;

export default officialApksDataReducer.reducer;
