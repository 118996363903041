import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";

function parseDDMMYYYYToDate(dateStr) {
  const parts = dateStr.split("/");

  if (parts.length !== 3) {
    throw new Error('Invalid date format. Use "dd/mm/yyyy" format.');
  }

  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is zero-based
  const year = parseInt(parts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    throw new Error("Invalid date components.");
  }

  return new Date(year, month, day);
}

const QueryStringHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);

  // Access specific query parameters
  const query = queryParams.get("query");
  if (query === null) {
    return "";
  } else {
    return query;
  }
};

const additionalParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const suggested_classification = queryParams.get("suggestedClassification")
    ? queryParams.get("suggestedClassification").split(",")
    : [];
  const threat_indicators = queryParams.get("threatIndicators")
    ? queryParams.get("threatIndicators").split(",")
    : [];
  const useOr = queryParams.get("useOr") === "true" ? true : false;
  return {
    use_or: useOr,
    threat_indicators: threat_indicators,
    suggested_classification: suggested_classification,
  };
};

const filterParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const client = queryParams.get("clients")
    ? queryParams.get("clients").split(",")
    : [];
  const brand = queryParams.get("brands")
    ? queryParams.get("brands").split(",")
    : [];
  const isWhitelisted = queryParams.get("isWhitelisted")
    ? queryParams.get("isWhitelisted").split(",")
    : "";
  return {
    client_ids: client,
    brand_ids: brand,
    is_whitelisted: isWhitelisted,
  };
};

const regexParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const isRegex = queryParams.get("isRegex") === "true" ? true : false;
  return {
    isRegex: isRegex,
  };
};

const dateParamsHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get("fromDate")
    ? parseDDMMYYYYToDate(queryParams.get("fromDate"))
    : null;
  const toDate = queryParams.get("toDate")
    ? parseDDMMYYYYToDate(queryParams.get("toDate"))
    : null;

  return { fromDate: fromDate, toDate: toDate };
};

const pageNumberHandler = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const pageNumber = queryParams.get("page");
  if (pageNumber) {
    return parseInt(pageNumber);
  } else {
    return 1;
  }
};

const initialState = {
  error: null,
  status: null,
  showFilterModal: false,
  showExportModal: false,
  showApkDetailsModal: false,
  showEditModal: false,
  showInfoModal: false,
  isLoading: false,
  isExportLoading: false,
  showSearchInfoModal: false,
  showSyncOptionModal: false,
  ...regexParamsHandler(),
  queryString: QueryStringHandler(),
  pageNumber: pageNumberHandler(),
  ...dateParamsHandler(),
  additionalFilters: additionalParamsHandler(),
  totalPages: null,
  totalResults: null,
  apkResultsPerPage: 15,
  apkResultData: [],
  resultId: "",
  resultTitle: "",
  resultLink: "",
  resultJson: {},
  dumpedData: [],
  ...filterParamsHandler(),
  defaultData: {
    id: "",
    title: "",
    packageName: "",
    source: "",
    organizationName: "",
    commonName: "",
    classification: "",
    downloadLinks: "",
    indicators: "",
    md5: "",
    sha1: "",
    sha256: "",
    targetedBrands: "",
    targetedClients: "",
    createdAt: "",
    lastSeen: "",
    apkIcon: "",
    comment: "",
    cert_sha: "",
  },
  updateApkData: {
    targeted_clients: [],
    targeted_brands: [],
    comment: "",
    apk_id: "",
  },
};

function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  if (!date) {
    return "";
  }
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}

export const getApkData = createAsyncThunk("apk/data", () => {
  let stateData = store.getState();
  let resultsData = stateData.ApksResultsData;
  let isWhitelistes = resultsData.is_whitelisted;
  let cIds = resultsData.client_ids;
  let bIds = resultsData.brand_ids;

  // if (isWhitelistes) {
  // if (typeof isWhitelistes === "object") {
  //   isWhitelistes = isWhitelistes.toString();
  // }
  if (isWhitelistes === "") {
    isWhitelistes = null;
  }
  // }
  let dataToPost = {
    page_number: resultsData.pageNumber,
    results_per_page: resultsData.apkResultsPerPage,
    dump_all: false,
    query: resultsData.queryString,
    is_regex: resultsData.isRegex,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    client_ids: cIds,
    brand_ids: bIds,
    is_whitelisted: isWhitelistes,
    additional_filters: resultsData.additionalFilters,
  };

  return apiCall("/sw_apk/apk_feed", "post", dataToPost);
});

export const getResultsDump = createAsyncThunk("apk/dump", () => {
  let stateData = store.getState();
  let resultsData = stateData.ApksResultsData;
  let isWhitelistes = resultsData.is_whitelisted;
  let cIds = resultsData.client_ids;
  let bIds = resultsData.brand_ids;
  if (isWhitelistes === "") {
    isWhitelistes = null;
  }
  let dataToPost = {
    page_number: resultsData.pageNumber,
    results_per_page: resultsData.apkResultsPerPage,
    dump_all: true,
    query: resultsData.queryString,
    is_regex: resultsData.isRegex,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    client_ids: cIds,
    brand_ids: bIds,
    is_whitelisted: isWhitelistes,
    additional_filters: resultsData.additionalFilters,
  };

  return apiCall("/sw_apk/apk_feed", "post", dataToPost);
});

export const updateApkData = createAsyncThunk("apk/update", () => {
  let stateData = store.getState();
  let resultsData = stateData.ApksResultsData.updateApkData;
  let cIds = resultsData.targeted_clients;
  let bIds = resultsData.targeted_brands;

  let dataToPost = {
    targeted_clients: cIds,
    targeted_brands: bIds,
    comment: resultsData.comment,
    apk_id: resultsData.apk_id,
  };
  return apiCall("/sw_apk/update_document", "post", dataToPost);
});

export const getApkDataSkipLoad = createAsyncThunk("apk_load/data", () => {
  let stateData = store.getState();
  let resultsData = stateData.ApksResultsData;
  let isWhitelistes = resultsData.is_whitelisted;
  let cIds = resultsData.client_ids;
  let bIds = resultsData.brand_ids;

  // if (isWhitelistes) {
  // if (typeof isWhitelistes === "object") {
  //   isWhitelistes = isWhitelistes.toString();
  // }
  if (isWhitelistes === "") {
    isWhitelistes = null;
  }
  // }
  let dataToPost = {
    page_number: resultsData.pageNumber,
    results_per_page: resultsData.apkResultsPerPage,
    dump_all: false,
    query: resultsData.queryString,
    is_regex: resultsData.isRegex,
    from_date: formatDateToDDMMYYYY(resultsData.fromDate),
    to_date: formatDateToDDMMYYYY(resultsData.toDate),
    client_ids: cIds,
    brand_ids: bIds,
    is_whitelisted: isWhitelistes,
    additional_filters: resultsData.additionalFilters,
  };

  return apiCall("/sw_apk/apk_feed", "post", dataToPost);
});

export const ApksResultsDataReducer = createSlice({
  name: "ApksResultsData",
  initialState,
  reducers: {
    toggleFilterModal: (state) => {
      state.showFilterModal = !state.showFilterModal;
    },
    toggleExportModal: (state) => {
      state.showExportModal = !state.showExportModal;
    },
    toggleApkDetailsModal: (state) => {
      state.showApkDetailsModal = !state.showApkDetailsModal;
    },
    setDefaultValue: (state, action) => {
      state.defaultData.id = action.payload.id;
      state.defaultData.title = action.payload.title;
      state.defaultData.packageName = action.payload.packageName;
      state.defaultData.source = action.payload.source;
      state.defaultData.organizationName = action.payload.organizationName;
      state.defaultData.commonName = action.payload.commonName;
      state.defaultData.classification = action.payload.classification;
      state.defaultData.downloadLinks = action.payload.downloadLinks;
      state.defaultData.indicators = action.payload.indicators;
      state.defaultData.md5 = action.payload.md5;
      state.defaultData.sha1 = action.payload.sha1;
      state.defaultData.sha256 = action.payload.sha256;
      state.defaultData.targetedBrands = action.payload.targetedBrands;
      state.defaultData.targetedClients = action.payload.targetedClients;
      state.defaultData.createdAt = action.payload.createdAt;
      state.defaultData.lastSeen = action.payload.lastSeen;
      state.defaultData.apkIcon = action.payload.apkIcon;
      state.defaultData.comment = action.payload.comment;
      state.defaultData.cert_sha = action.payload.cert_sha;
    },
    toggleShowEditModal: (state) => {
      state.showEditModal = !state.showEditModal;
    },
    updatePageNumberApkResults: (state, action) => {
      if (action.payload < 1 || action.payload > state.totalPages) {
        return;
      }
      state.pageNumber = action.payload;
    },
    updateApkResultsPerPage: (state, action) => {
      state.apkResultsPerPage = action.payload;
    },
    toggleInfoModal: (state) => {
      state.showInfoModal = !state.showInfoModal;
    },
    setInfoResult: (state, action) => {
      state.resultId = action.payload.resultId;
      state.resultTitle = action.payload.resultTitle;
      state.resultLink = action.payload.resultLink;
      state.resultJson = action.payload.resultJson;
    },
    clearDataDump: (state) => {
      state.dumpedData = [];
      state.isExportLoading = false;
    },
    exportVisibleData: (state) => {
      state.dumpedData = state.apkResultData;
    },
    updateQueryStrings: (state, action) => {
      state.queryString = action.payload;
    },
    toggleSearchInfoModal: (state) => {
      state.showSearchInfoModal = !state.showSearchInfoModal;
    },
    toggleIsRegix: (state) => {
      state.isRegex = !state.isRegex;
    },
    toggleIsRegixApkResults: (state) => {
      state.isRegex = false;
    },
    updateDateFilterApk: (state, action) => {
      state.fromDate = action.payload.selection.startDate;
      state.toDate = action.payload.selection.endDate;
    },
    updateErrorApkResults: (state) => {
      state.error = null;
    },
    updateClientFilters: (state, action) => {
      state.client_ids = action.payload;
    },
    updateBrandFilters: (state, action) => {
      state.brand_ids = action.payload;
    },
    updateIndicatorFilters: (state, action) => {
      state.is_whitelisted = action.payload;
    },
    clearFiltersApk: (state, action) => {
      state.brand_ids = [];
      state.client_ids = [];
      state.is_whitelisted = "";
    },
    updateExistingApkData: (state, action) => {
      state.updateApkData = { ...state.updateApkData, ...action.payload };
    },
    toggleShowEditModalFalse: (state) => {
      state.showEditModal = false;
    },
    changeApkEditStatus: (state, action) => {
      state.status = action.payload;
    },
    addAdditionalFiltersApk: (state, action) => {
      state.additionalFilters = {
        ...state.additionalFilters,
        ...action.payload,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getApkData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getApkData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apkResultData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalResults = action.payload.data.data.total_results;
      })
      .addCase(getApkData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(getResultsDump.pending, (state) => {
        state.isExportLoading = true;
        state.dumpedData = {};
      })
      .addCase(getResultsDump.fulfilled, (state, action) => {
        state.isExportLoading = false;
        state.dumpedData = action.payload.data.data.page_results;
      })
      .addCase(getResultsDump.rejected, (state, action) => {
        state.isExportLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateApkData.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(updateApkData.fulfilled, (state, action) => {
        state.status = "SUCCESS";
        // state.isLoading = false;
        state.updateApkData = initialState.updateApkData;
      })
      .addCase(updateApkData.rejected, (state, action) => {
        // state.isLoading = false;
        state.error = action.error.message;
        state.updateApkData = initialState.updateApkData;
      })
      .addCase(getApkDataSkipLoad.pending, (state) => {})
      .addCase(getApkDataSkipLoad.fulfilled, (state, action) => {
        state.apkResultData = action.payload.data.data.page_results;
        state.totalPages = action.payload.data.data.total_pages;
        state.totalResults = action.payload.data.data.total_results;
      })
      .addCase(getApkDataSkipLoad.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleFilterModal,
  toggleExportModal,
  toggleApkDetailsModal,
  setDefaultValue,
  toggleShowEditModal,
  updatePageNumberApkResults,
  updateApkResultsPerPage,
  toggleInfoModal,
  setInfoResult,
  clearDataDump,
  exportVisibleData,
  updateQueryStrings,
  toggleSearchInfoModal,
  toggleIsRegix,
  toggleIsRegixApkResults,
  updateDateFilterApk,
  updateErrorApkResults,
  updateClientFilters,
  updateBrandFilters,
  clearFiltersApk,
  updateIndicatorFilters,
  updateExistingApkData,
  toggleShowEditModalFalse,
  changeApkEditStatus,
  addAdditionalFiltersApk,
} = ApksResultsDataReducer.actions;

export default ApksResultsDataReducer.reducer;
