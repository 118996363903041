import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFileUpload } from "reduxConfig/slices/uploadApkSlice";
import { updateNewFileUpload } from "reduxConfig/slices/uploadApkSlice";
import { toggleFileUploadModal } from "reduxConfig/slices/uploadApkSlice";
import ClientSelect from "./ClientSelect";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import BrandSelect from "./BrandSelect";
import { clearNewFileUpload } from "reduxConfig/slices/uploadApkSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import SourceTagInput from "./SourceTagInput";

function FileUploadModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);

  const checkClientSelect = useSelector(
    (state) => state.UploadApkData.fileUpload.client_id
  );
  const checkfileSelect = useSelector(
    (state) => state.UploadApkData.fileUpload.apk_file
  );
  const checksourceSelect = useSelector(
    (state) => state.UploadApkData.fileUpload.discoverd_source
  );
  const checksampleTypeSelect = useSelector(
    (state) => state.UploadApkData.fileUpload.is_official
  );

  const fileData = useSelector((state) => state.UploadApkData.fileUpload);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleFileUploadModal());
      dispatch(clearNewFileUpload());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  function handleFileChange(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      dispatch(
        updateNewFileUpload({
          apk_file: selectedFile,
        })
      );
    } else {
    }
  }

  const handleUpload = () => {
    // Validate required fields
    if (
      checkClientSelect.length === 0 ||
      !checkfileSelect ||
      checksourceSelect.length === 0 ||
      !checksampleTypeSelect
    ) {
      setValidationError(true);

      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    // Perform the upload
    dispatch(toggleFileUploadModal());
    dispatch(createFileUpload());
  };

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 flex-col justify-center ">
        <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
          <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
            <div className="flex items-center justify-center text-2xl font-semibold text-[#000000]">
              File Upload
            </div>

            <div className="mt-3 ">
              <div className="mb-1 flex items-center">
                <label className="text-xl font-semibold" htmlFor="fileInput">
                  Choose File
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can upload</br>a apk file from you have"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <input
                type="file"
                id="fileInput"
                accept=".apk"
                className="w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
                onChange={(e) => handleFileChange(e)}
              />
            </div>

            <div className="mt-3">
              <div className="mb-1 flex items-center">
                <label className="text-xl font-semibold" htmlFor="clientInput">
                  Client
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can assign</br>a client your apk"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <ClientSelect isFileupload={true} />
            </div>

            {checkClientSelect.length != 0 && (
              <div className="mt-3">
                <label className="text-xl font-semibold" htmlFor="brandInput">
                  Brand
                </label>
                <BrandSelect isFileupload={true} />
              </div>
            )}

            <div className="mt-3 ">
              <div className="mb-1 flex items-center">
                <label
                  className="text-xl font-semibold"
                  htmlFor="sourceTextarea"
                >
                  Source
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can add Source</br>for your apk files and you can paste </br> valued separated by comma,</br> and it will auto split after press enter"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <SourceTagInput/>
              {/* <textarea
                id="sourceTextarea"
                rows="4"
                className="max-h-40 w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
                onChange={(e) =>
                  dispatch(
                    updateNewFileUpload({
                      discoverd_source: e.target.value,
                    })
                  )
                }
              /> */}
            </div>

            <div className="mt-3 ">
              <div className="mb-1 flex items-center">
                <label className="text-xl font-semibold" htmlFor="sampleSelect">
                  Type of Sample
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can select a apk file</br>is Offical or Not Offical"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <select
                onChange={(e) =>
                  dispatch(
                    updateNewFileUpload({
                      is_official: e.target.value,
                    })
                  )
                }
                id="sampleSelect"
                className="w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
              >
                <option value="NONE">Unknown</option>
                <option value="WHITELISTED">Official App</option>
                <option value="THREAT">Not Official App</option>
              </select>
            </div>

            <div class="mt-3">
              <label class="flex items-center text-xl font-semibold">
                Description
                <div className="text-base font-normal">
                  <a
                    data-tooltip-id="my-tooltip-desc"
                    data-tooltip-html="Here you can add a</br> Description for your apk"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" ml-1 h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-desc" />
                </div>
              </label>
              <textarea
                class="max-h-40 w-full border border-[#000000] px-4 py-2 text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder="Add Description"
                value={fileData.description}
                onChange={(e) =>
                  dispatch(
                    updateNewFileUpload({
                      description: e.target.value,
                    })
                  )
                }
              />
            </div>

            <div className="flex items-center justify-center space-x-4 pt-4">
              <button
                className="flex w-1/4 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white focus:outline-none"
                onClick={(e) => {
                  handleUpload();
                }}
              >
                Upload
              </button>
              <button
                className="flex w-1/4 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
                onClick={(e) => {
                  dispatch(toggleFileUploadModal());
                  dispatch(clearNewFileUpload());
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileUploadModal;
