import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import apiCall from "axiosConfig/axiosConfig";
import cronstrue from "cronstrue";
import { AiOutlineCopy } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { AiOutlinePieChart, AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { toggleRuleModal } from "reduxConfig/slices/rulesSlice";
import { updateExistingRule } from "reduxConfig/slices/rulesSlice";
import { updateAlert, toggleAlert } from "reduxConfig/slices/alertSlice";
import {
  toggleStatChartModal,
  updateStatChart,
} from "reduxConfig/slices/rulesSlice";
import slackIcon from "assets/img/slackIcon.png";
import loadingIcon from "assets/svg/loading.svg";
import { toggleShowDeleteModal } from "reduxConfig/slices/rulesSlice";
import { updateDeleteData } from "reduxConfig/slices/rulesSlice";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { updateIsLoadingRules } from "reduxConfig/slices/rulesSlice";
import { toggleDateRunModal } from "reduxConfig/slices/rulesSlice";
import { updateRunDate } from "reduxConfig/slices/rulesSlice";
import getIstTime from "./helpers/istConvertor";
import { getRulesDataSkipLoad } from "reduxConfig/slices/rulesSlice";
import { getRulesData } from "reduxConfig/slices/rulesSlice";

const RulesCard = ({ rulesData }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [toggle, setToggle] = useState(rulesData.active);
  const brandData = useSelector((state) => state.brandsData.brandData);
  const dispatch = useDispatch();
  const [isRuleRunning, _] = useState(
    rulesData?.task_status?.current_status === "RUNNING"
  );
  const [showRunNowModal, toggleRunNowModal] = useState(false);
  const dropdownRef = useRef(null);
  const toggleRules = (toggleValue, ruleId) => {
    apiCall(`/sw_query/${ruleId}`, "put", {
      active: toggleValue,
    })
      .then((response) => {
        dispatch(
          updateAlert(
            toggleValue
              ? { alertText: "Rule Enable", alertType: "Success" }
              : { alertText: "Rule Disable", alertType: "Success" }
          )
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);
        dispatch(getRulesDataSkipLoad());
      })
      .catch((error) => {
        dispatch(
          updateAlert(toggleValue({ alertText: "Error", alertType: "Error" }))
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);
        dispatch(getRulesDataSkipLoad());
      });
  };
  // const utcToIst = (utcTimestamp) => {
  //   const [dateStr, timeStr] = utcTimestamp.split(" ");
  //   const [year, month, day] = dateStr.split("-");
  //   const [hour, minute, second] = timeStr.split(":");
  //   // Create a new Date object in UTC
  //   const utcDate = new Date(
  //     Date.UTC(year, month - 1, day, hour, minute, second)
  //   );
  //   // Convert UTC time to IST
  //   utcDate.setUTCHours(utcDate.getUTCHours() + 5);
  //   utcDate.setUTCMinutes(utcDate.getUTCMinutes() + 30);
  //   // Format the IST date and time
  //   const istFormatted = utcDate
  //     .toISOString()
  //     .replace("T", " ")
  //     .replace("Z", " IST");
  //   return istFormatted;
  // };
  const handleDateSelection = (selectedDate) => {
    const formattedDate = format(selectedDate, "MM/dd/yyyy");
    dispatch(toggleDateRunModal());
    dispatch(
      updateRunDate({
        runDate: formattedDate,
        query_id: rulesData._id,
      })
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleRunNowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "Brand Deleted";
  };
  const getBrandDetails = (brand_id) => {
    const brandDetails = brandData.find((element) => element._id == brand_id);
    if (brandDetails) {
      return {
        value: brandDetails._id,
        label: brandDetails.brand_name,
      };
    }
    return { value: "Brand Deleted", label: "Brand Deleted" };
  };

  return (
    <div class="flex  flex-col items-center justify-center">
      <div class="!z-5 3xl:p-![18px] undefined relative mb-10  flex w-[25vw] max-w-[25vw] flex-col  rounded-[20px] bg-white bg-clip-border !p-4 shadow-3xl shadow-shadow-500">
        <div>
          <div className="">
            <a
              data-tooltip-id="my-tooltip-title"
              data-tooltip-html="Turn on the toggle button to initiate the periodic execution of the rule."
              data-tooltip-place="top"
            >
              <button className="absolute top-1 left-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer">
                <div className="flex h-full w-full items-center justify-center rounded-full text-xl hover:bg-gray-50">
                  <label className="relative inline-flex cursor-pointer items-center">
                    <input
                      type="checkbox"
                      value=""
                      className="peer sr-only"
                      checked={toggle}
                      onChange={() => {
                        toggleRules(!toggle, rulesData._id);
                        setToggle(!toggle);
                      }}
                    />
                    <div className="peer h-6 w-10 rounded-full border-2 bg-gray-200 after:absolute after:top-[2px] after:left-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-[#4318FF] peer-checked:after:translate-x-full peer-checked:after:border-2 peer-checked:after:border-[#4318FF] peer-focus:outline-none"></div>
                  </label>
                </div>
              </button>
            </a>
            <Tooltip className="z-10" id="my-tooltip-title" />
          </div>
          <div className="mt-5 flex items-center justify-end">
            <div className="flex w-[85%] items-center justify-center">
              <a
                data-tooltip-id="my-tooltip-title"
                data-tooltip-html="Turn on the Slack icon to receive alerts after the rule is being executed."
                data-tooltip-place="top"
              >
                <img
                  onClick={(e) => {
                    apiCall(`/sw_query/toggle_slack_alert`, "post", {
                      query_id: rulesData._id,
                      set_as: !rulesData.slack_config.notification_enabled,
                    })
                      .then((response) => {
                        dispatch(
                          updateAlert(
                            rulesData?.slack_config?.notification_enabled
                              ? {
                                  alertText: "Slack Disable",
                                  alertType: "Success",
                                }
                              : {
                                  alertText: "Slack Enable",
                                  alertType: "Success",
                                }
                          )
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                        dispatch(getRulesDataSkipLoad());
                      })
                      .catch((error) => {
                        dispatch(
                          updateAlert({
                            alertText: "Error on slack notificaton update",
                            alertType: "Error",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                        dispatch(getRulesDataSkipLoad());
                      });
                  }}
                  src={slackIcon}
                  className={`h-5 w-5 cursor-pointer ${
                    rulesData.slack_config.notification_enabled
                      ? ""
                      : "grayscale"
                  }`}
                  alt="logo"
                />
              </a>
              <Tooltip className="z-10" id="my-tooltip-title" />
              <p
                className="hide-scrollbar whitespace-nowrap p-2 text-center text-xl font-bold text-navy-700"
                style={{
                  maxWidth: "80%",
                  overflow:
                    rulesData.title && rulesData.title.length > 15
                      ? "auto "
                      : "hidden",
                }}
              >
                {rulesData.title ? rulesData.title.toUpperCase() : "No Name"}
              </p>
            </div>
            <div
              className="cursor-pointer"
              onClick={() => {
                const brandIds = Object.values(rulesData.brand_ids).map((obj) =>
                  getBrandDetails(obj)
                );

                dispatch(
                  updateExistingRule({
                    ruleId: rulesData._id,
                    brandIds,
                    queryStrings: rulesData.query_strings,
                    cronExpression: rulesData.cron_value,
                    description: rulesData.description,
                    searchType: rulesData.search_params.search_type,
                    title: rulesData.title,
                    slack_web_hook: rulesData.slack_config.slack_web_hook_url,
                    auto_assign: rulesData.auto_assign,
                    toggle: rulesData.active,
                  })
                );

                dispatch(toggleRuleModal());
              }}
            >
              <FiEdit className="h-8 w-8 rounded border border-[#4318FF] p-[4px] text-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
            </div>
          </div>

          <div class="text-md m-0 flex justify-center p-2 text-center font-medium text-gray-800">
            <CopyToClipboard
              text={rulesData._id}
              onCopy={() => {
                dispatch(
                  updateAlert({
                    alertText: "Rule ID Copied",
                    alertType: "Success",
                  })
                );
                setTimeout(() => {
                  dispatch(toggleAlert());
                }, 2000);
              }}
            >
              <span className="flex cursor-grabbing">
                {" "}
                {rulesData._id}
                <AiOutlineCopy
                  className="m-0 h-6 w-6 rounded-md  p-[2px]"
                  color="#4318FF"
                />
              </span>
            </CopyToClipboard>
          </div>
        </div>

        <div class="h-full w-full">
          <div class="relative flex w-full justify-center bg-[#ADB7D5] p-2">
            {isRuleRunning ? (
              <>
                {" "}
                <span className="flex items-center justify-center font-bold">
                  {" "}
                  Rule is running{" "}
                </span>
                <img
                  src={loadingIcon}
                  className={`h-[150px] w-[150px] items-center rounded-xl`}
                  alt="logo"
                />
              </>
            ) : (
              <>
                {" "}
                <img
                  src="https://images.unsplash.com/photo-1553835973-dec43bfddbeb?ixlib=rb-4.0.3"
                  class="h-[150px] w-[150px] items-center rounded-xl"
                  alt=""
                />
              </>
            )}
          </div>
          <div className="p-1 text-left font-semibold">Targeted Brands:</div>

          {rulesData?.brand_ids?.length ? (
            <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
              {Object.values(rulesData.brand_ids).map((brand_id) => (
                <div
                  class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {getBrandName(brand_id)}
                </div>
              ))}
            </div>
          ) : (
            <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
              <div class="text-#000000 ml-1  h-[35%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
                Brand Not Selected
              </div>
            </div>
          )}
          <div className="flex justify-between">
            <div className="p-1 text-left font-semibold">Search Strings:</div>
            <div>
              <CopyToClipboard
                text={
                  rulesData?.query_strings?.length
                    ? rulesData.query_strings.join("\n") // Join array elements with new lines
                    : "None"
                }
                onCopy={() => {
                  dispatch(
                    updateAlert({
                      alertText: "Search Strings Copied",
                      alertType: "Success",
                    })
                  );
                  setTimeout(() => {
                    dispatch(toggleAlert());
                  }, 2000);
                }}
              >
                <AiOutlineCopy
                  className="m-0 h-6 w-6 cursor-grabbing p-[2px]"
                  color="#4318FF"
                />
              </CopyToClipboard>
            </div>
          </div>

          <div
            className="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap justify-start overflow-auto"
            style={{ scrollbarWidth: "thin" }}
          >
            {rulesData.query_strings.map((keyword, index) => (
              <div
                key={index}
                className={`mx-1 flex h-full items-center break-all rounded-lg border-2 border-[#4318FF] px-[10px] py-[1px] text-center text-sm text-[#4318FF]`}
                style={{ marginBottom: "5px" }}
              >
                {keyword}
              </div>
            ))}
          </div>

          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Description:</div>
                <div class="flex max-h-[4em] min-h-[4em] w-full flex-row flex-wrap justify-start overflow-auto break-all">
                  {rulesData.description
                    ? rulesData.description
                    : "No descritpion"}
                </div>
              </dl>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div class=" flex flex-col items-center justify-between px-1 md:items-start">
              <div class="">
                <dl class="grid grid-cols-2 gap-2">
                  <div class=" font-semibold">Cron Value:</div>
                  <div class="text-gray-800">{rulesData.cron_value}</div>
                </dl>
                <div className="text-sm italic text-[#9A9A9A]">
                  {cronstrue.toString(rulesData.cron_value)}
                </div>
              </div>
            </div>
          </div>

          <div className="mx-1 flex  justify-start">
            <div>
              <div className="flex justify-start">
                {" "}
                <p class=" text-[#0000000] text-left font-semibold">
                  Last Run:
                </p>
              </div>

              <div className="text-[12px] text-[#9A9A9A]">
                {rulesData.last_run
                  ? getIstTime(rulesData.last_run)
                  : "Not Yet Run"}{" "}
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <div>
              <div className="p-1 text-left font-semibold">Search type:</div>
              <div class="mx-1 flex w-full flex-row flex-wrap overflow-auto">
                {rulesData.search_params.search_type
                  ? rulesData.search_params.search_type.replaceAll("_", " ")
                  : "None"}
              </div>
            </div>
            <div
              onClick={() => {
                dispatch(updateIsLoadingRules(true));
                apiCall(`/sw_query/rule_stats/${rulesData._id}`, "get")
                  .then((response) => {
                    // if (response.status === 200) {
                    dispatch(updateIsLoadingRules(false));
                    dispatch(
                      updateStatChart({
                        description: rulesData.title,
                        id: rulesData._id,
                        links: response.data.data.total_urls,
                        threatCount: response.data.data.threat_count,
                        whitelistedCount: response.data.data.whitelisted_count,
                        noOfApk: response.data.data.apk_count,
                        noOfPhishingDomains: response.data.data.domain_count,
                        noOfUnknowns: response.data.data.unknown_count,
                      })
                    );
                    dispatch(toggleStatChartModal());
                    // } else {
                    //   dispatch(updateIsLoadingRules(true));
                    // }
                  })
                  .catch((error) => {
                    dispatch(updateIsLoadingRules(false));
                    dispatch(
                      updateAlert({
                        alertText: error?.response?.data?.error
                          ? error.response.data.error
                          : "Error on Stat check",
                        alertType: "Error",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  });
              }}
            >
              <a
                data-tooltip-id="my-tooltip-title"
                data-tooltip-html="Click the stats icon to view the statistics of the rule."
                data-tooltip-place="top"
              >
                <AiOutlinePieChart className="m-3 h-10 w-10 cursor-pointer rounded-md border border-[#4318FF]  p-[2px] text-[#4318FF] hover:bg-[#4318FF] hover:text-white" />
              </a>
              <Tooltip className="z-10" id="my-tooltip-title" />
            </div>
          </div>

          <div
            className={`flex ${
              isRuleRunning ? "justify-end" : "justify-between"
            }`}
          >
            {isRuleRunning ? (
              <></>
            ) : (
              <div>
                <AiOutlineDelete
                  data-modal-target="popup-modal"
                  data-modal-toggle="popup-modal"
                  type="button"
                  className="m-0 h-10 w-10 cursor-pointer  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#ff0000] hover:bg-[#ff0000] hover:text-white"
                  onClick={(e) => {
                    dispatch(
                      updateDeleteData({
                        title: rulesData.title,
                        ruleId: rulesData._id,
                      })
                    );
                    dispatch(toggleShowDeleteModal());
                  }}
                />
              </div>
            )}

            <div className="flex">
              {isRuleRunning ? (
                <></>
              ) : (
                <button
                  href=""
                  class="linear ml-2 mr-2 rounded-[10px]  border border-[#4318FF] px-4 py-2 text-base font-bold text-[#4318FF]  transition duration-200 hover:bg-[#4318FF] hover:text-white"
                  onClick={() => {
                    window.open(
                      `/admin/urls_results?query=rule:"${rulesData._id}"`,
                      "_blank"
                    );
                  }}
                >
                  Results
                </button>
              )}

              {isRuleRunning ? (
                <button
                  href=""
                  class="linear ml-2 items-center rounded-[10px] border border-[#4318FF] px-4 py-2 text-base font-medium text-[#4318FF] transition duration-200 hover:bg-[#4318FF] hover:text-white active:bg-[#4318FF]"
                  onClick={() => {
                    apiCall(
                      `/sw_tasks/stop_task/${rulesData.task_status._id}`,
                      "get",
                      {}
                    )
                      .then((response) => {
                        dispatch(
                          updateAlert({
                            alertText: "Stop Executed",
                            alertType: "Success",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                        dispatch(getRulesData());
                      })
                      .catch((error) => {
                        dispatch(
                          updateAlert({
                            alertText: "Error on stop rule",
                            alertType: "Error",
                          })
                        );
                        setTimeout(() => {
                          dispatch(toggleAlert());
                        }, 2000);
                        dispatch(getRulesData());
                      });
                  }}
                >
                  Stop Now
                </button>
              ) : (
                <>
                  {rulesData?.search_params?.search_type ==
                  "GOOGLE_PLAYSTORE" ? (
                    <>
                      <button
                        class="inline-flex items-center rounded-[10px]  border border-[#4318FF] px-4 py-2.5 text-center text-sm  font-bold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                        type="button"
                        data-dropdown-toggle="dropdown"
                        onClick={() => {
                          apiCall(`/sw_tasks/run_now`, "post", {
                            query_id: rulesData._id,
                          })
                            .then((response) => {
                              dispatch(
                                updateAlert({
                                  alertText: "Run Executed",
                                  alertType: "Success",
                                })
                              );
                              setTimeout(() => {
                                dispatch(toggleAlert());
                              }, 2000);
                              dispatch(getRulesData());
                            })
                            .catch((error) => {
                              dispatch(
                                updateAlert({
                                  alertText: "Error on run rule",
                                  alertType: "Error",
                                })
                              );
                              setTimeout(() => {
                                dispatch(toggleAlert());
                              }, 2000);
                              dispatch(getRulesData());
                            });
                        }}
                      >
                        Run{" "}
                      </button>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div class="">
                        <button
                          class="inline-flex items-center rounded-[10px]  border border-[#4318FF] px-4 py-2.5 text-center text-sm  font-bold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                          type="button"
                          data-dropdown-toggle="dropdown"
                          onClick={(e) => {
                            toggleRunNowModal(!showRunNowModal);
                          }}
                        >
                          Run{" "}
                          <svg
                            class={`ml-2 h-4 w-4 ${
                              showRunNowModal ? "rotate-180" : "rotate-0"
                            }`}
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M19 9l-7 7-7-7"
                            ></path>
                          </svg>
                        </button>

                        <div
                          class={
                            `absolute z-20 list-none  items-center divide-y divide-gray-100 rounded bg-white text-base shadow` +
                            (showRunNowModal ? "" : " hidden")
                          }
                          ref={dropdownRef}
                          id="dropdown"
                        >
                          <ul class="py-1" aria-labelledby="dropdown">
                            <li>
                              <a
                                href="#"
                                class="block rounded-[10px] border border-[#4318FF] px-4 py-2 text-sm text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                                onClick={() => {
                                  apiCall(`/sw_tasks/run_now`, "post", {
                                    query_id: rulesData._id,
                                    timeline: false,
                                  })
                                    .then((response) => {
                                      dispatch(
                                        updateAlert({
                                          alertText: "Last Run Executed",
                                          alertType: "Success",
                                        })
                                      );
                                      setTimeout(() => {
                                        dispatch(toggleAlert());
                                      }, 2000);
                                      dispatch(getRulesData());
                                    })
                                    .catch((error) => {
                                      dispatch(
                                        updateAlert({
                                          alertText: "Error on run rule",
                                          alertType: "Error",
                                        })
                                      );
                                      setTimeout(() => {
                                        dispatch(toggleAlert());
                                      }, 2000);
                                      dispatch(getRulesData());
                                    });
                                }}
                              >
                                Last Run
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                class="block rounded-[10px] border border-[#4318FF] px-4 py-2 text-sm text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                                onClick={() => {
                                  apiCall(`/sw_tasks/run_now`, "post", {
                                    query_id: rulesData._id,
                                    timeline: true,
                                  })
                                    .then((response) => {
                                      dispatch(
                                        updateAlert({
                                          alertText: "Fresh Run Executed",
                                          alertType: "Success",
                                        })
                                      );
                                      setTimeout(() => {
                                        dispatch(toggleAlert());
                                      }, 2000);
                                      dispatch(getRulesData());
                                    })
                                    .catch((error) => {
                                      dispatch(
                                        updateAlert({
                                          alertText: "Error on run rule",
                                          alertType: "Error",
                                        })
                                      );
                                      setTimeout(() => {
                                        dispatch(toggleAlert());
                                      }, 2000);
                                      dispatch(getRulesData());
                                    });
                                }}
                              >
                                Fresh Run
                              </a>
                            </li>
                            <div class="">
                              <DatePicker
                                selected={selectedDate}
                                onChange={(date) => handleDateSelection(date)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={new Date()}
                                className="rounded-md border border-[#4318FF] px-4 py-2 text-sm text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                              />
                            </div>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RulesCard;
