import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updatePageNumber } from "reduxConfig/slices/resultSlice";
import { updateResultPerPage } from "reduxConfig/slices/resultSlice";
import { getSearchResults } from "reduxConfig/slices/resultSlice";

function ResultsPerPage() {
  const noOfResults = useSelector((state) => state.resultsData.resultsPerPage);
  const dispatch = useDispatch();
  return (
    <div className="-mt-2 flex">
      <div className="flex items-center text-center">
        <div className="w-[8vw] text-center font-bold text-[#4318FF]">
          Results per page
        </div>
      </div>
      <div className="flex items-center text-center">
        <select
          id="default"
          class=" block h-8 w-[60px] cursor-pointer items-center rounded-md  border-2 border-[#4318FF] bg-gray-50 text-center text-sm text-[#4318FF] outline-none hover:bg-[#4318FF] hover:text-white"
          value={noOfResults}
          onChange={(e) => {
            dispatch(updateResultPerPage(e.target.value));
            dispatch(updatePageNumber(1));
            dispatch(getSearchResults());
          }}
        >
          <option value="15">15</option>
          <option value="45">45</option>
          <option value="90">90</option>
        </select>
      </div>
    </div>
  );
}

export default ResultsPerPage;
