import apiCall from "axiosConfig/axiosConfig";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { getRulesData } from "reduxConfig/slices/rulesSlice";
import { toggleDateRunModal } from "reduxConfig/slices/rulesSlice";

function DateRunModal() {
  const dispatch = useDispatch();
  const dialogBoxRef = useRef(null);

  const selectedDate = useSelector(
    (state) => state.rulesData.updateRunOption.runDate
  );
  const query_id = useSelector(
    (state) => state.rulesData.updateRunOption.query_id
  );

  const handleClickOutside = (e) => {
    if (dialogBoxRef.current && !dialogBoxRef.current.contains(e.target)) {
      dispatch(toggleDateRunModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div class="fixed top-96 z-10">
      <div
        class={`relative rounded-lg bg-white shadow dark:bg-gray-700`}
        // style={{ display: showBrandDeleteModal ? "" : "none" }}
      >
        <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
          <div class="absolute top-0 left-[37%] z-20 mt-20 h-full w-full flex-col justify-center py-6 sm:py-12">
            <div
              class="fixed top-60 rounded-lg border bg-white p-6 text-center"
              ref={dialogBoxRef}
            >
              <h3 class="mb-5 font-normal text-[#000000]">
                Are you sure you want to run the rule from
                <spam className="font-bold"> {selectedDate} </spam>
                to the current date?
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                class="mr-2 inline-flex items-center rounded-lg bg-[#4318FF] px-5 py-2.5 text-center text-sm font-medium text-white"
                onClick={() => {
                  dispatch(toggleDateRunModal());
                  apiCall(`/sw_tasks/run_now`, "post", {
                    query_id: query_id,
                    timeline: selectedDate,
                  })
                    .then((response) => {
                      dispatch(
                        updateAlert({
                          alertText: "Date Run Executed",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                      dispatch(getRulesData());
                    })
                    .catch((error) => {
                      dispatch(
                        updateAlert({
                          alertText: "Error on run rule",
                          alertType: "Error",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                      dispatch(getRulesData());
                    });
                }}
              >
                Yes
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                class="rounded-lg border border-[#4318FF] bg-white px-5 py-2.5 text-sm font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={(e) => {
                  dispatch(toggleDateRunModal());
                }}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateRunModal;
