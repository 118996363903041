import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleDriveUploadModal } from "reduxConfig/slices/uploadApkSlice";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import { updateNewDriveUpload } from "reduxConfig/slices/uploadApkSlice";
import ClientSelect from "./ClientSelect";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { clearNewDriveUpload } from "reduxConfig/slices/uploadApkSlice";
import { BsQuestion } from "react-icons/bs";
import BrandSelect from "./BrandSelect";
import { createDriveUpload } from "reduxConfig/slices/uploadApkSlice";
import TagInput from "./TagInput";
import apiCall from "axiosConfig/axiosConfig";

function DriveUploadModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);

  const folderId = useSelector(
    (state) => state.UploadApkData.driveUpload.folder_id
  );
  const workSheetName = useSelector(
    (state) => state.UploadApkData.driveUpload.worksheet_name
  );
  const spreadSheetName = useSelector(
    (state) => state.UploadApkData.driveUpload.spreadsheet_name
  );
  const Client = useSelector(
    (state) => state.UploadApkData.driveUpload.client_id
  );
  const vtHashes = useSelector(
    (state) => state.UploadApkData.driveUpload.vtHashes
  );

  const driveData = useSelector((state) => state.UploadApkData.driveUpload);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleDriveUploadModal());
      dispatch(clearNewDriveUpload());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleUpload = () => {
    // Validate required fields
    if (
      !folderId ||
      !workSheetName ||
      !spreadSheetName ||
      Client.length === 0
    ) {
      setValidationError(true);

      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    // Perform the upload
    dispatch(toggleDriveUploadModal());
    dispatch(createDriveUpload());
  };

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 flex-col justify-center ">
        <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
          <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
            <div className="flex items-center justify-center text-2xl font-semibold text-[#000000]">
              Drive Upload
            </div>

            <div class="flex flex-col">
              <label class="flex items-center text-xl font-semibold leading-loose">
                Google Drive - Folder ID
                <sup className="text-red-500">&#8727;</sup>
                <div className="text-base font-normal">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Here you can add a google drive - Folder ID </br> & Click The Check Button To verify ID </br> Is Valid or Invalid"
                    data-tooltip-place="right"
                  >
                    <AiOutlineInfoCircle className="ml-1 h-6 w-6  cursor-pointer rounded-md p-[2px] text-[#4318FF]" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </label>
              <div className="flex justify-between">
                <input
                  type="text"
                  class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  placeholder="Add Folder ID"
                  value={folderId}
                  onChange={(e) =>
                    dispatch(
                      updateNewDriveUpload({
                        folder_id: e.target.value,
                      })
                    )
                  }
                />
                {folderId !== null && folderId !== "" && (
                  <button
                    className="flex w-20 items-center justify-center border  border-[#4318FF] bg-[#4318FF] text-white sm:text-sm "
                    onClick={(e) => {
                      apiCall(`/sw_misc/check_drive/${folderId}`, "get", {})
                        .then((response) => {
                          dispatch(
                            updateAlert({
                              alertText: response.data.message,
                              alertType: "Success",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        })
                        .catch((error) => {
                          dispatch(
                            updateAlert({
                              alertText: error.response.data.error,
                              alertType: "Error",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        });
                    }}
                  >
                    check
                  </button>
                )}
              </div>
            </div>

            <div class="flex flex-col">
              <label class="flex items-center text-xl font-semibold leading-loose">
                Google Sheet - Spreadsheet Name
                <sup className="text-red-500">&#8727;</sup>
                <div className="text-base font-normal">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Here you can add a google sheet - Spreadsheet Name </br> & Click The Check Button In  WorkSheet </br> Input box  To Verify"
                    data-tooltip-place="right"
                  >
                    <AiOutlineInfoCircle className="ml-1 h-6 w-6  cursor-pointer rounded-md p-[2px] text-[#4318FF]" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </label>
              <div className="flex justify-between">
                <input
                  type="text"
                  class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  placeholder="Add Folder Name"
                  value={spreadSheetName}
                  onChange={(e) =>
                    dispatch(
                      updateNewDriveUpload({
                        spreadsheet_name: e.target.value,
                      })
                    )
                  }
                />
                {/* {spreadSheetName !== null && spreadSheetName !== "" && (
                  <button
                    className="flex w-20 items-center justify-center border  border-[#4318FF] bg-[#4318FF] text-white sm:text-sm "
                    onClick={(e) => {
                      apiCall(`/sw_misc/test_webhook/`, "post", {
                        web_hook: ruleData.slack_web_hook,
                      })
                        .then((response) => {
                          dispatch(
                            updateAlert({
                              alertText: "Check Slack",
                              alertType: "Success",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        })
                        .catch((error) => {
                          dispatch(
                            updateAlert({
                              alertText: "Invalid URL",
                              alertType: "Error",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        });
                    }}
                  >
                    check
                  </button>
                )} */}
              </div>
            </div>

            <div class="flex flex-col">
              <label class="flex items-center text-xl font-semibold leading-loose">
                Google Sheet - Worksheet Name
                <sup className="text-red-500">&#8727;</sup>
                <div className="text-base font-normal">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Here you can add a google sheet - Worksheet Name </br> & Click The Check Button To verify </br> SpreadSheet & WorkSheet"
                    data-tooltip-place="right"
                  >
                    <AiOutlineInfoCircle className="ml-1 h-6 w-6  cursor-pointer rounded-md p-[2px] text-[#4318FF]" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </label>
              <div className="flex justify-between">
                <input
                  type="text"
                  class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                  placeholder="Add Sheet Name"
                  value={workSheetName}
                  onChange={(e) =>
                    dispatch(
                      updateNewDriveUpload({
                        worksheet_name: e.target.value,
                      })
                    )
                  }
                />
                {workSheetName !== null && workSheetName !== "" && (
                  <button
                    className="flex w-20 items-center justify-center border  border-[#4318FF] bg-[#4318FF] text-white sm:text-sm "
                    onClick={(e) => {
                      apiCall(
                        `/sw_misc/check_sheet/${spreadSheetName}/${workSheetName}`,
                        "get",
                        {}
                      )
                        .then((response) => {
                          dispatch(
                            updateAlert({
                              alertText: response.data.message,
                              alertType: "Success",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        })
                        .catch((error) => {
                          dispatch(
                            updateAlert({
                              alertText: error.response.data.error,
                              alertType: "Error",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                        });
                    }}
                  >
                    check
                  </button>
                )}
              </div>
            </div>

            <div className="mt-3">
              <div className="mb-1 flex items-center">
                <label
                  className="text-xl font-semibold"
                  htmlFor="sourceTextarea"
                >
                  VT Hashes
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can add VT Hashes</br>for your apk files and you can paste </br> valued separated by comma,</br> and it will auto split after press enter"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <TagInput />
              {/* <textarea
                id="sourceTextarea"
                rows="4"
                className="max-h-40 w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
                onChange={(e) =>
                  dispatch(
                    updateNewDriveUpload({
                      vtHashes: e.target.value,
                    })
                  )
                }
              /> */}
            </div>

            {/* <div className="mt-3">
            <label className="font-semibold" htmlFor="sampleSelect">
              Type of Sample:
            </label>
            <select
              id="sampleSelect"
              className="w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
            >
              <option value="UNKNOWN">Unknown</option>
              <option value="WHITELISTED">Whitelisted</option>
              <option value="THREAT">Threat</option>
            </select>
          </div> */}

            <div className="mt-3 ">
              <div className="mb-1 flex items-center">
                <label
                  className="flex items-center text-xl font-semibold"
                  htmlFor="clientInput"
                >
                  Client
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can assign a clients</br>for your apks"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <ClientSelect isFileupload={false} />
            </div>

            {Client.length != 0 && (
              <div className="mt-3">
                <label className="text-xl font-semibold" htmlFor="brandInput">
                  Brand
                </label>
                <BrandSelect isFileupload={false} />
              </div>
            )}

            <div class="mt-3">
              <label class="flex items-center text-xl font-semibold">
                Notes For Classifications
                <div className="text-base font-normal">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Here you can add </br> note for your Classifications"
                    data-tooltip-place="right"
                  >
                    <AiOutlineInfoCircle className="ml-1 h-6 w-6  cursor-pointer rounded-md p-[2px] text-[#4318FF]" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </label>
              <textarea
                class="max-h-40 w-full border border-[#000000] px-4 py-2 text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder="Add note"
                value={driveData.user_note}
                onChange={(e) =>
                  dispatch(
                    updateNewDriveUpload({
                      user_note: e.target.value,
                    })
                  )
                }
              />
            </div>

            <div className="flex items-center justify-center space-x-4 pt-4">
              <button
                className="flex w-1/4 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white focus:outline-none"
                onClick={(e) => {
                  handleUpload();
                }}
              >
                Process
              </button>
              <button
                className="flex w-1/4 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
                onClick={(e) => {
                  dispatch(toggleDriveUploadModal());
                  dispatch(clearNewDriveUpload());
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DriveUploadModal;
