import React from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updatePageNumberClientsBrands } from "reduxConfig/slices/brandSlice";
import { getBrandData } from "reduxConfig/slices/brandSlice";
import { updateBrandsPerPage } from "reduxConfig/slices/brandSlice";
function BrandsPerPage() {
  const noOfResults = useSelector((state) => state.brandsData.brandsPerPage);
  const dispatch = useDispatch();
  return (
    <div className="-mt-2 flex">
      <div className="flex items-center text-center">
        <div className="w-[8vw] text-center font-bold text-[#4318FF]">
          Results per page
        </div>
      </div>
      <div className="flex items-center text-center">
        <select
          id="default"
          class=" block h-8 w-[60px] cursor-pointer items-center rounded-md  border-2 border-[#4318FF] bg-gray-50 text-center text-sm text-[#4318FF] outline-none hover:bg-[#4318FF] hover:text-white"
          value={noOfResults}
          onChange={(e) => {
            dispatch(updateBrandsPerPage(e.target.value));
            dispatch(updatePageNumberClientsBrands(1));
            dispatch(getBrandData());
          }}
        >
          <option value="15">15</option>
          <option value="45">45</option>
          <option value="90">90</option>
        </select>
      </div>
    </div>
  );
}

export default BrandsPerPage;
