import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showEditModal } from "reduxConfig/slices/settingSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { toggleType } from "reduxConfig/slices/settingSlice";
import { updateExistingSlack } from "reduxConfig/slices/settingSlice";
import { EditSlackConfig } from "reduxConfig/slices/settingSlice";
import { clearDataSlackOld } from "reduxConfig/slices/settingSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import apiCall from "axiosConfig/axiosConfig";

const EditChannel = () => {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const defaultData = useSelector(
    (state) => state.settingsData.updateSlackData
  );
  const checkName = useSelector(
    (state) => state.settingsData.updateSlackData.credName
  );
  const checkUrl = useSelector(
    (state) => state.settingsData.updateSlackData.webHookUrl
  );
  const type = useSelector((state) => state.settingsData);

  // Add an event listener to detect clicks outside the modal
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        dispatch(clearDataSlackOld());
        dispatch(showEditModal());
      }
    }

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dispatch]);

  return (
    <div className="mt-1/4  fixed top-0 z-30 ">
      <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
        <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 mt-10 flex-col justify-center ">
          <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
            <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
              <div class="">
                <div class="flex items-center justify-center">
                  <h2 class="text-#000000 block  pl-2 text-4xl font-semibold">
                    Edit Channel
                  </h2>
                </div>
                <div class="">
                  <div class="text-#000000 space-y-2 py-8 text-base   leading-6 sm:text-lg sm:leading-7">
                    <div class="flex flex-col text-xl">
                      <div className="mt-3 flex items-center font-semibold">
                        Choose Type:
                        <div className="ml-1 text-lg font-normal">
                          <a
                            data-tooltip-id="my-tooltip-URLs"
                            data-tooltip-html="Here you can choose </br> the type is OAUTH </br> or WEBHOOK"
                            data-tooltip-place="right"
                          >
                            <BsQuestion
                              className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                              color="#4318FF"
                            />
                          </a>
                          <Tooltip className="z-10" id="my-tooltip-URLs" />
                        </div>
                      </div>
                      <div>
                        <select
                          value={defaultData.credtype}
                          onChange={(e) => {
                            dispatch(toggleType(e.target.value));
                            dispatch(
                              updateExistingSlack({
                                credtype: e.target.value,
                              })
                            );
                          }}
                          className="w-full rounded-md border-2 border-gray-500 px-4  py-2 text-[#000000] focus:outline-none"
                        >
                          <option value="OAUTH">OAUTH</option>
                          <option value="WEBHOOK">WEB HOOK</option>
                        </select>
                      </div>

                      <label class="flex items-center font-semibold leading-loose">
                        Enter Name:
                        <sup className="text-red-500">&#8727;</sup>
                        <div className="ml-1 text-lg font-normal">
                          <a
                            data-tooltip-id="my-tooltip-title"
                            data-tooltip-html="Here you can add </br> a name for your channel"
                            data-tooltip-place="right"
                          >
                            <BsQuestion
                              className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                              color="#4318FF"
                            />
                          </a>
                          <Tooltip className="z-10" id="my-tooltip-title" />
                        </div>
                      </label>
                      <input
                        type="text"
                        class="w-full rounded-md border-2 border-gray-500 px-4  py-2 text-[#000000] focus:outline-none"
                        placeholder="Enter the name of your channel"
                        value={defaultData.credName}
                        onChange={(e) =>
                          dispatch(
                            updateExistingSlack({
                              credName: e.target.value,
                            })
                          )
                        }
                      />

                      {type.type == "WEBHOOK" ? (
                        <>
                          {" "}
                          <label class="flex items-center font-semibold leading-loose">
                            Web Hook URL:
                            <sup className="text-red-500">&#8727;</sup>
                            <div className="ml-1 text-lg font-normal">
                              <a
                                data-tooltip-id="my-tooltip-title"
                                data-tooltip-html="Here, you can add a Web Hook URL for </br> your channel, and you can also check </br> whether the URL is valid or not by </br> clicking the check button."
                                data-tooltip-place="right"
                              >
                                <BsQuestion
                                  className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                                  color="#4318FF"
                                />
                              </a>
                              <Tooltip className="z-10" id="my-tooltip-title" />
                            </div>
                          </label>
                          <div className="flex justify-between">
                            <input
                              type="text"
                              class="w-full rounded-md border-2 border-gray-500 px-4  py-2 text-[#000000] focus:outline-none"
                              placeholder="Add Slack Webhook URL"
                              value={defaultData.webHookUrl}
                              onChange={(e) =>
                                dispatch(
                                  updateExistingSlack({
                                    webHookUrl: e.target.value,
                                  })
                                )
                              }
                            />
                            {defaultData.webHookUrl !== null &&
                              defaultData.webHookUrl !== "" && (
                                <button
                                  className="flex w-20 items-center justify-center border  border-[#4318FF] bg-[#4318FF] text-white sm:text-sm "
                                  onClick={(e) => {
                                    apiCall(`/sw_misc/test_webhook/`, "post", {
                                      web_hook: defaultData.webHookUrl,
                                    })
                                      .then((response) => {
                                        dispatch(
                                          updateAlert({
                                            alertText: "Check Slack",
                                            alertType: "Success",
                                          })
                                        );
                                        setTimeout(() => {
                                          dispatch(toggleAlert());
                                        }, 2000);
                                      })
                                      .catch((error) => {
                                        dispatch(
                                          updateAlert({
                                            alertText: "Invalid URL",
                                            alertType: "Error",
                                          })
                                        );
                                        setTimeout(() => {
                                          dispatch(toggleAlert());
                                        }, 2000);
                                      });
                                  }}
                                >
                                  check
                                </button>
                              )}
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <label class="flex items-center font-semibold leading-loose">
                            channel ID:
                            <div className="ml-1 text-lg font-normal">
                              <a
                                data-tooltip-id="my-tooltip-title"
                                data-tooltip-html="Here you can add </br> a ID for your channel"
                                data-tooltip-place="right"
                              >
                                <BsQuestion
                                  className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                                  color="#4318FF"
                                />
                              </a>
                              <Tooltip className="z-10" id="my-tooltip-title" />
                            </div>
                          </label>
                          <input
                            type="text"
                            class="w-full rounded-md border-2 border-gray-500 px-4  py-2 text-[#000000] focus:outline-none"
                            placeholder="Enter the name of your channel"
                            value={defaultData.channelId}
                            onChange={(e) =>
                              dispatch(
                                updateExistingSlack({
                                  channelId: e.target.value,
                                })
                              )
                            }
                          />
                          <label class="flex items-center font-semibold leading-loose">
                            Oauth Token ID:
                            <div className="ml-1 text-lg font-normal">
                              <a
                                data-tooltip-id="my-tooltip-title"
                                data-tooltip-html="Here you can add </br> a Oauth Token for your channel"
                                data-tooltip-place="right"
                              >
                                <BsQuestion
                                  className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                                  color="#4318FF"
                                />
                              </a>
                              <Tooltip className="z-10" id="my-tooltip-title" />
                            </div>
                          </label>
                          <input
                            type="text"
                            class="w-full rounded-md border-2 border-gray-500 px-4  py-2 text-[#000000] focus:outline-none"
                            placeholder="Enter the name of your channel"
                            value={defaultData.oauthToken}
                            onChange={(e) =>
                              dispatch(
                                updateExistingSlack({
                                  oauthToken: e.target.value,
                                })
                              )
                            }
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div class="flex items-center justify-center space-x-4 pt-4">
                    <button
                      className="flex w-20  items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 font-semibold text-white"
                      onClick={() => {
                        if (!checkName || !checkUrl) {
                          dispatch(
                            updateAlert({
                              alertText: "Please fill the required fields",
                              alertType: "Error",
                            })
                          );
                          setTimeout(() => {
                            dispatch(toggleAlert());
                          }, 2000);
                          return;
                        }
                        if (defaultData.credtype === "WEBHOOK") {
                          apiCall(`/sw_misc/test_webhook/`, "post", {
                            web_hook: defaultData.webHookUrl,
                          })
                            .then((response) => {
                              dispatch(showEditModal());
                              dispatch(EditSlackConfig());
                              // dispatch(
                              //   updateAlert({
                              //     alertText: "Check Slack",
                              //     alertType: "Success",
                              //   })
                              // );
                              // setTimeout(() => {
                              //   dispatch(toggleAlert());
                              // }, 2000);
                            })
                            .catch((error) => {
                              dispatch(
                                updateAlert({
                                  alertText: "Invalid WebHook URL",
                                  alertType: "Error",
                                })
                              );
                              setTimeout(() => {
                                dispatch(toggleAlert());
                              }, 2000);
                            });
                        } else {
                          dispatch(showEditModal());
                          dispatch(EditSlackConfig());
                        }
                      }}
                    >
                      Save
                    </button>
                    <button
                      class="flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                      onClick={() => {
                        dispatch(clearDataSlackOld());
                        dispatch(showEditModal());
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditChannel;
