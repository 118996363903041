import { Navigate, Outlet } from "react-router-dom";
import {store} from 'reduxConfig/store'
import { getBrandData } from "reduxConfig/slices/brandSlice";
import { useDispatch } from "react-redux";
const useAuth = () => {
    const authData = store.getState()
    if (authData.userAuth.isAuthenticated){
        return true
    }
    return false

};

const ProtectedRoutes = () => {
  const isAuth = useAuth();
  const dispatch=useDispatch()
  if (isAuth){
    dispatch(getBrandData())
  }
  return isAuth ? <Outlet /> : <Navigate to="/auth/sign-in" />;
};

export default ProtectedRoutes;