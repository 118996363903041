import React, { useState, useEffect, useRef } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { updatePageNumberApkResults } from "reduxConfig/slices/apkResultSlice";
import { getApkData } from "reduxConfig/slices/apkResultSlice";
import { addAdditionalFiltersApk } from "reduxConfig/slices/apkResultSlice";

function AdditionalFilter() {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const navigate = useNavigate();
  const [checkboxes, setCheckboxes] = useState({
    Or: false,
    Threat: false,
    Whitelisted: false,
    Suspicious: false,
    APK: false,
    "Phishing Domains": false,
    Unknown: false,
  });
  const filterOptionsRef = useRef(null);
  const dispatch = useDispatch();
  const additionalFilters = useSelector(
    (state) => state.ApksResultsData.additionalFilters
  );
  const threat_indicators = [
    "App Name Matched",
    "Brand Name Matched",
    "Different Certificate",
    "File Name Match",
    "Original Certificate Match",
    "Package Name Match",
    "Package Name Mismatch",
    "Package Name Substring Match",
    "Third Party Appstore",
  ];
  const suggested_classification = [
    "MOD_APP",
    "WHITELISTED_APP",
    "THIRD_PARTY_APP",
    "MALICIOUS_APP",
    "FRAUDULENT_APP",
    "IMPERSONATING_APP",
  ];

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterOptionsRef.current &&
        !filterOptionsRef.current.contains(event.target)
      ) {
        setShowFilterOptions(false);
      }
    };

    if (showFilterOptions) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showFilterOptions]);

  const handleFilterClick = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  const applyFilters = () => {
    setShowFilterOptions(false);
  };

  const cancelFilters = () => {
    setShowFilterOptions(false);
  };

  return (
    <div className="relative z-10 inline-block text-center">
      <button
        type="button"
        onClick={handleFilterClick}
        className={`bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-3 py-2 px-6 text-center font-semibold text-[#4318FF] hover:bg-[#4318FF] hover:text-white ${
          showFilterOptions ? "w-[10vw]" : "w-[10vw]"
        }`}
      >
        Additional Filter
      </button>
      {showFilterOptions && (
        <div
          ref={filterOptionsRef}
          className="transdiv absolute -ml-8 mt-2 -translate-x-1/2 rounded border border-gray-300 bg-white p-4 shadow-md"
        >
          <div className="mb-2 flex items-center justify-center">
            <div className="mx-8">
              <label className="mr-4 text-xl font-semibold">Filters</label>
              <label className="flex items-center">
                <input
                  className=" cursor-pointer"
                  type="checkbox"
                  name="Or"
                  checked={additionalFilters.use_or}
                  onChange={(e) => {
                    dispatch(
                      addAdditionalFiltersApk({
                        use_or: !additionalFilters.use_or,
                      })
                    );
                  }}
                />
                <span className="mx-2">Or</span>
                <div className="">
                  <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Select the checkbox to enable the or operator."
                    data-tooltip-place="right"
                  >
                    <AiOutlineInfoCircle className="cursor-pointer text-[#4318FF]" />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip" />
                </div>
              </label>
            </div>
          </div>
          <div className="-mt-20 flex justify-end">
            <button
              type="button"
              onClick={() => {
                dispatch(
                  addAdditionalFiltersApk({
                    use_or: false,
                    threat_indicators: [],
                    suggested_classification: [],
                  })
                );
              }}
              className="underline-text p-7  text-[#4318FF] hover:text-[#4318FF]"
            >
              Clear All
            </button>
          </div>
          <div className="flex w-full justify-evenly">
            <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Threat Indicators
              </h3>
              {threat_indicators.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.threat_indicators.includes(
                      element
                    )}
                    onChange={(e) => {
                      additionalFilters.threat_indicators.includes(element)
                        ? dispatch(
                            addAdditionalFiltersApk({
                              threat_indicators:
                                additionalFilters.threat_indicators.filter(
                                  (item) => item != element
                                ),
                            })
                          )
                        : dispatch(
                            addAdditionalFiltersApk({
                              threat_indicators: [
                                ...additionalFilters.threat_indicators,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  {/* <span className="ml-2">{element}</span> */}
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div>
            <span className="border-black border-2"></span>
            <div className="m-1 items-center space-y-2 p-1">
              <h3 className="flex justify-start text-lg font-semibold">
                Classifications
              </h3>
              {suggested_classification.map((element) => (
                <label className="flex items-center">
                  <input
                    className=" cursor-pointer"
                    type="checkbox"
                    name="Threat"
                    value={element}
                    checked={additionalFilters.suggested_classification.includes(
                      element
                    )}
                    onChange={(e) => {
                      additionalFilters.suggested_classification.includes(
                        element
                      )
                        ? dispatch(
                            addAdditionalFiltersApk({
                              suggested_classification:
                                additionalFilters.suggested_classification.filter(
                                  (item) => item != element
                                ),
                            })
                          )
                        : dispatch(
                            addAdditionalFiltersApk({
                              suggested_classification: [
                                ...additionalFilters.suggested_classification,
                                e.target.value,
                              ],
                            })
                          );
                    }}
                  />
                  <span className="ml-2 whitespace-nowrap">
                    {element.replace(/_/g, " ")}
                  </span>
                </label>
              ))}
            </div>
          </div>
          <div className="mt-4 flex justify-center">
            <button
              type="button"
              onClick={() => {
                dispatch(getApkData());
                dispatch(updatePageNumberApkResults(1));
                setShowFilterOptions(false);
              }}
              className="mr-4 rounded-xl bg-[#4318FF] px-4 py-2 text-white "
            >
              Apply
            </button>
            <button
              type="button"
              onClick={cancelFilters}
              className="rounded-xl border border-[#4318FF] px-4 py-2 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdditionalFilter;
