function formatDateToDDMMYYYY(date) {
  // Get day, month, and year from the Date object
  const day = date.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
  const year = date.getFullYear();

  // Format the date as "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
}
const urlParamsBuilder = (filterData) => {
  const urlParams = [];
  if (filterData.queryString) {
    urlParams.push(`query=${encodeURIComponent(filterData.queryString)}`);
  }
  if (filterData.fromDate) {
    urlParams.push(
      `fromDate=${encodeURIComponent(
        formatDateToDDMMYYYY(filterData.fromDate)
      )}`
    );
  }
  if (filterData.toDate) {
    urlParams.push(
      `toDate=${encodeURIComponent(formatDateToDDMMYYYY(filterData.toDate))}`
    );
  }
  if (filterData.additionalFilters.result_type.length) {
    urlParams.push(
      `resultType=${encodeURIComponent(
        filterData.additionalFilters.result_type.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.entity_type.length) {
    urlParams.push(
      `entityType=${encodeURIComponent(
        filterData.additionalFilters.entity_type.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.source.length) {
    urlParams.push(
      `source=${encodeURIComponent(
        filterData.additionalFilters.source.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.marked_as.length) {
    urlParams.push(
      `markedAs=${encodeURIComponent(
        filterData.additionalFilters.marked_as.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.possible_url_classifications.length) {
    urlParams.push(
      `possibleUrls=${encodeURIComponent(
        filterData.additionalFilters.possible_url_classifications.join(",")
      )}`
    );
  }
  if (filterData.additionalFilters.use_or) {
    urlParams.push(`useOr=${filterData.additionalFilters.use_or}`);
  }
  if (filterData.pageNumber) {
    urlParams.push(`page=${filterData.pageNumber}`);
  }

  if (filterData.isRegex) {
    urlParams.push(`isRegex=${filterData.isRegex}`);
  }

  return urlParams.join("&");
};

export default urlParamsBuilder;
