import React, { useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { updateNewUrl } from "reduxConfig/slices/urlSlice";

function RuleSelect() {
  const ruleData = useSelector((state) => state.rulesData.rulesData);
  const componentDefaultValue = useSelector(
    (state) => state.urlsData.newUrlData.chooseRule
  );
  const dispatch = useDispatch();

  const options = ruleData.map((rule) => ({
    value: rule._id,
    label: rule.title,
  }));

  return (
    <div>
      {options.length === 0 ? (
        <div className="font-bold text-red-500 text-xs">
          No rules have been created. Please create a rule.
        </div>
      ) : (
        <Select
          className="border border-[#000000] text-sm outline-none"
          options={options}
          value={options.find(
            (option) => option.value === componentDefaultValue.value
          )}
          onChange={(selectedOption) => {
            dispatch(updateNewUrl({ chooseRule: selectedOption }));
          }}
        />
      )}
    </div>
  );
}

export default RuleSelect;
