import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleExportModal } from "reduxConfig/slices/resultSlice";
function ExportButtonModal() {
  const dispatch = useDispatch();

  return (
    <div>
      <button
        class="inline-flex items-center rounded-xl px-4 py-3.5 text-center text-sm font-bold border-[#4318FF]  text-[#4318FF] hover:bg-[#4318FF] hover:text-white border h-12"
        type="button"
        data-dropdown-toggle="dropdown"
        onClick={(e) => {
          dispatch(toggleExportModal());
        }}
      >
        Export Data{" "}
      </button>
    </div>
  );
}

export default ExportButtonModal;
