import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { store } from "../store";
import apiCall from "axiosConfig/axiosConfig";
import * as Loadash from "lodash";

const getBrandIds = () => {
  const storeData = store.getState();
  let ownedBrands = storeData.brandsData.brandData;
  return ownedBrands;
};
const initialState = {
  resultId: null,
  showModal: false,
  brandData: [],
  markedWhiteListed: [],
  markedThreat: [],
  entityInterestType: "UNKNOWN",
  resultType: "UNKNOWN",
  choosenType: null,
  resultTitle: "",
  resultLink: "",
  resultJson: {},
  status: null,
  resultImg: "",
  error: null,
  Seen: false,
  comment: "",
};

export const AddUserActions = createAsyncThunk("actions/add", () => {
  const storeData = store.getState();
  let userProvidedData = storeData.resultEditData;
  const selectedBrand = () => {
    if (userProvidedData.brandData.length === 0) {
      var selectedBrands = userProvidedData.brandData;
    } else if (userProvidedData.brandData[0].value) {
      var selectedBrands = Loadash.map(userProvidedData.brandData, "value");
    } else {
      var selectedBrands = userProvidedData.brandData;
    }
    if (userProvidedData.choosenType === "NONE") {
      return {
        threat_brand_ids: [],
        whitelisted_brand_ids: [],
      };
    } else {
      if (userProvidedData.choosenType === "WHITELISTED") {
        return {
          threat_brand_ids: [],
          whitelisted_brand_ids: selectedBrands,
        };
      } else {
        return {
          threat_brand_ids: selectedBrands,
          whitelisted_brand_ids: [],
        };
      }
    }
  };

  let dataToPost = {
    result_id: userProvidedData.resultId,
    result_type: userProvidedData.resultType,
    entity_interest_type: userProvidedData.entityInterestType,
    comment: userProvidedData.comment,
    ...selectedBrand(),
  };
  return apiCall("/sw_actions/mark_result", "put", dataToPost);
});
export const brandDataSlice = createSlice({
  name: "resultData",
  initialState,
  reducers: {
    setResultId: (state, action) => {
      state.resultId = action.payload;
    },
    setDefaultResult: (state, action) => {
      state.entityInterestType = action.payload.entityInterestType;
      state.resultType = action.payload.resultType;
      state.resultId = action.payload.resultId;
      state.choosenType = action.payload.choosenType;
      state.brandData = action.payload.brandData;
      state.resultTitle = action.payload.resultTitle;
      state.resultImg = action.payload.resultImg;
      state.comment = action.payload.comment;
    },
    markWhiteListed: (state, action) => {
      state.markedWhiteListed = action.payload;
    },
    markThreat: (state, action) => {
      state.markedThreat = action.payload;
    },
    setEntityType: (state, action) => {
      state.entityInterestType = action.payload;
    },
    setResultType: (state, action) => {
      state.resultType = action.payload;
    },
    toggleEditModal: (state) => {
      state.showModal = !state.showModal;
    },
    toggleUserSelection: (state, action) => {
      state.choosenType = action.payload;
      if (state.choosenType == "NONE") {
        state.choosenType = "NONE";
        state.resultType = "UNKNOWN";
        state.entityInterestType = "UNKNOWN";
        state.markedThreat = initialState.markedWhiteListed;
        state.markedWhiteListed = initialState.markedWhiteListed;
      } else {
        if (state.choosenType == "WHITELISTED") {
          state.choosenType = "WHITELISTED";
          state.resultType = "UNKNOWN";
          state.entityInterestType = "UNKNOWN";
          state.markedThreat = initialState.markedWhiteListed;
          state.markedWhiteListed = state.markedWhiteListed;
        } else {
          state.choosenType = "THREAT";
          state.resultType = "UNKNOWN";
          state.entityInterestType = "UNKNOWN";
          state.markedWhiteListed = initialState.markedThreat;
          state.markedThreat = state.markedThreat;
        }
      }
    },
    setEyeResult: (state, action) => {
      state.resultId = action.payload.resultId;
      state.resultTitle = action.payload.resultTitle;
      state.resultLink = action.payload.resultLink;
      state.resultJson = action.payload.resultJson;
      state.Seen = action.payload.seen;
    },
    changeStatus: (state, action) => {
      state.status = action.payload;
    },
    changeImage: (state, action) => {
      state.resultImg = action.payload;
    },
    updateErrorResultsEdit: (state, action) => {
      state.error = null;
    },
    updateBrandData: (state, action) => {
      state.brandData = action.payload;
    },
    updateComment: (state, action) => {
      state.comment = action.payload;
    },
    updateSeenResults: (state, action) => {
      state.Seen = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(AddUserActions.pending, (state) => {
        state.status = "POSTED";
      })
      .addCase(AddUserActions.fulfilled, (state, action) => {
        state.status = "SUCCESS";
      })
      .addCase(AddUserActions.rejected, (state, action) => {
        state.status = "FAILED";
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  markThreat,
  markWhiteListed,
  setEntityType,
  setResultType,
  setResultId,
  toggleEditModal,
  toggleUserSelection,
  setDefaultResult,
  setEyeResult,
  changeStatus,
  changeImage,
  updateErrorResultsEdit,
  updateBrandData,
  updateComment,
  updateSeenResults,
} = brandDataSlice.actions;

export default brandDataSlice.reducer;
