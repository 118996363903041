import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { AiOutlineCopy, AiOutlineInfoCircle } from "react-icons/ai";
import { toggleApkDetailsModal } from "reduxConfig/slices/apkResultSlice";
import { setDefaultValue } from "reduxConfig/slices/apkResultSlice";
import { toggleInfoModal } from "reduxConfig/slices/apkResultSlice";
import { setInfoResult } from "reduxConfig/slices/apkResultSlice";
import { Tooltip } from "react-tooltip";
import noPreview from "assets/img/androdApk.png";
import { MdNavigateNext } from "react-icons/md";
import getIstTime from "./helpers/istConvertor";

const ApkResultsCard = ({ cardData }) => {
  const dispatch = useDispatch();
  const brandData = useSelector((state) => state.brandsData.brandData);
  const clientData = useSelector((state) => state.brandsData.clientData);

  const getClientName = (client_id) => {
    const clientName = clientData.find((element) => element._id == client_id);
    if (clientName) {
      return clientName.client_name;
    }
    return "Client Deleted";
  };

  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "Brand Deleted";
  };

  return (
    <div class="flex  flex-col items-center justify-center">
      <div class="!z-5 3xl:p-![18px] undefined relative mb-10  flex w-[25vw] max-w-[25vw] flex-col  rounded-[20px] bg-white bg-clip-border !p-4 shadow-3xl shadow-shadow-500">
        <div>
          <div className="">
            <button className="absolute top-1 left-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer"></button>
          </div>
          <div className="mt-5 flex items-center justify-center">
            <div className="flex w-[85%] items-center justify-center">
              <p
                className={`hide-scrollbar flex justify-start whitespace-nowrap p-2 text-center text-xl font-bold ${
                  cardData?.app_name ? "text-navy-700" : "text-red-500"
                } `}
                style={{
                  maxWidth: "80%",
                  overflow:
                    cardData?.app_name && cardData?.app_name?.length > 15
                      ? "auto "
                      : "hidden",
                }}
              >
                {cardData?.app_name ? (
                  cardData.app_name.toUpperCase()
                ) : (
                  <>
                    <a
                      data-tooltip-id="my-tooltip-title"
                      data-tooltip-html="This app does not contain an app name."
                      data-tooltip-place="top"
                    >
                      {"NO NAME"}
                    </a>
                    <Tooltip
                      className="z-10 font-normal"
                      id="my-tooltip-title"
                    />
                  </>
                )}
              </p>
            </div>
          </div>

          <div class="text-md m-0 -mt-4 flex justify-center p-2 text-center font-medium text-gray-800">
            <CopyToClipboard
              text={cardData?._id}
              onCopy={() => {
                dispatch(
                  updateAlert({
                    alertText: "Result ID Copied",
                    alertType: "Success",
                  })
                );
                setTimeout(() => {
                  dispatch(toggleAlert());
                }, 2000);
              }}
            >
              <span className="flex cursor-grabbing">
                {" "}
                {cardData?._id}
                <AiOutlineCopy
                  className="m-0 h-6 w-6 rounded-md  p-[2px]"
                  color="#4318FF"
                />
              </span>
            </CopyToClipboard>
          </div>
        </div>

        <div class="mb-2 h-full w-full">
          <div class="relative flex w-full justify-center bg-[#ADB7D5] p-2">
            <img
              src={
                cardData?.main_icon?.storage_attributes?.bucket_id
                  ? `https://dsgx98c21wj98.cloudfront.net/${cardData.main_icon?.storage_attributes?.bucket_id}`
                  : noPreview
              }
              class="h-[150px] w-[150px] items-center rounded-xl"
              alt=""
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Package Name</div>
                <div
                  className={`hide-scrollbar ${
                    cardData?.package_name?.length > 30 ? "w-[10vw]" : " "
                  } overflow-y-scroll text-[#000000]`}
                >
                  {cardData?.package_name ? cardData.package_name : "NONE"}
                </div>
              </dl>
            </div>
          </div>
          <div class="flex w-[35%] items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Indicator</div>
                <div
                  className={`text-sm ${
                    cardData?.user_actions?.is_whitelisted
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {cardData?.user_actions?.is_whitelisted
                    ? "WHITELISTED"
                    : "THREAT"}
                </div>
              </dl>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between px-1 md:items-start">
          <div class="mb-2">
            <dl class="grid-cols-2">
              <div class="font-semibold">md5</div>
              <div class="flex text-[#000000]">
                {cardData?.md5}
                <CopyToClipboard
                  text={cardData?.md5}
                  onCopy={() => {
                    dispatch(
                      updateAlert({
                        alertText: "MD5 Copied",
                        alertType: "Success",
                      })
                    );
                    setTimeout(() => {
                      dispatch(toggleAlert());
                    }, 2000);
                  }}
                >
                  <span className="flex cursor-grabbing">
                    <AiOutlineCopy
                      className="m-0 flex h-6 w-6  rounded-md p-[2px]"
                      color="#4318FF"
                    />
                  </span>
                </CopyToClipboard>
              </div>
            </dl>
          </div>
        </div>

        <div className="flex p-1 text-left font-semibold md:items-start">
          Source
        </div>
        {cardData?.file_info_attributes?.discovered_sources?.length ? (
          <div className="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(
              cardData?.file_info_attributes?.discovered_sources
            ).map((source, index) => {
              let domainName = source;

              try {
                const url = new URL(source);
                domainName = url.hostname;
              } catch (error) {
                console.error(
                  `Error creating URL object for source: ${source}`
                );
              }

              return (
                <div
                  key={index}
                  class="ml-1 flex h-[35%] items-center rounded-lg border-2  border-[#000000] px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {domainName}
                  <CopyToClipboard
                    text={source}
                    onCopy={() => {
                      dispatch(
                        updateAlert({
                          alertText: "Source Copied",
                          alertType: "Success",
                        })
                      );
                      setTimeout(() => {
                        dispatch(toggleAlert());
                      }, 2000);
                    }}
                  >
                    <AiOutlineCopy
                      className="m-0 h-6 w-6 cursor-grabbing  rounded-md p-[2px]"
                      color="#4318FF"
                    />
                  </CopyToClipboard>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            <div
              class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
              style={{ marginBottom: "2px" }}
            >
              {"NONE"}
            </div>
          </div>
        )}

        <div className="my-1 ml-1 text-left font-semibold">Classifications</div>
        {cardData?.user_actions?.suggested_classifications?.length ? (
          <div class="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(
              cardData?.user_actions?.suggested_classifications
            ).map((classification) => (
              <div
                class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                style={{ marginBottom: "2px" }}
              >
                {classification.value.replace(/_/g, " ")}
              </div>
            ))}
          </div>
        ) : (
          <div class="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            <div class="text-#000000 ml-1  h-[35%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
              {"None"}
            </div>
          </div>
        )}

        <div className="my-1 ml-1 text-left font-semibold">
          Threat Indicators
        </div>
        {cardData?.user_actions?.threat_indicators?.length ? (
          <div class="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(cardData.user_actions.threat_indicators).map(
              (threat) => (
                <div
                  class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {threat}
                </div>
              )
            )}
          </div>
        ) : (
          <div class="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            <div class="text-#000000 ml-1  h-[35%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
              {"None"}
            </div>
          </div>
        )}

        <div className="my-1 ml-1 text-left font-semibold">
          Targeted Clients
        </div>
        {cardData?.user_actions?.client_ids?.length &&
        cardData?.user_actions?.client_ids[0] !== "" ? (
          <div class="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(cardData?.user_actions?.client_ids).map(
              (client_id) => (
                <div
                  class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {getClientName(client_id)}
                </div>
              )
            )}
          </div>
        ) : (
          <div class="mb-2 flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            <div class="text-#000000 ml-1  h-[35%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
              Client Not Selected
            </div>
          </div>
        )}
        <div className="my-1 ml-1 text-left font-semibold">Targeted Brands</div>
        {cardData?.user_actions?.brand_ids?.length &&
        cardData?.user_actions?.brand_ids[0] !== "" ? (
          <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            {Object.values(cardData?.user_actions?.brand_ids).map(
              (brand_id) => (
                <div
                  class="ml-1 h-[35%] items-center rounded-lg border-2 border-[#000000]  px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "2px" }}
                >
                  {getBrandName(brand_id)}
                </div>
              )
            )}
          </div>
        ) : (
          <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto">
            <div class="text-#000000 ml-1  h-[35%] items-center rounded-lg border-2 border-[#000000] px-[10px]  py-[1px] text-center text-sm">
              Brand Not Selected
            </div>
          </div>
        )}

        <div className="flex justify-between">
          <div class="flex items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Discovered At</div>
                <div
                  className={`hide-scrollbar ${
                    cardData?.user_actions?.created_at?.length > 30
                      ? "w-[10vw]"
                      : " "
                  } overflow-y-scroll text-[#000000]`}
                >
                  {cardData?.user_actions?.created_at
                    ? getIstTime(cardData.user_actions.created_at)
                    : "NONE"}
                </div>
              </dl>
            </div>
          </div>
          <div class="flex w-[35%] items-center justify-between px-1 md:items-start">
            <div class="mb-2">
              <dl class="grid-cols-2">
                <div class="font-semibold">Last Update</div>
                <div className={`text-sm`}>
                  {cardData?.user_actions?.updated_at
                    ? getIstTime(cardData.user_actions.updated_at)
                    : "None"}
                </div>
              </dl>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <div>
            <div>
              <div className="flex items-center justify-between px-1 md:items-start">
                <div className="mb-2">
                  <div className="font-semibold">Version</div>
                  <div
                    className={`hide-scrollbar overflow-x-scroll text-[#000000]`}
                    style={{
                      whiteSpace: "nowrap",
                      maxWidth: "6em",
                    }}
                  >
                    {cardData?.androguard_attributes?.android_displayed_version
                      ? cardData.androguard_attributes.android_displayed_version
                      : "None"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              className="flex cursor-pointer items-center justify-center"
              onClick={() => {
                window.open(
                  `/apk_detailed_view/?query=${cardData._id}`,
                  "_blank"
                );
              }}
            >
              <div className="">
                <a
                  data-tooltip-id="my-tooltip-import"
                  data-tooltip-html="Selecting this icon will navigate to the APK detailed view page, displaying all the data."
                  data-tooltip-place="top"
                >
                  <MdNavigateNext
                    data-modal-target="popup-modal"
                    data-modal-toggle="popup-modal"
                    type="button"
                    className="mr-1 h-12 w-12  rounded-xl border border-[#4318FF] p-[6px] text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip-import" />
              </div>
            </div>
            <div
              className="flex cursor-pointer items-center justify-center"
              onClick={() => {
                dispatch(
                  setInfoResult({
                    resultId: cardData._id,
                    resultTitle: cardData.app_name,
                    resultLink: cardData.file_attributes.bucket_id,
                    resultJson: {
                      androguard_attributes: cardData.androguard_attributes,
                      app_name: cardData.app_name,
                      certificate: cardData.certificate,
                      certificate_sha1: cardData.certificate_sha1,
                      client_id: cardData.client_ids,
                      communication_endpoints: cardData.communication_endpoints,
                      created_at: getIstTime(cardData.user_actions.created_at),
                      engine_score: cardData.engine_score,
                      file_attributes: cardData.file_attributes,
                      file_info_attributes: cardData.file_info_attributes,
                      file_names: cardData.file_names,
                      first_seen_date_timestamp:
                        cardData.first_seen_date_timestamp,
                      hash_values: cardData.hash_values,
                      is_official: cardData.is_official,
                      main_icon: cardData.main_icon,
                      md5: cardData.md5,
                      package_name: cardData.package_name,
                      playstore_attributes: cardData.playstore_attributes,
                      sha1: cardData.sha1,
                      sha256: cardData.sha256,
                      size: cardData.size,
                      suggested_classifications:
                        cardData.suggested_classifications,
                      tags: cardData.tags,
                      threat_indicators: cardData.threat_indicators,
                      updated_at: getIstTime(cardData.user_actions.updated_at),
                      verdict: cardData.verdict,
                      _id: cardData._id,
                      _type: cardData._type,
                    },
                  })
                );
                dispatch(toggleInfoModal());
              }}
            >
              <div className="">
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Click the info icon to open the modal </br> where you can perform actions such as </br> > marking as seen </br> > deleting the result </br>  > copying result links and JSON."
                  data-tooltip-place="top"
                >
                  <AiOutlineInfoCircle
                    data-modal-target="popup-modal"
                    data-modal-toggle="popup-modal"
                    type="button"
                    className="mr-1 h-12 w-12  rounded-xl border border-[#4318FF] p-[6px] text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                  />
                </a>
                <Tooltip className="z-10" id="my-tooltip" />
              </div>
            </div>
            <button
              className="flex w-40  items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 font-bold text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              onClick={(e) => {
                dispatch(
                  setDefaultValue({
                    id: cardData._id,
                    title: cardData.app_name,
                    packageName: cardData.package_name,
                    source: cardData.file_info_attributes.discovered_sources,
                    organizationName: cardData.certificate.issuer.O,
                    commonName: cardData.certificate.issuer.CN,
                    classification:
                      cardData.user_actions.suggested_classifications,
                    downloadLinks: cardData.file_attributes.bucket_id,
                    indicators: cardData.user_actions.is_whitelisted,
                    md5: cardData.md5,
                    sha1: cardData.sha1,
                    sha256: cardData.sha256,
                    targetedBrands: cardData.user_actions.brand_ids,
                    targetedClients: cardData.user_actions.client_ids,
                    createdAt: getIstTime(cardData.user_actions.created_at),
                    lastSeen: getIstTime(cardData.user_actions.updated_at),
                    apkIcon: cardData.main_icon
                      ? cardData.main_icon?.storage_attributes?.bucket_id
                      : "",
                    comment: cardData.user_actions.comment
                      ? cardData.user_actions.comment
                      : "",
                    cert_sha: cardData.certificate_sha1,
                  })
                );
                dispatch(toggleApkDetailsModal());
              }}
            >
              View All Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApkResultsCard;
