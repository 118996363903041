import React, { useEffect, useState } from "react";
import { MultiSelect as Selector } from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import { updateNewFileUpload } from "reduxConfig/slices/uploadApkSlice";
import { updateNewDriveUpload } from "reduxConfig/slices/uploadApkSlice";

function BrandSelect({ isFileupload }) {
  const brandData = useSelector((state) => state.brandsData.brandData);
  const [formattedData, setBrandData] = useState([]);
  const checkClients = useSelector((state) =>
    isFileupload
      ? state.UploadApkData.fileUpload.client_id
      : state.UploadApkData.driveUpload.client_id
  );
  const componentDefaultValues = useSelector((state) =>
    isFileupload
      ? state.UploadApkData.fileUpload.brand_id
      : state.UploadApkData.driveUpload.brand_id
  );

  const dispatch = useDispatch();
  useEffect(() => {
    let stateData = [];
    for (let elements in brandData) {
      stateData.push({
        value: brandData[elements]._id,
        label: brandData[elements].brand_name,
      });
    }
    setBrandData(stateData);
  }, []);

  useEffect(() => {
    let filteredBrandData = [];

    // Extract client_id values from checkClients
    const clientIds = checkClients.map((client) => client.value);

    // Filter brandData based on extracted client_ids
    if (clientIds && clientIds.length > 0) {
      filteredBrandData = brandData.filter((brand) =>
        clientIds.includes(brand.client_id)
      );
    } else {
      filteredBrandData = [...brandData];
    }
    let stateData = filteredBrandData.map((brand) => ({
      value: brand._id,
      label: brand.brand_name,
    }));

    setBrandData(stateData);
  }, [brandData, checkClients]);

  return (
    <div>
      {formattedData.length ? (
        <Selector
          className="multiselect"
          options={formattedData}
          value={componentDefaultValues}
          onChange={(selectedOption) => {
            isFileupload
              ? // dispatch(updateNewFileUpload({ brand_id: selectedOption }));
                dispatch(updateNewFileUpload({ brand_id: selectedOption }))
              : dispatch(updateNewDriveUpload({ brand_id: selectedOption }));
          }}
          labelledBy={"Select"}
          isCreatable={false}
        />
      ) : (
        <div className="text-sm font-bold text-red-500">
          No brands have been created. Please create a brand.
        </div>
      )}
    </div>
  );
}

export default BrandSelect;
