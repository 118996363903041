import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete, AiOutlineCopy, AiFillAndroid } from "react-icons/ai";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { CopyToClipboard } from "@appluslaboratories/react-copy-to-clipboard";
import { changeBrandEditStatus } from "reduxConfig/slices/brandSlice";
import { toggleClientEditModal } from "reduxConfig/slices/brandSlice";
import { updateExistingClient } from "reduxConfig/slices/brandSlice";
import ClientIcon from "assets/img/Group.svg";
import { toggleClientDeleteModal } from "reduxConfig/slices/brandSlice";
import { updateClientDeleteData } from "reduxConfig/slices/brandSlice";
import { Tooltip } from "react-tooltip";
import { toggleExportModalClients } from "reduxConfig/slices/brandSlice";
import { updateExportClientId } from "reduxConfig/slices/brandSlice";
import getIstTime from "./helpers/istConvertor";

const ClientCard = ({ cardData }) => {
  const dispatch = useDispatch();
  const brandData = useSelector((state) => state.brandsData.brandData);

  const getBrandName = (brand_id) => {
    const brandName = brandData.find((element) => element._id == brand_id);
    if (brandName) {
      return brandName.brand_name;
    }
    return "NONE";
  };

  return (
    <div class=" w-[25vw] items-center justify-end rounded-lg border border-gray-200 bg-white p-5 shadow dark:border-gray-700 dark:bg-gray-800">
      <div className="flex justify-end p-2">
        <div>
          <a
            data-tooltip-id="my-tooltip-title"
            data-tooltip-html="Click on this APK icon;  it will navigate you </br> to the APK results page, showing the results </br> based on your selected clients."
            data-tooltip-place="top"
          >
            <AiFillAndroid
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              type="button"
              className="m-0 mr-1 h-8 w-8 cursor-pointer justify-end  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#4318FF] hover:bg-[#4318FF] hover:text-white"
              onClick={(e) => {
                window.open(
                  `/admin/apks_results?query=client:"${cardData._id}"`,
                  "_blank"
                );
              }}
            />
          </a>
          <Tooltip className="z-10" id="my-tooltip-title" />
        </div>
        {/* <AiOutlineDelete
          data-modal-target="popup-modal"
          data-modal-toggle="popup-modal"
          type="button"
          className="m-0 h-8 w-8 cursor-pointer justify-end  rounded-md border-2 border-[#4318FF] p-[2px] text-[#4318FF] hover:border-[#ff0000] hover:bg-[#ff0000] hover:text-white"
          onClick={(e) => {
            dispatch(
              updateClientDeleteData({
                clientName: cardData.client_name,
                clientId: cardData._id,
              })
            );
            dispatch(toggleClientDeleteModal());
          }}
        /> */}
      </div>
      <div class="flex flex-col items-center pb-10">
        <img
          src={ClientIcon}
          className="h-[150px] w-[150px] items-center rounded-lg bg-[#F5F8FE] p-2"
          alt="logo "
        />

        <h5 class="mb-1 text-xl font-bold text-[#000000] dark:text-white">
          {cardData.client_name}
        </h5>
        <span class="text-sm text-[#000000]">
          <CopyToClipboard
            text={cardData._id}
            onCopy={() => {
              dispatch(
                updateAlert({
                  alertText: "Client ID Copied",
                  alertType: "Success",
                })
              );
              setTimeout(() => {
                dispatch(toggleAlert());
              }, 2000);
            }}
          >
            <span className="flex cursor-grabbing">
              {" "}
              {cardData._id}
              <AiOutlineCopy
                className="m-0 h-5 w-5 rounded-md  p-[2px]"
                color="#4318FF"
              />
            </span>
          </CopyToClipboard>
        </span>
      </div>

      <div className="hide-scrollbar ml-4 flex w-[100%] flex-wrap justify-start overflow-y-scroll">
        <div class="mt-1 flex flex-col justify-start">
          <dt class="flex justify-start font-semibold text-[#000000]">
            Brand Keywords:
          </dt>
          {cardData.brand_keywords.length ? (
            <div className="flex">
              {cardData.brand_keywords.map((words) => (
                <p
                  tabindex="0"
                  class=" mr-1 flex max-h-[2em] min-h-[2em]  flex-row flex-wrap items-center justify-start rounded-md border border-[#000000] px-[10px] py-[1px]  text-center text-sm  focus:outline-none"
                >
                  {words}
                </p>
              ))}
            </div>
          ) : (
            <div className="flex">
              <p
                tabindex="0"
                class=" mr-1 flex max-h-[2em] min-h-[2em] w-full flex-row flex-wrap items-center justify-start rounded-md border border-[#000000] px-[10px]  py-[1px] text-sm  focus:outline-none"
              >
                No Keywords Selected
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="items-center justify-start px-4 pt-4 font-semibold text-[#000000]">
        Brands:
      </div>
      {cardData.brand_ids.length ? (
        <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto px-4">
          {Object.values(cardData.brand_ids).map((brand_id) => {
            const brandName = getBrandName(brand_id);
            // Render the <div> only if a valid brandName is found
            if (brandName !== "NONE") {
              return (
                <div
                  class="mr-1 h-[20%] items-center rounded-md border border-[#000000] px-[10px] py-[1px] text-center text-sm text-[#000000]"
                  style={{ marginBottom: "5px" }}
                >
                  {brandName}
                </div>
              );
            }
            // Return null if brandName is "NONE" to exclude it from rendering
            return null;
          })}
        </div>
      ) : (
        <div class="flex max-h-[5em] min-h-[5em] w-full flex-row flex-wrap overflow-auto px-4">
          <div class="text-#000000  h-[20%] items-center rounded-md border border-[#000000] px-[10px]  py-[1px] text-center text-sm">
            Brands Not Selected
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <div class="items-center justify-start px-4 pt-4">
          <dt class="font-semibold text-[#000000]">Date Created:</dt>
          <dd class="flex text-sm">
            {cardData.created_at ? getIstTime(cardData.created_at) : "None"}
          </dd>
        </div>

        <div class="items-center justify-start px-4 pt-4">
          <dt class="font-semibold text-[#000000]">Update At:</dt>
          <dd class="flex text-sm">
            {cardData.updated_at ? getIstTime(cardData.updated_at) : "None"}
          </dd>
        </div>
      </div>

      <div class="mt-4 flex cursor-pointer justify-center space-x-3 md:mt-6">
        <a
          class="dark:focus:ring-blue-[#4318FF] inline-flex items-center rounded-lg bg-[#4318FF] px-4 py-2 text-center text-sm font-medium text-white hover:bg-[#4318FF] focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-[#4318FF] dark:hover:bg-[#4318FF]"
          onClick={() => {
            dispatch(
              updateExportClientId({
                exportClientId: cardData._id,
              })
            );
            dispatch(toggleExportModalClients());
          }}
        >
          Export Data
        </a>
        <a
          class="inline-flex items-center rounded-lg border border-[#4318FF] bg-white px-4 py-2 text-center text-sm font-medium text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
          onClick={() => {
            dispatch(
              updateExistingClient({
                clientName: cardData.client_name,
                brandKeyWords: cardData.brand_keywords,
                clientId: cardData._id,
              })
            );
            dispatch(toggleClientEditModal());
          }}
        >
          Edit Client
        </a>
      </div>
    </div>
  );
};

export default ClientCard;
