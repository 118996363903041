import { useState } from "react";
import { authPostBackend } from "reduxConfig/slices/authSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeAlert } from "reduxConfig/slices/authSlice";
import ModMonLogo from "../../assets/img/ModMonLogo.svg";
import { AiOutlineEye } from "react-icons/ai";
import { RiEyeCloseLine } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function SignIn() {
  const [showPassword, setShowPassword] = useState(false);
  const isAuthenticated = useSelector(
    (state) => state.userAuth.isAuthenticated
  );
  const isWrongCreds = useSelector((state) => state.userAuth.isWrongCreds);

  const dispatch = useDispatch();
  const [userEmail, setEmail] = useState("");
  const [userPassword, setPassword] = useState("");
  const navigate = useNavigate();
  if (isAuthenticated) {
    navigate("/admin/dashboard");
    dispatch(changeAlert());
  }
  if (isWrongCreds) {
    toast.error("Wrong Credentials", {
      position: "top-right",
      autoClose: 2000, // Automatically close the toast after 2000 milliseconds (2 seconds)
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
    dispatch(changeAlert());
  }
  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full">
        <img className="mx-auto h-[5em] w-[5em]" src={ModMonLogo} alt="logo" />
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                value={userEmail}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
                className="block w-full rounded-md border-0 p-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
              <div className="relative mt-2 rounded-md shadow-sm">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  value={userPassword}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 p-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                  <button
                    type="button"
                    className="focus:shadow-outline-indigo p-1 focus:outline-none"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <AiOutlineEye className="h-7 w-7 cursor-pointer rounded-md p-[2px]" />
                    ) : (
                      <RiEyeCloseLine className="h-7 w-7 cursor-pointer rounded-md p-[2px]" />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                dispatch(
                  authPostBackend({
                    email_id: userEmail,
                    password: userPassword,
                  })
                );
              }}
            >
              Log in
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
