import RulesCard from "./components/RulesCard";
import { useEffect } from "react";
import CreateRule from "./components/CreateRule";
import apiCall from "axiosConfig/axiosConfig";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getRulesData } from "reduxConfig/slices/rulesSlice";
import UpdateRule from "./components/UpdateRule";
import { toggleCreateRuleModal } from "reduxConfig/slices/rulesSlice";
import StatChartRule from "./components/StatChartRule";
import { RingLoader } from "react-spinners";
import PaginationComp from "./components/paginationComp";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import DeleteModal from "./components/DeleteModal";
import { getSlackDatas } from "reduxConfig/slices/settingSlice";
import DateRunModal from "./components/DateRunModal";
import { updateRuleStatus } from "reduxConfig/slices/rulesSlice";
import Lottie from "lottie-react";
import noDataAnimationData from "assets/animation/noDataAnimation.json";
import urlParamsBuilder from "./components/helpers/getUrlParams";
import { toggleFilterModal } from "reduxConfig/slices/rulesSlice";
import RulesFilterModal from "./components/RulesFilter";
import { getRulesDataSkipLoad } from "reduxConfig/slices/rulesSlice";

const RulesTab = () => {
  const filterData = useSelector((state) => state.rulesData);
  const pageNumber = useSelector((state) => state.rulesData.pageNumber);
  const rulesData = useSelector((state) => state.rulesData.rulesData);
  const isLoading = useSelector((state) => state.rulesData.isLoading);
  const showCreateRuleModal = useSelector(
    (state) => state.rulesData.showCreateRuleModal
  );
  const showRuleModal = useSelector((state) => state.rulesData.showRuleModal);
  const showStatModal = useSelector(
    (state) => state.rulesData.showStatChartModal
  );
  const showDeleteModal = useSelector(
    (state) => state.rulesData.showDeleteModal
  );
  const showDateRunModal = useSelector(
    (state) => state.rulesData.showDateRunModal
  );
  const showFilterModal = useSelector(
    (state) => state.rulesData.showFilterModal
  );
  const error = useSelector((state) => state.rulesData.error);
  const checkStatus = useSelector((state) => state.rulesData.status);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRulesData());
  }, [pageNumber]);

  useEffect(() => {
    dispatch(getSlackDatas());
  }, []);

  useEffect(() => {
    if (checkStatus === "CREATE_RULE") {
      dispatch(
        updateAlert({
          alertText: "Create Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getRulesDataSkipLoad());
      dispatch(updateRuleStatus());
    }
    if (checkStatus === "UPDATE_RULE") {
      dispatch(
        updateAlert({
          alertText: "Update Success",
          alertType: "Success",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);
      dispatch(getRulesDataSkipLoad());
      dispatch(updateRuleStatus());
    }
  }, [checkStatus]);

  useEffect(() => {
    const body = document.body;

    if (
      showCreateRuleModal ||
      showRuleModal ||
      showStatModal ||
      showDeleteModal ||
      showDateRunModal ||
      showFilterModal
    ) {
      // Disable scrolling
      body.style.overflow = "hidden";
    } else {
      // Enable scrolling
      body.style.overflow = "auto";
    }

    return () => {
      // Clean up and enable scrolling when the component unmounts
      body.style.overflow = "auto";
    };
  }, [
    showCreateRuleModal,
    showRuleModal,
    showStatModal,
    showDeleteModal,
    showDateRunModal,
    showFilterModal,
  ]);

  useEffect(() => {
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${urlParamsBuilder(filterData)}`
    );
  });

  //Code Added by VigneshB on [20-09-2023] for overflow
  return (
    <div
      // className={
      //   "mt-4 " +
      //   (showCreateRuleModal ||
      //   showRuleModal ||
      //   showStatModal ||
      //   showDeleteModal ||
      //   showDateRunModal
      //     ? "h-[80vh] overflow-x-hidden overflow-y-hidden"
      //     : "")
      // }
      className={
        "mt-4 " +
        (showCreateRuleModal ||
        showRuleModal ||
        showStatModal ||
        showDeleteModal ||
        showDateRunModal ||
        showFilterModal
          ? ""
          : "")
      }
    >
      {error ? (
        <div className="mt-[10%] flex justify-center text-4xl font-bold text-red-600">
          {error}
        </div>
      ) : (
        <>
          <div className="relative top-[-8vh] left-[11vw] flex w-[70vw] justify-end">
            <div className=" mr-4">
              <button
                type="button"
                onClick={(e) => {
                  dispatch(toggleFilterModal());
                }}
                className={`bg-transparent hover:border-transparent h-12 rounded-xl border border-[#4318FF] p-3 py-2 px-6 text-center font-semibold text-blue-700 hover:bg-[#4318FF] hover:text-white ${"w-[6vw]"}`}
              >
                Filter
              </button>
            </div>
            <div className="mr-9 flex flex-row justify-end">
              <button
                class="bg-transparent hover:border-transparent items-center rounded-xl border border-[#4318FF] py-2 px-4 font-semibold  text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                onClick={() => {
                  dispatch(toggleCreateRuleModal());
                }}
              >
                Add Rule
              </button>
            </div>
          </div>{" "}
          {isLoading ? (
            <div className="mt-[10%] flex h-full w-full items-center justify-center">
              <RingLoader
                loading={true}
                size={200}
                color="#4318FF"
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <div className="-mt-4 grid  h-full grid-cols-1 gap-2 2xl:grid-cols-3">
                {rulesData.length ? (
                  rulesData.map((key) => <RulesCard rulesData={key} />)
                ) : (
                  <></>
                )}
              </div>
              {rulesData.length ? (
                <PaginationComp />
              ) : (
                <>
                  <div className="flex items-center  justify-center">
                    <Lottie
                      animationData={noDataAnimationData}
                      loop={true}
                      autoplay={true}
                      style={{ width: 500, height: 500 }}
                      speed={1.5}
                    />
                    {/* <div className="text-3xl font-bold">No Rules Found</div> */}
                  </div>
                </>
              )}
            </>
          )}
          {showCreateRuleModal ? <CreateRule /> : <></>}
          {showRuleModal ? <UpdateRule /> : <></>}
          {showStatModal ? <StatChartRule /> : <></>}
          {showDeleteModal ? <DeleteModal /> : <></>}
          {showDateRunModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <DateRunModal />
              </div>{" "}
            </div>
          ) : (
            <></>
          )}
          {showFilterModal ? (
            <div className="mt-1/4  fixed top-0 z-30 ">
              <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
                <RulesFilterModal />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default RulesTab;
