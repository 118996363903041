import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { updateNewUploadApk } from "reduxConfig/slices/resultSlice";
import { clearUploadApk } from "reduxConfig/slices/resultSlice";
import { toggleShowUploadModal } from "reduxConfig/slices/resultSlice";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { createDriveUploads } from "reduxConfig/slices/resultSlice";
import ClientSelect from "./ClientSelect";
import BrandSelectUpload from "./BrandSelectUpload";
import apiCall from "axiosConfig/axiosConfig";
import { createFileUploads } from "reduxConfig/slices/resultSlice";
import TagInput from "./TagInput";
import DriveTagInput from "./DriveTagInput";

function UploadModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);
  const checkClientSelect = useSelector(
    (state) => state.resultsData.uploadApk.client_id
  );
  const checkfileSelect = useSelector(
    (state) => state.resultsData.uploadApk.apk_file
  );
  const checksourceSelect = useSelector(
    (state) => state.resultsData.uploadApk.discoverd_source
  );
  const checksampleTypeSelect = useSelector(
    (state) => state.resultsData.uploadApk.is_official
  );
  const checkFolderId = useSelector(
    (state) => state.resultsData.uploadApk.folder_id
  );
  const typeee = useSelector((state) => state.resultsData.uploadApk.type);

  const uploadData = useSelector((state) => state.resultsData.uploadApk);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(toggleShowUploadModal());
      dispatch(clearUploadApk());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  // function handleFileChange(event) {
  //   const selectedFile = event.target.files[0];
  //   if (selectedFile) {
  //     dispatch(
  //       updateNewUploadApk({
  //         apk_file: selectedFile,
  //       })
  //     );
  //   } else {
  //   }
  // }
  function handleFileChange(event) {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      dispatch(
        updateNewUploadApk({
          apk_file: selectedFiles,
        })
      );
    } else {
    }
  }

  const handleLocalUpload = () => {
    // Validate required fields
    if (
      checkClientSelect.length == 0 ||
      checkfileSelect.length == 0 ||
      checksourceSelect.length == 0 ||
      !typeee
    ) {
      setValidationError(true);

      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    // Perform the upload
    dispatch(toggleShowUploadModal());
    dispatch(createFileUploads());
  };

  const handleDriveUpload = () => {
    // Validate required fields
    if (checkClientSelect.length == 0 || !checkFolderId || !typeee) {
      setValidationError(true);

      dispatch(
        updateAlert({
          alertText: "Please Fill The Required Fields",
          alertType: "Error",
        })
      );
      setTimeout(() => {
        dispatch(toggleAlert());
      }, 2000);

      return;
    }

    // Clear validation error state
    setValidationError(false);

    // Perform the upload
    dispatch(toggleShowUploadModal());
    dispatch(createDriveUploads());
  };

  return (
    <div className="fixed top-0 z-10 h-full w-screen rounded-lg bg-gray-600 bg-opacity-30 backdrop-blur-sm">
      <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[37%] z-20 flex-col justify-center ">
        <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
          <div class="mx-8 max-h-[100vh] w-[30vw] overflow-y-auto rounded-xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
            <div className="flex items-center justify-center text-2xl font-semibold text-[#000000]">
              Upload APKs
            </div>

            <div className="mt-3 ">
              <div className="mb-1 flex items-center">
                <label className="text-xl font-semibold" htmlFor="sampleSelect">
                  Type of Upload
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can select a Upload Type"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <select
                onChange={(e) =>
                  dispatch(
                    updateNewUploadApk({
                      type: e.target.value,
                    })
                  )
                }
                id="sampleSelect"
                className="w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
              >
                {/* <option value="NONE">Unknown</option> */}
                <option value="local">Local Upload</option>
                <option value="drive">Drive Upload</option>
              </select>
            </div>

            {uploadData.type === "local" ? (
              <>
                <div className="mt-3 ">
                  <div className="mb-1 flex items-center">
                    <label
                      className="text-xl font-semibold"
                      htmlFor="fileInput"
                    >
                      Choose File
                      <sup className="text-red-500">&#8727;</sup>
                    </label>
                    <div className="ml-1 text-lg font-normal">
                      <a
                        data-tooltip-id="my-tooltip-title"
                        data-tooltip-html="Here you can upload</br>a apk file from you have"
                        data-tooltip-place="right"
                      >
                        <BsQuestion
                          className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                          color="#4318FF"
                        />
                      </a>
                      <Tooltip className="z-10" id="my-tooltip-title" />
                    </div>
                  </div>
                  <input
                    type="file"
                    id="fileInput"
                    accept=".apk"
                    multiple
                    className="w-full border border-[#000000] p-2 py-2 px-4 text-[#000000] focus:outline-none"
                    onChange={(e) => handleFileChange(e)}
                  />
                </div>
                <div class="mt-3">
                  <label class="flex items-center text-xl font-semibold">
                    Discovered Source
                    <sup className="text-red-500">&#8727;</sup>
                    <div className="text-base font-normal">
                      <a
                        data-tooltip-id="my-tooltip-desc"
                        data-tooltip-html="Here you can add Discovered Source</br>for your apk files and you can paste </br> valued separated by comma,</br> and it will auto split after press enter"
                        data-tooltip-place="right"
                      >
                        <BsQuestion
                          className=" ml-1 h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                          color="#4318FF"
                        />
                      </a>
                      <Tooltip className="z-10" id="my-tooltip-desc" />
                    </div>
                  </label>
                  <TagInput />
                </div>
              </>
            ) : (
              <>
                <div class="flex flex-col">
                  <label class="flex items-center text-xl font-semibold leading-loose">
                    Google Sheet - Folder ID
                    <sup className="text-red-500">&#8727;</sup>
                    <div className="text-base font-normal">
                      <a
                        data-tooltip-id="my-tooltip"
                        data-tooltip-html="Here you can add a google sheet - Folder ID </br> & Click The Check Button To verify ID </br> Is Valid or Invalid"
                        data-tooltip-place="right"
                      >
                        <AiOutlineInfoCircle className="ml-1 h-6 w-6  cursor-pointer rounded-md p-[2px] text-[#4318FF]" />
                      </a>
                      <Tooltip className="z-10" id="my-tooltip" />
                    </div>
                  </label>
                  <div className="flex justify-between">
                    <input
                      type="text"
                      class="w-full border border-[#000000] px-4 py-2  text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                      placeholder="Add Folder ID"
                      value={uploadData.folder_id}
                      onChange={(e) =>
                        dispatch(
                          updateNewUploadApk({
                            folder_id: e.target.value,
                          })
                        )
                      }
                    />
                    {checkFolderId !== null && checkFolderId !== "" && (
                      <button
                        className="flex w-20 items-center justify-center border  border-[#4318FF] bg-[#4318FF] text-white sm:text-sm "
                        onClick={(e) => {
                          apiCall(
                            `/sw_misc/check_drive/${checkFolderId}`,
                            "get",
                            {}
                          )
                            .then((response) => {
                              dispatch(
                                updateAlert({
                                  alertText: response.data.message,
                                  alertType: "Success",
                                })
                              );
                              setTimeout(() => {
                                dispatch(toggleAlert());
                              }, 2000);
                            })
                            .catch((error) => {
                              dispatch(
                                updateAlert({
                                  alertText: error.response.data.error,
                                  alertType: "Error",
                                })
                              );
                              setTimeout(() => {
                                dispatch(toggleAlert());
                              }, 2000);
                            });
                        }}
                      >
                        check
                      </button>
                    )}
                  </div>
                </div>

                <div class="mt-3">
                  <label class="flex items-center text-xl font-semibold">
                    Discovered Source
                    <sup className="text-red-500">&#8727;</sup>
                    <div className="text-base font-normal">
                      <a
                        data-tooltip-id="my-tooltip-desc"
                        data-tooltip-html="Here you can add Discovered Source</br>for your apk files and you can paste </br> valued separated by comma,</br> and it will auto split after press enter"
                        data-tooltip-place="right"
                      >
                        <BsQuestion
                          className=" ml-1 h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                          color="#4318FF"
                        />
                      </a>
                      <Tooltip className="z-10" id="my-tooltip-desc" />
                    </div>
                  </label>
                  <DriveTagInput />
                </div>
              </>
            )}

            <div className="mt-3">
              <div className="mb-1 flex items-center">
                <label className="text-xl font-semibold" htmlFor="clientInput">
                  Client
                  <sup className="text-red-500">&#8727;</sup>
                </label>
                <div className="ml-1 text-lg font-normal">
                  <a
                    data-tooltip-id="my-tooltip-title"
                    data-tooltip-html="Here you can assign</br>a client your apk"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-title" />
                </div>
              </div>
              <ClientSelect />
            </div>

            {checkClientSelect.length != 0 && (
              <div className="mt-3">
                <label className="text-xl font-semibold" htmlFor="brandInput">
                  Brand
                </label>
                <BrandSelectUpload />
              </div>
            )}

            <div class="mt-3">
              <label class="flex items-center text-xl font-semibold">
                Description
                <div className="text-base font-normal">
                  <a
                    data-tooltip-id="my-tooltip-desc"
                    data-tooltip-html="Here you can add a</br> Description for your apk"
                    data-tooltip-place="right"
                  >
                    <BsQuestion
                      className=" ml-1 h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                      color="#4318FF"
                    />
                  </a>
                  <Tooltip className="z-10" id="my-tooltip-desc" />
                </div>
              </label>
              <textarea
                class="max-h-40 w-full border border-[#000000] px-4 py-2 text-[#000000] focus:border-gray-900 focus:outline-none focus:ring-gray-500 sm:text-sm"
                placeholder="Add Description"
                value={uploadData.description}
                onChange={(e) =>
                  dispatch(
                    updateNewUploadApk({
                      description: e.target.value,
                    })
                  )
                }
              />
            </div>

            <div className="flex items-center justify-center space-x-4 pt-4">
              <button
                className="flex w-1/4 items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white focus:outline-none"
                onClick={(e) => {
                  if (uploadData.type === "local") {
                    handleLocalUpload();
                  } else {
                    handleDriveUpload();
                  }
                }}
              >
                Upload
              </button>
              <button
                className="flex w-1/4 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white focus:outline-none"
                onClick={(e) => {
                  dispatch(toggleShowUploadModal());
                  dispatch(clearUploadApk());
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadModal;
