import React, { useEffect } from "react";
import { useState } from "react";
import { MultiSelect as Selector } from "react-multi-select-component";
import { useDispatch } from "react-redux";
import { updateNewRule } from "reduxConfig/slices/rulesSlice";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { updateExistingRule } from "reduxConfig/slices/rulesSlice";
function MultiSelect() {
  const brandData = useSelector((state) => state.brandsData.brandData);
  const [formattedData, setBrandData] = useState([]);
  const whichModal = useSelector((state) => state.rulesData.showRuleModal);
  const componentDefaultValues = useSelector((state) =>
    whichModal
      ? state.rulesData.updatedRuleData.brandIds
      : state.rulesData.newRuleData.brandIds
  );
  const dispatch = useDispatch();
  useEffect(() => {
    let stateData = [];
    for (let elements in brandData) {
      stateData.push({
        value: brandData[elements]._id,
        label: brandData[elements].brand_name,
      });
    }
    setBrandData(stateData);
  }, []);

  return (
    <div>
      {formattedData.length ? (
        <Selector
          className="multiselect"
          options={formattedData}
          value={componentDefaultValues}
          onChange={(selectedOption) => {
            whichModal
              ? dispatch(updateExistingRule({ brandIds: selectedOption }))
              : dispatch(updateNewRule({ brandIds: selectedOption }));
          }}
          labelledBy={"Select"}
          isCreatable={false}
        />
      ) : (
        <div className="font-bold text-red-500 text-sm">
        No brands have been created. Please create a brand.
      </div>
      )}
    </div>
  );
}

export default MultiSelect;
