import React, { useState } from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateAlert } from "reduxConfig/slices/alertSlice";
import { toggleAlert } from "reduxConfig/slices/alertSlice";
import { BsQuestion } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { updateSyncClient } from "reduxConfig/slices/officialApkSlice";
import { toggleShowSyncOptionModal } from "reduxConfig/slices/officialApkSlice";
import ClientSelectSync from "./ClientSelectSync";
import { updateSyncType } from "reduxConfig/slices/officialApkSlice";
import { clearClientSync } from "reduxConfig/slices/officialApkSlice";
import { updateClientSync } from "reduxConfig/slices/officialApkSlice";

const SyncOptionModal = () => {
  const dispatch = useDispatch();
  const brandData = useSelector((state) => state.brandsData.clientData);

  const [formattedData, setBrandData] = useState([]);

  const checkClients = useSelector(
    (state) => state.officialApksData.clientsSync
  );

  const checkSyncType = useSelector((state) => state.officialApksData.syncType);

  const modalRef = useRef(null);
  const [validationError, setValidationError] = useState(false);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch(clearClientSync());
      dispatch(toggleShowSyncOptionModal());
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    let stateData = [];
    for (let elements in brandData) {
      stateData.push({
        value: brandData[elements]._id,
        label: brandData[elements].client_name,
      });
    }

    setBrandData(stateData);
  }, [brandData]);
  const handleUpload = () => {
    // Validate required fields
    if (checkSyncType === "syncSelect") {
      if (!checkClients.length) {
        setValidationError(true);

        dispatch(
          updateAlert({
            alertText: "Please Fill The Required Fields",
            alertType: "Error",
          })
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);

        return;
      }
    }

    if (checkSyncType === "syncAll") {
      if (formattedData.length > 0) {
        const selectedValues = formattedData.map((option) => option.value);
        dispatch(updateClientSync(selectedValues));
      } else {
        dispatch(
          updateAlert({
            alertText: "There is no clients for this user",
            alertType: "Error",
          })
        );
        setTimeout(() => {
          dispatch(toggleAlert());
        }, 2000);
      }
    }

    // Clear validation error state
    if (formattedData.length > 0) {
      setValidationError(false);

      // Perform the upload
      dispatch(updateSyncClient());
      dispatch(toggleShowSyncOptionModal());
    }
  };

  return (
    <div className="mt-1/4  fixed top-0 z-30 ">
      <div className="fixed inset-0 h-screen w-[100vw] items-center justify-center bg-gray-600 bg-opacity-30 backdrop-blur-sm">
        <div class=" transform-translate-x-[-50%] w-3/10 absolute top-0 left-[35%] z-20 flex-col justify-center ">
          <div class="sm:max-w-x relative py-3 sm:mx-auto" ref={modalRef}>
            <div class="mx-8 max-h-[100vh] w-[30vw]  rounded-3xl bg-white px-4 py-10 shadow sm:p-10 md:mx-0">
              <div class="">
                <div class="flex items-center justify-center">
                  <h2 class="text-#000000 block  pl-2 text-2xl font-semibold">
                    Sync Data
                  </h2>
                </div>

                <div class="text-#000000 py-8 text-base leading-6 sm:text-lg sm:leading-7">
                  <div className="mb-1 flex items-center">
                    <label
                      className="text-xl font-semibold"
                      htmlFor="sampleSelect"
                    >
                      Sync Type
                      <sup className="text-red-500">&#8727;</sup>
                    </label>
                    <div className="ml-1 text-lg font-normal">
                      <a
                        data-tooltip-id="my-tooltip-title"
                        data-tooltip-html="Here you can select a Sync Type </br> for sync all it should sync all the clients </br> & for sync select it should sync for the separat client"
                        data-tooltip-place="right"
                      >
                        <BsQuestion
                          className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                          color="#4318FF"
                        />
                      </a>
                      <Tooltip className="z-10" id="my-tooltip-title" />
                    </div>
                  </div>
                  <select
                    onChange={(e) => dispatch(updateSyncType(e.target.value))}
                    id="sampleSelect"
                    className="w-full rounded border border-[#000000] bg-white p-2 py-2 px-4 text-sm text-[#000000] focus:outline-none"
                  >
                    <option value="syncAll">Sync All</option>
                    <option value="syncSelect">Sync Select</option>
                  </select>
                  {checkSyncType === "syncAll" ? (
                    <></>
                  ) : (
                    <>
                      {" "}
                      <div class="flex flex-col text-xl">
                        <div className="flex items-center">
                          <label class="font-semibold leading-loose">
                            Select Client
                            <sup className="text-red-500">&#8727;</sup>
                          </label>
                          <div className="ml-1 text-lg font-normal">
                            <a
                              data-tooltip-id="my-tooltip-title"
                              data-tooltip-html="Here you can select </br> a client to sync"
                              data-tooltip-place="right"
                            >
                              <BsQuestion
                                className=" h-6 w-6 cursor-pointer rounded-xl border-2 border-[#4318FF] bg-white p-[2px]"
                                color="#4318FF"
                              />
                            </a>
                            <Tooltip className="z-10" id="my-tooltip-title" />
                          </div>
                        </div>
                        <ClientSelectSync />
                      </div>
                    </>
                  )}
                </div>
                <div class="flex items-center justify-center space-x-4 ">
                  <button
                    className="flex w-20  items-center justify-center rounded-xl bg-[#4318FF] px-4 py-3 text-white "
                    onClick={() => {
                      handleUpload();
                    }}
                  >
                    Process
                  </button>
                  <button
                    class="flex w-20 items-center justify-center rounded-xl border border-[#4318FF] px-4 py-3 text-[#4318FF] hover:bg-[#4318FF] hover:text-white"
                    onClick={() => {
                      dispatch(clearClientSync());
                      dispatch(toggleShowSyncOptionModal());
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SyncOptionModal;
